import { JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import { SortDirectionEnum } from '@sympli/ui-framework/components/table';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { GroupedOptions } from 'src/components/multiple-select-checkbox/MultipleSelectCheckbox';
import { WorkspaceDetailBoxDataModel } from 'src/containers/shared/workspace-detail-box/models';
import { AllocatedUser, CurrentPartiesModel, PaginatedItemsModel, SettlementDateTimeModel, TableFiltersModel, TitleReferenceEntityModel } from 'src/models';
import { ApiStatus } from 'src/utils/http';
import { WorkspaceFilterQueryParamsModel } from '../financial-workspaces/models';

export type FetchDashboardFeedRequestModel = WorkspacesSummaryApiRequest;
export type FetchDashboardFeedResponseModel = PaginatedItemsModel<WorkspaceSummaryModel>;

export interface WorkspaceFeedDetailDataModel extends WorkspaceDetailBoxDataModel {
  participants: WorkspaceParticipantApiResponse[];
}

export interface WorkspacesSummaryApiResponse extends PaginatedItemsModel<WorkspaceSummaryModel> {}

export interface WorkspaceSummaryModel {
  id: string;
  sympliId: string;
  isLocked: boolean;
  jurisdiction: LookupEnumModel<JurisdictionsEnum>;
  lodgementCaseStatus: LookupEnumModel<LodgementCaseStatusEnum>;
  workspaceStatus: LookupEnumModel<WorkspaceStatusEnum>;
  workspaceType: LookupEnumModel<WorkspaceTypeEnum>;
  settlementDateTime?: SettlementDateTimeModel;
  expectedLodgementDate?: SettlementDateTimeModel;
  // TODO xinxin to add this property to dashboard items so we can remove this optional flag
  // numberOfTasks?: number; // number of tasks for all participants in the workspace
  createdDate: WorkspaceDateTimeModel;
  lastUpdatedDate: WorkspaceDateTimeModel;

  // different from the basic endpoint
  titleReferences: Array<TitleReferenceEntityModel>;
  currentParties: CurrentPartiesModel;
  matter: string;
  participants: WorkspaceParticipantApiResponse[]; // all
  propertyAddress?: string;
  registered?: string;
  isIntegrated?: boolean;
  currentSubscriberParticipants?: WorkspaceParticipantApiResponse[];
  messagesCount?: number;
  requiresStampDuty?: boolean;
  isRollover?: boolean;
  userLastAccessedDate?: WorkspaceDateTimeModel;
}

export interface WorkspaceSummaryDetailModel extends WorkspaceSummaryModel {
  acceptedParticipants: WorkspaceParticipantApiResponse[]; // calculated in saga
  awaitingParticipants: WorkspaceParticipantApiResponse[]; // calculated locally by selector (in saga)
}

export type WorkspacesSummaryApiRequest = TableFiltersModel & WorkspaceFilterQueryParamsModel;

export interface UpdateDashboardAllocatedUserPayload {
  assignedParticipants: { [participantId: string]: boolean };
  allocatedUser: AllocatedUser;
  workspaceId: string;
}

// generic state shared across other workspace dashboards
export interface WorkspaceDashboardFeedDetailState {
  data?: WorkspaceSummaryModel | null;
  status: ApiStatus;
  error?: string;
}

// generic initialState shared across other workspace dashboards
export const dashboardFeedDetailInitialState: WorkspaceDashboardFeedDetailState = {
  data: undefined,
  status: 'idle',
  error: undefined
};

// generic state shared across other workspace dashboards
export interface WorkspaceDashboardFeedState {
  selectedRowIndex: number;
  selectedWorkspaceId?: string;

  items: Array<WorkspaceSummaryModel>;
  error?: string;
  status: ApiStatus;
  totalCount: number;

  query: WorkspacesSummaryApiRequest;
}

// generic initialState shared across other workspace dashboards
export const dashboardFeedInitialState: WorkspaceDashboardFeedState = {
  selectedRowIndex: 0,

  items: [],
  status: 'idle',
  error: undefined,

  totalCount: 0,

  query: {
    settlingPeriod: undefined,
    workspaceStatusIds: undefined,
    jurisdictionId: undefined,
    workspaceRoleIds: undefined,
    groupId: undefined,
    userId: undefined,
    hasUnreadMessages: undefined,
    searchTerm: undefined,
    sortBy: 'tillSettlement',
    sortOrder: SortDirectionEnum.asc,
    pageSize: 10,
    pageNumber: 1 // service page number is index from 1
  }
};

export interface WorkspaceSummaryCountApiResponse {
  totalCurrent: number;
  totalArchived: number;
}

export type GroupOptionModel = {
  id: string;
  name: string;
  jurisdictions: LookupEnumModel<JurisdictionsEnum>[];
};

export type WorkspaceGroupsApiResponse = GroupOptionModel[];
export type SubscriberAssignableGroupsApiResponse = GroupOptionModel[];
export type WorkspaceAssignableGroupsApiResponse = GroupOptionModel[];
export type ForwardableGroupsApiResponse = GroupOptionModel[];

export type SingleGroupMembersRequestModel = {
  groupIds: string;
  excludeInactive: boolean;
};

export type WorkspaceGroupRequestModel = {
  includeUnassignableGroups: boolean;
};

/**
 * Tickle.ClientService.Client.Model.Group.GroupNameDto
 */
export interface GroupNameDtoModel {
  id: string;
  parentId?: string;
  name: string;
  isWorkspaceAssignable: boolean;
  isPublic: boolean;
  isSearchable: boolean;
  refSubscriberId?: string;
  emailAddress?: string;
  jurisdictions: LookupEnumModel<JurisdictionsEnum>[];
  groupDocumentWorkflow?: any; // not used
}

export enum AssignmentTypeEnum {
  AssignedToMe = 0,
  AssignedToGroup = 1,
  ReAssigned = 2
}

export interface DashboardDateRangeModel {
  start: Date | string | null;
  end: Date | string | null;
}

export enum DashboardSortDirectionEnum {
  asc = 0,
  desc = 1
}

/**
 * Tickle.Application.Service.Shared.DateTimeInfo
 */
export interface DashboardDateTimeInfoModel {
  userLocalTime: string;
  workspaceLocalTime: string;
  userTimezoneAbbreviation: string;
  workspaceTimezoneAbbreviation: string;
}

export const UNASSIGNED_OPTION: LookupItemModel<string>[] = [
  {
    id: '00000000-0000-0000-0000-000000000000',
    name: 'Unassigned'
  }
];

type FilterType = 'groupMultipleCheckbox' | 'multipleCheckbox' | 'singleCheckbox' | 'multipleSearchCheckbox' | 'datetimePicker';

export interface FilterDataModel {
  name: string; // unique identify
  type: FilterType;
  label: string;
  searchBoxLabel?: string;
  placeHolder?: string;
  values?: (string | number)[];
  GroupOptions?: GroupedOptions<number>[];
  options?: LookupItemModel<number | string>[]; // Datetime component is undefined
  unit?: string;
  additionalOptionsOnTop?: LookupItemModel<number | string>[];
  defaultOpen?: boolean;
}

export enum DashboardTypeEnum {
  Standalone = 'standalone-dashboard',
  Financial = 'financial-dashboard',
  Invitation = 'invitation-dashboard'
}

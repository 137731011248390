import React from 'react';

import classNames from 'classnames';
import { DebouncedFunc } from 'lodash-es/debounce';
import { useNavigate } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';

import { SearchBoxUpdatedFeedState } from 'src/containers/shared/app-bar/reducers/searchBoxUpdated';
import { SearchBoxUpdatedArchivedFeedState } from 'src/containers/shared/app-bar/reducers/searchBoxUpdatedArchived';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { colors } from 'src/theme';
import { useDialogControlContext } from '../../GlobalSearchContext';
import { SearchCategoryEnum, SearchDashboardPageTabsEnum } from '../../models';
import { columns, Data, FormattersProps } from './models';
import { TABLE_WIDTH, useStyles } from './styles';

const FixedHeaderContent = () => {
  const classes = useStyles();

  return (
    <TableRow
      sx={{
        display: 'flex',
        width: TABLE_WIDTH,
        height: 60,
        gap: '37px',
        borderRadius: 0,
        border: 'none',
        borderTop: '1px solid #292F36',
        borderBottom: '1px solid #292F36',
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: '#FFF',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {columns.map(column => (
        <TableCell sx={{ border: 'none' }} className={classes.noPaddingX} key={column.dataKey} variant="head" align="left" style={{ width: column.width }}>
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
};

const ItemContent = (_index: number, row: Data) => {
  return (
    <>
      {columns.map(column => {
        const props: FormattersProps = { row, value: row[column.dataKey], columnName: column.dataKey };
        return (
          <TableCell
            key={column.dataKey}
            align="left"
            sx={{
              padding: 0,
              border: 'none',
              backgroundColor: '#FFF'
            }}
            style={{ width: column.width }}
          >
            {column.formatter ? React.createElement(column.formatter, props) : row[column.dataKey]}
          </TableCell>
        );
      })}
    </>
  );
};

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => {
    const classes = useStyles();
    return <TableContainer className={classes.scrollBar} component={Paper} {...props} ref={ref} />;
  }),
  Table: props => <Table {...props} />,
  TableHead,
  TableRow: ({ item, ...props }) => {
    const navigate = useNavigate();
    const context = useDialogControlContext();

    return (
      <TableRow
        {...props}
        sx={{
          display: 'flex',
          width: TABLE_WIDTH,
          height: 40,
          gap: '37px',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '8px',
          paddingRight: '8px',
          border: 'none',
          borderBottom: '1px solid #EAEAEB',
          '&:hover': {
            background: colors.GREEK_WATERS_TRANSLUCENT,
            cursor: 'pointer',
            '& td': {
              background: colors.GREEK_WATERS_TRANSLUCENT
            }
          }
        }}
        onClick={() => {
          if (context) {
            const { setIsOpen } = context;
            setIsOpen(false);
          }
          const { workspaceId, participantId } = item;
          navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
        }}
      />
    );
  },

  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => {
    return <TableBody {...props} ref={ref} />;
  }),
  TableFoot: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableFooter sx={{ position: 'relative !important' }} {...props} ref={ref}></TableFooter>)
};

interface SearchTableProps {
  searchTerm: string;
  searchesFeed: SearchBoxUpdatedFeedState | SearchBoxUpdatedArchivedFeedState;
  selectedTab: SearchDashboardPageTabsEnum;
  fetchSearchBoxWithDebounce: DebouncedFunc<(selectedTab: SearchDashboardPageTabsEnum, searchTerm?: string, searchCategoryId?: SearchCategoryEnum, pageNumber?: number) => void>;
}

function SearchTable({ searchesFeed, selectedTab, fetchSearchBoxWithDebounce, searchTerm }: SearchTableProps) {
  const classes = useStyles();
  const { isLoading, error, totalCount, newSearchItems: items, pageNumber, totalPages, isRefetching } = searchesFeed;

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <InlineLoader alignCenter />
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (items === undefined) {
    return null;
  }

  if (items?.length === 0) {
    return (
      <Typography variant="body2" className={classes.typographyRoot}>
        There are no {selectedTab === SearchDashboardPageTabsEnum.Archived ? 'archived' : 'active'} workspaces that meet your search criteria.
      </Typography>
    );
  }

  const rows: Array<Data> = items?.map(x => {
    return {
      sympliId: x.sympliId,
      address: x.titleAddresses,
      reference: x.matter,
      jurisdiction: x.jurisdiction,
      title: x.titles,
      id: x.id,
      customer: x.clientNames,
      workspaceId: x.workspaceId,
      participantId: x.participantId,
      searchHighlights: x.searchHighlights,
      workspaceType: x.workspaceType
    };
  });

  const FixedFooterContent = () => {
    const handleOnClick = () => {
      fetchSearchBoxWithDebounce(selectedTab, searchTerm, SearchCategoryEnum.All, pageNumber + 1);
    };

    return (
      <TableRow
        sx={{
          display: 'flex',
          width: TABLE_WIDTH,
          height: 70,
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '8px',
          paddingRight: '8px',
          border: 'none',
          borderBottom: '1px solid #292F36'
        }}
      >
        <TableCell className={classNames(classes.noPaddingX, classes.noBorder)}>
          <Button variant="outlined" className={classes.button} onClick={handleOnClick} disabled={isRefetching}>
            {isRefetching ? 'Loading...' : 'Load more'}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  /**
   * When we click on the load more button, we want to show user the newly loaded data, so we need to calculate the position for them.
   * For example, on initial loading, pageNumber is 1, and position should be 0, means the first item of data array
   * on next load more, pageNumber should be 2, and position would be 0 + (2 - 1) * 15, the 16th item of data array should be presented to user
   * 15 is the default fetch data size for 1920px screen,
   * We need a hook to tell us which screen we are on, so it will dynamically do this for us.
   * TODO replace 15 depending on screen size with the hook value. https://tickleme.atlassian.net/browse/WEB-33983
   */
  const initialPosition = 0 + (pageNumber - 1) * 15;

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.typography}>
        <span className="font-[700]">{totalCount}</span> {totalCount === 1 ? 'workspace' : 'workspaces'} found
      </Typography>
      <Paper className={classNames(classes.paper, 'medium-screen:max-h-[452px] large-screen:max-h-[657px]')}>
        <TableVirtuoso //
          data={rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={FixedHeaderContent}
          fixedFooterContent={pageNumber < totalPages ? FixedFooterContent : null}
          itemContent={ItemContent}
          initialTopMostItemIndex={initialPosition}
        />
      </Paper>
    </div>
  );
}

export default React.memo(SearchTable);

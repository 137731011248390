import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 32
    },
    cardMargin: {
      '& + &': {
        marginLeft: 24,
        '@media (min-width:1536px) and (max-width:1920px)': {
          marginLeft: 22
        },
        '@media (min-width:1920px)': {
          marginLeft: 26
        }
      }
    },
    cardRoot: {
      width: 120,
      height: 78,
      padding: '16px 4px',
      borderRadius: 11,
      background: colors.NEUTRAL_000,
      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)', // This is to remove default MUI box shadow
      '@media (min-width:1536px) and (max-width:1920px)': {
        width: 140,
        height: 78,
        padding: '16px 10px'
      },
      '@media (min-width:1920px)': {
        width: 160,
        height: 78,
        padding: '16px 18px'
      },
      position: 'relative'
    },
    hoverEffect: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    cardContentRoot: {
      padding: 0,
      transition: 'margin-top 0.3s ease',
      '&:hover': {
        marginTop: -10
      },
      /**
       * Material UI card component has paddingBottom 24px for last child,
       * in our case, we also want this even not for last child to align our components
       */
      '&:not(:last-child)': {
        paddingBottom: '24px'
      }
    },
    cardTitle: {
      color: colors.NEUTRAL_1000,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-medium',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: 1,
      textTransform: 'uppercase',
      minHeight: 20
    },
    cardNumber: {
      color: colors.GREEK_WATERS_DARK,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-xbold',
      fontSize: 40,
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '46px',
      '@media (min-width:1920px)': {
        fontSize: 44
      }
    },
    cardStatus: {
      color: colors.SYMPLI_GREEN,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-xbold',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '16px',
      '@media (min-width:1920px)': {
        lineHeight: '20px',
        fontSize: 16
      },
      '@media (min-width:1536px) and (max-width:1920px)': {
        lineHeight: '20px'
      }
    },
    cardAction: {
      color: colors.GREEK_WATERS_DARK,
      textAlign: 'center',
      fontFamily: 'volkswagen_serial-xbold',
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '16px',
      '@media (min-width:1920px)': {
        lineHeight: '20px'
      }
    },
    cardToday: {
      color: colors.SUNNY_DAY_DARK
    },
    rowStyle: {
      transition: 'box-shadow 0.3s ease',
      '&:hover': {
        boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.07)'
      }
    },
    separatorStyle: {
      height: 58,
      width: 2,
      border: 'none',
      margin: 0,
      background: colors.NEUTRAL_100,
      borderRadius: 5
    },
    extraBox: {
      width: 20,
      height: 20,
      borderRadius: 10,
      color: colors.NEUTRAL_1000,
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      right: 8,
      '@media (min-width:1920px)': {
        right: 16
      },
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    settledBox: {
      background: colors.GREEK_WATERS_TRANSLUCENT,
      top: 16
    },
    rebookBox: {
      background: colors.WATERMELON_LITE,
      top: 40
    }
  }),
  {
    name: 'AggregatorCard'
  }
);

import * as React from 'react';

import { useDispatch } from 'react-redux';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { getDefaultLodgementCaseId, resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import { useLinkedWorkspaces } from 'src/containers/shared/linked-workspace-list/reducer';
import { findCurrentWorkspaceParticipantSelector, isCurrentUserWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { useWorkspaceReportsFeed } from '../../shared/detail/components/tab-workspace-report/reducer';
import { useWorkspaceBasicInfo } from '../../shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from '../../shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from '../../shared/detail/reducers/workspaceDocuments';
import { useWorkspaceParticipants } from '../../shared/detail/reducers/workspaceParticipants';
import { useWorkspaceTasks } from '../../shared/detail/reducers/workspaceTasks';
import {
  lodgeOnlyWorkspaceLodgementErrorsSelector,
  orderedAvailableParticipantsSelector,
  titleReferenceRisChangedSetSelector,
  titleReferenceTacChangedSetSelector,
  WorkspaceIssuesModel
} from '../../shared/detail/selectors';
import LodgementDetail from './LodgementDetail';

function LodgementDetailContainer(props: { workspaceId: string; participantId: string }) {
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId } = props;
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const linkedWorkspacesState = useLinkedWorkspaces(workspaceId);
  const workspaceReportsState = useWorkspaceReportsFeed(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);
  const primaryLodgementCaseId = getDefaultLodgementCaseId(workspaceBasicInfoState.detail?.lodgementCases);
  const allDocumentsSigned = workspaceDocumentsState.items.filter(x => x.lodgementCaseId === primaryLodgementCaseId).every(x => x.isFullySignedByAllParticipants);
  const profileState = useProfile();
  const isMLCEnabled = useFeatureFlag(FeatureToggleEnum.mlcEnabled);

  const isCurrentUserWorkspaceParticipant: boolean = isCurrentUserWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState,
    workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
    workspaceTypeId: workspaceBasicInfoState.detail?.workspaceTypeId
  });
  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const workspaceParticipants = orderedAvailableParticipantsSelector({
    //
    workspaceParticipants: workspaceParticipantsState
  });

  const workspaceIssues: WorkspaceIssuesModel = lodgeOnlyWorkspaceLodgementErrorsSelector(
    //
    workspaceDetailState.detail?.lodgementDetails[0]?.lodgementCompliances
  );

  // updated ris info
  const titleReferenceRisChangedSet = titleReferenceRisChangedSetSelector(
    //
    workspaceDetailState.detail?.titleReferences
  );
  const titleReferenceTacChangedSet = titleReferenceTacChangedSetSelector(
    //
    workspaceDetailState.detail?.titleReferences
  );

  const { workspaceStatusId, workspaceTypeId, isLocked, jurisdictionId } = workspaceBasicInfoState.detail || {};
  const isAbandonedWorkspace = Number.isInteger(workspaceStatusId) && WorkspaceStatusEnum.Abandoned === workspaceStatusId;

  return (
    <LodgementDetail
      {...props}
      isLoadingWorkspaceBasicInfo={workspaceBasicInfoState.isLoading}
      isLoadingWorkspaceParticipants={workspaceParticipantsState.isLoading}
      isLoadingWorkspaceTasks={workspaceTasksState.isLoading}
      isLoadingWorkspaceReports={workspaceReportsState.isLoading}
      isLoadingLinkedWorkspaces={linkedWorkspacesState.isLoading}
      workspaceDetail={{
        // basic workspace info
        isLocked,
        jurisdictionId,
        lodgementCaseStatusId,
        workspaceStatusId,
        workspaceTypeId,
        // full workspace info
        isAutoTacCeased: workspaceDetailState.detail?.isAutoTacCeased,
        titleReferenceRisChangedSet,
        titleReferenceTacChangedSet,
        // participant related details about workspace
        matter: currentWorkspaceParticipant?.reference || workspaceBasicInfoState.cacheFallback?.matter,
        lodgementCase: workspaceDetailState.detail?.lodgementCases
      }}
      isAbandonedWorkspace={isAbandonedWorkspace}
      // current participant info
      isWorkspaceParticipant={isCurrentUserWorkspaceParticipant}
      // list of sorted workspace participants
      workspaceParticipants={workspaceParticipants}
      // list of linked workspaces
      linkedWorkspaces={linkedWorkspacesState.items}
      // list of reports
      workspaceReports={workspaceReportsState.items}
      // list of tasks for current participant
      workspaceTasksState={workspaceTasksState}
      // list of documents
      allDocumentsSigned={allDocumentsSigned}
      // profile data
      profile={{
        userId: profileState.data!.userId,
        subscriberId: profileState.data!.subscriberId
      }}
      workspaceIssues={workspaceIssues}
      dispatch={dispatch}
      // feature flags
      isMLCEnabled={isMLCEnabled}
    />
  );
}

export default React.memo(LodgementDetailContainer);

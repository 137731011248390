import * as yup from 'yup';

import { DocumentAttachmentItemModel, DocumentAttachmentModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import { AttachmentTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import msg from '@sympli/ui-framework/utils/messages';

import { AdditionalAttachmentDocumentTypes } from '../../enums';
import { Mortgage2_21_1Model } from '../../models';

// this file was automatically generated from validationSchema.ts.mustache
interface AdditionalAttachmentsContext {
  termsAndConditions: Mortgage2_21_1Model['termsAndConditions'];
  additionalAttachments: Mortgage2_21_1Model['additionalAttachments'];
}

const yupAdditionalAttachment = memoizeSchemaWithContext(
  yup
    .object<DocumentAttachmentItemModel, AdditionalAttachmentsContext>({
      documentType: yup
        .mixed()
        .nullable(false)
        .required(msg.REQUIRED)
        .oneOf(AdditionalAttachmentDocumentTypes, msg.REQUIRED)
        .testContextualRule({
          uniqueTestName: 'Cannot have more than one attachment',
          message: 'Cannot have more than one attachment',
          onlyIf: (parent: DocumentAttachmentItemModel) => {
            return parent.documentType !== null && parent.documentType !== AttachmentTypeEnum.CaveatorsConsent;
          },
          requirement: (parent: DocumentAttachmentItemModel, context: AdditionalAttachmentsContext) => {
            return !context.additionalAttachments!.some(item => item !== parent && item.documentType === parent.documentType);
          }
        }),
      attachment: yup
        .array<DocumentAttachmentModel>()
        .defined()
        .max(1, msg.MAX_ITEMS(1))
        .testContextualRule({
          uniqueTestName: '"attachment" contextual validation rule #1',
          message: msg.REQUIRED,
          onlyIf: (parent: DocumentAttachmentItemModel) => parent.documentType !== null,
          requirement: (parent: DocumentAttachmentItemModel) => parent.attachment.length > 0
        })
    })
    .defined()
    .log(),
  (parentContext: AdditionalAttachmentsContext): AdditionalAttachmentsContext => parentContext
);

const yupAdditionalAttachments = memoizeSchemaWithContext(
  yup.array<DocumentAttachmentItemModel, AdditionalAttachmentsContext>().max(20, msg.MAX_ITEMS(20)).of(yupAdditionalAttachment).log(),
  ({ termsAndConditions, additionalAttachments }: Mortgage2_21_1Model): AdditionalAttachmentsContext => {
    return {
      termsAndConditions,
      additionalAttachments
    };
  }
);

export default yupAdditionalAttachments;

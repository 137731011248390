import React from 'react';

import { FormikProps } from 'formik';

import { BankAccountTypeEnum, LoanAdvanceCategoryEnum, PaymentMethodEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import {
  UpdateWorkspaceDirectionsApiRequestBody,
  WorkspaceDirectionsApiResponse,
  WorkspaceDirectionsCategoriesApiResponse,
  WorkspaceParticipantApiResponse
} from '@sympli/api-gateway/models';

import Formik from 'src/components/formik';
import DirectionsSummary from 'src/containers/workspace/financial/directions/components/directions-summary';
import { isDistributionValid, isSourceFundValid } from 'src/containers/workspace/financial/directions/helpers';
import SourceFundList from 'src/containers/workspace/financial/directions/views/edit-directions/components/source-fund-list';
import { NEW_SOURCEFUND } from 'src/containers/workspace/financial/directions/views/edit-directions/components/source-fund-list/models';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { DistributionFormikModel, SourceFundFormikModel } from '../../../../models';
import DirectionForm from '../../components/direction-form';
import DirectionRecordList from '../../components/direction-record-list';
import { getKeyValueMatchingNumberInObject } from '../../helper';
import useEditDirections from '../../hooks/useEditDirections';
import { directionsSummarySelector, otherPartiesDirectionsSummarySelector } from '../../selectors';
import { SourceFundModel } from '../discharge-mortgage-directions/models';
import { getDefaultLoanAdvanceSourceFund, isLoanAdvance, LOAN_ADVANCE_CATEGORY, LOAN_ADVANCE_RANGE_CATEGORY } from './helper';
import { IncomingDirectionsFormModel } from './models';
import { getValidationSchema } from './validationSchema';

const WORKSPACE_ROLE = WorkspaceRoleEnum.IncomingMortgagee;
interface Props {
  canEdit: boolean;
  queryParams: WorkspaceDetailRouteParams;
  detail: WorkspaceDirectionsApiResponse;
  currentParticipant: WorkspaceParticipantApiResponse;
  workspaceType: WorkspaceTypeEnum;
  directionsCategoriesDetail: WorkspaceDirectionsCategoriesApiResponse;
  portalIdForDirectionSummary: string;
  portalIdForFormSubmit: string;
  requiresStampDuty: boolean;
  onFormCancel(): void;
  havePurchaserParticipant?: boolean;
}

function IncomeMortgageDirections({
  portalIdForDirectionSummary,
  canEdit,
  onFormCancel,
  portalIdForFormSubmit,
  queryParams,
  currentParticipant,
  detail,
  directionsCategoriesDetail,
  workspaceType,
  requiresStampDuty,
  havePurchaserParticipant
}: Props) {
  const {
    workspaceId,
    participantId,
    defaultDistributionsValue,
    onPostSubmit,
    isSaveAsDraft,
    setIsSaveAsDraft,
    trustAccountOptions,
    trustAccountBankDetailMap,
    fssLineItemSaveEnabled
  } = useEditDirections(queryParams, detail, currentParticipant, directionsCategoriesDetail.directionCategories);

  const sourceFundDefaultValue = React.useMemo(() => {
    return { ...NEW_SOURCEFUND, subscriberName: currentParticipant.name };
  }, [currentParticipant.name]);

  const getInitialValues = React.useCallback((): IncomingDirectionsFormModel => {
    const { directions, loanAdvance, directionsSummary, sourceFunds } = detail;

    const defaultDistributions = directions.map(item => {
      const result: DistributionFormikModel = { ...defaultDistributionsValue, ...item };
      return {
        ...result,
        isEditorOpen: !isDistributionValid(result)
      };
    });

    const defaultSourceFunds = sourceFunds.map(item => {
      const result: SourceFundFormikModel = { ...NEW_SOURCEFUND, ...item };
      return {
        ...result,
        isEditorOpen: !isSourceFundValid(result)
      };
    });

    const initialLoanAdvanceAmount =
      loanAdvance?.category === LoanAdvanceCategoryEnum.Range && directionsSummary.autoBalancedLoanAdvanceAmount > 0
        ? directionsSummary.autoBalancedLoanAdvanceAmount
        : loanAdvance?.amount || 0;

    if (loanAdvance?.bankDetails) {
      // display the loan advance in the source funds list
      defaultSourceFunds.unshift({
        ...NEW_SOURCEFUND,
        trustAccountId: loanAdvance.accountId,
        amount: loanAdvance.amount,
        reference: loanAdvance.reference,
        category: loanAdvance.isAutoBalancingSurplusForIMAllowed ? LOAN_ADVANCE_RANGE_CATEGORY : LOAN_ADVANCE_CATEGORY,
        id: loanAdvance.id,
        isEditorOpen: false,
        bankDetails: loanAdvance.bankDetails,
        initialLoanAdvanceAmount: initialLoanAdvanceAmount,
        displayLoanAdvanceAmount: initialLoanAdvanceAmount,
        isAutoBalancingSurplusForIMAllowed: loanAdvance.isAutoBalancingSurplusForIMAllowed
      });
      return {
        distributions: defaultDistributions,
        sourceFunds: defaultSourceFunds
      };
    }

    const loanAdvanceAccountId = trustAccountOptions.length === 1 ? trustAccountOptions[0].id : '';
    if (loanAdvance && loanAdvance.bankDetails == null) {
      defaultSourceFunds.push(getDefaultLoanAdvanceSourceFund(loanAdvanceAccountId, initialLoanAdvanceAmount, loanAdvance?.isAutoBalancingSurplusForIMAllowed, loanAdvance.id));
    }

    return {
      distributions: defaultDistributions,
      sourceFunds: defaultSourceFunds
    };
  }, [defaultDistributionsValue, detail, trustAccountOptions]);

  const handleOnPreSubmit = React.useCallback(
    (values: IncomingDirectionsFormModel): UpdateWorkspaceDirectionsApiRequestBody => {
      const distributions: UpdateWorkspaceDirectionsApiRequestBody['directions'] = values.distributions
        .filter(item => !item.isLocked)
        .map(item => {
          const { bankDetails, bpayDetails, paymentMethod, holdingAccountDetails, amount, directionCategory, category, categoryOther, bankAccountId, id } = item;
          const commonValues = {
            paymentMethod,
            amount: Number(amount),
            directionCategory,
            category,
            categoryOther,
            id
          };
          switch (paymentMethod) {
            case PaymentMethodEnum.BPAY:
              return { ...commonValues, bpayDetails };
            case PaymentMethodEnum.HoldingAccount:
              return { ...commonValues, holdingAccountDetails };
            case PaymentMethodEnum.TrustAccount:
              return { ...commonValues, bankAccountId, bankDetails };
            case PaymentMethodEnum.BankTransfer:
            default:
              return { ...commonValues, bankDetails };
          }
        });

      // filter out the loan advance, loan advance submitted separately to the source funds list
      const sourceFunds: SourceFundModel[] = values.sourceFunds
        .filter(x => !isLoanAdvance(x.category))
        .map(item => {
          const { trustAccountId, amount, category, reference, categoryOther, id } = item;

          const trustAccount = trustAccountBankDetailMap[trustAccountId];
          if (trustAccount && trustAccount.bankAccountType === BankAccountTypeEnum.SympliSourceAccount) {
            return { trustAccountId, amount: Number(amount), reference: trustAccount.bankDetails.accountNumber, category, categoryOther, id };
          }
          return { trustAccountId, amount: Number(amount), reference, category, categoryOther, id };
        });

      const loanAdvanceSourceFund = values.sourceFunds.find(x => isLoanAdvance(x.category));
      return {
        directions: distributions,
        sourceFunds: sourceFunds,
        isSaveAsDraft: isSaveAsDraft,
        loanAdvance: loanAdvanceSourceFund
          ? {
              amount: loanAdvanceSourceFund.amount,
              accountId: loanAdvanceSourceFund.trustAccountId,
              reference: loanAdvanceSourceFund.reference,
              isAutoBalancingSurplusForIMAllowed: loanAdvanceSourceFund.isAutoBalancingSurplusForIMAllowed
            }
          : undefined
      };
    },
    [isSaveAsDraft, trustAccountBankDetailMap]
  );

  return (
    <Formik //
      method="post"
      action={`/workspaces/${encodeURIComponent(workspaceId)}/participants/${encodeURIComponent(participantId)}/directions`}
      httpConfig={{ withCredentials: true }}
      validateOnMount
      getInitialValues={getInitialValues}
      validationSchema={getValidationSchema(trustAccountBankDetailMap, workspaceId)}
      onPreSubmit={handleOnPreSubmit}
      onPostSubmit={onPostSubmit}
    >
      {(formikProps: FormikProps<IncomingDirectionsFormModel>) => {
        const { distributions, sourceFunds } = formikProps.values;
        const isMultipleEditorsOpen = getKeyValueMatchingNumberInObject(formikProps.values, 'isEditorOpen', true) >= 1;

        // * formik will update twice with same value (due to async validation)
        // * these selector prevent recalculation and re-render

        // need to use the source fund data for the loan advance when flag turned on
        const sourceFundLoanAdvance = sourceFunds.find(x => isLoanAdvance(x.category));
        const editingLoanAdvanceAmount = fssLineItemSaveEnabled ? detail.loanAdvance?.amount : sourceFundLoanAdvance?.displayLoanAdvanceAmount;

        const savedLoanAdvanceAmount =
          detail.loanAdvance?.category === LoanAdvanceCategoryEnum.Range && detail.directionsSummary.autoBalancedLoanAdvanceAmount > 0
            ? detail.directionsSummary.autoBalancedLoanAdvanceAmount
            : detail.loanAdvance?.amount || 0;

        const otherPartiesSummary = otherPartiesDirectionsSummarySelector({
          workspaceRole: WORKSPACE_ROLE,
          directionsSummary: detail.directionsSummary,
          sourceFunds: detail.sourceFunds,
          directions: detail.directions,
          initialLoanAdvanceAmount: savedLoanAdvanceAmount
        });

        const updatedSummary = directionsSummarySelector({
          workspaceRole: WORKSPACE_ROLE,
          otherPartiesSummary,
          sourceFunds: fssLineItemSaveEnabled ? detail.sourceFunds.filter(x => !isLoanAdvance(x.category)) : sourceFunds.filter(x => !isLoanAdvance(x.category)),
          distributions: fssLineItemSaveEnabled ? detail.directions : distributions,
          loanAdvanceRequiredAmount: detail.directionsSummary.loanAdvanceRequiredAmount || 0,
          editingLoanAdvanceAmount: Number(editingLoanAdvanceAmount) || 0
        });

        // make sure all editors are closed for add and edit,
        // todo is it ok if it is edit model but no data been modified? do we want to keep it disabled?
        const distributionsInEditModel = distributions.some(item => item.isEditorOpen);
        const sourceFundsInEditMode = sourceFunds.some(item => item.isEditorOpen);

        return (
          <DirectionForm
            workflowType={detail.distributionWorkflowType}
            stepperCanEdit={canEdit && !distributionsInEditModel && !sourceFundsInEditMode}
            canEdit={canEdit}
            isSubmitting={formikProps.isSubmitting}
            isSaveAsDraft={isSaveAsDraft}
            dirty={fssLineItemSaveEnabled ? formikProps.dirty && isMultipleEditorsOpen : formikProps.dirty}
            onFormCancel={onFormCancel}
            handleOnSaveAndContinueClick={() => {
              setIsSaveAsDraft(false);
              formikProps.submitForm();
            }}
            handleOnSaveDraftClick={() => {
              setIsSaveAsDraft(true);
              formikProps.submitForm();
            }}
            onCustomButtonClick={() => {
              setIsSaveAsDraft(true);
              formikProps.submitForm();
            }}
            portalIdForFormSubmit={portalIdForFormSubmit}
          >
            <SourceFundList
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              workspaceId={workspaceId}
              participantId={participantId}
              canEdit={canEdit}
              formikProps={formikProps}
              currentParticipant={currentParticipant}
              defaultValue={sourceFundDefaultValue}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              sourceFundCategories={directionsCategoriesDetail.sourceFundsCategories}
              canSetAutoBalanceForIM={detail.canSetAutoBalanceForIM}
              autoBalanceDefaultValue={detail.autoBalanceDefaultValue}
            />
            <DirectionRecordList
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              canEdit={canEdit}
              formikProps={formikProps}
              defaultValue={defaultDistributionsValue}
              currentParticipant={currentParticipant}
              trustAccountOptions={trustAccountOptions}
              trustAccountBankDetailMap={trustAccountBankDetailMap}
              workspaceId={workspaceId}
              participantId={participantId}
              workspaceRole={WORKSPACE_ROLE}
              paymentMethodOptions={detail.directionPaymentMethods}
            />
            <DirectionsSummary
              workspaceTypeId={workspaceType}
              workspaceRoleId={WORKSPACE_ROLE}
              directionsSummary={updatedSummary}
              portalIdForDirectionSummary={portalIdForDirectionSummary}
              requiresStampDuty={requiresStampDuty}
              havePurchaserParticipant={havePurchaserParticipant}
            />
          </DirectionForm>
        );
      }}
    </Formik>
  );
}

export default IncomeMortgageDirections;

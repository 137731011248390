import { createSelector } from 'reselect';

import { DocumentAttachmentItemModel } from '@sympli-mfe/document-forms-framework/components/sections/document-attachment';
import { BaseDocumentModel } from '@sympli-mfe/document-forms-framework/core/models';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { dateTimeLine } from '@sympli/ui-framework/utils/formatters';

import { getCurrentParticipantDocuments } from 'src/containers/documents/selectors';
import { getTimeInJurisdiction } from 'src/utils/timezone/timezoneHelper';
import { UploadFileTypeEnum, WorkspaceFileStatusEnum } from '../workspace-files/enums';
import { WorkspaceFile, WorkspaceFilesApiResponse } from '../workspace-files/models';

/**
 * This will return all workspace files uploaded from workspace overview page and all LRS document attachments
 */
export const selectWorkspaceFiles = createSelector<
  {
    participantId: string;
    workspaceDocuments: WorkspaceDocumentSummaryApiResponse[];
    workspaceFiles: WorkspaceFilesApiResponse[];
    isDocumentAttachmentsEnhancementEnabled: boolean;
  },
  string,
  WorkspaceDocumentSummaryApiResponse[],
  WorkspaceFilesApiResponse[],
  boolean,
  WorkspaceFile[]
>(
  args => args.participantId,
  args => args.workspaceDocuments,
  args => args.workspaceFiles,
  args => args.isDocumentAttachmentsEnhancementEnabled,
  (participantId, workspaceDocuments, workspaceFiles, isDocumentAttachmentsEnhancementEnabled) => {
    const participantDocuments = getCurrentParticipantDocuments(participantId, workspaceDocuments);

    const documentAttachments: WorkspaceFile[] = isDocumentAttachmentsEnhancementEnabled
      ? participantDocuments
          .filter(d => d.data) // make sure data exists, this is important for placeholder documents that does not have data yet
          .flatMap(document => {
            const documentAttachmentItems = (JSON.parse(document.data!) as BaseDocumentModel).attachments;
            return documentAttachmentItems?.flatMap(item => mapDocumentAttachmentsToWorkspaceFiles(item, document.documentId, document.jurisdiction.id)) ?? [];
          })
      : [];

    let allWorkspaceFiles: WorkspaceFile[] = workspaceFiles.map(file => ({
      ...file,
      uploadFileType: UploadFileTypeEnum.WorkspaceFile
    }));

    allWorkspaceFiles = allWorkspaceFiles.concat(documentAttachments);

    allWorkspaceFiles.sort((a, b) => new Date(b.dateUploaded).getTime() - new Date(a.dateUploaded).getTime());

    return allWorkspaceFiles;
  }
);

function mapDocumentAttachmentsToWorkspaceFiles(documentAttachmentItem: DocumentAttachmentItemModel, documentId: string, jurisdiction: JurisdictionsEnum): WorkspaceFile[] {
  return documentAttachmentItem.attachment.map(attachment => {
    return {
      s3Key: attachment.id!,
      id: attachment.id!,
      uploadFileType: UploadFileTypeEnum.DocumentAttachment,
      uploadedByParticipantId: attachment.uploadedByParticipantId!,
      dateUploaded: attachment.uploadedDateTimeUtc ? dateTimeLine(getTimeInJurisdiction(new Date(attachment.uploadedDateTimeUtc), jurisdiction)) : '',
      attachedToDocumentId: documentId,
      fileName: attachment.fileName!,
      description: documentAttachmentItem.documentType as string,
      status: WorkspaceFileStatusEnum.Clean
    };
  });
}

import React from 'react';

import queryString from 'query-string';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DocumentActionTypeEnum, DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsStatusApiResponse } from '@sympli/api-gateway/models';
import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import { UserAvatar101 } from 'src/components/avatars';
import { UserAvatarModel } from 'src/components/avatars/multi-user-avatar';
import AvatarListItem from 'src/components/list-item/avatar-list-item';
import Breadcrumbs from 'src/components/workspace-status-rebuild/breadcrumbs/Breadcrumbs';
import { DocumentActionModel } from 'src/containers/documents/models';
import { AccountAuthorisationRecordTypeEnum } from 'src/containers/workspace/shared/detail/components/my-tasks/models';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import FssSectionStatus from './components/fss-section-status/FssSectionStatus';
import PartiallySignedTrustAccountAuthorisationRecord from './components/tooltip-contents/taar-tooltip-contents/trust-account-auth-record-partially-signed/PartiallySignedTrustAccountAuthorisationRecord';
import PreparedTrustAccountAuthorisationRecord from './components/tooltip-contents/taar-tooltip-contents/trust-account-auth-record-prepared/PreparedTrustAccountAuthorisationRecord';
import SignedTrustAccountAuthorisationRecord from './components/tooltip-contents/taar-tooltip-contents/trust-account-auth-record-signed/SignedTrustAccountAuthorisationRecord';
import { AllParticipantFinancialSettlementScheduleStatusEnum, SectionTypeEnum, TrustAccountAuthorisationRecordStatusEnum, TrustAccountAuthorisationRecordSteps } from './models';

export function resolveAuthorisationRecordLink(params: WorkspaceDetailRouteParams, accountId: string, type: AccountAuthorisationRecordTypeEnum) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  return `/workspace/${encodeURIComponent(workspaceId)}/trust-account-authorisation-records/${encodeURIComponent(accountId)}?${query}`;
}

export function getSeparateFinancialStatuses(
  paymentsStatus: WorkspaceDirectionsStatusApiResponse['paymentsStatus'],
  sourceFundsStatus: WorkspaceDirectionsStatusApiResponse['sourceFundsStatus'],
  participant: UserAvatarModel
) {
  return (
    <>
      <AvatarListItem //
        key="participantId-Payments"
        avatar={<UserAvatar101 src={participant?.avatarUrl} item={participant} toolTipPlacement="right-start" tooltipType="document" />}
        avatarSize="s"
        primary="My Payments"
        secondary={
          <FssSectionStatus
            showToolTips
            financialStatus={paymentsStatus!.financialStatus}
            numberOfSigners={paymentsStatus!.distributionSummarySigners}
            sectionType={SectionTypeEnum.Payments}
          />
        }
      />
      <AvatarListItem //
        key="participantId-Sources"
        avatar={<UserAvatar101 src={participant?.avatarUrl} item={participant} toolTipPlacement="right-start" tooltipType="document" />}
        avatarSize="s"
        primary="My Sources"
        secondary={
          <FssSectionStatus
            showToolTips
            financialStatus={sourceFundsStatus!.financialStatus}
            numberOfSigners={sourceFundsStatus!.distributionSummarySigners}
            sectionType={SectionTypeEnum.Sources}
          />
        }
      />
    </>
  );
}

// all direction display is diff to the participant FSS level
export function resolveAllFinancialSettlementScheduleStatusText(
  //
  documentStatus: DocumentStatusEnum,
  isPartiallySigned: boolean,
  isNotApplicable: boolean
): AllParticipantFinancialSettlementScheduleStatusEnum {
  if (isNotApplicable) {
    return AllParticipantFinancialSettlementScheduleStatusEnum.NotApplicable;
  }

  if (isPartiallySigned) {
    return AllParticipantFinancialSettlementScheduleStatusEnum.PartiallySigned;
  }

  switch (documentStatus) {
    case DocumentStatusEnum.InPreparation:
    case DocumentStatusEnum.Reviewing:
      return AllParticipantFinancialSettlementScheduleStatusEnum.InPreparation;
    case DocumentStatusEnum.Approved:
    case DocumentStatusEnum.Signing:
      return AllParticipantFinancialSettlementScheduleStatusEnum.Approved;
    case DocumentStatusEnum.LodgementInProgress:
    case DocumentStatusEnum.LodgementVerificationInProgress:
    case DocumentStatusEnum.Lodged:
    case DocumentStatusEnum.Signed:
      return AllParticipantFinancialSettlementScheduleStatusEnum.Signed;
    case DocumentStatusEnum.Awaiting:
      return AllParticipantFinancialSettlementScheduleStatusEnum.Invited;
    default:
      return AllParticipantFinancialSettlementScheduleStatusEnum.InPreparation;
  }
}

export function getTrustAccountAuthorisationRecordTooltip(documentStatus: DocumentStatusEnum, documentActions?: Array<DocumentActionModel>, numberOfActions?: number) {
  const { status, description, tooltipContent } = getTrustAccountAuthorisationRecordStatus(documentStatus, documentActions, numberOfActions);

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column">
          {tooltipContent}
          <Breadcrumbs
            currentStep={TrustAccountAuthorisationRecordSteps.indexOf(status) + 1}
            steps={TrustAccountAuthorisationRecordSteps}
            sxBox={{ margin: theme => theme.spacing(2, 0, 0, 0) }}
          />
        </Box>
      }
      tooltipType="document"
    >
      <Typography variant="body6">{description}</Typography>
    </Tooltip>
  );
}

export function getTrustAccountAuthorisationRecordStatus(
  documentStatus: DocumentStatusEnum,
  documentActions?: Array<DocumentActionModel>,
  numberOfActions?: number
): { status: TrustAccountAuthorisationRecordStatusEnum; description: string; tooltipContent: React.ReactNode } {
  const status = documentStatus === DocumentStatusEnum.Signing ? TrustAccountAuthorisationRecordStatusEnum.Prepared : TrustAccountAuthorisationRecordStatusEnum.Signed;

  if (!documentActions || !numberOfActions) {
    return { status, description: status, tooltipContent: <PreparedTrustAccountAuthorisationRecord /> };
  }

  const signInfos = documentActions.filter(action => action.type === DocumentActionTypeEnum.Sign);
  if (numberOfActions && numberOfActions > 1) {
    if (signInfos.length === 0) {
      return { status, description: status, tooltipContent: <PreparedTrustAccountAuthorisationRecord /> };
    }
    return {
      status,
      description: `${documentStatus === DocumentStatusEnum.Signing ? 'PARTIALLY SIGNED' : 'SIGNED'} (${signInfos.length}/${numberOfActions})`,
      tooltipContent: documentStatus === DocumentStatusEnum.Signing ? <PartiallySignedTrustAccountAuthorisationRecord /> : <SignedTrustAccountAuthorisationRecord />
    };
  }

  return {
    status,
    description: status,
    tooltipContent: documentStatus === DocumentStatusEnum.Signing ? <PreparedTrustAccountAuthorisationRecord /> : <SignedTrustAccountAuthorisationRecord />
  };
}

import * as React from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import List from '@mui/material/List';

import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { DocumentItem } from 'src/containers/shared/document-list/components/document-item';
import { DocumentListModel } from '../../models';
import { getItemStyle } from './helpers';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  orderedItems: DocumentListModel[];
  isSaving: boolean;
  onDragEnd(props: DropResult): void;
  onSave(): void;
  onCancel(): void;
}

function DocumentListReorder({
  //
  orderedItems,
  isSaving,
  onDragEnd,
  onSave,
  onCancel
}: Props) {
  const classes = useStyles();

  function renderList() {
    return orderedItems.map((item, index) => {
      const { documentId, isLI } = item;

      // LI can't be reordered
      if (isLI) {
        return (
          <React.Fragment key={documentId}>
            <DocumentItem document={item} />
            {item.supportingDocuments.map(item => (
              <DocumentItem //
                key={item.documentId}
                document={item}
              />
            ))}
          </React.Fragment>
        );
      }

      return (
        <Draggable //
          draggableId={documentId}
          key={documentId}
          index={index}
          isDragDisabled={isLI}
        >
          {(provided, snapshot) => (
            <div //
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={getItemStyle(provided.draggableProps.style)}
            >
              <DocumentItem //
                document={item}
                className={snapshot.isDragging ? classes.isDragging : undefined}
              />
              {item.supportingDocuments.map(item => (
                <DocumentItem //
                  key={item.documentId}
                  document={item}
                  className={snapshot.isDragging ? classes.isDragging : undefined}
                />
              ))}
            </div>
          )}
        </Draggable>
      );
    });
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable //
          droppableId="droppable"
          isDropDisabled={false}
        >
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <List //
                className={classes.outerGutters}
              >
                {renderList()}
              </List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <WizardStepper //
        className={classes.wizardStepper}
        backLabel="Cancel"
        nextLabel="Save"
        onBack={onCancel}
        onNext={onSave}
        isLoading={isSaving}
        disabled={isSaving}
      />
    </>
  );
}

export default DocumentListReorder;

import { AttachmentTypeEnum, NameSuffixEnum } from '@sympli-mfe/enums-2-21/nsw';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// this file was automatically generated from enums.ts.mustache
/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 path:
 - applicantCapacity

 schema version:
[{"id":4,"value":"Trustee","name":"Trustee","_meta":null},{"id":6,"value":"Administrator","name":"Administrator","_meta":null},{"id":9,"value":"Executor","name":"Executor","_meta":null},{"id":17,"value":"ExecutorByRepresentation","name":"Executor By Representation","_meta":null}]

 hash:
 WzQsNiw5LDE3XQ==
*/
export enum ApplicantCapacityEnum {
  Trustee = 4,
  Administrator = 6,
  Executor = 9,
  ExecutorByRepresentation = 17
}
export const APPLICANT_CAPACITY_LOOKUP_OPTIONS: LookupEnumModel<ApplicantCapacityEnum>[] = [
  {
    id: ApplicantCapacityEnum.Trustee,
    name: 'Trustee'
  },
  {
    id: ApplicantCapacityEnum.Administrator,
    name: 'Administrator'
  },
  {
    id: ApplicantCapacityEnum.Executor,
    name: 'Executor'
  },
  {
    id: ApplicantCapacityEnum.ExecutorByRepresentation,
    name: 'Executor by representation'
  }
];

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].dateOfDeath.dateOfDeathType

 schema version:
[{"id":0,"value":"ActualDate","name":"Actual date","_meta":null},{"id":1,"value":"DateDescription","name":"Date description","_meta":null},{"id":2,"value":"DateRange","name":"Date range","_meta":null}]

 hash:
 WzAsMSwyXQ==
*/
export enum DateOfDeathTypeEnum {
  ActualDate = 0,
  DateDescription = 1,
  DateRange = 2
}
export const TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$DATE_OF_DEATH$DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS: LookupEnumModel<DateOfDeathTypeEnum>[] = [
  {
    id: DateOfDeathTypeEnum.ActualDate,
    name: 'Actual date'
  },
  {
    id: DateOfDeathTypeEnum.DateDescription,
    name: 'Date description'
  },
  {
    id: DateOfDeathTypeEnum.DateRange,
    name: 'Date range'
  }
];

/**
 path:
 - titleReferences[*].deceasedProprietorGroups[*].deceasedProprietors[*].evidences[*].evidenceTypeValue

 schema version:
[{"id":0,"value":"CertificateUnderNSWTrusteeAndGuardianAct","name":"Certificate under NSW Trustee and Guardian Act","_meta":null},{"id":4,"value":"DeathCertificate","name":"Death Certificate","_meta":null},{"id":5,"value":"DeedOfAppointment","name":"Deed of Appointment","_meta":null},{"id":9,"value":"LettersOfAdministration","name":"Letters of Administration","_meta":null},{"id":12,"value":"Probate","name":"Probate","_meta":null},{"id":13,"value":"StatutoryDeclaration","name":"Statutory Declaration","_meta":null}]

 hash:
 WzAsNCw1LDksMTIsMTNd
*/
export enum EvidenceTypeValueEnum {
  CertificateUnderNSWTrusteeAndGuardianAct = 0,
  DeathCertificate = 4,
  DeedOfAppointment = 5,
  LettersOfAdministration = 9,
  Probate = 12,
  StatutoryDeclaration = 13
}
export const TITLE_REFERENCES$DECEASED_PROPRIETOR_GROUPS$DECEASED_PROPRIETORS$EVIDENCES$EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS: LookupEnumModel<EvidenceTypeValueEnum>[] = [
  {
    id: EvidenceTypeValueEnum.CertificateUnderNSWTrusteeAndGuardianAct,
    name: 'Certificate under NSW Trustee and Guardian Act'
  },
  {
    id: EvidenceTypeValueEnum.DeathCertificate,
    name: 'Death Certificate'
  },
  {
    id: EvidenceTypeValueEnum.DeedOfAppointment,
    name: 'Deed of Appointment'
  },
  {
    id: EvidenceTypeValueEnum.LettersOfAdministration,
    name: 'Letters of Administration'
  },
  {
    id: EvidenceTypeValueEnum.Probate,
    name: 'Probate'
  },
  {
    id: EvidenceTypeValueEnum.StatutoryDeclaration,
    name: 'Statutory Declaration'
  }
];

const TransmissionWithoutDutyAttachmentDocumentTypes: AttachmentTypeEnum[] = [AttachmentTypeEnum.MinistersConsent, AttachmentTypeEnum.CaveatorsConsent];

export const TAE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS: LookupEnumModel<AttachmentTypeEnum, string>[] = TransmissionWithoutDutyAttachmentDocumentTypes.map(
  (x): LookupEnumModel<AttachmentTypeEnum> => ({ id: x, name: x })
);

export const TAE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS_WITHOUT_MINISTERSCONSENT = TAE_ATTACHMENT_DOCUMENT_TYPE_OPTIONS.filter(x => x.id !== AttachmentTypeEnum.MinistersConsent);

export const NAME_SUFFIX_LOOKUP_OPTIONS: LookupEnumModel<NameSuffixEnum, string>[] = [
  {
    id: NameSuffixEnum.Senior,
    name: 'SENIOR'
  },
  {
    id: NameSuffixEnum.Junior,
    name: 'JUNIOR'
  }
];

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';

import { useSafeDispatch } from 'src/hooks';
import { actionFetchInvitationsArchivedFeed } from './actions';
import { resolveInvitationDetail } from './helper';
import { useInvitationsArchivedFeed } from './reducers/invitationsArchivedFeed';
import { useInvitationsFeedV2 } from './reducers/invitationsFeedV2';
import InvitationsTableRowDetail from './table/InvitationsTableRowDetail';

interface InvitationsDashboardRowContainerProps {}

function InvitationsDashboardRowContainer(props: InvitationsDashboardRowContainerProps) {
  const dispatch = useSafeDispatch(useDispatch());
  const { items, rowDetailIndex } = useInvitationsFeedV2();
  const item = resolveInvitationDetail(items, rowDetailIndex);
  const { status, items: archivedLogs } = useInvitationsArchivedFeed(item?.participantId);

  useEffect(() => {
    if (item?.participantId) {
      dispatch(
        actionFetchInvitationsArchivedFeed.request({
          invitationId: item.participantId
        })
      );
    }
  }, [item?.participantId, dispatch]);

  if (status === 'idle' || status === 'pending') {
    return (
      <div className="p-[20px]">
        <InlineLoader />
      </div>
    );
  }

  if (!item || !archivedLogs.length) {
    return null;
  }

  return <InvitationsTableRowDetail items={archivedLogs} invitationDetail={item} />;
}

export default React.memo(InvitationsDashboardRowContainer);

import * as React from 'react';

import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import { DocumentListModel } from 'src/containers/shared/document-list/models';
import { DocumentListBase } from '../document-list-base';
import { useStyles } from './styles';

type Props = {
  items: DocumentListModel[];
  forceDisable?: boolean;
  onCancel(): void;
};

function DocumentListDelete({ items, forceDisable, onCancel }: Props) {
  const classes = useStyles();
  const disabled = items.every(x => !x.canRemove) || forceDisable;

  const getItems = React.useCallback((items: DocumentListModel[]) => {
    if (items.length === 0) return [];
    return items.map(x => {
      const newItem: DocumentListModel = { ...x };
      if (x.disabled) {
        // when a document is not able to be deleted by current participant, we remove the SELECT TO DELETE text and delete icon
        newItem.description = undefined;
        newItem.icon = undefined;
        newItem.className = 'mt-[4px]'; // add some extra space when description is not set for better looking
      }
      if (x.supportingDocuments) {
        // recursive checking supporting documents
        newItem.supportingDocuments = getItems(newItem.supportingDocuments);
      }
      return newItem satisfies DocumentListModel;
    });
  }, []);

  return (
    <>
      <DocumentListBase items={getItems(items)} disabled={disabled} />
      <WizardStepper //
        className={classes.wizardStepper}
        backLabel="Cancel"
        showNext={false}
        onBack={onCancel}
      />
    </>
  );
}

export default DocumentListDelete;

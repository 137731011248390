import { OktaAuth } from '@okta/okta-auth-js';

import Logger from '@sympli/ui-logger';

import environments from 'src/environments';
import fetchConfigurations from 'src/environments/fetchConfigurations';
import { LOCAL_ENV } from '../environments/resolveRunTimeEnv';

class UserManagerProvider {
  private static instance: OktaAuth;

  static get authClient(): OktaAuth {
    if (this.instance) {
      return this.instance;
    } else {
      throw new Error('Must load user manager on app launch.');
    }
  }

  static provide = async (): Promise<OktaAuth> => {
    const configurations = await fetchConfigurations();

    const isLocalhost = environments.APP_ENV === 'localhost';
    const host = window.location.host.toLowerCase();

    const SCOPE: string[] = ['openid', 'profile', 'tickle', 'offline_access', 'email'];

    const oidcClient = isLocalhost
      ? configurations.oidcClients?.find(client => client?.env === LOCAL_ENV)
      : configurations.oidcClients?.find(client => client?.domain?.toLowerCase() === host);

    if (!oidcClient) {
      Logger.captureException(new Error('No Oidc Client is found'));
    }

    const authClient = new OktaAuth({
      issuer: oidcClient?.authorityUrl,
      clientId: oidcClient?.clientId,
      redirectUri: `${window.location.protocol}//${window.location.host}${environments.BASENAME}/logged-in`,
      scopes: SCOPE,
      responseType: 'code',
      services: {
        autoRenew: false, // We need to manually renew token when user is considered as active
        autoRemove: true,
        syncStorage: true
      },
      postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}${environments.BASENAME}/logged-out`,
      storageManager: {
        token: {
          storageType: 'sessionStorage', // for security sake, we store in session storage
          storageTypes: []
        }
      }
    });

    this.instance = authClient;

    return this.instance;
  };
}

export default UserManagerProvider;

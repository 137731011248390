import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_21_1Model, LodgementInstructions2_21_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions2_21_1Model, ApiLodgementInstructions2_21_1Model> {
  fromApiToFormModel(apiValues: ApiLodgementInstructions2_21_1Model, context: IConverterContext): LodgementInstructions2_21_1Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant ?? false;
    return formValues;
  }
  fromFormToApiModel(formValues: LodgementInstructions2_21_1Model, originalApiModel: ApiLodgementInstructions2_21_1Model): ApiLodgementInstructions2_21_1Model {
    const apiModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    return apiModel;
  }
}

export default new Converter();

import { LookupItemModel } from '@sympli/ui-framework/models';

import { DashboardInvitationStatusFilterEnum, InvitationArchivedTypeEnum } from '../table/models';

export enum FilterEnum {
  ReceivedDate = 0,
  SentDate = 1,
  FromSubscriber = 2,
  ToSubscriber = 3,
  Jurisdiction = 4,
  MyRole = 5,
  ProposedSettlementDate = 6,
  ArchivedType = 7,
  InvitationStatus = 8,
  Groups = 9,
  Members = 10,
  FromToSubscriber = 11,
  ArchivedDate = 12
}

export const FilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyRole,
    name: 'Role'
  },
  {
    id: FilterEnum.ProposedSettlementDate,
    name: 'Proposed Settlement Date'
  }
];

export const ArchivedFilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.ArchivedDate,
    name: 'Archived Date'
  },
  {
    id: FilterEnum.FromToSubscriber,
    name: 'From/To Subscriber'
  },
  {
    id: FilterEnum.ArchivedType,
    name: 'Invitation Type'
  },
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyRole,
    name: 'Role'
  },
  {
    id: FilterEnum.InvitationStatus,
    name: 'Invitation Status'
  }
];

export enum MyRoleEnum {
  IncomingMortgagee = 0,
  DischargeMortgagee = 1,
  Purchaser = 2,
  Vendor = 3,
  CaveatorOnTitle = 4,
  Beneficiary = 5,
  ControllingParty = 6
}

export const MyRoleOptions: LookupItemModel<number>[] = [
  {
    id: MyRoleEnum.IncomingMortgagee,
    name: 'Incoming Mortgagee'
  },
  {
    id: MyRoleEnum.DischargeMortgagee,
    name: 'Discharge Mortgagee'
  },
  {
    id: MyRoleEnum.Purchaser,
    name: 'Purchaser'
  },
  {
    id: MyRoleEnum.Vendor,
    name: 'Vendor'
  },
  {
    id: MyRoleEnum.CaveatorOnTitle,
    name: 'Caveator On Title'
  },
  {
    id: MyRoleEnum.Beneficiary,
    name: 'Beneficiary'
  },
  {
    id: MyRoleEnum.ControllingParty,
    name: 'Controlling Party'
  }
];

export const ArchivedTypeOptions: LookupItemModel<number>[] = [
  {
    id: InvitationArchivedTypeEnum.Received,
    name: 'Received'
  },
  {
    id: InvitationArchivedTypeEnum.Sent,
    name: 'Sent'
  }
];

export const InvitationStatusOptions: LookupItemModel<number>[] = [
  {
    id: DashboardInvitationStatusFilterEnum.Accepted,
    name: 'Accepted'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Declined,
    name: 'Declined'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Forwarded,
    name: 'Forwarded'
  },
  {
    id: DashboardInvitationStatusFilterEnum.Withdrawn,
    name: 'Withdrawn'
  }
];

import { DirectionCategoryEnum, PaymentMethodEnum } from '@sympli/api-gateway/enums';

import { BankDetailsModel, BpayDetailsModel, DistributionFormikModel, HoldingAccountDetailsModel, SourceFundFormikModel } from '../../../../models';

export enum CategoryEnum {
  LoanPayout = 'Loan Payout',
  CustomerLoanAccount = 'Customer Loan Account',
  AcceptSurplus = 'Accept Surplus',
  ProfessionalFees = 'Professional Fees',
  ThirdPartyBeneficiary = 'Third Party Beneficiary',
  Other = 'Other'
}

export interface DischargeDirectionsFormModel {
  distributions: Array<DistributionFormikModel>;
  sourceFunds: Array<SourceFundFormikModel>;
}
export interface SourceFundModel {
  trustAccountId: string;
  amount: number;
  reference?: string;
  category: string;
  categoryOther?: string;
  id?: string;
}

/**
 * MR - svc moved
 * Tickle.Application.Domain.Workspaces.Model.Directions.DirectionUpsert
 */
export interface DirectionModel {
  paymentMethod?: PaymentMethodEnum;
  bankAccountId?: string;
  bankDetails?: BankDetailsModel;
  bpayDetails?: BpayDetailsModel;
  holdingAccountDetails?: HoldingAccountDetailsModel;
  amount?: string | number;
  directionCategory?: DirectionCategoryEnum;
  category?: string;
  categoryOther?: string;
  id?: string;
}

export interface LoanAdvanceModel {
  amount?: string | number;
  accountId?: string;
  reference?: string;
  isAutoBalancingSurplusForIMAllowed: boolean;
}

// MR - moved
// export interface UpdateDischargeDirectionsApiRequest {
//   sourceFunds?: SourceFundModel[];
//   directions: DirectionModel[];
//   loanAdvance?: LoanAdvanceModel;
//   purchaserPayAmount?: number;
//   loanAdvanceRequiredAmount?: number;
//   isSaveAsDraft: boolean;
// }

import dateFormat from 'dateformat';

import { WA_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/wa/2-19/config';
import { WA_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/wa/2-19/config';
import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';
import { DateFormatEnum } from '@sympli-mfe/document-forms-framework/models';
import { resolveAddressBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/address';
import { resolvePartyBookConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_19_1Model, LodgementInstructions2_19_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
const { convertPartyBookFromApiToFormModel, convertPartyBookFromFormToApiModel } = resolvePartyBookConversion({
  partyFormConfig: WA_PARTY_FORM_CONFIG
});

const { convertAddressBookFromApiToFormModel, convertAddressBookFromFormToApiModel } = resolveAddressBookConversion({
  addressFormConfig: WA_ADDRESS_FORM_CONFIG
});

class Converter implements IConverter<LodgementInstructions2_19_1Model, ApiLodgementInstructions2_19_1Model> {
  fromApiToFormModel(apiValues: ApiLodgementInstructions2_19_1Model, context: IConverterContext): LodgementInstructions2_19_1Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    return {
      ...formValues,
      isResponsibleParticipant: context.currentParticipant?.isResponsibleParticipant ?? false,
      partyBook: convertPartyBookFromApiToFormModel(apiValues.partyBook || []),
      addressBook: convertAddressBookFromApiToFormModel(apiValues.addressBook || [])
    };
  }
  fromFormToApiModel(formValues: LodgementInstructions2_19_1Model, originalApiModel: ApiLodgementInstructions2_19_1Model): ApiLodgementInstructions2_19_1Model {
    let formModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    const { addressBook, partyBook, duplicateHoldingDetail, ...rest } = formModel;
    return {
      ...rest,
      partyBook: convertPartyBookFromFormToApiModel(partyBook || [], originalApiModel.partyBook || []),
      addressBook: convertAddressBookFromFormToApiModel(addressBook || [], originalApiModel.addressBook || []),
      duplicateHoldingDetail: duplicateHoldingDetail?.map(d => ({
        ...d,
        editionDate: d.editionDate ? dateFormat(d.editionDate as Date, DateFormatEnum.DATE) : null
      }))
    };
  }
}

export default new Converter();

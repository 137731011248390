import { DocumentTypeIdentifierEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { resolveUuid } from 'src/utils/formUtils';
import { AllowedAdditionalParticipantRole } from '../../../../models';
import { CreateDocumentsItemModel } from '../../../reg-only-form/models';
import { DEFAULT_INVITATION_ITEM_MODEL } from '../../initialValues';
import { AdditionalParticipantRoleConfig, InvitationModel } from '../../models';
import { InvitationItemModel } from './models';

export const compulsoryInvitationMapping: Partial<Record<WorkspaceRoleEnum, WorkspaceRoleEnum[]>> = {
  [WorkspaceRoleEnum.Vendor]: [WorkspaceRoleEnum.Purchaser],
  [WorkspaceRoleEnum.Purchaser]: [WorkspaceRoleEnum.Vendor]
};

export function resolveDocumentByWorkspaceRole(createdDocuments: CreateDocumentsItemModel[], workspaceRole: WorkspaceRoleEnum) {
  switch (workspaceRole) {
    case WorkspaceRoleEnum.IncomingMortgagee: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.Mortgage);
    }
    case WorkspaceRoleEnum.DischargingMortgagee: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.DischargeOfMortgage);
    }
    case WorkspaceRoleEnum.Purchaser: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.Transfer);
    }
    case WorkspaceRoleEnum.Vendor: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.Form24PartB);
    }
    case WorkspaceRoleEnum.DischargingCaveator: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.WithdrawalOfCaveat);
    }
    case WorkspaceRoleEnum.Beneficiary: {
      return createdDocuments.find(doc => doc.documentIdentifier === DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin);
    }
    default: {
      return undefined;
    }
  }
}

export function getDefaultInvitation(groupId: string, matterNumber: string, workspaceRole?: WorkspaceRoleEnum): InvitationModel {
  return { role: workspaceRole, id: resolveUuid('number', 20), invitation: { ...DEFAULT_INVITATION_ITEM_MODEL, groupId, matterNumber } };
}

export function setInvitationDefaultGroupId(invitation: InvitationModel, defaultGroupId: string): InvitationModel {
  return { ...invitation, invitation: invitation.invitation && { ...invitation.invitation, groupId: defaultGroupId } };
}

export function setInvitationItemDefaultGroupId(invitationItem: InvitationItemModel, defaultGroupId: string): InvitationItemModel {
  return { ...invitationItem, groupId: defaultGroupId };
}

export function getAdditionalParticipantRoleConfigs(
  allowedAdditionalParticipantRoles: AllowedAdditionalParticipantRole[]
): Partial<Record<WorkspaceRoleEnum, AdditionalParticipantRoleConfig>> {
  const result: Partial<Record<WorkspaceRoleEnum, AdditionalParticipantRoleConfig>> = allowedAdditionalParticipantRoles.reduce((map, allowedAdditionalParticipantRole) => {
    map[allowedAdditionalParticipantRole.workspaceRole] = {
      compatibleRoles: allowedAdditionalParticipantRole.compatibleWorkspaceRoles,
      numberOfParticipantsAllowed: allowedAdditionalParticipantRole.numberOfParticipantsAllowed
    };
    return map;
  }, {});
  return result;
}

import * as React from 'react';

import classNames from 'classnames';
import _debounce, { DebouncedFunc } from 'lodash-es/debounce';

import Input from '@sympli/ui-framework/components/form/base-components/input';

import { IconClose, IconSearch } from 'src/components/layout/v2/Icons';
import { SearchCategoryEnum, SearchDashboardPageTabsEnum } from '../../models';
import { useStyles } from './styles';

export interface GlobalSearchBoxProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  selectedTab: SearchDashboardPageTabsEnum;
  setSelectedTab: React.Dispatch<React.SetStateAction<SearchDashboardPageTabsEnum | null>>;
  fetchSearchBoxWithDebounce: DebouncedFunc<(selectedTab: SearchDashboardPageTabsEnum, searchTerm?: string, searchCategoryId?: SearchCategoryEnum, pageNumber?: number) => void>;
}

function GlobalSearchBox({ value, setValue, selectedTab, setSelectedTab, fetchSearchBoxWithDebounce }: GlobalSearchBoxProps) {
  const classes = useStyles();

  const placeholder = "Search references, ID's, titles, addresses, customers";

  const handleOnInputChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = _event.target.value;
    setValue(newValue);
    // when user adds more spacing we don't want to call backend again
    if (value.trim() !== newValue.trim()) {
      fetchSearchBoxWithDebounce(selectedTab, newValue, SearchCategoryEnum.All);
    }
  };

  const handleOnBlur = (event: any) => {
    event.persist();
  };
  const handleOnClearSearchInput = () => {
    setValue('');
    // always reset tab to active workspace when clear search input
    setSelectedTab(SearchDashboardPageTabsEnum.Workspaces);
  };

  const renderEndAdornment = () => {
    return <IconClose className={classNames(classes.actionIcon, classes.searchBoxIconClose)} onClick={handleOnClearSearchInput} data-testid="clear-button" />;
  };

  return (
    <Input
      className={classes.searchInput}
      name={'search'}
      aria-label="Search"
      autoFocus={true}
      classes={{ formControl: classes.inputStyle }}
      placeholder={placeholder}
      value={value}
      onChange={handleOnInputChange}
      onBlur={handleOnBlur}
      inputRef={inputRef => inputRef && inputRef.focus()}
      startAdornment={<IconSearch className={classNames(classes.actionIcon, classes.searchBoxIconSearch)} />}
      endAdornment={renderEndAdornment()}
      disabled={false}
      inputProps={{ className: classes.inputBox }}
    />
  );
}

export default React.memo(GlobalSearchBox);

import * as React from 'react';

import { Form } from 'formik';

import { DocumentWorkflowTypeEnum } from '@sympli/api-gateway/enums';
import { PortalSource } from '@sympli/ui-framework/components/portal';

import PromptConfirmDialog from 'src/components/prompt-confirm-dialog';
import { PromptCallBack } from 'src/components/prompt-confirm-dialog/model';
import SaveFormStepper from '../save-form-stepper';

export interface Props {
  workflowType: DocumentWorkflowTypeEnum;
  canEdit: boolean;
  stepperCanEdit: boolean;
  isSubmitting: boolean;
  isSaveAsDraft: boolean;
  dirty: boolean;
  onFormCancel: () => void;
  handleOnSaveAndContinueClick: () => void;
  handleOnSaveDraftClick: () => void;
  children: React.ReactNode;
  onCustomButtonClick?: (promptCallback: PromptCallBack) => void;
  portalIdForFormSubmit?: string;
  //feature flag
  isFssLineItemSaveEnabled: boolean;
}

function DirectionForm({
  canEdit,
  stepperCanEdit,
  isSaveAsDraft,
  onCustomButtonClick,
  dirty,
  onFormCancel,
  isSubmitting,
  handleOnSaveDraftClick,
  handleOnSaveAndContinueClick,
  workflowType,
  portalIdForFormSubmit,
  isFssLineItemSaveEnabled,
  children
}: Props) {
  const renderStepper = (haveCancelButton: boolean) => (
    <SaveFormStepper
      workflowType={workflowType}
      isSubmitting={isSubmitting}
      canEdit={stepperCanEdit}
      isSaveAsDraft={isSaveAsDraft}
      haveCancelButton={haveCancelButton}
      formDirty={dirty}
      onFormCancel={onFormCancel}
      handleOnSaveAndContinueClick={handleOnSaveAndContinueClick}
      handleOnSaveDraftClick={handleOnSaveDraftClick}
      isFssLineItemSaveEnabled={isFssLineItemSaveEnabled}
    />
  );

  const handleOnLeavePage = (promptCallback: PromptCallBack) => {
    promptCallback(true); // redirect to new url
  };

  return (
    <>
      <PromptConfirmDialog
        when={dirty}
        title={isFssLineItemSaveEnabled ? 'You have unsaved changes!' : 'Save changes on this page?'}
        message={isFssLineItemSaveEnabled ? 'Your unsaved changes will be lost. Are you sure you want to leave?' : "Your changes will be lost if you don't save them."}
        secondaryButtonText={isFssLineItemSaveEnabled ? undefined : "Don't save"}
        primaryButtonText="Cancel"
        customButtonText={isFssLineItemSaveEnabled ? 'Leave' : 'Save'}
        onCustomButtonClick={isFssLineItemSaveEnabled ? handleOnLeavePage : onCustomButtonClick}
      />
      <Form>
        {children}
        {canEdit && renderStepper(true)}
        {portalIdForFormSubmit && <PortalSource id={portalIdForFormSubmit}>{renderStepper(false)}</PortalSource>}
      </Form>
    </>
  );
}

export default DirectionForm;

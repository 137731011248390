import * as React from 'react';

import _set from 'lodash-es/set';
import { batch } from 'react-redux';
import { Action } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { DialogTypeEnum } from 'src/components/message-dialog/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { ActionStore } from 'src/store/globalErrorMiddleware';
import { actionMfaCheckComplete } from './actions';
import { pollMfaStatus, VerificationResultEnum } from './api';
import AuthenticationRequired from './icons/authentication-required';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  showDialog: boolean;
  mfaId?: string;
  dispatch: SafeDispatch<Action>;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class AuthenticationDialog extends React.PureComponent<Props> {
  private timer: NodeJS.Timer;
  private readonly defaultInterval: number = 5000; // this need to check later with product

  public componentDidUpdate(prevProps: Props) {
    const { showDialog, mfaId = '' } = this.props;

    // toggle with feature flag
    if (showDialog && mfaId) {
      this.timer = setInterval(this.checkMFAStatus, this.defaultInterval);
    }
  }

  public componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  get isLoading() {
    // const { detail, isLoading, error } = this.props;
    // return !error && (isLoading || !detail);
    return false;
  }

  render() {
    const { showDialog } = this.props;

    return (
      <ConfirmationDialog
        open={showDialog} // {this.state.openConfirmationDialog}
        title=""
        showActionButtons={false}
        onClose={this.handleOnCloseClick} // {this.handleOnClose}
      >
        <div style={{ textAlign: 'center' }}>
          <AuthenticationRequired />
          <h3>Authentication required</h3>
          <p>You need to check your OKTA mobile authentication app and follow the prompts.</p>
        </div>
      </ConfirmationDialog>
    );
  }

  private checkMFAStatus = () => {
    const { mfaId, dispatch } = this.props;
    // mfaId always required once we start to polling status
    if (!mfaId) {
      return;
    }
    pollMfaStatus(mfaId).then(d => {
      if (d.result === VerificationResultEnum.WAITING) {
        return;
      }

      if (d.transactionId === mfaId && d.result === VerificationResultEnum.SUCCESS) {
        ActionStore.popAll().forEach(action => {
          const { payload } = action;
          _set(payload, 'httpConfig.params.mfaId', mfaId);
          dispatch(action);
        });
        dispatch(actionMfaCheckComplete());
      } else {
        ActionStore.popAll();
        batch(() => {
          dispatch(actionMfaCheckComplete());
          dispatch(
            actionCreateGlobalErrorMessage({
              title: 'Permission denied',
              message: 'You have denied an authentication request on your OKTA mobile authentication app. Your changes have not been saved, please try again.',
              type: DialogTypeEnum.Warning
            })
          );
        });
      }
      this.timer && clearInterval(this.timer);
    });
  };

  private handleOnCloseClick = () => {
    ActionStore.popAll();
    this.props.dispatch(actionMfaCheckComplete());
  };
}

const styledComponent = withStyles(styles)(AuthenticationDialog);

export default styledComponent;

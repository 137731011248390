import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    headingIcon: {
      fill: theme.palette.error.main,
      width: 20,
      height: 20
    },
    headingText: {
      marginLeft: 8
    },
    errorButton: {
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  }),
  { name: 'DeleteDocumentDialog' }
);

import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, SettlementDateKindEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import NavLink from '@sympli/ui-framework/components/nav-link';
import { IconCalendar, IconCheck, IconClock, IconLocked, IconUnlock } from '@sympli/ui-framework/icons';

import { SettlementDateTimeModel } from 'src/models';
import { dateTimeLine } from 'src/utils/formatters';
import { getTimeInJurisdiction } from 'src/utils/timezone/timezoneHelper';
import styles, { ClassKeys } from './styles';

export const TIME_ONLY_DISPLAY_FORMAT = 'h:MM TT';

interface OwnProps {
  // workspace basic info
  isLocked?: boolean;
  isRollover?: boolean;
  jurisdictionId?: JurisdictionsEnum | null;
  workspaceStatusId: WorkspaceStatusEnum;
  // settlement details info
  isAcceptedByAll?: boolean;
  isAcceptedByUser?: boolean;
  isProposedByUser?: boolean;
  localProposedSettlementDate?: string;
  proposedSettlementDate?: string;
  settlementAwaitingAcceptanceCount?: number;
  settlementDateTime?: SettlementDateTimeModel;
  expectedSettlementDateTime?: ExpectedSettlementDate;
  // other
  isLoading?: boolean;
  // always passed by parent
  editLink: string;
  declineLink: string;
  onAcceptClick(): void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

class SettlementDateTimeCard extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    isAcceptedByAll: true
  };

  // * Conditions
  // * - As long as it is not settled, we show overdue
  get isSettlementOverdue() {
    const { settlementDateTime } = this.props;
    if (!settlementDateTime) return false;

    return settlementDateTime.hoursTill < 0;
  }

  /** return true if proposedSettlementDate is not in the past*/
  get isValidProposedSettlementDate() {
    const { proposedSettlementDate, jurisdictionId = null } = this.props;

    if (proposedSettlementDate) {
      const proposedTimeInJurisdictionMs = getTimeInJurisdiction(new Date(proposedSettlementDate), jurisdictionId).getTime();
      const currentTimeInJurisdictionMs = getTimeInJurisdiction(new Date(), jurisdictionId).getTime();
      return proposedTimeInJurisdictionMs - currentTimeInJurisdictionMs >= 0;
    }

    return false;
  }

  get isDateOnlyOrNoDateTime() {
    return !this.props.settlementDateTime;
  }

  get isDateOnly() {
    return Boolean(
      this.props.expectedSettlementDateTime && this.props.expectedSettlementDateTime.settlementDateKind === SettlementDateKindEnum.DateOnly && !this.props.proposedSettlementDate
    );
  }

  get isNoDateTime() {
    return Boolean(!this.props.expectedSettlementDateTime && !this.props.settlementDateTime);
  }

  // * - rebook is required for Failed, or Overdue
  get requireRebook() {
    const { workspaceStatusId, isAcceptedByUser, isRollover } = this.props;

    if (workspaceStatusId === WorkspaceStatusEnum.Failed) {
      return true;
    }

    // no requireRebook if workspace Status is Success or
    // if settlement failed and workspace is not locked, we will display requireRebook
    // if all accept the new data time, the ws will be in progress status again
    // we cannot use settlement status here as next settlement have not been triggered yet
    // if the new date/time has been accept and the settlement is not overdue, we do not need to display
    // if we've rolled over the settlement to the next time slot, do not show rebook
    if (workspaceStatusId === WorkspaceStatusEnum.Success || (isAcceptedByUser && !this.isSettlementOverdue) || isRollover) {
      return false;
    }

    // this is for existing dirty data
    return this.isSettlementOverdue;
  }

  // * - yellow notification for accept a new settlement time
  get hasNewSettlementTime() {
    const { isAcceptedByAll } = this.props;
    return !isAcceptedByAll;
  }

  get iconColorClass() {
    const { classes, isAcceptedByAll, isRollover, workspaceStatusId, settlementDateTime } = this.props;
    if (!settlementDateTime) {
      return classes.nonSDTIconColor;
    }

    let iconColor = classes.normalIconColor;

    if (this.requireRebook) {
      iconColor = classes.errorIconColor;
    } else if (!isAcceptedByAll || (isRollover && this.isWorkspaceInProgress(workspaceStatusId))) {
      iconColor = classes.noticeIconColor;
    }
    return iconColor;
  }

  render() {
    const { classes, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className={classes.root}>
          <FlexLayout flexDirection="row" alignItems="center" justifyContent="center" className={classes.content}>
            <FlexLayout flexDirection="column" alignItems="center" className={classes.inlineLoaderStyle}>
              <InlineLoader size={25} />
            </FlexLayout>
          </FlexLayout>
        </div>
      );
    }
    return (
      <div data-testid="settlement-date-time-card" className={classes.root}>
        {this.renderNoticeTriangle()}

        {this.renderDateTime()}

        {this.renderBottomBox()}
      </div>
    );
  }

  private renderDateTime() {
    const { classes } = this.props;

    return (
      <FlexLayout flexDirection="row" alignItems="flex-start" justifyContent="space-between" className={classes.content}>
        <FlexLayout flexDirection="column" justifyContent="center" alignItems="center" className={classes.reminderContainer}>
          {this.renderReminder()}
        </FlexLayout>
        <FlexLayout flexDirection="column" className={classes.dateTimeContainer}>
          {this.renderDate()}
          {this.renderTime()}
        </FlexLayout>
      </FlexLayout>
    );
  }

  private renderNoticeTriangle() {
    const { classes, isRollover, workspaceStatusId } = this.props;

    if (this.isDateOnlyOrNoDateTime) {
      return (
        <React.Fragment>
          <div className={classNames(classes.noticeTriangle, classes.yellowNoticeTriangle)} />
          <Paper className={classes.card}>
            <div className={classes.cardContent}>
              <Typography component="p">Please set date and time to proceed with this settlement</Typography>
            </div>
          </Paper>
        </React.Fragment>
      );
    }

    if (isRollover && this.isWorkspaceInProgress(workspaceStatusId)) {
      return <div className={classNames(classes.noticeTriangle)} />;
    }
    if (this.requireRebook) {
      return <div className={classNames(classes.noticeTriangle, classes.redNoticeTriangle)} />;
    }
    if (this.hasNewSettlementTime) {
      return (
        <React.Fragment>
          <div className={classes.noticeTriangle} />
          <Paper className={classes.card}>
            <div className={classes.cardContent}>
              <Typography component="p">
                <strong>This settlement cannot proceed</strong> until all invited participants have joined the workspace and accepted the settlement date and time, or until pending
                invitations have been withdrawn.
              </Typography>
            </div>
          </Paper>
        </React.Fragment>
      );
    }
    return null;
  }

  private renderIconLock() {
    const { classes } = this.props;
    return <IconLocked className={classes.iconLock101} />;
  }

  private renderReminder() {
    const { workspaceStatusId, isLocked, classes, isRollover } = this.props;

    if (this.isNoDateTime) {
      return (
        <>
          <Typography variant="h2" className={classNames('pt-4 font-medium', classes.nonSDT)}>
            Settlement
          </Typography>
          <Typography variant="h2" className={classNames(classes.boldText, classes.nonSDT)}>
            date not set
          </Typography>
        </>
      );
    }

    switch (workspaceStatusId) {
      case WorkspaceStatusEnum.Failed: {
        return (
          <Typography variant="h2" className={classNames(classes.errorText, 'ml-[-10px] grow pt-6 text-base font-bold not-italic leading-6')}>
            Unsuccessful
          </Typography>
        );
      }
      case WorkspaceStatusEnum.Success: {
        return (
          <React.Fragment>
            <IconCheck className={classes.iconCheck} />
            <Typography className={classNames(classes.statusText, 'text-center')}>Settlement completed</Typography>
          </React.Fragment>
        );
      }
      case WorkspaceStatusEnum.SettlementIssue: {
        return (
          <React.Fragment>
            {isLocked ? this.renderIconLock() : <IconUnlock className={classes.iconUnlock} />}
            <Typography className={classNames(classes.statusText, 'text-center')}>Settlement Issue</Typography>
          </React.Fragment>
        );
      }
      case WorkspaceStatusEnum.SettlementInProgress: {
        return (
          <React.Fragment>
            {isLocked ? this.renderIconLock() : <IconUnlock className={classes.iconUnlock} />}
            <Typography className={classNames(classes.statusText, 'text-center')}>Settlement in Progress</Typography>
          </React.Fragment>
        );
      }
      case WorkspaceStatusEnum.OnSchedule:
      case WorkspaceStatusEnum.ReadyForSettlement: {
        return isRollover ? (
          <React.Fragment>
            <Typography variant="h2" className={classes.settlingToday}>
              S/ment
            </Typography>
            <Typography variant="h2" className={classes.boldText}>
              rolled
            </Typography>
          </React.Fragment>
        ) : (
          this.renderDueIn()
        );
      }
      default:
        return this.renderDueIn();
    }
  }

  private renderDueIn = () => {
    const { settlementDateTime, classes, expectedSettlementDateTime } = this.props;

    const calculatedSettlementDateTime = settlementDateTime || expectedSettlementDateTime?.workspaceDateTime;

    if (!calculatedSettlementDateTime) return null;

    // Due date settlement is based on calendar days
    // WEB-4265
    // 1. The weeks remaining until settlement - if settlement time is in 14 calendar days and over
    // 2. The days remaining until settlement - if settlement time is in 13 days and under
    // 3. "Settlement Today" - if the settlement is on the same day (after 12 am on the same day)
    // 4. "Settlement Overdue" - if the settlement date and time has passed
    const { daysTill, hoursTill, weeksTill } = calculatedSettlementDateTime;
    let dueInElement: JSX.Element;

    if (hoursTill < 0) {
      dueInElement = (
        <Typography variant="h2" className={'grow pt-6 text-center text-base font-bold not-italic leading-6'}>
          Overdue
        </Typography>
      );
    } else if (hoursTill > 0 && daysTill === 0) {
      dueInElement = (
        <React.Fragment>
          <Typography variant="h2" className={classNames(classes.settlingToday, classes.boldText)}>
            Settling
          </Typography>
          <Typography variant="h2" className={classes.boldText}>
            today
          </Typography>
        </React.Fragment>
      );
    } else if (daysTill > 0 && daysTill < 14) {
      dueInElement = (
        <div className={classes.dueIn}>
          <Typography>{daysTill}</Typography>
          <Typography>
            {pluralize('day', daysTill)} until
            <br /> settlement
          </Typography>
        </div>
      );
    } else if (daysTill >= 14 && weeksTill > 0) {
      dueInElement = (
        <div className={classes.dueIn}>
          <Typography>{weeksTill}</Typography>
          <Typography>
            {pluralize('week', weeksTill)} until
            <br /> settlement
          </Typography>
        </div>
      );
    } else {
      dueInElement = (
        <div className={classes.dueIn}>
          <Typography>Happening soon</Typography>
        </div>
      );
    }

    return dueInElement;
  };

  private renderDate() {
    const { localProposedSettlementDate, settlementDateTime, expectedSettlementDateTime, classes, isRollover } = this.props;

    if (this.isNoDateTime) {
      return (
        <Typography className={classes.dateLine}>
          <IconCalendar className={classNames(classes.iconCalender, this.iconColorClass)} />
          Date not set
        </Typography>
      );
    }

    const { workspaceLocalTime } = (settlementDateTime || expectedSettlementDateTime?.workspaceDateTime) as WorkspaceDateTimeModel;
    const settlementDate = isRollover ? workspaceLocalTime : localProposedSettlementDate || workspaceLocalTime;

    return (
      <Typography className={classes.dateLine}>
        <IconCalendar className={classNames(classes.iconCalender, this.iconColorClass)} />
        {dateTimeLine(settlementDate, 'ddd d mmm yyyy')}
      </Typography>
    );
  }

  private renderTime() {
    const {
      localProposedSettlementDate,
      settlementDateTime,
      editLink,
      declineLink,
      classes,
      workspaceStatusId,
      isLocked,
      isProposedByUser,
      settlementAwaitingAcceptanceCount,
      isAcceptedByUser,
      isRollover
    } = this.props;

    if (this.isDateOnlyOrNoDateTime) {
      return (
        <Typography className={classes.dateLine}>
          <IconClock height="22" width="18" className={classNames(classes.iconClock, this.iconColorClass)} />
          Time not set
          <NavLink to={editLink!} icon={false} className={classes.navLink}>
            Edit
          </NavLink>
        </Typography>
      );
    }
    const enableEdit = !!editLink && !isLocked && workspaceStatusId !== WorkspaceStatusEnum.Success;
    const enableDecline =
      !isProposedByUser && !isAcceptedByUser && !!settlementAwaitingAcceptanceCount && !!declineLink && !isLocked && workspaceStatusId !== WorkspaceStatusEnum.Success;

    const enableRebookLink = this.requireRebook && !this.isValidProposedSettlementDate;
    const linkText = enableRebookLink ? 'Rebook' : enableDecline ? 'Decline' : 'Edit';
    const link = enableDecline ? declineLink : enableEdit ? editLink : undefined;

    const { workspaceLocalTime } = settlementDateTime!;
    const settlementDate = isRollover ? workspaceLocalTime : localProposedSettlementDate || workspaceLocalTime;

    return (
      <Typography className={classes.dateLine}>
        <IconClock height="22" width="18" className={classNames(classes.iconClock, this.iconColorClass)} />
        {dateTimeLine(settlementDate, TIME_ONLY_DISPLAY_FORMAT)}
        {(enableEdit || enableDecline) && (
          <NavLink to={link!} icon={false} className={classes.navLink}>
            {linkText}
          </NavLink>
        )}
      </Typography>
    );
  }

  private renderBottomBox() {
    const { isAcceptedByAll, isAcceptedByUser, settlementAwaitingAcceptanceCount, workspaceStatusId, isLocked, isProposedByUser, isRollover } = this.props;

    if (this.isNoDateTime) {
      return this.renderNoDateTime();
    }

    if (this.isDateOnly) {
      return this.renderDateOnly();
    }

    const isValidAndNotLocked = this.isValidProposedSettlementDate && !isLocked && workspaceStatusId !== WorkspaceStatusEnum.Success;
    const showQuickAccept = !isAcceptedByUser && settlementAwaitingAcceptanceCount && isValidAndNotLocked;
    const showProposedAndNotAccepted = isProposedByUser && !isAcceptedByAll && isValidAndNotLocked;
    const showAcceptedByAll = isAcceptedByAll && isValidAndNotLocked;
    const showAcceptedWaitingForOthers = settlementAwaitingAcceptanceCount && isAcceptedByUser && isValidAndNotLocked;
    const showEditSettlementDate = isRollover && !isLocked && this.isWorkspaceInProgress(workspaceStatusId);

    if (showQuickAccept) {
      return this.renderToBeAcceptedByUser();
    }

    if (showProposedAndNotAccepted) {
      return this.renderProposedAndNotAccepted();
    }

    if (showAcceptedWaitingForOthers) {
      return this.renderAcceptedWaitingForOthers();
    }

    if (showEditSettlementDate) {
      return this.renderEditSettlementDate();
    }

    if (showAcceptedByAll) {
      return this.renderAcceptedByAll();
    }
  }

  private renderNoDateTime() {
    const { classes, editLink } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.editSettlementDate)}>
        <IconCalendar />
        <NavLink to={editLink!} icon={false} className={classes.whiteNavLink}>
          Propose settlement date and time
        </NavLink>
      </FlexLayout>
    );
  }

  private renderDateOnly() {
    const { classes, editLink } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.bottomBoxIcon, classes.editSettlementDate)}>
        <IconClock />
        <NavLink to={editLink!} icon={false} className={classes.whiteNavLink}>
          Propose settlement time
        </NavLink>
      </FlexLayout>
    );
  }

  private renderAcceptedWaitingForOthers() {
    const { settlementAwaitingAcceptanceCount, classes } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.acceptedWaitingForOthers)}>
        <React.Fragment>
          <IconCheck />
          <span className={classes.acceptedSpan}>You've accepted</span>,{' '}
          {`awaiting ${settlementAwaitingAcceptanceCount} ${pluralize('participant', settlementAwaitingAcceptanceCount)}.`}
        </React.Fragment>
      </FlexLayout>
    );
  }

  private renderToBeAcceptedByUser() {
    const { onAcceptClick, classes } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.toBeAcceptedByUser)}>
        <React.Fragment>
          <Link component="button" color="inherit" variant="body2" onClick={() => onAcceptClick()} className={classes.quickAcceptButton} underline="hover">
            Accept
          </Link>
          &nbsp;settlement date and time
        </React.Fragment>
      </FlexLayout>
    );
  }

  private renderProposedAndNotAccepted() {
    const { settlementAwaitingAcceptanceCount, classes } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.proposedAndNotAccepted)}>
        <React.Fragment>
          <IconCheck />
          <span className={classes.proposedNotAcceptedSpan}>You've accepted</span>,{' '}
          {`awaiting ${settlementAwaitingAcceptanceCount} ${pluralize('participant', settlementAwaitingAcceptanceCount)}.`}
        </React.Fragment>
      </FlexLayout>
    );
  }

  private renderAcceptedByAll() {
    const { classes } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.acceptedByAll)}>
        <IconCheck />
        <span className={classes.acceptedByAllSpan}>Accepted by all participants</span>
      </FlexLayout>
    );
  }

  private renderEditSettlementDate() {
    const { classes, editLink } = this.props;
    return (
      <FlexLayout alignItems="center" justifyContent="center" className={classNames(classes.bottomBox, classes.editSettlementDate)}>
        {
          <React.Fragment>
            <IconCalendar />
            <NavLink to={editLink!} icon={false} className={classes.whiteNavLink}>
              Set a new settlement date and time
            </NavLink>
          </React.Fragment>
        }
      </FlexLayout>
    );
  }

  private isWorkspaceInProgress(workspaceStatusId: WorkspaceStatusEnum) {
    return workspaceStatusId === WorkspaceStatusEnum.OnSchedule || workspaceStatusId === WorkspaceStatusEnum.ReadyForSettlement;
  }
}

export default withStyles(styles)(SettlementDateTimeCard);

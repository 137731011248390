import * as yup from 'yup';

import { validateWhenVisible } from '@sympli-mfe/document-forms-framework/validation';

import { isMortgageeNameMismatch } from '../../helpers';
import { DischargeOfMortgage2_24_0Model, MortgageePartyModel } from '../../models';
import { VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES } from '../../visibilityChecks';

// path: mortgagees
const yupMortgagees = yup.mixed<MortgageePartyModel[]>().test(
  'mortgagees[*] name mismatch',
  'Mortgagee names must match. Please select Name differs to title and provide a reason for name difference.', //
  function test(this: yup.TestContext<DischargeOfMortgage2_24_0Model>, value: any) {
    const values = this.options.context!;
    return !isMortgageeNameMismatch(values);
  }
);

export default yup //
  .mixed<MortgageePartyModel[]>()
  .test(
    validateWhenVisible({
      visibilityCheck: VISIBILITY_CHECK_MORTGAGEES_AND_TITLE_REFERENCES,
      validationWhenVisible: yupMortgagees,
      isObjectOrArray: true
    })
  );

import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import { actionFetchInvitationTitles } from '../actions';
import { DashboardTitleReferenceModel, InvitationTitlesApiRequest } from '../models';

export interface InvitationTitlesState {
  items: DashboardTitleReferenceModel[];
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  args?: InvitationTitlesApiRequest;
}

const initialState: InvitationTitlesState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  args: undefined
};

export function useInvitationTitles(id?: string) {
  const state = useStoreSelector(store => store.invitationTitles);
  if (!id) return initialState;
  if (state.args?.id === id) {
    return state;
  }
  return initialState;
}

const reducer = createReducer<
  //
  InvitationTitlesState,
  Action
>(initialState)
  .handleAction(actionFetchInvitationTitles.request, (state, action): InvitationTitlesState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';

    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchInvitationTitles.success, (state, action): InvitationTitlesState => {
    return {
      ...state,
      status: 'resolved',
      isLoading: false,
      isRefetching: false,
      items: action.payload.data.titleReferences
    };
  })
  .handleAction(
    actionFetchInvitationTitles.failure,
    (state, action): InvitationTitlesState => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    })
  );

export default reducer;

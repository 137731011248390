import { ClientWorkspaceRoleEnum, JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// Map role to workspace TYPE terminology
export const FinancialSettlementTypeMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  [WorkspaceRoleEnum.IncomingMortgagee]: 'Incoming Mortgagee',
  [WorkspaceRoleEnum.DischargingMortgagee]: 'Discharging Mortgagee',
  [WorkspaceRoleEnum.Vendor]: 'Vendor',
  [WorkspaceRoleEnum.Purchaser]: 'Purchaser',
  [WorkspaceRoleEnum.DischargingCaveator]: 'Caveator on Title',
  [WorkspaceRoleEnum.IncomingCaveator]: 'Caveat',
  [WorkspaceRoleEnum.Beneficiary]: 'Beneficiary'
};

// Map role to workspace ROLE terminology
export const FinancialRoleMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  [WorkspaceRoleEnum.DischargingMortgagee]: 'Discharging mortgagee',
  [WorkspaceRoleEnum.Vendor]: 'Vendor',
  [WorkspaceRoleEnum.Purchaser]: 'Purchaser',
  [WorkspaceRoleEnum.IncomingMortgagee]: 'Incoming mortgagee',
  [WorkspaceRoleEnum.DischargingCaveator]: 'Caveator on title',
  [WorkspaceRoleEnum.IncomingCaveator]: 'Incoming caveator',

  [WorkspaceRoleEnum.ControllingParty]: 'CoRD Holder Consent',
  [WorkspaceRoleEnum.ResponsibleSubscriber]: 'Responsible subscriber',
  [WorkspaceRoleEnum.Applicant]: 'Applicant',
  [WorkspaceRoleEnum.Beneficiary]: 'Beneficiary'
};

export const WorkspaceRoleMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  [WorkspaceRoleEnum.DischargingMortgagee]: 'Discharging mortgagee',
  [WorkspaceRoleEnum.Vendor]: 'Vendor',
  [WorkspaceRoleEnum.Purchaser]: 'Purchaser',
  [WorkspaceRoleEnum.IncomingMortgagee]: 'Incoming mortgagee',
  [WorkspaceRoleEnum.DischargingCaveator]: 'Caveator on title',
  [WorkspaceRoleEnum.IncomingCaveator]: 'Incoming caveator',
  [WorkspaceRoleEnum.ResponsibleSubscriber]: 'Responsible subscriber',
  [WorkspaceRoleEnum.Applicant]: 'Applicant',
  [WorkspaceRoleEnum.Lessee]: 'Lessee',
  [WorkspaceRoleEnum.Lessor]: 'Lessor',
  [WorkspaceRoleEnum.ControllingParty]: 'Controlling Party',
  [WorkspaceRoleEnum.Beneficiary]: 'Beneficiary'
};

export function resolveWorkspaceRole(role?: WorkspaceRoleEnum): string {
  if (role != null) {
    const type = WorkspaceRoleMapping[role];
    if (type != null) {
      return type;
    }
  }
  return 'Unknown workspace role';
}

export const RegistrationRoleMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  ...FinancialRoleMapping,
  [WorkspaceRoleEnum.Vendor]: 'Transferor',
  [WorkspaceRoleEnum.Purchaser]: 'Transferee',
  [WorkspaceRoleEnum.Lessee]: 'Lessee',
  [WorkspaceRoleEnum.Lessor]: 'Lessor'
};

export const InvitationRoleMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  ...FinancialRoleMapping,
  [WorkspaceRoleEnum.Lessee]: 'Lessee',
  [WorkspaceRoleEnum.Lessor]: 'Lessor',
  [WorkspaceRoleEnum.ControllingParty]: 'Controlling Party'
};

const WorkspaceRoleFilterMapping: Partial<Record<WorkspaceRoleEnum, string>> = {
  [WorkspaceRoleEnum.IncomingMortgagee]: 'Incoming Mortgagee',
  [WorkspaceRoleEnum.DischargingMortgagee]: 'Discharging Mortgagee',
  [WorkspaceRoleEnum.Purchaser]: 'Purchaser',
  [WorkspaceRoleEnum.Vendor]: 'Vendor',
  [WorkspaceRoleEnum.DischargingCaveator]: 'Caveator on title',
  [WorkspaceRoleEnum.IncomingCaveator]: 'Incoming Caveator',
  [WorkspaceRoleEnum.ControllingParty]: 'Controlling Party',
  [WorkspaceRoleEnum.Applicant]: 'Applicant',
  [WorkspaceRoleEnum.Lessor]: 'Lessor',
  [WorkspaceRoleEnum.Beneficiary]: 'Beneficiary'
};

export function resolveWorkspaceType(workspaceType: WorkspaceTypeEnum, role?: WorkspaceRoleEnum) {
  switch (workspaceType) {
    case WorkspaceTypeEnum.RegistrationOnly:
      return 'Standalone';
    case WorkspaceTypeEnum.FinancialSettlement:
      if (!Number.isInteger(role)) {
        return 'Financial';
      }
    // fall through
    case WorkspaceTypeEnum.Refinance:
      if (!Number.isInteger(role)) {
        return 'Refinance';
      }
    // fall through
    default:
      return resolveFinancialSettlementType(role);
  }
}

export function resolveFinancialSettlementType(role?: WorkspaceRoleEnum): string {
  if (role != null) {
    const type = FinancialSettlementTypeMapping[role];
    if (type != null) {
      return type;
    }
  }
  return 'Unknown workspace type';
}

export function resolveInvitationType(role?: WorkspaceRoleEnum): string {
  if (role != null) {
    const type = InvitationRoleMapping[role];
    if (type != null) {
      return type;
    }
  }
  return 'Unknown workspace type';
}

export function resolveFinancialRoleType<T extends number>(role: T): string {
  if (!FinancialRoleMapping.hasOwnProperty(role)) {
    return 'Unknown financial settlement role';
  }
  return FinancialRoleMapping[role]!;
}

export function resolveWorkspaceRoleFilterRoleType(role: WorkspaceRoleEnum): string {
  if (!WorkspaceRoleFilterMapping.hasOwnProperty(role)) {
    return 'Unknown workspace filter role';
  }
  return WorkspaceRoleFilterMapping[role]!;
}

export const financialWorkspaceRoles: number[] = [
  ClientWorkspaceRoleEnum.DischargingMortgagee,
  ClientWorkspaceRoleEnum.Vendor,
  ClientWorkspaceRoleEnum.Purchaser,
  ClientWorkspaceRoleEnum.IncomingMortgagee,
  ClientWorkspaceRoleEnum.Beneficiary
];

export const invitationWorkspaceRoles: WorkspaceRoleEnum[] = [
  //
  ...financialWorkspaceRoles,
  WorkspaceRoleEnum.ControllingParty,
  WorkspaceRoleEnum.Lessee,
  WorkspaceRoleEnum.Lessor,
  WorkspaceRoleEnum.DischargingCaveator
];

export const refinanceWorkspaceRoles: number[] = [
  ClientWorkspaceRoleEnum.DischargingCaveator, //
  ClientWorkspaceRoleEnum.DischargingMortgagee,
  ClientWorkspaceRoleEnum.IncomingMortgagee
];

const workspaceFilterRoles: WorkspaceRoleEnum[] = [
  WorkspaceRoleEnum.IncomingMortgagee,
  WorkspaceRoleEnum.DischargingMortgagee,
  WorkspaceRoleEnum.Purchaser,
  WorkspaceRoleEnum.Vendor,
  WorkspaceRoleEnum.DischargingCaveator,
  WorkspaceRoleEnum.Beneficiary
];

const workspaceTaskFilterRoles: WorkspaceRoleEnum[] = [
  ...workspaceFilterRoles,
  WorkspaceRoleEnum.IncomingCaveator,
  WorkspaceRoleEnum.ControllingParty,
  WorkspaceRoleEnum.Applicant,
  WorkspaceRoleEnum.Lessor
];

// invitation role mapping
export const DEFAULT_INVITATION_WORKSPACE_ROLE_OPTIONS: LookupEnumModel[] = invitationWorkspaceRoles.map(id => ({
  id,
  name: resolveInvitationType(id)
}));

// Workspace type options (based on workspace TYPE terminology)
export const DEFAULT_WORKSPACE_TYPE_OPTIONS: Array<LookupEnumModel<WorkspaceRoleEnum>> = financialWorkspaceRoles.map(id => ({ id, name: resolveFinancialSettlementType(id) }));

// This is a special workspace type enum for lodgement only
// Used in the task dashboard filter only
export const LODGEMENT_ONLY_WORKSPACE_TYPE_ENUM = 99;
// Workspace type options for task dashboard filter (including the Lodgement only)
export const WORKSPACE_TYPE_FOR_FILTERS_OPTIONS: LookupEnumModel[] = [...DEFAULT_WORKSPACE_TYPE_OPTIONS, { id: LODGEMENT_ONLY_WORKSPACE_TYPE_ENUM, name: 'Standalone' }];

// Workspace role options (based on workspace ROLE terminology)
export const DEFAULT_WORKSPACE_ROLE_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = financialWorkspaceRoles.map(id => ({ id, name: resolveFinancialRoleType(id) }));

// Workspace type options (based on workspace TYPE terminology)
export const REFINANCE_WORKSPACE_TYPE_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = refinanceWorkspaceRoles.map(id => ({ id, name: resolveFinancialSettlementType(id) }));

// Workspace role options (based on workspace ROLE terminology)
export const REFINANCE_WORKSPACE_ROLE_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = refinanceWorkspaceRoles.map(id => ({ id, name: resolveFinancialRoleType(id) }));

export const WORKSPACE_ROLE_FILTER_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = workspaceFilterRoles.map(id => ({ id, name: resolveWorkspaceRoleFilterRoleType(id) }));
export const WORKSPACE_ROLE_TASK_FILTER_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = workspaceTaskFilterRoles.map(id => ({ id, name: resolveWorkspaceRoleFilterRoleType(id) }));

export const RoleChatPermissionMapping: Partial<Record<WorkspaceRoleEnum, WorkspaceRoleEnum[]>> = {
  [WorkspaceRoleEnum.DischargingMortgagee]: [WorkspaceRoleEnum.Vendor],
  [WorkspaceRoleEnum.Vendor]: [WorkspaceRoleEnum.DischargingMortgagee, WorkspaceRoleEnum.Purchaser],
  [WorkspaceRoleEnum.Purchaser]: [WorkspaceRoleEnum.Vendor, WorkspaceRoleEnum.IncomingMortgagee],
  [WorkspaceRoleEnum.IncomingMortgagee]: [WorkspaceRoleEnum.Purchaser]
};

export function getWorkspaceRoleTypeOptions(isReapEnabled: boolean, jurisdiction: JurisdictionsEnum | null): LookupEnumModel<ClientWorkspaceRoleEnum, string>[] {
  let roles: ClientWorkspaceRoleEnum[] = [];
  if (isReapEnabled) {
    roles = [ClientWorkspaceRoleEnum.DischargingCaveator, ClientWorkspaceRoleEnum.DischargingMortgagee, ClientWorkspaceRoleEnum.IncomingMortgagee];
  } else if (jurisdiction === JurisdictionsEnum.NSW) {
    roles = [
      ClientWorkspaceRoleEnum.DischargingCaveator,
      ClientWorkspaceRoleEnum.DischargingMortgagee,
      ClientWorkspaceRoleEnum.Vendor,
      ClientWorkspaceRoleEnum.Purchaser,
      ClientWorkspaceRoleEnum.IncomingMortgagee,
      ClientWorkspaceRoleEnum.Beneficiary
    ];
  } else {
    roles = [
      ClientWorkspaceRoleEnum.DischargingCaveator,
      ClientWorkspaceRoleEnum.DischargingMortgagee,
      ClientWorkspaceRoleEnum.Vendor,
      ClientWorkspaceRoleEnum.Purchaser,
      ClientWorkspaceRoleEnum.IncomingMortgagee
    ];
  }

  return roles.map(id => ({ id, name: resolveFinancialRoleType(id) }));
}

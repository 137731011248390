import React from 'react';

import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconClipboard } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  /**
   * Text that will be copied to the clipboard
   */
  textToCopy: string;
  /**
   * Field name that will be used in the tooltip title ("Copy fieldName")
   */
  fieldName: string;
  /**
   * size - can be large or regular, if not provided, regular size is default
   */
  size?: 'large' | 'regular';

  children: React.ReactNode;

  className?: string;
}

const CopyToClipboardContainer = ({ textToCopy, fieldName, className, children, size = 'regular' }: Props) => {
  const [copied, setCopied] = React.useState(false);
  const classes = useStyles();

  //MuiTooltip needs access to the IconButton's ref  https://mui.com/guides/composition/#caveat-with-refs
  const Component = React.forwardRef((props: any, ref: React.Ref<any>) => (
    <CopyToClipboard text={textToCopy}>
      <IconButton
        {...props}
        ref={ref}
        classes={{
          root: classNames(classes.button, size === 'large' ? classes.buttonLarge : classes.buttonRegular)
        }}
        aria-label="Copy"
        onClick={e => {
          //   /**
          //    * To make sure the redirection not happening when using within the nav link
          //    * we need to prevent the default event been triggered or propagate to the parent
          //    */
          e.stopPropagation();
          e.preventDefault();
          setCopied(true);
        }}
        size="large"
      >
        <IconClipboard className={classes.icon} />
      </IconButton>
    </CopyToClipboard>
  ));

  return (
    <FlexLayout className={classNames(classes.container, className)}>
      {children}
      <Tooltip
        disableInteractive
        classes={
          copied
            ? {
                tooltip: classes.darkTooltip,
                arrow: classes.darkArrow
              }
            : {
                tooltip: classes.tooltip
              }
        }
        title={copied ? 'Copied' : `Copy ${fieldName}`}
        aria-label={textToCopy}
        placement="top"
        arrow
        TransitionProps={{
          onExited: () => {
            setCopied(false);
          }
        }}
      >
        <Component />
      </Tooltip>
    </FlexLayout>
  );
};

export default CopyToClipboardContainer;

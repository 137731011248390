import * as React from 'react';

import { Form, FormikProps } from 'formik';
import { NavigateFunction } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import { GroupOptionModel, SubscriberAssignableGroupsApiResponse } from 'src/containers/dashboard/shared/models';
import { resolveDocumentDetailLink } from 'src/containers/documents/helpers';
import { fromRepresentationSubscriberTypeToApiQuery } from 'src/containers/workspace/financial/detail/components/invite-additional-participants/helpers';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { CreateDocumentsItemModel } from '../../../reg-only-form/models';
import { prepareFinSubmitData } from '../../helpers';
import {
  CreateFinancialWorkspaceInviteParticipantsStepFormModel,
  CreateFinancialWorkspaceMatterDetailsStepResponse,
  CreateFinancialWorkspaceSharedModel,
  CreateFinancialWorkspaceStepEnum
} from '../../models';
import { getRepresentationSubscriberType, isLinkedWorkspaceRequired } from '../matter-detail/helpers';
import AdditionalInvitations from './components/additional-invitations';
import Invitations from './components/invitations';
import { getAdditionalParticipantRoleConfigs, getDefaultInvitation, resolveDocumentByWorkspaceRole, setInvitationDefaultGroupId } from './helpers';
import { CreateFinancialWorkspaceInvitationsStepRequest, CreateFinancialWorkspaceInvitationsStepResponse } from './models';
import styles, { ClassKeys } from './styles';
import getValidationSchema from './validationSchema';

export interface OwnProps {
  workspaceTypeId: WorkspaceTypeEnum;
  onGlobalValuesUpdate: (value?: Partial<CreateFinancialWorkspaceSharedModel>) => CreateFinancialWorkspaceSharedModel;
  onStepChange: (step: CreateFinancialWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  currentSubscriberData: UserProfileModel;
  assignableGroups: SubscriberAssignableGroupsApiResponse;
  navigate: NavigateFunction;
  isTransferEAPEnabled: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

type FormModel = CreateFinancialWorkspaceInviteParticipantsStepFormModel;

class InviteParticipants extends React.PureComponent<Props> {
  private formikProps: FormikProps<FormModel>;
  private globalValues: CreateFinancialWorkspaceSharedModel = this.props.onGlobalValuesUpdate();

  // ! for the release version2.0 and ticket web-5021 by default we will select all vendors
  private maximumInvitationNumber: number = 1;

  private get isInteroperable(): boolean | undefined {
    return this.globalValues.matterDetailsStepData.isInteroperable;
  }

  private get jurisdictionId(): JurisdictionsEnum {
    // when user reached the invite step, this property won't be null
    return this.globalValues.matterDetailsStepData.jurisdictionId as JurisdictionsEnum;
  }

  private get createdByRoleId(): WorkspaceRoleEnum {
    // when user reached the invite step, this property won't be null
    return this.globalValues.matterDetailsStepData.createdByRoleId as WorkspaceRoleEnum;
  }

  private get matterDetailsStepApiResponse(): CreateFinancialWorkspaceMatterDetailsStepResponse {
    // when user reached the invite step, this property won't be undefined
    return this.globalValues.matterDetailsStepApiResponse as CreateFinancialWorkspaceMatterDetailsStepResponse;
  }

  private get subscriberAssignableGroups(): GroupOptionModel[] {
    const { assignableGroups } = this.props;
    const jurisdictionId = this.globalValues.matterDetailsStepData.jurisdictionId;
    return assignableGroups.filter(x => x.jurisdictions.some(x => x.id === jurisdictionId));
  }

  private get defaultGroupId(): string {
    const {
      matterDetailsStepData: { groupId }
    } = this.globalValues;
    return this.subscriberAssignableGroups.length > 0 ? this.subscriberAssignableGroups[0].id : groupId;
  }

  private getInitialValues = (): CreateFinancialWorkspaceInviteParticipantsStepFormModel => {
    const {
      matterDetailsStepData: {
        isControllingParty,
        titleInformation: { titleVerificationResult },
        createdByReference
      },
      inviteParticipantsStepData
    } = this.globalValues;

    const { vendors, invitableRoles } = this.matterDetailsStepApiResponse;

    const initialValue: CreateFinancialWorkspaceInviteParticipantsStepFormModel = {
      ...inviteParticipantsStepData,
      invitations: invitableRoles.map(role => getDefaultInvitation(this.defaultGroupId, createdByReference, role)),
      additionalInvitations: inviteParticipantsStepData.additionalInvitations.map(x => setInvitationDefaultGroupId(x, this.defaultGroupId))
    };

    if (vendors.length) {
      initialValue.vendors = vendors.map(vendor => vendor.id);
    }

    // WEB-16073 - display a invite participant field to invite CP as party to a linked workspace
    // field is displayed when the workspace creator did not chose to be a controlling party
    // Note: incase the workspace creator chose to be a CP, then an invite is generated within prepareFinSubmitData()
    if (isLinkedWorkspaceRequired(this.jurisdictionId, titleVerificationResult?.hasPaperTitle) && !isControllingParty) {
      initialValue.invitedRoles = {
        ...initialValue.invitedRoles,
        [WorkspaceRoleEnum.ControllingParty]: true
      };
    }

    return initialValue;
  };

  render() {
    const { currentSubscriberData } = this.props;
    const { allowedAdditionalParticipantRoles } = this.matterDetailsStepApiResponse;
    const additionalParticipantRoleConfigs = getAdditionalParticipantRoleConfigs(allowedAdditionalParticipantRoles);

    const validationSchema = getValidationSchema(
      //
      this.createdByRoleId,
      currentSubscriberData,
      additionalParticipantRoleConfigs
    );
    return (
      <Formik //
        method="post"
        action="/workspaces"
        getInitialValues={this.getInitialValues}
        validationSchema={validationSchema}
        onPreSubmit={this.handleOnPreSubmit}
        onPostSubmit={this.handleOnPostSubmit}
        validateOnMount={true}
      >
        {(formikProps: FormikProps<FormModel>) => this.renderForm(formikProps)}
      </Formik>
    );
  }

  private get disableContinue() {
    const { isValid, isSubmitting } = this.formikProps;
    if (!isValid || isSubmitting) {
      return true;
    }

    return false;
  }

  private get anyInvitationSelected() {
    return (
      Object.keys(this.formikProps.values.invitedRoles).some(role => this.formikProps.values.invitedRoles[role]) ||
      Boolean(
        this.formikProps.values.additionalInvitations.length && this.formikProps.values.additionalInvitations.every(additionalInvitation => additionalInvitation.role !== undefined)
      )
    );
  }

  private renderForm(formikProps: FormikProps<FormModel>) {
    this.formikProps = formikProps;

    // We have all possible invitations here and do the condition filtering in invitedRoles object
    return <Form>{this.renderInvitationForm(formikProps)}</Form>;
  }

  private renderInvitationForm(formikProps: FormikProps<FormModel>) {
    const { classes, isTransferEAPEnabled, currentSubscriberData } = this.props;
    const { isSubmitting } = formikProps;
    const matterDetailsStepApiResponse = this.matterDetailsStepApiResponse;
    const createdByRoleId = this.globalValues.matterDetailsStepData.createdByRoleId;
    const representationSubscriberType = getRepresentationSubscriberType(isTransferEAPEnabled, currentSubscriberData.subscriberOrganisationType);
    const representationSubscriberTypeToApiQuery = fromRepresentationSubscriberTypeToApiQuery(createdByRoleId, representationSubscriberType);

    return (
      <>
        <FlexLayout alignItems="center">
          <Typography variant="subtitle1">Workspace roles</Typography>
          <Typography //
            className={classes.description}
          >
            Invite others to fill these roles or assume them yourself.
          </Typography>
        </FlexLayout>
        <Divider className={classes.divider} />
        {matterDetailsStepApiResponse.invitableRoles.map(roleId => {
          return (
            <Invitations //
              isInteroperable={this.isInteroperable}
              jurisdictionId={this.jurisdictionId}
              roleId={roleId}
              groups={this.subscriberAssignableGroups}
              key={`invitation-${roleId}`}
              representationSubscriberType={representationSubscriberTypeToApiQuery}
            />
          );
        })}
        <AdditionalInvitations //
          isInteroperable={this.isInteroperable}
          jurisdictionId={this.jurisdictionId}
          groups={this.subscriberAssignableGroups}
          allowedRoleIds={matterDetailsStepApiResponse.invitableRoles}
          currentParticipantGroupId={this.defaultGroupId}
          representationSubscriberType={representationSubscriberTypeToApiQuery}
        />
        <WizardStepper //
          onBack={this.handleOnClickBack}
          nextLabel="Continue"
          isLoading={isSubmitting}
          disabled={this.disableContinue || !this.anyInvitationSelected}
        >
          <div className="mr-[16px] flex grow flex-row-reverse">
            <SympliButton //
              color="primary"
              variant="outlined"
              disabled={isSubmitting}
              onClick={() => this.handleSkipOnClick(formikProps)}
            >
              Skip this step
            </SympliButton>
          </div>
        </WizardStepper>
      </>
    );
  }

  private handleOnClickBack = () => {
    this.props.onStepChange(CreateFinancialWorkspaceStepEnum.AddMatterDetails);
  };

  private handleSkipOnClick = (formikProps: FormikProps<FormModel>) => {
    const { submitForm, resetForm } = formikProps;
    resetForm();
    submitForm();
  };

  private handleOnPreSubmit = (
    //
    values: CreateFinancialWorkspaceInviteParticipantsStepFormModel
  ): CreateFinancialWorkspaceInvitationsStepRequest => {
    const { currentSubscriberData } = this.props;
    const finSubmitData = prepareFinSubmitData({ ...this.globalValues, inviteParticipantsStepData: values }, currentSubscriberData);
    return {
      //
      workspaceTypeId: this.props.workspaceTypeId,
      ...finSubmitData
    };
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<FormModel, CreateFinancialWorkspaceInvitationsStepResponse>) => {
    if (args.error) {
      return;
    }

    const { response, formValues } = args;
    if (response.isSuccess) {
      const { workspaceId, createdByParticipantId, createdDocuments } = response;
      const redirectDocument = resolveDocumentByWorkspaceRole(createdDocuments, this.createdByRoleId);
      const link = this.resolveRedirectLink(workspaceId, createdByParticipantId, redirectDocument);
      this.props.onClose(link); // redirect user directly to document detail screen
    } else {
      this.props.onGlobalValuesUpdate({
        //
        inviteParticipantsStepData: formValues,
        inviteParticipantsStepApiResponse: response
      });
      this.props.onStepChange(CreateFinancialWorkspaceStepEnum.AddMatterDetails);
    }
  };

  private resolveRedirectLink = (workspaceId: string, participantId: string, redirectDocument?: CreateDocumentsItemModel) => {
    if (redirectDocument) {
      return resolveDocumentDetailLink({ workspaceId, participantId, documentId: redirectDocument.id });
    } else {
      const scope = Logger.scopeWithCustomAttributes({ workspaceId, participantId });
      Logger.captureException(new BusinessLogicError('No redirectDocument found.'), scope);

      return resolveWorkspaceDetailLink({ workspaceId, participantId });
    }
  };
}

export default withStyles(styles)(InviteParticipants);

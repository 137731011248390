import * as yup from 'yup';

import { FractionModel, ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { validateWhenVisible2 } from '@sympli-mfe/document-forms-framework/validation';

import PartyValidations from 'src/containers/documents/party-merge/PartyValidations';
import { resolveDeceasedProprietorSchema } from './components/deceased-proprietor/validationSchema';
import { DeceasedTenancyDetailValidationConfiguration } from './config';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedProprietorModel, DeceasedTenancyDetailModel } from './models';
import { VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP } from './visibilityChecks';

const yupDeceasedProprietorGroups = (config?: DeceasedTenancyDetailValidationConfiguration) =>
  yup
    .array<DeceasedProprietorGroupModel>(
      yup
        .object<DeceasedProprietorGroupModel>({
          parties: yup
            .array<DeceasedProprietorModel>()
            .when(['isSelected', 'proprietorGroupType'], (isSelected: boolean, proprietorGroupType: ProprietorGroupTypeEnum, schema: yup.ArraySchema<DeceasedProprietorModel>) => {
              return isSelected
                ? schema.of<DeceasedProprietorModel>(resolveDeceasedProprietorSchema(proprietorGroupType, config))
                : schema.of<DeceasedProprietorModel>(yup.mixed<DeceasedProprietorModel>());
            })
            .required()
            .defined(),
          shareFraction: yup.mixed<FractionModel>(),
          isSelected: yup
            .boolean()
            .testContextualRule({
              uniqueTestName: 'At least one proprietor must be selected',
              onlyIf: ({ proprietorGroupType, isSelected }: DeceasedProprietorGroupModel, { deceasedTenancyDetail: { tenancyType } }: BaseDeceasedTenancyDetailComponentModel) =>
                (tenancyType === TenancyTypeEnum.JointTenants || proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse) && isSelected,
              requirement: ({ parties }: DeceasedProprietorGroupModel) => parties.some((p: DeceasedProprietorModel) => p.isSelected),
              message: 'You must specify the recently deceased party'
            })
            .defined(),
          proprietorGroupType: yup.mixed()
        })
        .defined()
    )
    .defined()
    .log();

export const yupDeceasedTenancyDetailSchema = (getLegalEntityName: (party: any) => string | undefined, config?: DeceasedTenancyDetailValidationConfiguration) =>
  yup
    .object<DeceasedTenancyDetailModel>({
      tenancyType: yup.mixed(),
      proprietorGroups: validateWhenVisible2<DeceasedProprietorGroupModel[]>({
        isObjectOrArray: true,
        visibilityCheck: (parent: DeceasedTenancyDetailModel) => VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP(parent),
        validationWhenVisible: yupDeceasedProprietorGroups(config),
        disableEarlyAbort: true
      })
    })
    .testContextualRule({
      uniqueTestName: 'At least one Proprietor group must be selected',
      onlyIf: ({ deceasedTenancyDetail }: BaseDeceasedTenancyDetailComponentModel) => VISIBILITY_CHECK_DECEASED_PROPRIETOR_GROUP(deceasedTenancyDetail),
      requirement: ({ deceasedTenancyDetail: { proprietorGroups } }: BaseDeceasedTenancyDetailComponentModel) => proprietorGroups.some(pg => pg.isSelected),
      message: 'Please select a proprietor group'
    })
    .test(
      //
      'Deceased Proprietors requires name change test',
      'The parties on the selected titles do not match; please provide name justification or remove the mismatched titles.',
      function test(this: yup.TestContext<BaseDeceasedTenancyDetailComponentModel>) {
        const { partyBook, deceasedTenancyDetail } = this.options.context!;

        const validationResult = PartyValidations.verifyJustification(partyBook, getLegalEntityName);
        if (validationResult) return true;

        const selectedDeceasedProprietorId = deceasedTenancyDetail.proprietorGroups.find(pg => pg.isSelected)?.parties.find(p => p.isSelected)?.partyBookId;
        const deceasedProprietorName = partyBook.find(pb => pb.id === selectedDeceasedProprietorId)?.legalEntityName;

        const uniqueNamesRequiringJustification = partyBook
          .filter(pb => pb.mergeMetadata?.requiresJustification)
          .reduce((partyNames: string[], party) => {
            if (!partyNames.includes(party.legalEntityName!)) {
              partyNames.push(party.legalEntityName!);
            }
            return partyNames;
          }, []);

        if (deceasedProprietorName !== undefined) {
          const duplicatePartyNamesRequiringJustification = partyBook.filter(pb => pb.legalEntityName === deceasedProprietorName && pb.mergeMetadata?.requiresJustification);
          const otherPartiesRequiringJustification = uniqueNamesRequiringJustification.filter(name => name !== deceasedProprietorName);

          return duplicatePartyNamesRequiringJustification.length > 1 || otherPartiesRequiringJustification.length > 1
            ? this.createError({ message: 'The parties on the selected titles do not match and will require multiple justifications; please remove the mismatched titles.' })
            : false;
        }

        return uniqueNamesRequiringJustification.length > 2
          ? this.createError({ message: 'The parties on the selected titles do not match and will require multiple justifications; please remove the mismatched titles.' })
          : false;
      }
    )
    .test(
      'Deceased Proprietors Sole Proprietor party capacity check',
      'The party has a party capacity; it is not supported for the document.',
      function test(this: yup.TestContext<BaseDeceasedTenancyDetailComponentModel>) {
        const { deceasedTenancyDetail } = this.options.context!;

        if (!config?.disableProprietorPartyCapacity || deceasedTenancyDetail.tenancyType !== TenancyTypeEnum.SoleProprietor) return true;

        const proprietors = deceasedTenancyDetail.proprietorGroups[0].parties;
        return proprietors.every(p => !p.partyCapacity?.capacity);
      }
    )
    .defined();

import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DocumentPermissionEnum, DocumentStatusEnum, DocumentWorkflowTypeEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { DocumentWorkflowPanelProps } from 'src/containers/documents/components/document-workflow-panel';
import { resolveReviewDocumentActionInfo } from 'src/containers/workspace/shared/detail/selectors';
import { useSafeDispatch } from 'src/hooks';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from '../../models';
import { reviewDocument } from '../shared/helpers';
import ViewDocument from './ViewDocument';

interface Props {
  queryParams: DocumentPageRouteParams;
  documentDetail: DocumentFormAndDetailModel;
  jurisdictionId?: JurisdictionsEnum;
  workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'>;
  documentIds: string[];
  displayDocumentAsPdf: boolean;
  updateWorkflowStep?: (status: DocumentStatusEnum) => void;
  currentParticipant?: WorkspaceParticipantApiResponse;
}

function ViewDocumentContainer({
  //
  queryParams,
  documentDetail,
  jurisdictionId,
  workflowPanelProps,
  documentIds,
  displayDocumentAsPdf,
  updateWorkflowStep,
  currentParticipant
}: Props) {
  const navigate = useNavigate();
  const [isWorkflowLoading, setIsWorkflowLoading] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());
  const { documentWorkflowType } = documentDetail;
  const isWorkflowStandard = documentWorkflowType === DocumentWorkflowTypeEnum.Standard;
  const nextActionLabel = isWorkflowStandard ? 'Save and approve' : 'Save and review';
  const hasPermissionToReview = documentDetail.documentPermissions.includes(DocumentPermissionEnum.Review);
  const nextActionDisabled = !isWorkflowStandard || isWorkflowLoading || !hasPermissionToReview;
  const { participantId } = queryParams;
  const reviewActionInfo = resolveReviewDocumentActionInfo({ detail: documentDetail, participantId });

  const handleReviewClick = React.useCallback(() => {
    reviewDocument(queryParams, documentDetail, documentIds, dispatch, navigate, setIsWorkflowLoading, updateWorkflowStep);
  }, [dispatch, documentDetail, documentIds, navigate, queryParams, updateWorkflowStep]);

  return (
    <ViewDocument
      queryParams={queryParams}
      documentDetail={documentDetail}
      jurisdictionId={jurisdictionId}
      workflowPanelProps={workflowPanelProps}
      reviewActionInfo={reviewActionInfo}
      nextActionLabel={nextActionLabel}
      nextActionDisabled={nextActionDisabled}
      isWorkflowLoading={isWorkflowLoading}
      isWorkflowStandard={isWorkflowStandard}
      displayDocumentAsPdf={displayDocumentAsPdf}
      handleOnClick={handleReviewClick}
      currentParticipant={currentParticipant}
    />
  );
}
export default React.memo(ViewDocumentContainer);

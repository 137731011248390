import React, { createContext, useContext } from 'react';

import { EvidenceJurisdictionEnum } from '@sympli-mfe/enums-shared/necds';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

import { DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS, DateOfDeathTypeEnum } from '../components/date-of-death/enums';
import { EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS, EvidenceTypeEnum } from '../components/evidence/enums';
import { DeceasedTenancyPartyCapacityEnum } from '../enums';

export interface PartyCapacityLookup {
  capacity: DeceasedTenancyPartyCapacityEnum;
  evidenceTypes: LookupItemModel<EvidenceTypeEnum>[];
}

export class DeceasedTenancyContextData {
  partyCapacities: LookupItemModel<DeceasedTenancyPartyCapacityEnum>[];
  maxEvidences: number;
  dateOfDeathLookupOptions: LookupItemModel<DateOfDeathTypeEnum>[];
  evidenceJurisdictionOptions: LookupEnumModel<EvidenceJurisdictionEnum>[];
  /**
   *
   */
  constructor(
    public partyCapacityLookups: PartyCapacityLookup[],
    public showPartyRoleCapacityDescription?: boolean,
    public disableDefaultAddedEvidenceType?: boolean,
    public disableEvidenceType?: boolean,
    maxEvidences?: number,
    dateOfDeathLookupOptions?: LookupItemModel<DateOfDeathTypeEnum>[],
    public disableJointTenants?: boolean,
    evidenceJurisdictionOptions?: LookupEnumModel<EvidenceJurisdictionEnum>[],
    public disableProprietorCapacity?: boolean
  ) {
    this.partyCapacities = partyCapacityLookups.map(p => ({ id: p.capacity, name: p.capacity }));
    this.disableDefaultAddedEvidenceType = disableDefaultAddedEvidenceType;
    this.showPartyRoleCapacityDescription = showPartyRoleCapacityDescription;
    this.disableEvidenceType = disableEvidenceType;
    this.maxEvidences = maxEvidences ?? 20;
    this.dateOfDeathLookupOptions = dateOfDeathLookupOptions ?? DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS;
    this.disableJointTenants = disableJointTenants;
    this.evidenceJurisdictionOptions = evidenceJurisdictionOptions ?? [];
  }

  public GetEvidenceTypes(defaultAdded: boolean, isSelected: boolean, partyCapacity?: DeceasedTenancyPartyCapacityEnum | null): LookupItemModel<EvidenceTypeEnum>[] {
    if (!isSelected) {
      return [{ id: EvidenceTypeEnum.DeathCertificate, name: EvidenceTypeEnum.DeathCertificate }];
    }

    if (!partyCapacity || !defaultAdded) {
      return EVIDENCE_TYPE_VALUE_LOOKUP_OPTIONS;
    }

    const evidenceTypes = this.partyCapacityLookups.find(pcl => pcl.capacity === partyCapacity)!.evidenceTypes;
    return evidenceTypes;
  }
}

const DeceasedTenancyContext = createContext<DeceasedTenancyContextData>(new DeceasedTenancyContextData([]));

type DeceasedTenancyContextProviderProps = Omit<React.ComponentProps<typeof DeceasedTenancyContext.Provider>, 'value'> & {
  config: DeceasedTenancyContextData;
};

export function DeceasedTenancyContextProvider({ children, config }: DeceasedTenancyContextProviderProps): JSX.Element {
  const contextValue = React.useMemo(() => config, [config]);
  return <DeceasedTenancyContext.Provider value={contextValue}>{children}</DeceasedTenancyContext.Provider>;
}

export const useDeceasedTenancyContext = () => useContext(DeceasedTenancyContext);

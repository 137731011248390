import { NSW_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/nsw/2-21/config';
import { resolveMemoizedTenancyValidation } from '@sympli-mfe/document-forms-framework/components/sections/tenancy/receiving';

const yupLessees = resolveMemoizedTenancyValidation({
  //
  isAddressRequired: false,
  partyFormConfig: NSW_PARTY_FORM_CONFIG
});

export default yupLessees;

import { LookupItemModel } from '@sympli/ui-framework/models';

import { GroupedOptions } from 'src/components/multiple-select-checkbox/MultipleSelectCheckbox';

export enum FilterEnum {
  AccessedDate = 0, // only shown up in the Recent tab
  SettlementDate = 1,
  Jurisdiction = 2,
  MyRole = 3,
  MyTasks = 4,
  DocumentsStatus = 5,
  FinancialStatus = 6,
  FundsStatus = 7,
  WorkspaceStatus = 8,
  Groups = 9,
  Members = 10,
  ArchivedDate = 11,
  Messages = 12
}

export const FilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.SettlementDate,
    name: 'Settlement Date'
  },
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyRole,
    name: 'My Role'
  },
  {
    id: FilterEnum.MyTasks,
    name: 'My Tasks'
  },
  {
    id: FilterEnum.DocumentsStatus,
    name: 'Document Status'
  },
  {
    id: FilterEnum.FinancialStatus,
    name: 'Financial Status'
  },
  {
    id: FilterEnum.FundsStatus,
    name: 'Funds Status'
  },
  {
    id: FilterEnum.WorkspaceStatus,
    name: 'Workspace Status'
  },
  {
    id: FilterEnum.Messages,
    name: 'Messages'
  }
];

// jurisdictions are required we fetch from backend

// my tasks
export enum MyTaskEnum {
  CompleteDocuments = 0,
  ReviewDocuments = 1,
  SignDocuments = 2,
  ResolveDocumentIssues = 3,
  CompleteFinancials = 4,
  ReviewFinancials = 5,
  SignFinancials = 6,
  VerifyStampDuty = 7,
  SignTrustAccountAuthorisation = 8,
  ReviewSettlementDate = 9,
  RebookSettlementDate = 10
}

export const MyTaskGroupOptions: GroupedOptions<number>[] = [
  {
    title: 'Documents',
    options: [
      {
        id: MyTaskEnum.CompleteDocuments,
        name: 'Complete'
      },
      {
        id: MyTaskEnum.ReviewDocuments,
        name: 'Review'
      },
      {
        id: MyTaskEnum.SignDocuments,
        name: 'Sign'
      }
    ]
  },
  {
    title: 'Financials',
    options: [
      {
        id: MyTaskEnum.CompleteFinancials,
        name: 'Complete'
      },
      {
        id: MyTaskEnum.ReviewFinancials,
        name: 'Review'
      },
      {
        id: MyTaskEnum.SignFinancials, // this should include sign trust account as well
        name: 'Sign'
      }
    ]
  },
  {
    title: 'Settlement Date & Time',
    options: [
      {
        id: MyTaskEnum.ReviewSettlementDate,
        name: 'Review'
      },
      {
        id: MyTaskEnum.RebookSettlementDate,
        name: 'Rebook'
      }
    ]
  }
];

// my roles
export enum MyRoleEnum {
  IncomingMortgagee = 0,
  DischargeMortgagee = 1,
  Purchaser = 2,
  Vendor = 3,
  CaveatorOnTitle = 4,
  Beneficiary = 5
}

export const MyRoleOptions: LookupItemModel<number>[] = [
  {
    id: MyRoleEnum.IncomingMortgagee,
    name: 'Incoming Mortgagee'
  },
  {
    id: MyRoleEnum.DischargeMortgagee,
    name: 'Discharge Mortgagee'
  },
  {
    id: MyRoleEnum.Purchaser,
    name: 'Purchaser'
  },
  {
    id: MyRoleEnum.Vendor,
    name: 'Vendor'
  },
  {
    id: MyRoleEnum.CaveatorOnTitle,
    name: 'Caveator On Title'
  },
  {
    id: MyRoleEnum.Beneficiary,
    name: 'Beneficiary'
  }
];

// Documents Status
export enum DocumentsStatusEnum {
  InPreparation = 0,
  Prepared = 1,
  InPreparationWithErrors = 2,
  Ready = 3,
  Lodging = 4,
  Lodged = 5,
  Completed = 6
}

export const DocumentsStatusOptions: LookupItemModel<number>[] = [
  {
    id: DocumentsStatusEnum.InPreparation,
    name: 'In Preparation'
  },
  {
    id: DocumentsStatusEnum.InPreparationWithErrors,
    name: 'In Preparation (Errors)'
  },
  {
    id: DocumentsStatusEnum.Prepared,
    name: 'Prepared'
  },
  {
    id: DocumentsStatusEnum.Ready,
    name: 'Ready'
  },
  {
    id: DocumentsStatusEnum.Lodging,
    name: 'Lodging'
  },
  {
    id: DocumentsStatusEnum.Lodged,
    name: 'Lodged'
  },
  {
    id: DocumentsStatusEnum.Completed,
    name: 'Completed'
  }
];

export enum WorkspaceMessageStatusEnum {
  Read = 0,
  Unread = 1
}

export const WorkspaceMessageStatusOptions: LookupItemModel<number>[] = [
  {
    id: WorkspaceMessageStatusEnum.Unread,
    name: 'Unread Messages'
  },
  {
    id: WorkspaceMessageStatusEnum.Read,
    name: 'Read Messages'
  }
];

// Financial Status
export enum FinancialStatusEnum {
  InPreparation = 0,
  InPreparationWithErrors = 1,
  Ready = 2,
  Settling = 3,
  Settled = 4,
  Disbursing = 5,
  Disbursed = 6
}

export const FinancialStatusOptions: LookupItemModel<number>[] = [
  {
    id: FinancialStatusEnum.InPreparation,
    name: 'In Preparation'
  },
  {
    id: FinancialStatusEnum.InPreparationWithErrors,
    name: 'In Preparation (Errors)'
  },
  {
    id: FinancialStatusEnum.Ready,
    name: 'Ready'
  },
  {
    id: FinancialStatusEnum.Settling,
    name: 'Settling'
  },
  {
    id: FinancialStatusEnum.Settled,
    name: 'Settled'
  },
  {
    id: FinancialStatusEnum.Disbursing,
    name: 'Disbursing'
  },
  {
    id: FinancialStatusEnum.Disbursed,
    name: 'Disbursed'
  }
];

// Funds Status
export enum FundsStatusEnum {
  Balanced = 0,
  Unbalanced = 1
}

export const FundsStatusOptions: LookupItemModel<number>[] = [
  {
    id: FundsStatusEnum.Balanced,
    name: 'Balanced'
  },
  {
    id: FundsStatusEnum.Unbalanced,
    name: 'Unbalanced'
  }
];

// Workspace Status
export enum WorkspaceStatusEnum {
  InPreparation = 0,
  InPreparationWithErrors = 1,
  Ready = 2,
  Settling = 3,
  SettlingWithErrors = 4,
  Settled = 5,
  Archived = 6,
  Withdrawn = 7,
  Abandoned = 8
}

export const WorkspaceStatusOptions: LookupItemModel<number>[] = [
  {
    id: WorkspaceStatusEnum.InPreparation,
    name: 'In Preparation'
  },
  {
    id: WorkspaceStatusEnum.InPreparationWithErrors,
    name: 'In Preparation (Errors)'
  },
  {
    id: WorkspaceStatusEnum.Ready,
    name: 'Ready'
  },
  {
    id: WorkspaceStatusEnum.Settling,
    name: 'Settling'
  },
  {
    id: WorkspaceStatusEnum.SettlingWithErrors,
    name: 'Settling (Errors)'
  },
  {
    id: WorkspaceStatusEnum.Settled,
    name: 'Settled'
  }
];

export const WorkspaceArchivedStatusOptions: LookupItemModel<number>[] = [
  {
    id: WorkspaceStatusEnum.Archived,
    name: 'Archived'
  },
  {
    id: WorkspaceStatusEnum.Withdrawn,
    name: 'Withdrawn'
  },
  {
    id: WorkspaceStatusEnum.Abandoned,
    name: 'Abandoned'
  }
];

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    tooltip: {
      color: 'black',
      width: 'auto',
      fontSize: 13,
      boxShadow: theme.shadows[1]
    }
  }),
  {
    name: 'ConditionalTooltip'
  }
);

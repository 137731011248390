import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipantSettings, WorkspaceParticipantSettingState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipantSetting';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { DirectionCommonProps } from '../../models';
import EditDirections from './EditDirections';

interface Props extends DirectionCommonProps {
  isLocked: boolean;
  onDialogToggle: (dialogOpen: boolean) => void;
  dialogOpen: boolean;
}

function EditDirectionsContainer(props: Props) {
  const {
    queryParams: { workspaceId, participantId },
    ...rest
  } = props;

  const dispatch = useSafeDispatch(useDispatch());
  const navigate = useNavigate();
  const isFormLoading = useStoreSelector(store => store.submitFormik.isLoading);
  const workspaceParticipantSetting: WorkspaceParticipantSettingState = useWorkspaceParticipantSettings(workspaceId, participantId);
  const workspaceDetailsState = useWorkspaceDetail(workspaceId, participantId);

  return (
    <EditDirections //
      {...rest}
      queryParams={props.queryParams}
      dispatch={dispatch}
      isFormLoading={isFormLoading}
      workspaceParticipantSetting={workspaceParticipantSetting}
      navigate={navigate}
      requiresStampDuty={workspaceDetailsState.detail?.requiresStampDuty ?? false}
    />
  );
}

export default React.memo(EditDirectionsContainer);

import React from 'react';

import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import Formik from '@sympli/ui-framework/components/formik';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import msg from '@sympli/ui-framework/utils/messages';

export interface DeclineInviteFormValues {
  invitationId: string;
  workspaceId: string;
  note: string;
}

const fieldName = modelKey<DeclineInviteFormValues>();

interface DeclineInviteFormProps {
  invitationId: string;
  workspaceId: string;

  onCancel(): void;
  onSubmit(data: DeclineInviteFormValues): Promise<void>;
}

const NOTE_MAX_LENGTH = 250;
const NOTE_MIN_LENGTH = 10;
const validationSchema = yup.object<DeclineInviteFormValues>({
  invitationId: yup.mixed<string>(),
  workspaceId: yup.mixed<string>(),
  note: yup //
    .string()
    .trim()
    .required(msg.REQUIRED)
    .trim()
    .min(NOTE_MIN_LENGTH, msg.LENGTH_MUST_BE_AT_LEAST_CHARACTERS(NOTE_MIN_LENGTH))
    .max(NOTE_MAX_LENGTH, msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(NOTE_MAX_LENGTH))
});

function DeclineInviteForm({
  //
  invitationId,
  workspaceId,
  onCancel,
  onSubmit
}: DeclineInviteFormProps) {
  const initialValues: DeclineInviteFormValues = React.useMemo<DeclineInviteFormValues>(
    () => ({
      invitationId,
      workspaceId,
      note: ''
    }),
    [invitationId, workspaceId]
  );

  const fieldClasses = {
    inputLabel: 'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]',
    formControl: 'mt-[28px]',
    marginBottom: 'mb-[24px]'
  };

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty, values }: FormikProps<DeclineInviteFormValues>) => {
        const note = (values.note ?? '').trim();
        const descriptionLengthText = `${note.length}/${NOTE_MAX_LENGTH}`;

        return (
          <Form className="flex grow flex-col justify-between">
            <div className="relative w-full">
              <Field //
                component={InputField}
                classes={fieldClasses}
                format="string"
                label="Decline Note"
                placeholder="Enter your message"
                name={fieldName('note')}
                multiline
                minRows={6}
                maxRows={10}
                fullWidth
                disabled={isSubmitting}
              />
              <div //
                className={classNames(
                  'absolute bottom-[8px] right-0 text-[12px] font-[400] leading-[12px]',
                  isValid ? 'text-[var(--neutral-600)]' : 'text-[var(--mui-palette-error-main)]'
                )}
              >
                {descriptionLengthText}
              </div>
            </div>
            <div className="flex justify-center gap-[8px]">
              <Button
                //
                disabled={isSubmitting}
                className="rounded-[34px] border-[2px]  px-[24px] py-[6px]"
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting}
                disabled={isSubmitting || !(isValid && dirty)}
                type="submit"
                variant="contained"
                color="primary"
                className="rounded-[34px] px-[24px] py-[6px]"
              >
                Decline
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(DeclineInviteForm);

import React, { memo, useEffect, useMemo } from 'react';

import { batch, useDispatch } from 'react-redux';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { AssignmentTypeEnum, DashboardDateRangeModel, DashboardTypeEnum, FilterDataModel, UNASSIGNED_OPTION } from 'src/containers/dashboard/shared/models';
import { useSafeDispatch, useStoreSelector } from 'src/hooks';
import { actionFetchUserJurisdictions, actionFetchWorkspaceGroups } from '../../shared/actions';
import { useGroupMembers } from '../../shared/reducers/groupMembers';
import { UserJurisdictionsState } from '../../shared/reducers/userJurisdictions';
import { useWorkspaceGroups } from '../../shared/reducers/workspaceGroups';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2, FinancialWorkspacesV2ApiRequest } from '../models';
import Filters from './Filters';
import {
  convertDateTimeObjectToArrayString,
  getDefaultDocumentsStatusOptions,
  getDefaultFinancialStatusOptions,
  getDefaultSelectOptions,
  getDefaultWorkspaceOptions
} from './helper';
import { FilterEnum, FundsStatusOptions, MyRoleOptions, MyTaskGroupOptions, WorkspaceMessageStatusOptions } from './models';

const fieldName = modelKey<FinancialWorkspacesV2ApiRequest>();

export interface FiltersContainerProps {
  selectedTab: FinancialDashboardRouteTabsEnumV2;
  currentTableFilters: DashboardFilterModel;
  onAddFilterChange(currentTabFilters: DashboardFilterModel): void;
  onFilterChange(changedFilter: Partial<FinancialWorkspacesV2ApiRequest>, currentTabFilters: DashboardFilterModel): void;
  assignmentType: AssignmentTypeEnum;
}

const FiltersContainer = ({
  //
  selectedTab,
  currentTableFilters,
  onAddFilterChange,
  onFilterChange,
  assignmentType
}: FiltersContainerProps) => {
  // Jurisdictions value need to fetch from the api as it is per subscriber onboarded agreement
  const dispatch = useSafeDispatch(useDispatch());
  useEffect(() => {
    batch(() => {
      dispatch(actionFetchUserJurisdictions.request());
      dispatch(actionFetchWorkspaceGroups.request({ includeUnassignableGroups: true }));
    });
  }, [dispatch]);

  const userJurisdictionsState: UserJurisdictionsState = useStoreSelector(store => store.userJurisdictions);

  // assigned to group is always there for the group value
  const workspaceGroupState = useWorkspaceGroups().items;
  // group user members
  const groupMembersState = useGroupMembers();

  // rendered filters
  const handleOnAddFilter = (newValue: FilterEnum) => {
    const newLocalStorageModel: DashboardFilterModel = {
      ...currentTableFilters,
      selectedFilters: [...currentTableFilters.selectedFilters!, newValue],
      defaultOpenFilter: newValue
    };
    onAddFilterChange(newLocalStorageModel);
  };

  const handleOnFilterChange = (name: string, newValues: number[] | string[]) => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'filters',
      logGroupId: `${DashboardTypeEnum.Financial}-dashboard`,
      filterName: name,
      filterValue: newValues
    });

    const newLocalStorageModel = { ...currentTableFilters };
    newLocalStorageModel[name] = [...newValues];

    onFilterChange(
      {
        [name]: newValues
      },
      newLocalStorageModel
    );
  };

  const handleOnDateTimeFilterChange = (name: string, newValues: string[]) => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'filters',
      logGroupId: `${DashboardTypeEnum.Financial}-dashboard`,
      filterName: name,
      filterValue: newValues
    });

    let datePeriod: DashboardDateRangeModel | undefined | string =
      newValues.length === 0 ? 'AllDates' : newValues.length === 1 ? { start: newValues[0], end: newValues[0] } : { start: newValues[0], end: newValues[1] };

    // for single day or date range, in case we use the clear button, the value would be empty string,
    // when this happens, we don't want to set the filter on settlementPeriod
    if (newValues.some(x => x === '')) {
      datePeriod = undefined;
    }

    const localStorageDataModel: DashboardFilterModel = { ...currentTableFilters, [name]: datePeriod };
    if (datePeriod) {
      onFilterChange(
        {
          [name]: datePeriod
        },
        localStorageDataModel
      );
    } else {
      onFilterChange(
        {
          [name]: null
        },
        localStorageDataModel
      );
    }
  };

  const selectedFilters = currentTableFilters.selectedFilters;

  // on the assign to group,  groups filters are default show up and all options should be selected. Unless
  // user un-select and then check the data also member is only allowed for group
  const allowedFilters = useMemo(() => {
    const defaultFilters = getDefaultSelectOptions(selectedTab, assignmentType);
    return defaultFilters.filter(o => selectedFilters!.indexOf(o.id) === -1);
  }, [selectedTab, selectedFilters, assignmentType]);

  const workspaceStatusOptions = useMemo(() => getDefaultWorkspaceOptions(selectedTab, assignmentType), [selectedTab, assignmentType]);
  const financialStatusOptions = useMemo(() => getDefaultFinancialStatusOptions(selectedTab), [selectedTab]);
  const documentsStatusOptions = useMemo(() => getDefaultDocumentsStatusOptions(selectedTab), [selectedTab]);
  const assignedGroupOptions = useMemo(() => {
    return workspaceGroupState.map(
      (item): LookupItemModel<string> => ({
        id: item.id,
        name: item.name
      })
    );
  }, [workspaceGroupState]);

  const groupMembers = useMemo(() => {
    return groupMembersState.items.map(
      (item): LookupItemModel<string> => ({
        id: item.id,
        name: item.name
      })
    );
  }, [groupMembersState]);

  const selectedFiltersData = useMemo((): FilterDataModel[] => {
    const jurisdictionOptions = userJurisdictionsState.items.map(x => ({ id: x.id, name: JurisdictionsEnum[x.id] }));

    let filterModels: FilterDataModel[] = [];

    currentTableFilters.selectedFilters?.forEach(selectedFilter => {
      switch (selectedFilter) {
        case FilterEnum.MyRole:
          filterModels.push({
            name: fieldName('myRoles'),
            label: 'My Role',
            placeHolder: 'Select',
            values: currentTableFilters.myRoles ?? [],
            options: MyRoleOptions,
            type: 'multipleCheckbox',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.MyTasks:
          filterModels.push({
            label: 'My Tasks',
            placeHolder: 'Select',
            values: currentTableFilters.myTasks ?? [],
            GroupOptions: MyTaskGroupOptions,
            type: 'groupMultipleCheckbox',
            name: fieldName('myTasks'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.DocumentsStatus:
          filterModels.push({
            label: 'Document Status',
            placeHolder: 'Select',
            values: currentTableFilters.documentStatus ?? [],
            options: documentsStatusOptions,
            type: 'multipleCheckbox',
            name: fieldName('documentStatus'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.FinancialStatus:
          filterModels.push({
            label: 'Financial Status',
            placeHolder: 'Select',
            values: currentTableFilters.financialStatus ?? [],
            options: financialStatusOptions,
            type: 'multipleCheckbox',
            name: fieldName('financialStatus'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.FundsStatus:
          filterModels.push({
            label: 'Funds Status',
            placeHolder: 'Select',
            values: currentTableFilters.fundsStatus ?? [],
            options: FundsStatusOptions,
            type: 'multipleCheckbox',
            name: fieldName('fundsStatus'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.WorkspaceStatus:
          filterModels.push({
            label: selectedTab === FinancialDashboardRouteTabsEnumV2.archived ? 'Status' : 'Workspace Status',
            placeHolder: 'Select',
            values: currentTableFilters.workspaceStatus ?? [],
            options: workspaceStatusOptions,
            type: 'multipleCheckbox',
            name: fieldName('workspaceStatus'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.Jurisdiction:
          filterModels.push({
            label: 'Jurisdiction',
            placeHolder: 'Select',
            values: currentTableFilters.jurisdictions ?? [],
            options: jurisdictionOptions,
            type: 'multipleCheckbox',
            name: fieldName('jurisdictions'),
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.SettlementDate:
          filterModels.push({
            name: fieldName('settlementPeriod'),
            label: 'Settlement Date',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.settlementPeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.AccessedDate:
          filterModels.push({
            name: fieldName('lastAccessPeriod'),
            label: 'Last Accessed',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.lastAccessPeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.ArchivedDate:
          filterModels.push({
            name: fieldName('archivedDatePeriod'),
            label: 'Archived Date',
            placeHolder: 'Select',
            values: convertDateTimeObjectToArrayString(currentTableFilters.archivedDatePeriod),
            type: 'datetimePicker',
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
          break;
        case FilterEnum.Groups:
          assignmentType !== AssignmentTypeEnum.AssignedToMe &&
            filterModels.push({
              name: fieldName('groupIds'),
              label: 'Group',
              placeHolder: 'Select',
              values: currentTableFilters.groupIds ?? [],
              type: assignmentType === AssignmentTypeEnum.ReAssigned ? 'singleCheckbox' : 'multipleCheckbox',
              options: assignedGroupOptions,
              defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
            });
          break;
        case FilterEnum.Members:
          assignmentType !== AssignmentTypeEnum.AssignedToMe &&
            filterModels.push({
              name: fieldName('userIds'),
              label: 'Member',
              placeHolder: 'Select',
              values: currentTableFilters.userIds ?? [],
              type: 'multipleSearchCheckbox',
              unit: 'Member',
              options: groupMembers,
              additionalOptionsOnTop: UNASSIGNED_OPTION,
              defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
            });
          break;
        case FilterEnum.Messages:
          filterModels.push({
            name: fieldName('workspaceMessageStatus'),
            label: 'Messages',
            placeHolder: 'Select',
            values: currentTableFilters.workspaceMessageStatus ?? [],
            type: 'multipleCheckbox',
            options: WorkspaceMessageStatusOptions,
            defaultOpen: selectedFilter === currentTableFilters.defaultOpenFilter
          });
      }
    });

    return filterModels;
  }, [
    currentTableFilters,
    assignmentType,
    assignedGroupOptions,
    documentsStatusOptions,
    financialStatusOptions,
    workspaceStatusOptions,
    selectedTab,
    userJurisdictionsState,
    groupMembers
  ]);

  return (
    <Filters //
      selectedFiltersData={selectedFiltersData}
      allowedFilters={allowedFilters}
      selectedTab={selectedTab}
      assignmentType={assignmentType}
      onFilterChange={handleOnFilterChange}
      onAddNewFilterChange={handleOnAddFilter}
      onDateTimeFilterChange={handleOnDateTimeFilterChange}
    />
  );
};

export default memo(FiltersContainer);

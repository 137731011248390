import React from 'react';

import { batch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';

import endpoints from '@sympli/api-gateway/endpoints';
import { DocumentStatusEnum } from '@sympli/api-gateway/enums';
import { ReviewWorkspaceDocumentApiResponse } from '@sympli/api-gateway/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionUpdateDocumentData } from 'src/containers/documents/actions';
import { DocumentDetailApiRequest, DocumentFormAndDetailModel, SaveDocumentFormRequest } from 'src/containers/documents/models';
import { actionUpdateWorkspaceDocumentsStatus, UpdateWorkspaceDocumentsStatusRequestModel } from 'src/containers/workspace/shared/detail/actions';
import { fetchWorkspaceDocuments } from 'src/containers/workspace/shared/detail/sagas';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { resolveNextFinancialDocumentTaskLink } from '../../helpers';

function reviewDocument(
  queryParams: SaveDocumentFormRequest,
  documentDetail: DocumentFormAndDetailModel,
  documentIds: string[],
  dispatch: SafeDispatch<any>,
  navigate: NavigateFunction,
  setIsWorkflowLoading: React.Dispatch<React.SetStateAction<boolean>>,
  updateWorkflowStep?: (status: DocumentStatusEnum) => void
) {
  setIsWorkflowLoading(true);
  endpoints
    .reviewDocument(queryParams, { hash: documentDetail.hash })
    .then(async (response: ReviewWorkspaceDocumentApiResponse) => {
      docUpdateHelper(dispatch, queryParams, response, documentIds);
      try {
        const documentList = await fetchWorkspaceDocuments(queryParams);
        setIsWorkflowLoading(false);

        const { status } = response;

        const nextDocumentTaskLink = resolveNextFinancialDocumentTaskLink(queryParams, documentList, documentDetail.documentId);
        if (nextDocumentTaskLink != null) {
          navigate(nextDocumentTaskLink);
          return;
        }

        // In case all the documents for the participant are approved,
        // take the user to the sign all screen.
        updateWorkflowStep?.(status);
      } catch (error) {
        Logger.console(SeverityEnum.Error, error);
        setIsWorkflowLoading(false);
      }
    })
    .catch(error => {
      const scope = Logger.scopeWithCustomAttributes({ documentIds, queryParams });
      Logger.captureException(error, scope);

      setIsWorkflowLoading(false);
      dispatch(actionCreateGlobalErrorMessage(error));
    });
}

function docUpdateHelper(dispatch: SafeDispatch<Action>, queryParams: DocumentDetailApiRequest, response: ReviewWorkspaceDocumentApiResponse, documentIds: Array<string>) {
  const { workspaceId, documentId, participantId } = queryParams;
  const {
    documentPermissions,
    documentDenyPermissions,
    status,
    documentActions,
    isFullySignedByAllParticipants,
    documentWorkflow: { isWorkspaceLocked }
  } = response;

  // make sure we update statuses in the transaction detail
  let statusUpdates: UpdateWorkspaceDocumentsStatusRequestModel = {
    workspaceId,
    participantId,
    documents: [
      //
      { statusId: status, documentId, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants }
    ]
  };

  // we need to check the status in this case, if it is lodgement verification then
  // we need to update all document status
  if (isWorkspaceLocked) {
    statusUpdates.documents = documentIds.map(d => ({
      statusId: status,
      documentId: d,
      documentActions,
      documentPermissions,
      documentDenyPermissions,
      isFullySignedByAllParticipants
    }));
  }

  // ! Order matters because after actionUpdateDocumentData
  // ! - new component(signDocument) will mount
  // ! - it will make async call based on Workspace Documents
  batch(() => {
    // * First update status of the document in the workspace detail
    dispatch(actionUpdateWorkspaceDocumentsStatus(statusUpdates));

    // * Second update document data that will cause transition to new state
    dispatch(actionUpdateDocumentData({ status, documentActions, documentPermissions, documentDenyPermissions, isFullySignedByAllParticipants }));
  });
}

export { reviewDocument };

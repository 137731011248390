import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger from '@sympli/ui-logger';

import { AssignmentTypeEnum, DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { InvitationsDashboardRouteTabsEnumV2, InvitationsDashboardUiFilterModel } from '../models';
import { ArchivedFilterOptions, FilterEnum, FilterOptions } from './models';

export const getFiltersWithSelectedTab = (
  //
  key: string,
  selectedTab: InvitationsDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
): [InvitationsDashboardUiFilterModel, boolean] => {
  const value = localStorage.getItem(key);

  const defaultOptions = defaultDashboardFilterModel(selectedTab, assignmentType);

  if (value) {
    const savedFilters = JSON.parse(value) as InvitationsDashboardUiFilterModel[];
    const savedFilter = savedFilters.find(d => d.selectedTab === selectedTab && d.assignmentType === assignmentType);

    if (!savedFilter) {
      return [defaultOptions, false];
    }

    return [
      {
        selectedTab: selectedTab,
        assignmentType: assignmentType,
        selectedFilters: savedFilter.selectedFilters,
        myRoles: savedFilter.myRoles,
        jurisdictions: savedFilter.jurisdictions,
        groupIds: savedFilter.groupIds,
        userIds: savedFilter.userIds,
        invitationArchivedType: savedFilter.invitationArchivedType,
        status: savedFilter.status,
        lastInvitationSentPeriod: savedFilter.lastInvitationSentPeriod,
        settlementPeriod: savedFilter.settlementPeriod,
        archivedDatePeriod: savedFilter.archivedDatePeriod,
        subscriberIds: savedFilter.subscriberIds
      },
      true
    ];
  }

  return [defaultOptions, false];
};

function resolveSelectedFilters(selectedTab: InvitationsDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): Pick<InvitationsDashboardUiFilterModel, 'selectedFilters'> {
  const selectedFilters: FilterEnum[] = [];

  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    selectedFilters.push(FilterEnum.Groups);
  }

  return { selectedFilters };
}

export const defaultDashboardFilterModel = (selectedTab: InvitationsDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): InvitationsDashboardUiFilterModel => {
  const { selectedFilters } = resolveSelectedFilters(selectedTab, assignmentType);
  // const [settlementPeriod, lastAccessPeriod] = defaultDateTimeRangeModel(selectedTab, undefined);
  const defaultOptions: InvitationsDashboardUiFilterModel = {
    assignmentType: assignmentType,
    selectedTab: selectedTab,
    selectedFilters,

    myRoles: [],
    jurisdictions: [],
    invitationArchivedType: [],
    groupIds: [],
    subscriberIds: []
  };

  return defaultOptions;
};

export const getDefaultSelectOptions = (selectedTab: InvitationsDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): LookupItemModel<number>[] => {
  // each one of them have diff default select list and assign to group allow has the members
  const defaultFilterOptions =
    assignmentType !== AssignmentTypeEnum.AssignedToMe
      ? FilterOptions.concat([
          {
            id: FilterEnum.Groups,
            name: 'Group'
          },
          {
            id: FilterEnum.Members,
            name: selectedTab === InvitationsDashboardRouteTabsEnumV2.sent ? 'Sent By Member' : 'Member'
          }
        ])
      : FilterOptions;

  switch (selectedTab) {
    case InvitationsDashboardRouteTabsEnumV2.received:
      return [
        {
          id: FilterEnum.ReceivedDate,
          name: 'Received Date '
        },
        {
          id: FilterEnum.FromSubscriber,
          name: 'From Subscriber'
        },
        ...defaultFilterOptions
      ];
    case InvitationsDashboardRouteTabsEnumV2.sent:
      return [
        {
          id: FilterEnum.SentDate,
          name: 'Sent Date'
        },
        {
          id: FilterEnum.ToSubscriber,
          name: 'To Subscriber'
        },
        ...defaultFilterOptions
      ];
    case InvitationsDashboardRouteTabsEnumV2.archived:
      return assignmentType === AssignmentTypeEnum.AssignedToGroup
        ? ArchivedFilterOptions.concat([
            {
              id: FilterEnum.Groups,
              name: 'Group'
            }
          ])
        : ArchivedFilterOptions;

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return defaultFilterOptions;
  }
};

export const convertDateTimeObjectToArrayString = (datePeriod: DashboardDateRangeModel | undefined) => {
  const result: string[] = [];
  if (!datePeriod) {
    return result;
  }

  if (datePeriod.start && typeof datePeriod.start === 'string') {
    result.push(datePeriod.start);

    if (
      datePeriod.end &&
      typeof datePeriod.end === 'string' && //
      datePeriod.start === datePeriod.end
    ) {
      return result;
    }
  }

  if (datePeriod.end && typeof datePeriod.end === 'string') {
    result.push(datePeriod.end);
  }

  return result;
};

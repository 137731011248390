import _isEqual from 'lodash-es/isEqual';
import _omit from 'lodash-es/omit';
import { createSelector, defaultMemoize } from 'reselect';

import { ComplianceLevelEnum, DocumentActionTypeEnum, ParticipantStatusEnum, SettleSmartStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDetailApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { SettlementStatusDetail } from '@sympli/api-gateway/shared';

import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { DocumentActionModel, DocumentFormAndDetailModel } from 'src/containers/documents/models';
import { allDistributionsParticipantOrderedList as GeneralParticipantOrderedList } from 'src/containers/shared/document-list/models';
import { sortParticipants } from 'src/containers/shared/participant-list/helpers';
import { resolveMultipleDocumentActionInfo, resolveSingleDocumentActionInfo } from 'src/containers/workspace/shared/detail/helpers';
import { LodgementCaseLogModel } from 'src/models';
import { Store } from 'src/reducers';
import { SettlementErrorModel } from '../../financial/detail/components/error-message-panel/models';
import { SettlementDateReviewerItemModel } from '../../financial/settlement-date/components/settlement-date-reviewer-list/models';
import { RescindSettlementDateTimeModel } from '../../financial/settlement-date/models';
import { ChangedItem } from '../../titles/models';
import { DocumentActionInfo, LodgementCaseLogMap, LodgementCompliance } from './models';

const getParticipants = <T extends Pick<Store, 'workspaceParticipants'>>(store: T): WorkspaceParticipantApiResponse[] => store.workspaceParticipants.items;

export const filterAvailableParticipantsSelector = defaultMemoize(
  <
    T extends {
      //
      participantStatus: WorkspaceParticipantApiResponse['participantStatus'];
      workspaceRole: WorkspaceParticipantApiResponse['workspaceRole'];
      archivedStatus?: WorkspaceParticipantApiResponse['archivedStatus'];
    }
  >(
    participants: T[] = []
  ) => {
    const availableParticipants = participants.filter(participant => {
      const status = participant.participantStatus?.id;
      // if archivedStatus exists, this participant is a withdrawn one, should not be counted as an active participant
      return !participant.archivedStatus && (status === ParticipantStatusEnum.Accepted || status === ParticipantStatusEnum.Pending);
    });
    return availableParticipants;
  }
);

export const orderedAvailableParticipantsSelector = createSelector<
  {
    workspaceParticipants: Store['workspaceParticipants'];
  },
  WorkspaceParticipantApiResponse[],
  WorkspaceParticipantApiResponse[]
>(
  getParticipants, //
  (participants: WorkspaceParticipantApiResponse[]) => {
    return sortParticipants(filterAvailableParticipantsSelector(participants), GeneralParticipantOrderedList);
  }
);

const getCurrentParticipantId = <T extends Pick<Store, 'workspaceDetail'>>(state: T) => {
  const { detail } = state.workspaceDetail;
  if (detail == null) {
    return undefined;
  }
  return detail.currentParticipantId;
};

export const currentParticipantSelector = createSelector<
  //
  {
    workspaceParticipants: Store['workspaceParticipants'];
    workspaceDetail: Store['workspaceDetail'];
  },
  WorkspaceParticipantApiResponse[],
  string | undefined,
  WorkspaceParticipantApiResponse | undefined
>(
  getParticipants, //
  getCurrentParticipantId,
  (participants = [], currentParticipantId) => {
    if (currentParticipantId == null) {
      return undefined;
    }
    return participants.find(participant => participant.id === currentParticipantId);
  }
);

// * we return undefined if no one has proposed new date;
const getSettlementParticipants = <T extends Pick<Store, 'settlementDateDetails'>>(state: T) => state.settlementDateDetails.detail.participants;

export const settlementAcceptedByParticipantsSelector = createSelector<
  //
  Partial<Store> & Pick<Store, 'settlementDateDetails'>,
  SettlementDateReviewerItemModel[],
  string[] | undefined
>(getSettlementParticipants, settlementParticipants => {
  if (settlementParticipants == null) {
    return undefined;
  }
  // archived participant like withdrawn participant, should not be count into settlement participants
  return settlementParticipants.filter(item => item.hasAccepted && !item.archivedStatus).map(item => item.id);
});

export const settlementDateNotificationsSelector = createSelector<
  //
  Partial<Store> & Pick<Store, 'settlementDateDetails'>,
  SettlementDateReviewerItemModel[],
  RescindSettlementDateTimeModel[] | undefined
>(getSettlementParticipants, settlementParticipants => {
  if (!settlementParticipants) return [];

  return settlementParticipants.filter(item => item.revokedCount > 0 && !item.hasAccepted).map(x => ({ id: x.revokedCount, participantId: x.id, workspaceId: undefined }));
});

// * we return undefined if no one has proposed new date;
const getWorkspaceLodgementCompliances = <T extends Pick<Store, 'workspaceDetail'>>(state: T) => {
  // this is only used for financial workspace
  const primaryLodgementCaseId = state.workspaceDetail.detail?.lodgementCases.find(x => x.isPrimary)?.id;
  return state.workspaceDetail.detail?.lodgementDetails.find(x => x.lodgementCaseId === primaryLodgementCaseId)?.lodgementCompliances;
};

const getSettlementStatus = <T extends Pick<Store, 'workspaceDetail'>>(state: T) => state.workspaceDetail.detail?.settlementStatusDetail;

const getWorkspaceStatus = <T extends Pick<Store, 'workspaceDetail'>>(state: T): WorkspaceStatusEnum | undefined => state.workspaceDetail.detail?.workspaceStatus.id;

export interface WorkspaceIssuesModel {
  lodgementCompliances?: LodgementCompliance[];
  settlementErrorMessages?: SettlementErrorModel;
}

// * we need the settlementStatus object here coz depends on the different type, we will need to display the different compliance level
function convertIssues(lodgementCompliances: Array<LodgementCompliance> = [], settlementStatus?: SettlementStatusDetail): WorkspaceIssuesModel {
  if (!settlementStatus) {
    return { lodgementCompliances };
  }

  const settlementErrorMessages: SettlementErrorModel = {
    errors: (settlementStatus.errors || []).map(errorMessage => ({
      message: errorMessage,
      level: settlementStatus.status === SettleSmartStatusEnum.Postponed ? ComplianceLevelEnum.Warning : ComplianceLevelEnum.Error
    })),
    alertType: settlementStatus.alertType,
    alertHeader: settlementStatus.alertHeader,
    dateCreated: new Date(settlementStatus.dateCreated)
  };

  return {
    //
    lodgementCompliances,
    settlementErrorMessages
  };
}

// Lodgement workspace does not have the settlement status
// ? need to reconsider this convertErrors and getSettlementStatus
export const lodgeOnlyWorkspaceLodgementErrorsSelector = createSelector<
  //
  LodgementCompliance[] | undefined,
  LodgementCompliance[] | undefined,
  WorkspaceIssuesModel
>(args => args, convertIssues); //

const DEFAULT_EMPTY_ERRORS: WorkspaceIssuesModel = {};
export const financialWorkspaceLodgementErrorsSelector = createSelector<
  //
  {
    workspaceDetail: Store['workspaceDetail'];
    settlementDateDetails: Store['settlementDateDetails'];
    participantId: string;
    isRollover?: boolean;
  },
  LodgementCompliance[] | undefined,
  SettlementStatusDetail | undefined,
  SettlementDateReviewerItemModel[],
  string | undefined,
  boolean | undefined,
  WorkspaceStatusEnum | undefined,
  WorkspaceIssuesModel
>(
  getWorkspaceLodgementCompliances, //
  getSettlementStatus,
  getSettlementParticipants,
  ({ participantId }) => participantId,
  ({ isRollover }) => isRollover,
  getWorkspaceStatus,
  (lodgementCompliances, settlementStatus, settlementParticipants = [], currentParticipantId, isRollover, workspaceStatus) => {
    if (!settlementParticipants.length) {
      return DEFAULT_EMPTY_ERRORS;
    }

    // hide the error message(s) when user re-book the settlement date time for the second time
    // !note backend cannot indicate second time re-book, we can safely assume (second+ round) if currently user accepts the time but getSettlementStatus is true
    if (currentParticipantId && (workspaceStatus === WorkspaceStatusEnum.OnSchedule || workspaceStatus === WorkspaceStatusEnum.ReadyForSettlement) && !isRollover) {
      const currentParticipant = settlementParticipants.find(p => p.id === currentParticipantId);

      if (currentParticipant?.hasAccepted && settlementStatus != null) {
        return convertIssues(lodgementCompliances);
      }
    }

    return convertIssues(lodgementCompliances, settlementStatus);
  }
);

const getAssignedGroups = <T extends Pick<Store, 'workspaceAssignableGroups'>>(state: T) => state.workspaceAssignableGroups.items;

export const displayAssignedGroupNameSelector = createSelector<
  //
  {
    workspaceParticipants: Store['workspaceParticipants'];
    workspaceDetail: Store['workspaceDetail'];
    workspaceAssignableGroups: Store['workspaceAssignableGroups'];
  },
  WorkspaceParticipantApiResponse | undefined,
  GroupOptionModel[],
  string | undefined
>(
  //
  currentParticipantSelector,
  getAssignedGroups,
  (currentParticipant, assignedGroups) => {
    // if there is only 1 assigned group no need to show
    if (!currentParticipant || assignedGroups.length <= 1) {
      return undefined;
    }

    // it should always has the name
    const currentGroup = assignedGroups.find(group => group.id === currentParticipant.groupId);
    return currentGroup?.name;
  }
);

const getDocumentActions = (param: { detail: DocumentFormAndDetailModel; participantId: string }) => {
  return param.detail.documentActions;
};

const getRequiredSigners = (param: { detail: DocumentFormAndDetailModel; participantId: string }) => {
  return param.detail.documentWorkflow.requiredSigners;
};

const getParticipantId = (param: { detail: DocumentFormAndDetailModel; participantId: string }) => {
  return param.participantId;
};

export const resolveReviewDocumentActionInfo = createSelector<
  //
  { detail: DocumentFormAndDetailModel; participantId: string },
  DocumentFormAndDetailModel['documentActions'],
  string,
  DocumentActionInfo
>(getDocumentActions, getParticipantId, (documentActions: DocumentActionModel[], participantId: string) => {
  const currentParticipantDocumentActions = documentActions.filter(d => d.participantId === participantId);
  return resolveSingleDocumentActionInfo(currentParticipantDocumentActions, DocumentActionTypeEnum.Review);
});

export const resolveSignDocumentActionInfoSelector = createSelector<
  //
  { detail: DocumentFormAndDetailModel; participantId: string },
  DocumentActionModel[],
  number,
  string,
  DocumentActionInfo
>(getDocumentActions, getRequiredSigners, getParticipantId, (documentActions: Array<DocumentActionModel>, requiredSigners: number, participantId: string) => {
  const currentParticipantDocumentActions = documentActions.filter(d => d.participantId === participantId);
  return resolveMultipleDocumentActionInfo(currentParticipantDocumentActions, DocumentActionTypeEnum.Sign, requiredSigners);
});

const getLodgementCaseLogs = <T extends Pick<Store, 'workspaceDetail'>>(state: T) => state.workspaceDetail.detail?.lodgementCaseLogs ?? [];

// export for testing purpose
export const getLodgementCaseLogSortMap = (lodgementCaseLogs: Array<LodgementCaseLogModel>) => {
  // * Can use priority queue and skip sorting if we only care about the latest one
  const statusLogMap: LodgementCaseLogMap = lodgementCaseLogs.reduce((acc, cur) => {
    const status = cur.lodgementCaseStatus;
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(cur);
    return acc;
  }, {} as LodgementCaseLogMap);
  // *  descending order sort each array
  for (let key of Object.keys(statusLogMap)) {
    statusLogMap[key].sort((a: LodgementCaseLogModel, b: LodgementCaseLogModel) => {
      const firstTime = new Date(a.createAt).valueOf();
      const secondTime = new Date(b.createAt).valueOf();
      if (firstTime > secondTime) {
        return -1;
      }
      if (firstTime < secondTime) {
        return 1;
      }
      return 0;
    });
  }
  return statusLogMap;
};

export const lodgementLogSortMapSelector = createSelector<
  //
  Pick<Store, 'workspaceDetail'>,
  LodgementCaseLogModel[],
  LodgementCaseLogMap
>(getLodgementCaseLogs, getLodgementCaseLogSortMap);

interface LodgementCaseLogsByLodgementCaseIdSelectorParams {
  state: Pick<Store, 'workspaceDetail'>;
  lodgementCaseId: string;
}

const getLodgementCaseLogsByLodgementCaseId = ({ state, lodgementCaseId }: LodgementCaseLogsByLodgementCaseIdSelectorParams) =>
  state.workspaceDetail.detail?.lodgementCaseLogs.filter(x => x.lodgementCaseId === lodgementCaseId) ?? [];

export const lodgementLogSortByLodgementCaseIdSelector = createSelector<
  //
  LodgementCaseLogsByLodgementCaseIdSelectorParams,
  LodgementCaseLogModel[],
  LodgementCaseLogMap
>(getLodgementCaseLogsByLodgementCaseId, getLodgementCaseLogSortMap);

export interface WorkspaceParticipantSelectorParamModel {
  id: string;
  workspaceParticipants: WorkspaceParticipantApiResponse[];
}
const getWorkspaceParticipantId = (data: WorkspaceParticipantSelectorParamModel) => {
  return data.id;
};

const getWorkspaceParticipants = (data: WorkspaceParticipantSelectorParamModel) => {
  return data.workspaceParticipants;
};

export const getParticipantByIdSelector = createSelector<
  //
  WorkspaceParticipantSelectorParamModel,
  string,
  WorkspaceParticipantApiResponse[],
  WorkspaceParticipantApiResponse | undefined
>(
  //
  getWorkspaceParticipantId,
  getWorkspaceParticipants,
  (participantId, participants = []) => participants.find(participant => participant.id === participantId)
);

const DEFAULT_TITLE_REFERENCES: WorkspaceDetailApiResponse['titleReferences'] = [];

export const compareRisDetail = (
  landRegistryDetail: WorkspaceDetailApiResponse['titleReferences'][number]['landRegistryDetail'],
  previousLandRegistryDetail: WorkspaceDetailApiResponse['titleReferences'][number]['previousLandRegistryDetail']
): boolean => {
  return _isEqual(_omit(landRegistryDetail, ['id', 'issuedDate', 'requestedDate']), _omit(previousLandRegistryDetail, ['id', 'issuedDate', 'requestedDate']));
};

export const titleReferenceRisChangedSetSelector = createSelector<
  //
  WorkspaceDetailApiResponse['titleReferences'] | undefined,
  WorkspaceDetailApiResponse['titleReferences'],
  ChangedItem[]
>(
  titleReferences => titleReferences || DEFAULT_TITLE_REFERENCES,
  titleReferences => {
    const changedSet: ChangedItem[] = [];
    if (!titleReferences.length) {
      return changedSet;
    }

    titleReferences.forEach(t => {
      const { landRegistryDetail, previousLandRegistryDetail } = t;
      // we only calculate when user order the ris
      if (previousLandRegistryDetail != null) {
        if (!compareRisDetail(landRegistryDetail, previousLandRegistryDetail)) {
          changedSet.push({ id: landRegistryDetail.id, title: landRegistryDetail.reference });
        }
      }
    });

    return changedSet;
  }
);

export const titleReferenceTacChangedSetSelector = createSelector<
  //
  WorkspaceDetailApiResponse['titleReferences'] | undefined,
  WorkspaceDetailApiResponse['titleReferences'],
  ChangedItem[]
>(
  titleReferences => titleReferences || DEFAULT_TITLE_REFERENCES,
  titleReferences => {
    const changedSet: ChangedItem[] = [];
    if (!titleReferences.length) {
      return changedSet;
    }

    titleReferences.forEach(t => {
      const { landRegistryDetail, titleActivityCheckResult } = t;

      if (titleActivityCheckResult && titleActivityCheckResult.hasChanges) {
        changedSet.push({ id: titleActivityCheckResult.lastCheckedDate, title: landRegistryDetail.reference });
      }
    });

    return changedSet;
  }
);

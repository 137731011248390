import React from 'react';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';

import MessageNotificationV2 from 'src/components/message-notification';

export interface Props {
  jurisdictionId: JurisdictionsEnum;
}

const jurisdictionMessageMapping: Partial<Record<JurisdictionsEnum, string>> = {
  [JurisdictionsEnum.NSW]: 'Please lodge your documents between 6:00am - 11:59pm.',
  [JurisdictionsEnum.QLD]: 'Please lodge your documents on business days between 8:30am - 4:30pm.',
  [JurisdictionsEnum.SA]: 'Please lodge your documents on business days between 9:00am - 5:00pm.',
  [JurisdictionsEnum.WA]: 'Please lodge your documents on business days between 8:30am - 4:30pm.'
  // VIC is 24/7
};

function OutsideOperationHoursMessageBox({ jurisdictionId }: Props) {
  if (![JurisdictionsEnum.NSW, JurisdictionsEnum.QLD, JurisdictionsEnum.SA, JurisdictionsEnum.WA].includes(jurisdictionId)) {
    return null;
  }

  const primary = `The ${JurisdictionsEnum[jurisdictionId]} land registry office is currently closed.`;

  return (
    <MessageNotificationV2 //
      variant="warning"
      primary={primary}
      secondary={jurisdictionMessageMapping[jurisdictionId]}
    />
  );
}

export default OutsideOperationHoursMessageBox;

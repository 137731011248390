import { AuthorityComplianceReportTypeEnum, LogTypeEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { SettlementNotificationStatusEnum, SettlementNotificationTypeEnum } from '../../models';

export interface LogWorkspaceSettlementInProgressEntity {
  Message: string;
  NotificationType: SettlementNotificationTypeEnum;
  StatusCode: SettlementNotificationStatusEnum;
}

export interface LogWorkspaceInviteEntity {
  InvitorId: string;
  InvitorName: string;
  InviteeId: string;
  InviteeName: string;
  InviteeRole: WorkspaceRoleEnum;
}

export interface WorkspaceActivityLogModel {
  participant?: WorkspaceParticipantApiResponse;
  participantId?: string;
  dateTime: string;
  userId?: string;
  reference?: string;
  text: string;
  workspaceLogType: LogTypeEnum;
  data: string;
  dataObject?: object;
  isPublicView: boolean;
  isTriggeredBySystem: boolean;
  template: string; // the markdown template generated from the item record
}

export interface LogEntity {
  dateTime: string;
  text: string;
}

export interface LogLineEntity {
  time: string;
  text: string;
}

export interface LogDateGroupEntity {
  date: string;
  logs: Array<LogLineEntity>;
}

export interface GetAuthorityComplianceReportRequest {
  authorityMessageId: string;
  messageType: AuthorityComplianceReportTypeEnum;
}

export interface AuthorityComplianceData {
  AuthorityMessageId: string;
  LodgementCaseName?: string;
  LodgementCaseId?: string;
}
// MR - moved
// export enum AuthorityComplianceReportTypeEnum {
//   LodgementVerification = 0,
//   Lodgement = 1
// }

export const AuthorityComplianceReportTypeDisplayMapping: Record<AuthorityComplianceReportTypeEnum, string> = {
  [AuthorityComplianceReportTypeEnum.LodgementVerification]: 'Lodgement verification',
  [AuthorityComplianceReportTypeEnum.Lodgement]: 'Lodgement'
};

// MR - moved
// export enum LogTypeEnum {
//   DirectionsReviewed = 0,
//   DirectionsSigned = 1,
//   DirectionsUnsigned = 2,
//   DirectionsUpdated = 3,
//   DocumentUpdated = 4,
//   DocumentCreated = 5,
//   DocumentDeleted = 6,
//   DocumentReviewed = 7,
//   DocumentSigned = 8,
//   DocumentUnsigned = 9,
//   InvitationResent = 10,
//   InvitationsSent = 11,
//   InvitationStatusUpdated = 12,
//   SettlementDateApproved = 13,
//   SettlementDateProposed = 14,
//   SettlementDateUpdated = 15,
//   SettlementFailed = 16,
//   SettlementSuccessful = 17,
//   WorkspaceArchived = 18,
//   WorkspaceCreated = 19,
//   WorkspaceLockedForLodgement = 20,
//   WorkspaceLockedForSettlement = 21,
//   TitleLandRegistryInformationSupplied = 22,
//   TitleActivityPositiveChange = 23,
//   TitleVerified = 24,
//   MatterUpdated = 25,
//   UserAssigned = 26,
//   UserUnassigned = 27,
//   AccountAuthorisationRecordCreated = 28,
//   AccountAuthorisationRecordSigned = 29,
//   AccountAuthorisationRecordUnsigned = 30,
//   AccountAuthorisationRecordDeleted = 31,
//   StampDutyVerifyInitated = 32,
//   StampDutyVerifySuccess = 33,
//   StampDutyVerifyFailed = 34,
//   LodgementVerificationInitated = 35,
//   LodgementVerificationSuccess = 36,
//   LodgementVerificationFailed = 37,
//   LodgementInitated = 38,
//   LodgementSuccess = 39,
//   LodgementFailed = 40,
//   LodgementCompleted = 41,
//   SettlementInProgress = 42,
//   PostSettlement = 43,
//   InvitationForwarded = 44,
//   InvitationForwardedToSubscriberAgent = 45,
//   TitleActivityNoChange = 46,
//   WorkspaceLocked = 47,
//   WorkspaceUnlocked = 48,
//   InvitationAccepted = 49,
//   InvitationRejected = 50,
//   InvitationWithdrawn = 51,
//   AccountAuthorisationRecordPartiallySigned = 52,
//   DocumentPartiallySigned = 53,
//   DirectionsPartiallySigned = 54,
//   DirectionsUpdatedBySystem = 55,
//   DirectionsUnsignedBySystem = 56,
//   StampDutySettlementVerifyInitated = 57,
//   WorkspaceAbandoned = 58,
//   LodgementInstructionsOrderUpdated = 59,
//   StampDutyTransactionCancellationInitated = 60,
//   StampDutyTransactionCancelledSuccess = 61,
//   StampDutyTransactionCancellationFailed = 62,
//   StampDutyTransactionCreationInitated = 63,
//   StampDutyTransactionCreationSuccess = 64,
//   StampDutyTransactionCreationFailed = 65,
//   WorkspaceExpectedLodgementDateUpdated = 66,
//   SettlementLodgementVerificationFailed = 67,
//   SettlementLodgementVerificationSucceeded = 68,
//   SettlementLodgementFailed = 69,
//   SettlementLodgementSucceeded = 70,
//   SettlementLodgementVerificationInitiated = 71,
//   SettlementLodgementInitiated = 72,
//   WorkspaceStatusUpdated = 73,
//   WorkspaceDocumentsChanged = 74,
//   LodgementFailedConnectivityError = 75,
//   PreSettlementCheckFailed = 76,
//   WorkspaceWithdraw = 77,
//   WorkspaceFileDeleted = 79,
//   WorkspaceTitleAdded = 80,
//   TitleActivityPositiveChangeWarningDismissed = 81,
//   WorkspaceAutoBalanced = 83
// }

export enum WorkspaceActivityLogLinkEnum {
  DirectionLink = 1,
  TitleLink = 2,
  DocumentLink = 3,
  AllDirectionsLink = 4
}

export const AuthorityLodgementVerificationComplianceLogType: LogTypeEnum[] = [
  LogTypeEnum.LodgementVerificationFailed,
  LogTypeEnum.LodgementVerificationSuccess,
  LogTypeEnum.SLCLodgementVerificationSuccess,
  LogTypeEnum.SLCLodgementVerificationFailed,
  LogTypeEnum.SettlementLodgementVerificationFailed,
  LogTypeEnum.SettlementLodgementVerificationSucceeded
];

export const AuthorityLodgementComplianceLogType: LogTypeEnum[] = [
  LogTypeEnum.LodgementFailed,
  LogTypeEnum.LodgementSuccess,
  LogTypeEnum.LodgementCompleted,
  LogTypeEnum.SLCLodgementCompleted,
  LogTypeEnum.SLCLodgementFailed,
  LogTypeEnum.SLCLodgementSuccess,
  LogTypeEnum.SettlementLodgementFailed,
  LogTypeEnum.SettlementLodgementSucceeded
];

export const AuthorityComplianceLogType: LogTypeEnum[] = [...AuthorityLodgementVerificationComplianceLogType, ...AuthorityLodgementComplianceLogType];

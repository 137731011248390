import { useState } from 'react';

import { FormikProps } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FinancialAccountApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/models';
import { BankDetailsModel } from 'src/containers/workspace/financial/directions/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createModelKeyAppender } from 'src/utils/formUtils';
import { bsbCheckFormikHelper } from '../../../../components/direction-payee-detail/helper';
import { BankTransferDistribution, ConditionalDistributionModel } from '../../../../components/direction-record/models';
import { getBankDetails, getDefaultAccountId } from '../../../../helper';
import { DischargeDirectionsFormModel } from '../../models';

const useMortgageEditor = (itemFieldName: string, dispatch: SafeDispatch<Action>) => {
  const fieldName = createModelKeyAppender<ConditionalDistributionModel>(itemFieldName);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveBankTransferDistribution = (
    itemFieldName: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    workspaceId: string,
    participantId: string,
    savingFssLineItem: boolean,
    keepDialogOpen?: boolean,
    distribution?: BankTransferDistribution
  ) => {
    setIsLoading(true);

    const { values } = formikProps;

    const bankDetails = _get(values, fieldName('bankDetails'));

    return bsbCheckFormikHelper(itemFieldName, bankDetails, formikProps, workspaceId, participantId, dispatch, savingFssLineItem, keepDialogOpen, distribution) //
      .finally(() => setIsLoading(false));
  };

  const prePopulateBankDetails = (accountId: string, formikProps: FormikProps<DischargeDirectionsFormModel>, financialAccounts?: FinancialAccountApiResponse[]) => {
    const { values, setFieldValue, setFieldTouched } = formikProps;

    const bankDetailsField = fieldName('bankDetails');

    const defaultBankDetails = getBankDetails(accountId, financialAccounts ?? []);

    const bankDetails = _get(values, bankDetailsField);

    const updatedBankDetails: BankDetailsModel = {
      ...bankDetails,
      accountName: defaultBankDetails?.accountName,
      accountNumber: defaultBankDetails?.accountNumber,
      bsb: defaultBankDetails?.bsbNumber,
      bankName: defaultBankDetails?.bankName
    };

    setFieldValue(bankDetailsField, updatedBankDetails);

    setFieldTouched(bankDetailsField);
  };

  const autoSelectAccount = (
    selectedCategory: string,
    formikProps: FormikProps<DischargeDirectionsFormModel>,
    usage?: GeneralAccountUsageApiResponse,
    financialAccounts?: FinancialAccountApiResponse[]
  ) => {
    const { setFieldValue } = formikProps;

    const selectAccountsField = fieldName('prepopulateAccountId');
    const defaultAccountId = getDefaultAccountId(selectedCategory, usage);

    if (defaultAccountId) {
      setFieldValue(selectAccountsField, defaultAccountId);
      prePopulateBankDetails(defaultAccountId, formikProps, financialAccounts);
      return;
    }

    setFieldValue(selectAccountsField, '');

    clearBankDetails(formikProps);
  };

  const clearBankDetails = (formikProps: FormikProps<DischargeDirectionsFormModel>) => {
    const { values, setFieldValue, setFieldTouched } = formikProps;

    const bankDetails = _get(values, fieldName('bankDetails'));

    const updatedBankDetails: BankDetailsModel = {
      ...bankDetails,
      accountName: '',
      reference: '',
      bsb: '',
      accountNumber: ''
    };

    setFieldValue(fieldName('bankDetails'), updatedBankDetails);
    setFieldTouched(fieldName('bankDetails'));
  };

  return {
    isLoading,
    setIsLoading,
    actions: {
      saveBankTransferDistribution,
      prePopulateBankDetails,
      autoSelectAccount,
      clearBankDetails
    }
  };
};

export default useMortgageEditor;

import React from 'react';

import { useDispatch } from 'react-redux';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import SourceFundList, { FormModel, OwnProps as Props } from './SourceFundList';

function SourceFundListContainer(props: Omit<Props<FormModel>, 'isFssLineItemSaveEnabled' | 'dispatch'>) {
  const isFssLineItemSaveEnabled = useFeatureFlag(FeatureToggleEnum.fssLineItemSaveEnabled);
  const dispatch = useSafeDispatch(useDispatch());
  return (
    <SourceFundList //
      {...props}
      isFssLineItemSaveEnabled={isFssLineItemSaveEnabled}
      dispatch={dispatch}
    />
  );
}

export default React.memo(SourceFundListContainer);

import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { fallbackMap } from './fallback';
import { ApiLodgementInstructions2_19_1Model, LodgementInstructions2_19_1Model } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions2_19_1Model, ApiLodgementInstructions2_19_1Model> {
  fromApiToFormModel(apiValues: ApiLodgementInstructions2_19_1Model, context: IConverterContext): LodgementInstructions2_19_1Model {
    let formValues = applyDefaultMap(apiValues, fallbackMap);
    formValues.isResponsibleParticipant = context.currentParticipant?.isResponsibleParticipant ?? false;
    const selectedPriorityNotice = formValues.priorityNotices?.find(x => x.isSelected);
    if (selectedPriorityNotice) {
      formValues.selectedPriorityNoticeId = selectedPriorityNotice.dealingNumber;
    }
    return formValues;
  }
  fromFormToApiModel(formValues: LodgementInstructions2_19_1Model, originalApiModel: ApiLodgementInstructions2_19_1Model): ApiLodgementInstructions2_19_1Model {
    const { selectedPriorityNoticeId } = formValues;
    let apiModel = applyVisibilityFallbackMap(formValues, fallbackMap);
    apiModel.priorityNotices = apiModel.priorityNotices?.map(({ dealingNumber }) => {
      return { dealingNumber: dealingNumber, isSelected: dealingNumber === selectedPriorityNoticeId };
    });

    return apiModel;
  }
}

export default new Converter();

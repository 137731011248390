import { createAction, createAsyncAction } from 'typesafe-actions';

import { ArchivedInvitationDetailApiResponse } from '@sympli/api-gateway/models';

import { InvitationDashboardArchivedDetailsApiRequest } from './api';
import {
  InvitationsDashboardV2ApiRequest,
  InvitationsDashboardV2ApiResponse,
  InvitationsSubscriberSearchApiRequest,
  InvitationsSubscriberSearchApiResponse,
  InvitationTitlesApiRequest,
  InvitationTitlesApiResponse,
  InvitesDashboardApiRequest,
  WithdrawParticipantInvitationRequestModel
} from './models';

export const actionFetchInvitationsFeedV2 = createAsyncAction(
  //
  'FETCH_INVITATIONS_FEED_V2',
  'FETCH_INVITATIONS_FEED_V2_SUCCESS',
  'FETCH_INVITATIONS_FEED_V2_ERROR'
)<
  //
  InvitationsDashboardV2ApiRequest,
  { data: InvitationsDashboardV2ApiResponse },
  { error: Error }
>();

export const actionFetchInvitationsSubscribers = createAsyncAction(
  //
  'FETCH_INVITATIONS_SUBSCRIBERS',
  'FETCH_INVITATIONS_SUBSCRIBERS_SUCCESS',
  'FETCH_INVITATIONS_SUBSCRIBERS_ERROR'
)<
  //
  InvitationsSubscriberSearchApiRequest,
  { data: InvitationsSubscriberSearchApiResponse },
  { error: Error }
>();

export const actionFetchInvitationTitles = createAsyncAction(
  //
  'FETCH_INVITATION_TITLES',
  'FETCH_INVITATION_TITLES_SUCCESS',
  'FETCH_INVITATION_TITLES_ERROR'
)<
  //
  InvitationTitlesApiRequest,
  { data: InvitationTitlesApiResponse },
  { error: Error }
>();

export const actionWithdrawParticipantInvitation = createAsyncAction(
  //
  'WITHDRAW_INVITE',
  'WITHDRAW_INVITE_SUCCESS',
  'WITHDRAW_INVITE_ERROR'
)<
  //
  WithdrawParticipantInvitationRequestModel,
  undefined,
  { error: Error }
>();

export interface ResendInviteApiRequest {
  id: string;
  participantName?: string;
  query?: InvitesDashboardApiRequest | InvitationsDashboardV2ApiRequest;
  isNewDashboard?: boolean;
}

export const actionResendInvite = createAsyncAction(
  //
  'RESEND_INVITE',
  'RESEND_INVITE_SUCCESS',
  'RESEND_INVITE_ERROR'
)<
  //
  ResendInviteApiRequest,
  undefined,
  { error: Error }
>();

export const showInvitationsDashboardFeedDetail = createAction(
  //
  'SHOW_INVITATIONS_DASHBOARD_FEED_DETAIL'
)<{ rowDetailIndex?: number }>();

export const actionFetchInvitationsArchivedFeed = createAsyncAction(
  //
  'INVITATIONS_ARCHIVED_FEED',
  'INVITATIONS_ARCHIVED_FEED_SUCCESS',
  'INVITATIONS_ARCHIVED_FEED_ERROR'
)<
  //
  InvitationDashboardArchivedDetailsApiRequest,
  { data: ArchivedInvitationDetailApiResponse },
  { error: Error }
>();

import * as React from 'react';
import { memo } from 'react';

import classNames from 'classnames';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { Card } from '@sympli/components-library/card';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { DashboardTypeEnum } from 'src/containers/dashboard/shared/models';
import { IconActiveLink } from '../layout/v2/Icons';
import { IconAggregatorLoading } from './LoadingIcon';
import { useStyles } from './styles';

export interface AggregatorItem {
  content: string | number;
  status: string;
  actionText?: string;
  displaySeparator?: boolean;
  id: string; // this id is the unique name for each aggregator, so when click back we know what to map back
  extraInfo?: {
    settledNumber: number;
    rebookNumber: number;
  };
}

export interface AggregatorProps {
  title: string;
  onActionClick: (id: string) => void;
  isLoading?: boolean;
  isFetchingGridData: (id: string) => boolean;
  items: AggregatorItem[];
  titleStyle?: string;
  rootStyle?: string;
  contentStyle?: string;
  statusStyle?: string;
  actionStyle?: string;
  containerStyle?: string;
  cardType: DashboardTypeEnum; // This is for logging feature usage purpose
}

const AggregatorCard = ({
  //
  title,
  items,
  isLoading,
  onActionClick,
  titleStyle,
  containerStyle,
  rootStyle,
  contentStyle,
  statusStyle,
  actionStyle,
  isFetchingGridData,
  cardType
}: AggregatorProps) => {
  const classes = useStyles();

  return (
    <FlexLayout flexDirection="column" alignItems="flex-start" className={classNames(classes.cardMargin, containerStyle)}>
      <Typography className={classNames(classes.cardTitle, titleStyle)}> {title}</Typography>
      <Card className={classNames('flex items-center justify-center', classes.rowStyle)}>
        {items.map((item, i) => {
          if (isLoading) {
            return (
              <Card className={classNames(classes.cardRoot, rootStyle)} key={i}>
                <CardContent className={classNames('flex justify-center', classes.cardContentRoot)}>
                  <IconAggregatorLoading />
                </CardContent>
              </Card>
            );
          }

          const isFetching = isFetchingGridData(item.id);

          return (
            <React.Fragment key={`${title}-${item.content}-${item.status}`}>
              <Card
                className={classNames('group', classes.cardRoot, rootStyle, !isFetching && classes.hoverEffect)}
                onClick={_ => {
                  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
                    feature: 'aggregator-card',
                    logGroupId: 'dashboard',
                    cardName: `${cardType}-${item.id}`
                  });
                  if (isFetching) return;
                  onActionClick(item.id);
                }}
              >
                <CardContent className={classes.cardContentRoot}>
                  <Typography className={classNames(classes.cardNumber, contentStyle)}>{item.content} </Typography>
                  <Typography className={classNames(classes.cardStatus, statusStyle)}>{item.status}</Typography>
                  <FlexLayout className="relative" flexDirection="row" justifyContent="center" alignItems="center">
                    <ButtonBase disabled={isFetching} className={classNames(classes.cardAction, actionStyle)}>
                      {item.actionText ?? 'Show me'}
                    </ButtonBase>
                    <IconActiveLink
                      //
                      aria-hidden
                      className="absolute left-[50%] hidden group-hover:block"
                      style={{
                        //
                        transform: 'translate(-50%, -50%)',
                        bottom: -6,
                        width: 16
                      }}
                    />
                  </FlexLayout>
                </CardContent>
                {item.extraInfo && (
                  <>
                    <Box className={classNames(classes.extraBox, classes.settledBox)}>{item.extraInfo.settledNumber}</Box>
                    <Box className={classNames(classes.extraBox, classes.rebookBox)}>{item.extraInfo.rebookNumber}</Box>
                  </>
                )}
              </Card>
              {item.displaySeparator && <hr className={classes.separatorStyle} />}
            </React.Fragment>
          );
        })}
      </Card>
    </FlexLayout>
  );
};

export default memo(AggregatorCard);

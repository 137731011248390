import { defaultMemoize } from 'reselect';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

export const resolveWorkspaceTypeOptions = defaultMemoize((props: Partial<{ financial: boolean; refinance: boolean }> = {}) => {
  const WORKSPACE_TYPE_OPTIONS: LookupEnumModel<WorkspaceTypeEnum>[] = [];

  if (props.financial) {
    WORKSPACE_TYPE_OPTIONS.push({ id: WorkspaceTypeEnum.FinancialSettlement, name: 'Financial settlement' });
  }
  if (props.refinance) {
    WORKSPACE_TYPE_OPTIONS.push({ id: WorkspaceTypeEnum.Refinance, name: 'Refinance' });
  }

  WORKSPACE_TYPE_OPTIONS.push({ id: WorkspaceTypeEnum.RegistrationOnly, name: 'Standalone' });
  return WORKSPACE_TYPE_OPTIONS;
});

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import useFeatureFlag from 'src/hooks/useFeatureFlag';

const useInteroperable = (workspaceTypeId?: WorkspaceTypeEnum) => {
  const interoperableWorkspaceIdEnabled = useFeatureFlag(FeatureToggleEnum.interoperableWorkspaceIdEnabled);
  const isInteroperable = interoperableWorkspaceIdEnabled && (workspaceTypeId === WorkspaceTypeEnum.Refinance || workspaceTypeId === WorkspaceTypeEnum.FinancialSettlement);

  return isInteroperable;
};

export default useInteroperable;

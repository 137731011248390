import React from 'react';

import { FormikValues } from 'formik';

import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import TopPanel from 'src/components/layout/v2/TopPanel';
import NavTabs from 'src/containers/dashboard/components/nav-tabs';
import { FavouriteFormikModel, ReportDetailsState, SchedulerFormikModel } from '../../models';
import ReportSchedulerDialog from '../report-scheduler-dialog';
import FavouriteReportDialog from './components/favourite-report-dialog';
import ReportDetailHeader from './components/report-detail-header';
import UserReport from './components/user-report';
import { useStyles } from './styles';

interface DialogProps<T extends FormikValues> {
  initialValues: T;
  isOpen: boolean;
  handleOnClick: () => void;
  handleOnClose: (value?: boolean) => void;
  handleOnPostSubmit: (args: FormikPostSubmitArgs<T>) => void;
}

export interface Props {
  //
  isLoading: boolean;
  reportDetail: ReportDetailsState | null;
  tableauRef: React.RefObject<HTMLDivElement>;
  onDownloadCSVClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDownloadPDFClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  viz: tableau.Viz | null;
  schedulerProps?: DialogProps<SchedulerFormikModel>;
  favouriteProps?: DialogProps<FavouriteFormikModel>;
  reportHeaderProps: {
    title?: string;
    subTypes: string[] | undefined;
    selectedSubType?: string;
    onSubTypeChangedHandler: (subType: string) => void;
  };
  filterData: string;
  parameterData?: string;
  isReportSubTypesUIEnabled?: boolean;
}

const PageWrapper = ({ children, header, subTypeHeader }: React.PropsWithChildren<{ header: React.ReactNode; subTypeHeader: React.ReactNode }>) => {
  return (
    <div className="grid h-screen w-screen grid-cols-[1fr] grid-rows-[auto,1fr]">
      {/* .container */}
      {/* .header */}
      <TopPanel />
      <div className="grid grid-cols-[1fr] grid-rows-[1fr] overflow-hidden">
        {/* .body */}

        <div className="overflow-y-auto">
          {/* .scrollable-content */}
          <div className="h-[128px] bg-[#EBFBFA]">
            <div className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
              <div className="flex h-[128px] shrink-0 flex-row items-center justify-between">
                {/* .green-header */}
                {header}
              </div>
            </div>
          </div>
          {subTypeHeader}
          <div className="mx-auto h-[calc(100%-154px)] min-h-[840px] max-w-[var(--small-content)] pt-[16px] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function ReportDetails({
  //
  isLoading,
  reportDetail,
  tableauRef,
  onDownloadCSVClick,
  onDownloadPDFClick,
  viz,
  schedulerProps,
  favouriteProps,
  reportHeaderProps,
  filterData,
  parameterData,
  isReportSubTypesUIEnabled
}: Props) {
  const classes = useStyles();

  const header = (
    <ReportDetailHeader
      //
      areButtonsDisabled={viz === null}
      isLoading={isLoading}
      hasSchedulerButton={Boolean(schedulerProps)}
      hasFavouriteButton={Boolean(favouriteProps)}
      onDownloadCSVClick={onDownloadCSVClick}
      onDownloadPDFClick={onDownloadPDFClick}
      handleOnFavouriteClick={favouriteProps?.handleOnClick}
      handleOnScheduleClick={schedulerProps?.handleOnClick}
      title={reportHeaderProps.title}
      className={classes.header}
    />
  );

  const subTypeHeader =
    reportHeaderProps.subTypes && isReportSubTypesUIEnabled ? (
      <div className="flex h-[64px] pt-[16px]">
        <div className="mx-auto max-w-[var(--small-content)] medium-screen:max-w-[var(--medium-content)] large-screen:max-w-[var(--large-content)]">
          <div className="flex h-[64px] shrink-0 flex-row items-center justify-between">
            <NavTabs<string> //
              tabs={reportHeaderProps.subTypes!}
              selectedTab={reportHeaderProps.selectedSubType!}
              onChange={reportHeaderProps.onSubTypeChangedHandler}
              resolveLabel={tab => tab}
            />
          </div>
        </div>
      </div>
    ) : null;

  return (
    <PageWrapper //
      header={header}
      subTypeHeader={subTypeHeader}
    >
      <FlexLayout flexDirection="row" className={classes.reportContent}>
        {isLoading || reportDetail == null ? ( //
          <BlockLoader />
        ) : (
          <UserReport tableauReportRef={tableauRef} />
        )}
      </FlexLayout>
      {schedulerProps && (
        <ReportSchedulerDialog //
          isOpen={schedulerProps.isOpen}
          onClose={schedulerProps.handleOnClose}
          onPostSubmit={schedulerProps.handleOnPostSubmit}
          initialValues={schedulerProps.initialValues}
          filterData={filterData}
        />
      )}
      {favouriteProps && (
        <FavouriteReportDialog //
          isOpen={favouriteProps.isOpen}
          onClose={favouriteProps.handleOnClose}
          maxNameLength={50}
          initialValues={favouriteProps.initialValues}
          handleOnPostSubmit={favouriteProps.handleOnPostSubmit}
          filterData={filterData}
          parameterData={parameterData}
        />
      )}
    </PageWrapper>
  );
}

export default React.memo(ReportDetails);

import React, { useCallback } from 'react';

import { FormikHelpers } from 'formik';

import { JurisdictionsEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import { LookupItemModel } from '@sympli/ui-framework/models';

import ToggleButtons from 'src/components/toggle-buttons/ToggleButtons';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { colors } from 'src/theme';
import { GroupOptionModel } from '../../../shared/models';
import { ForwardInvitationEnum } from '../../models';
import ForwardInviteToGroupForm, { ForwardInviteToGroupFormValues } from './ForwardInviteToGroupForm';
import ForwardInviteToSubscriberForm, { ForwardInviteToSubscriberFormValues } from './ForwardInviteToSubscriberForm';

interface ForwardInviteFormProps {
  invitationId: string;
  workspaceId: string;
  invitationForwardableGroups: GroupOptionModel[];
  jurisdictionId: JurisdictionsEnum;
  isLinkedWorkspace?: boolean;
  invitationDescription?: string;
  invitedRole: WorkspaceRoleEnum;

  className?: string;

  onCancel(): void;
  onForwardToGroupSubmit(data: ForwardInviteToGroupFormValues): Promise<void>;
  onForwardToSubscriberSubmit(data: ForwardInviteToSubscriberFormValues, formikHelpers: FormikHelpers<ForwardInviteToSubscriberFormValues>): Promise<void>;
}

const FORWARD_TYPE_LOOKUP_OPTIONS: LookupItemModel<ForwardInvitationEnum>[] = [
  {
    id: ForwardInvitationEnum.Internally,
    name: 'Internally'
  },
  {
    id: ForwardInvitationEnum.Externally,
    name: 'Externally'
  }
];

function ForwardInvitationForm({
  //
  invitationForwardableGroups,
  jurisdictionId,
  invitationId,
  workspaceId,
  onCancel,
  onForwardToGroupSubmit,
  onForwardToSubscriberSubmit,
  invitedRole,
  invitationDescription,
  isLinkedWorkspace
}: ForwardInviteFormProps) {
  const [internalForward, setInternalForward] = React.useState(ForwardInvitationEnum.Internally);
  const currentSubscriberData: UserProfileModel = useProfile().data!;

  const handleOnButtonClick = useCallback(
    (value: ForwardInvitationEnum) => {
      setInternalForward(value);
    },
    [setInternalForward]
  );

  return (
    <>
      <div className={'relative mb-5 flex items-center justify-center'}>
        <ToggleButtons //
          items={FORWARD_TYPE_LOOKUP_OPTIONS}
          value={internalForward}
          label="forward"
          onChange={handleOnButtonClick}
          className={'mb-[3px] ml-[5px] mr-[5px] mt-[3px] w-36'}
          sxGroupButtons={{
            position: 'relative',
            justifyContent: 'center',
            left: 'unset',
            background: colors.SUNNY_DAY
          }}
        />
      </div>
      {internalForward === ForwardInvitationEnum.Externally ? (
        <ForwardInviteToSubscriberForm
          //
          invitationId={invitationId}
          workspaceId={workspaceId}
          invitationForwardableGroups={invitationForwardableGroups}
          jurisdictionId={jurisdictionId}
          isLinkedWorkspace={isLinkedWorkspace}
          invitedRole={invitedRole}
          currentSubscriberId={currentSubscriberData.subscriberId}
          onCancel={onCancel}
          invitationDescription={invitationDescription}
          onSubmit={onForwardToSubscriberSubmit}
        />
      ) : (
        <ForwardInviteToGroupForm
          //
          invitationId={invitationId}
          workspaceId={workspaceId}
          invitationForwardableGroups={invitationForwardableGroups}
          jurisdictionId={jurisdictionId}
          onCancel={onCancel}
          onSubmit={onForwardToGroupSubmit}
        />
      )}
    </>
  );
}

export default React.memo(ForwardInvitationForm);

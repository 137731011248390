import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import { DocumentListModel } from '../../models';

export const getItemStyle = (draggableStyle?: DraggingStyle | NotDraggingStyle): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle
});

export const reorder = (list: DocumentListModel[] = [], startIndex: number, endIndex: number) => {
  const result = list.slice();
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

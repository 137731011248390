import React from 'react';

import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import SourceFundEditor, { Props } from './SourceFundEditor';

function SourceFundEditorContainer(props: Omit<Props<FormikValues>, 'isFssLineItemSaveEnabled' | 'dispatch'>) {
  const isFssLineItemSaveEnabled = useFeatureFlag(FeatureToggleEnum.fssLineItemSaveEnabled);
  const dispatch = useSafeDispatch(useDispatch());

  return (
    <SourceFundEditor //
      {...props}
      isFssLineItemSaveEnabled={isFssLineItemSaveEnabled}
      dispatch={dispatch}
    />
  );
}

export default React.memo(SourceFundEditorContainer);

import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { useWorkspaceDetail } from '../../shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from '../../shared/detail/reducers/workspaceParticipants';
import Directions from './Directions';
import { useDirectionsCategories } from './reducers/categories';
import { useDirectionsBreakdown } from './reducers/directionsBreakdown';

export interface DirectionsRouteParams {
  workspaceId: string;
  participantId: string;
}

export interface DirectionsRouteLocationState {
  showDialog?: boolean;
  workflowStep?: DocumentWorkflowStepsEnum;
}
interface TypedLocation extends Location {
  state: DirectionsRouteLocationState;
}

function DirectionsContainer() {
  const { workspaceId, participantId } = useRouterParams<DirectionsRouteParams>();
  const dispatch = useSafeDispatch(useDispatch());
  const { state, pathname, search } = useLocation() as TypedLocation;
  const navigate = useNavigate();

  const userProfile = useProfile().data!;
  const directionsBreakdownState = useDirectionsBreakdown(workspaceId, participantId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const currentParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const directionsCategoriesState = useDirectionsCategories(workspaceId, participantId);

  return (
    <Directions //
      state={state}
      pathname={pathname + search}
      navigate={navigate}
      workspaceId={workspaceId}
      participantId={participantId}
      detail={directionsBreakdownState.detail!}
      workspaceDetailState={workspaceDetailState!}
      currentParticipant={currentParticipant}
      userProfile={userProfile}
      directionsCategoriesState={directionsCategoriesState}
      workspaceParticipantsState={workspaceParticipantsState}
      dispatch={dispatch}
      isLoading={
        workspaceDetailState.isLoading ||
        workspaceParticipantsState.isLoading ||
        directionsBreakdownState.isLoading ||
        directionsBreakdownState.isRefetching ||
        directionsCategoriesState.isLoading
      }
    />
  );
}

export default React.memo(DirectionsContainer);

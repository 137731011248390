import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';

import { RepresentationSubscriberTypeEnum } from 'src/containers/dashboard/components/create-new-workspace/models';

export const invitableRolesInTransferEAP = (isTransferEAPEnabled: boolean, currentWorkspaceRoleId: WorkspaceRoleEnum, invitableRoles: WorkspaceRoleEnum[]): WorkspaceRoleEnum[] => {
  let newInvitableRoles: WorkspaceRoleEnum[] = invitableRoles;
  if (isTransferEAPEnabled) {
    switch (currentWorkspaceRoleId) {
      case WorkspaceRoleEnum.Purchaser:
      case WorkspaceRoleEnum.Vendor:
        newInvitableRoles = invitableRoles.filter(x => x !== WorkspaceRoleEnum.DischargingMortgagee && x !== WorkspaceRoleEnum.IncomingMortgagee);
        break;
      case WorkspaceRoleEnum.IncomingMortgagee:
      case WorkspaceRoleEnum.DischargingMortgagee:
        newInvitableRoles = invitableRoles.filter(x => x !== WorkspaceRoleEnum.Purchaser && x !== WorkspaceRoleEnum.Vendor);
        break;
    }
  }
  return newInvitableRoles;
};

export const fromRepresentationSubscriberTypeToApiQuery = (
  currentWorkspaceRole: WorkspaceRoleEnum | null,
  curentRepresentationSubscriberType: RepresentationSubscriberTypeEnum
): RepresentationSubscriberTypeEnum => {
  switch (currentWorkspaceRole) {
    case WorkspaceRoleEnum.Purchaser:
    case WorkspaceRoleEnum.Vendor:
    case WorkspaceRoleEnum.IncomingMortgagee:
    case WorkspaceRoleEnum.DischargingMortgagee:
      return curentRepresentationSubscriberType;
    default:
      return RepresentationSubscriberTypeEnum.Other;
  }
};

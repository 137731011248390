import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import { Action } from 'redux';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { ClientWorkspaceRoleEnum, JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTransactionTypeEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field/InputField';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import Formik from 'src/components/formik';
import WizardErrorMessage from 'src/containers/dashboard/components/create-new-workspace/components/wizard-error-message';
import { SupportedJurisdictionsState } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { WorkspaceAssignableGroupsState } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import VerifyPropertySection from 'src/containers/shared/verify-property-section';
import { DuplicateWorkspaceItem, duplicateWorkspacesSelector } from 'src/containers/shared/verify-property-section/components/duplicate-workspaces';
import { TitleListItem, titleListItemsSelector } from 'src/containers/shared/verify-property-section/components/title-list';
import { prepareTitleForSubmission, resolveTitles, verifyTitleReferences } from 'src/containers/shared/verify-property-section/helpers';
import { TitleVerificationRequest, TitleVerificationResponse } from 'src/containers/shared/verify-property-section/models';
import { TimeFormatTypeEnum } from 'src/containers/workspace/financial/settlement-date/helper';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import { modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { isBusinessDay } from 'src/utils/holiday/holidayCheck';
import { SharedInitialValues, WorkspaceCreationErrorsMap } from '../../../../models';
import { unifyTitleFormat } from '../../../reg-only-form/helpers';
import AboutYourMatterSection from '../../components/about-your-matter-section';
import {
  CreateFinancialWorkspaceMatterDetailsStepFormModel,
  CreateFinancialWorkspaceMatterDetailsStepResponse,
  CreateFinancialWorkspaceSharedModel,
  CreateFinancialWorkspaceStepEnum,
  FinancialWorkspaceTitleCreationDetailsStepRequest
} from '../../models';
import {
  excludeRolesWhenCreateWorkspaceInTransferEAP,
  getInvitationOrderByRole,
  isLinkedWorkspaceRequired,
  jurisdictionSelector,
  resolveJurisdictionOptionsInTransferEAP,
  settlementTimeOptionsSelector,
  TRANSACTION_TYPE_OPTIONS
} from './helpers';
import { workspaceRoleTypeOptionsSelector } from './selectors';
import styles, { ClassKeys } from './styles';
import { getValidationSchema } from './validationSchema';

const MIN_SETTLEMENT_DATE = new Date();

type FormModel = CreateFinancialWorkspaceMatterDetailsStepFormModel;
export interface OwnProps {
  // redux
  assignGroupsState: WorkspaceAssignableGroupsState;
  supportedJurisdictionsState: SupportedJurisdictionsState;
  subscriberProfile: UserProfileModel;
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  // feature toggles
  isReapEnabled: boolean;
  isTransferEAPEnabled: boolean;
  isSettlementDateOnlyEnabled: boolean;
  isSettlementNullDateTimeEnabled: boolean;
  // other
  workspaceTypeId: WorkspaceTypeEnum;
  onGlobalValuesUpdate: (value?: Partial<CreateFinancialWorkspaceSharedModel>) => CreateFinancialWorkspaceSharedModel;
  onStepChange: (step: CreateFinancialWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;
  dispatch: SafeDispatch<Action>;
  isSearchAndJoinWorkspaceEnabled?: boolean;
  isTransactionTypeEnabled: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isVerifyLoading: boolean;
  isSettlementDateTimeDisabled: boolean;
}

const fieldName = modelKey<FormModel>();

class MatterDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isVerifyLoading: false,
    isSettlementDateTimeDisabled: false
  };

  private formikProps: FormikProps<FormModel>;
  private globalValues: CreateFinancialWorkspaceSharedModel = this.props.onGlobalValuesUpdate();
  private inputRef = React.createRef<HTMLInputElement>();

  private getInitialValues = (): FormModel => {
    // explicitly always update globalValues
    let { matterDetailsStepData: matterDetail } = (this.globalValues = this.props.onGlobalValuesUpdate());

    // assign the group id if only one group
    const { assignGroupsState } = this.props;
    const { items: groupOptions = [] } = assignGroupsState;

    if (groupOptions.length === 1) {
      matterDetail = { ...matterDetail, groupId: groupOptions[0].id };
    }
    return matterDetail;
  };

  public componentDidUpdate(preProps: Props) {
    const { assignGroupsState } = this.props;
    if (preProps.assignGroupsState !== assignGroupsState) {
      const { items: groupOptions = [] } = assignGroupsState;
      if (groupOptions.length === 1) {
        this.formikProps.setFieldValue(fieldName('groupId'), groupOptions[0].id);
      }
    }
  }

  render() {
    const { classes, workspaceTypeId, workspaceTypeOptions: options, isSettlementDateOnlyEnabled, isSettlementNullDateTimeEnabled, isTransactionTypeEnabled } = this.props;

    return (
      <>
        <FormGroup className={classes.header} title={<Typography variant="subtitle1">Create new workspace</Typography>}>
          <Radio //
            className={classes.workspaceType}
            name="workspaceType" /* danger:disable */
            options={options}
            format="number"
            onChange={this.handleOnWorkspaceTypeChange}
            value={workspaceTypeId}
            aria-label="Workspace type"
          />
        </FormGroup>
        <Formik //
          method="post"
          action="/workspaces/financial-settlement/creation-details"
          getInitialValues={this.getInitialValues}
          validationSchema={getValidationSchema(isSettlementDateOnlyEnabled, isSettlementNullDateTimeEnabled, isTransactionTypeEnabled)}
          onPreSubmit={this.handleOnPreSubmit}
          onPostSubmit={this.handleOnPostSubmit}
          validateOnMount={true}
        >
          {(formikProps: FormikProps<FormModel>) => this.renderForm(formikProps)}
        </Formik>
      </>
    );
  }

  private shouldShowSettlementDetails = (values: FormModel) => values.groupId !== '';
  private shouldShowVerifyTitle = (values: FormModel) => Number.isInteger(values.jurisdictionId) && Number.isInteger(values.createdByRoleId);

  private get disableContinue() {
    const { isValid, isSubmitting } = this.formikProps;
    if (!isValid || isSubmitting) {
      return true;
    }

    return false;
  }

  private get showControllingParty(): boolean {
    const {
      jurisdictionId,
      titleInformation: { titleVerificationResult }
    } = this.formikProps.values;
    return isLinkedWorkspaceRequired(jurisdictionId, titleVerificationResult?.hasPaperTitle);
  }

  private shouldShowTransactionType = (values: FormModel) => this.props.isTransactionTypeEnabled && values.createdByRoleId !== null;

  private shouldShowTransactionTypeOtherDescription = (values: FormModel) => this.props.isTransactionTypeEnabled && values.transactionTypeId === WorkspaceTransactionTypeEnum.Other;

  private renderForm(formikProps: FormikProps<FormModel>) {
    const { classes, workspaceTypeId, isSearchAndJoinWorkspaceEnabled, subscriberProfile, onClose } = this.props;
    const { values, errors, isSubmitting, isValid } = formikProps;
    this.formikProps = formikProps;
    const { titleInformation } = values;
    const disableVerifyButton = Boolean((!titleInformation.titleInputValue && !titleInformation.titleChips.length) || errors.titleInformation?.titleChips);
    const titles: TitleListItem[] = titleListItemsSelector({
      titleVerificationResult: titleInformation.titleVerificationResult,
      workspaceCreationErrorsMap: values.workspaceCreationErrorsMap
    });
    const duplicateWorkspaces: DuplicateWorkspaceItem[] = duplicateWorkspacesSelector(
      //
      titleInformation.titleVerificationResult
    );

    Logger.console(SeverityEnum.Debug, 'errors', formikProps.errors);

    return (
      <Form className={classes.form}>
        <div className={classes.formContent}>
          <WizardErrorMessage //
            workspaceCreationErrorsMap={values.workspaceCreationErrorsMap}
            titleVerificationResult={titleInformation.titleVerificationResult}
          />
          <AboutYourMatterSection onGroupFieldChange={this.handleOnGroupFieldChange} workspaceTypeId={workspaceTypeId} />
          {this.shouldShowSettlementDetails(values) && this.renderSettlementDetail()}
          {this.shouldShowVerifyTitle(values) && (
            <VerifyPropertySection //
              name={fieldName('titleInformation')}
              jurisdictionId={values.jurisdictionId as JurisdictionsEnum}
              disableVerifyButton={disableVerifyButton}
              showLoader={this.state.isVerifyLoading}
              titles={titles}
              duplicateWorkspaces={duplicateWorkspaces}
              onVerifyClick={this.handleOnVerifyRequest}
              onDeleteClick={this.handleOnDeleteTitleClick}
              inputRef={this.inputRef}
              isFormValid={isValid}
              workspaceTypeId={workspaceTypeId}
              isSearchAndJoinWorkspaceEnabled={isSearchAndJoinWorkspaceEnabled}
              groupId={values.groupId}
              role={values.createdByRoleId!}
              rolePermissions={subscriberProfile.rolePermissions}
              subscriberId={subscriberProfile.subscriberId}
              matter={values.createdByReference}
              onClose={onClose}
            />
          )}
          {this.showControllingParty && this.renderControllingParty()}
        </div>
        <WizardStepper //
          isLoading={isSubmitting}
          showNext={true}
          disabled={this.disableContinue}
          onBack={this.handleOnClose}
          backLabel="Cancel"
          nextLabel="Continue"
        />
      </Form>
    );
  }

  private renderControllingParty() {
    const { classes } = this.props;

    return (
      <FormGroup //
        title="Controlling party"
        className={classNames(classes.controllingParty)}
      >
        <Field //
          component={CheckboxField}
          name={fieldName('isControllingParty')}
          label="I have control of this title"
          className={classes.controllingPartyCheckbox}
        />
      </FormGroup>
    );
  }

  private renderSettlementDetail() {
    const { values } = this.formikProps;

    if (!this.shouldShowSettlementDetails(values)) {
      return null;
    }

    const { classes } = this.props;
    const jurisdictionOptions = this.resolveJurisdictionOptions();
    const workspaceRoleTypeOptions = this.resolveWorkspaceRoleTypeOptions();

    return (
      <FormGroup title="Settlement details" className={classes.borderTop}>
        <Field //
          label="Jurisdiction"
          name={fieldName('jurisdictionId')}
          component={SelectField}
          placeholder={resolveSelectPlaceholder(true)}
          options={jurisdictionOptions}
          format="number"
          className={classes.jurisdictionField}
          onChange={this.handleOnJurisdictionFieldChange}
          data-testid="select-jurisdiction"
        />
        <Field //
          label="Your workspace role"
          name={fieldName('createdByRoleId')}
          component={SelectField}
          options={workspaceRoleTypeOptions}
          format="number"
          placeholder={resolveSelectPlaceholder(true)}
          className={classes.workspaceRoleType}
          onChange={this.handleOnRoleFieldChange}
        />
        {this.renderSettlementDate()}
        <FlexLayout fullWidth>
          {this.shouldShowTransactionType(values) && (
            <Field //
              label={'Transaction type'}
              name={fieldName('transactionTypeId')}
              component={SelectField}
              options={TRANSACTION_TYPE_OPTIONS}
              format="number"
              placeholder={resolveSelectPlaceholder(true)}
              className={classes.jurisdictionField}
            />
          )}
        </FlexLayout>
        <FlexLayout fullWidth>
          {this.shouldShowTransactionTypeOtherDescription(values) && (
            <Field //
              label="Other description"
              name={fieldName('transactionTypeOtherDescription')}
              component={InputField}
              fullWidth
            />
          )}
        </FlexLayout>
      </FormGroup>
    );
  }

  private renderSettlementDate() {
    const { classes, isSettlementDateOnlyEnabled, isSettlementNullDateTimeEnabled } = this.props;
    const jurisdictionId = this.formikProps.values.jurisdictionId as JurisdictionsEnum; // jurisdictionId is always present as per above check.
    const settleTime = this.formikProps.values.settleTime;
    const settleDate = this.formikProps.values.settleDate;

    let timeFieldLabel = jurisdictionNameMapping[jurisdictionId] ? `${jurisdictionNameMapping[jurisdictionId]} Time` : 'Time';
    if (isSettlementDateOnlyEnabled || (isSettlementNullDateTimeEnabled && !settleDate)) {
      timeFieldLabel = `${timeFieldLabel} (Optional)`;
    }

    let DateFieldLabel = 'Date';
    if (!settleTime && isSettlementNullDateTimeEnabled) {
      DateFieldLabel = 'Date (Optional)';
    }

    const settlementTimeOptions = settlementTimeOptionsSelector(
      //
      {
        jurisdictionId: jurisdictionId,
        format: TimeFormatTypeEnum._12H,
        settleDate: settleDate
      }
    );

    return (
      <>
        <FlexLayout fullWidth>
          <Field //
            name={fieldName('settleDate')}
            className={classes.settleDate}
            component={DatePickerField}
            label={DateFieldLabel}
            minDate={MIN_SETTLEMENT_DATE}
            shouldDisableDate={this.shouldDisableSettleDate}
            onChange={this.handleOnSettleDateFieldChange}
            disabled={this.state.isSettlementDateTimeDisabled}
          />
          <Field //
            name={fieldName('settleTime')}
            className={classes.settleTime}
            component={SelectField}
            native
            options={settlementTimeOptions}
            label={timeFieldLabel}
            placeholder={resolveSelectPlaceholder(true)}
            classes={{ helperTextError: classes.helperTextError }}
            disabled={this.state.isSettlementDateTimeDisabled}
          />
        </FlexLayout>
        {isSettlementNullDateTimeEnabled && (
          <Field //
            label={'Do not set settlement date and time now'}
            name={fieldName('isNoSettlementDateTime')}
            component={CheckboxField}
            onChange={this.handleOnCheckboxChange}
            classes={{ root: classes.checkbox }}
          />
        )}
      </>
    );
  }

  private handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    const { setValues } = this.formikProps;

    this.setState(state => ({ ...state, isSettlementDateTimeDisabled: checked }));

    setValues(values => {
      return {
        ...values,
        // clear the date time values if clicking on checkbox
        settleDate: undefined,
        settleTime: undefined,
        isNoSettlementDateTime: checked
      };
    });
  };

  private handleOnGroupFieldChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
    const {
      values: { groupId },
      setValues
    } = this.formikProps;

    if (groupId !== resolvedValue) {
      setValues(values => {
        return {
          //
          ...values,
          // reset data
          jurisdictionId: null,
          settleDate: null,
          settleTime: '',
          titleInformation: {
            ...values.titleInformation,
            jurisdictionId: null,
            // explicitly reset verification result
            titleVerificationResult: undefined
          }
        };
      });
    }
  };

  private handleOnJurisdictionFieldChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: JurisdictionsEnum | null) => {
    const { values, setValues, setFieldValue } = this.formikProps;
    const { jurisdictionId } = values;

    if (jurisdictionId !== resolvedValue) {
      const workspaceRoleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[] = this.resolveWorkspaceRoleTypeOptions(resolvedValue);

      if (this.resetCreatedByRoleIdOption(values, workspaceRoleTypeOptions)) {
        setFieldValue(fieldName('createdByRoleId'), null);
      }

      // TODO update date and time based on validity check
      // clear value for fields placed after jurisdiction (fields depending on jurisdiction)
      setValues(values => {
        return {
          //
          ...values,
          // * material-ui-pickers can NOT deal date with empty string, use null
          settleDate: null,
          settleTime: '',
          titleInformation: {
            ...values.titleInformation,
            // explicitly reset verification result
            titleVerificationResult: undefined,
            jurisdictionId: resolvedValue
          }
        };
      });
    }
  };

  private handleOnSettleDateFieldChange = (settleDate: Date | null) => {
    const { values, setValues } = this.formikProps;
    const { jurisdictionId, settleTime } = values;

    if (!jurisdictionId || !settleTime) {
      return;
    }

    const SETTLEMENT_TIME_OPTIONS: LookupEnumModel<string>[] = settlementTimeOptionsSelector(
      //
      {
        jurisdictionId: jurisdictionId,
        format: TimeFormatTypeEnum._12H,
        settleDate
      }
    );
    const isValidSettleTime = SETTLEMENT_TIME_OPTIONS.find(option => option.id === settleTime);

    if (!isValidSettleTime) {
      setValues(values => {
        return {
          //
          ...values,
          settleTime: ''
        };
      });
    }
  };

  private updateWorkspaceCreationErrors(workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap) {
    this.formikProps.setFieldValue(fieldName('workspaceCreationErrorsMap'), workspaceCreationErrorsMap);
    this.globalValues = this.props.onGlobalValuesUpdate({
      matterDetailsStepData: {
        ...this.globalValues.matterDetailsStepData,
        workspaceCreationErrorsMap
      }
    });
  }

  private handleOnRoleFieldChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: WorkspaceRoleEnum) => {
    this.updateWorkspaceCreationErrors(); // cleanup
    const { setFieldValue } = this.formikProps;
    setFieldValue(
      fieldName('transactionTypeId'),
      resolvedValue === WorkspaceRoleEnum.IncomingMortgagee || resolvedValue === WorkspaceRoleEnum.DischargingMortgagee
        ? WorkspaceTransactionTypeEnum.Refinance
        : WorkspaceTransactionTypeEnum.Transfer
    );
  };

  private handleOnWorkspaceTypeChange = (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: WorkspaceTypeEnum) => {
    const { values, setFieldValue } = this.formikProps;
    const { createdByReference, instructingOrganisationSettlementAgentId, groupId } = values;
    const workspaceRoleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[] = this.resolveWorkspaceRoleTypeOptions();

    if (this.resetCreatedByRoleIdOption(values, workspaceRoleTypeOptions)) {
      setFieldValue(fieldName('createdByRoleId'), null);
    }

    this.props.onWorkspaceTypeChange(resolvedValue, {
      //
      createdByReference,
      instructingOrganisationSettlementAgentId,
      groupId
    });
  };

  private resolveJurisdictionOptions() {
    const { subscriberProfile, workspaceTypeId, isTransferEAPEnabled, assignGroupsState, supportedJurisdictionsState } = this.props;
    const { items: assignableGroupOptions } = assignGroupsState;
    const { items: supportedJurisdictions } = supportedJurisdictionsState;
    const { values } = this.formikProps;
    const { groupId } = values;

    let jurisdictionOptions: LookupEnumModel<JurisdictionsEnum>[] = jurisdictionSelector({
      //
      groupId,
      assignableGroupOptions,
      workspaceTypeId,
      supportedJurisdictions
    });
    return resolveJurisdictionOptionsInTransferEAP(isTransferEAPEnabled, subscriberProfile.subscriberOrganisationType, jurisdictionOptions);
  }

  private resolveWorkspaceRoleTypeOptions(jurisdictionId: JurisdictionsEnum | null = null) {
    const { subscriberProfile, isReapEnabled, isTransferEAPEnabled } = this.props;
    const { values } = this.formikProps;
    jurisdictionId = jurisdictionId || values.jurisdictionId;

    const workspaceRoleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[] = workspaceRoleTypeOptionsSelector({
      //
      subscriberProfile,
      jurisdiction: jurisdictionId,
      isReapEnabled,
      isTransferEAPEnabled
    });
    return workspaceRoleTypeOptions;
  }

  private resolveTitleVerificationPayload(): TitleVerificationRequest {
    // merge existing titles with new title chips
    const {
      titleInformation: { titleChips, titleVerificationResult }
    } = this.formikProps.values;
    const references = resolveTitles(titleChips, titleVerificationResult?.titles);
    const { workspaceTypeId: workspaceType } = this.props;

    const payload: TitleVerificationRequest = {
      checkTitleReferences: true,
      checkDuplicateWorkspaces: true,
      jurisdictionId: this.formikProps.values.jurisdictionId as JurisdictionsEnum,
      workspaceType: workspaceType,
      references
    };

    return payload;
  }

  private handleOnVerifyRequest = async () => {
    this.setState(state => ({ ...state, isVerifyLoading: true }));
    const payload: TitleVerificationRequest = this.resolveTitleVerificationPayload();

    try {
      const titleVerificationResult: TitleVerificationResponse = await verifyTitleReferences(payload);

      // update ineligibility reason based on the status
      this.formikProps.setValues(values => ({
        ...values,
        titleInformation: {
          ...values.titleInformation,
          // reset the following data
          titleInputValue: '',
          titleChips: [],
          // save verification result
          titleVerificationResult
        }
      }));
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    } catch (error) {
      this.props.dispatch(actionCreateGlobalErrorMessage(error));
    }

    this.setState(state => ({ ...state, isVerifyLoading: false }));
  };

  private verifyDuplicateWorkspacesOnTitleDelete = async (referenceNumber: string) => {
    const payload: TitleVerificationRequest = this.resolveTitleVerificationPayload();
    payload.checkTitleReferences = false;
    payload.references = payload.references.filter(reference => reference !== referenceNumber);
    if (!payload.references.length) {
      return;
    }

    this.setState(state => ({ ...state, isVerifyLoading: true }));

    try {
      const response: TitleVerificationResponse = await verifyTitleReferences(payload);

      // update ineligibility reason based on the status
      this.formikProps.setValues(values => ({
        ...values,
        titleInformation: {
          ...values.titleInformation,
          // save verification result
          titleVerificationResult: {
            ...(values.titleInformation.titleVerificationResult ?? {
              titles: [],
              hasPaperTitle: false
            }),
            duplicateWorkspaces: response.duplicateWorkspaces
          }
        }
      }));
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    } catch (error) {
      // The search for duplicate workspaces will not block the process if the user still want to create a new workspace
      // hence if there is any error we just log it and ignore it
      const scope = Logger.scopeWithCustomAttributes({ ...payload });
      Logger.captureException(error, scope);
    }

    this.setState(state => ({ ...state, isVerifyLoading: false }));
  };

  private handleOnDeleteTitleClick = (e: React.MouseEvent<HTMLButtonElement>, referenceNumber: string) => {
    this.updateFormikTitleInformation(referenceNumber);
    this.verifyDuplicateWorkspacesOnTitleDelete(referenceNumber);
  };

  private handleOnPreSubmit = (values: FormModel): FinancialWorkspaceTitleCreationDetailsStepRequest => {
    const { workspaceTypeId: workspaceType } = this.props;
    const {
      isInteroperable,
      jurisdictionId,
      createdByRoleId,
      titleInformation: { titleVerificationResult }
    } = values;
    // titles should always exists, it will never be an empty array
    const titleReferences = titleVerificationResult?.titles.map(title => prepareTitleForSubmission(jurisdictionId!, title.titleReference)) || [];
    return {
      isInteroperable,
      jurisdiction: jurisdictionId as JurisdictionsEnum, // this property will be present when user hit submit
      workspaceRole: createdByRoleId as WorkspaceRoleEnum, // this property will be present when user hit submit
      titleReferences,
      workspaceType
    };
  };

  private handleOnPostSubmit = (args: FormikPostSubmitArgs<FormModel, CreateFinancialWorkspaceMatterDetailsStepResponse>) => {
    if (args.error) {
      return;
    }

    const { response, formValues } = args;
    const { canCreateWorkspace, titleReferenceErrors, invitableRoles } = response;
    if (!canCreateWorkspace) {
      const {
        titleInformation: { titleVerificationResult }
      } = args.formValues;

      const titleReferences: string[] = titleVerificationResult?.titles.map(t => t.titleReference) || [];
      const unifiedTitleReferences: string[] = titleReferences.map(unifyTitleFormat); // we need to unify the format due to potential inconsistency in between user input and returned title reference from API
      const workspaceCreationErrorsMap: WorkspaceCreationErrorsMap = titleReferenceErrors.reduce((acc, item) => {
        const index: number = unifiedTitleReferences.indexOf(unifyTitleFormat(item.titleReference));
        acc[titleReferences[index]] = item.message;
        return acc;
      }, {});

      this.updateWorkspaceCreationErrors(workspaceCreationErrorsMap);

      // when WizardErrorMessage is shown after submit, it's better we auto scroll to it if it's not visible
      document.querySelector('[data-error-name="workspaceCreationErrorsMap"]')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    const { isReapEnabled, isTransferEAPEnabled } = this.props;
    // calculate invitable roles based on current role
    let invitationOrderList: WorkspaceRoleEnum[] = getInvitationOrderByRole(isReapEnabled);
    invitationOrderList = excludeRolesWhenCreateWorkspaceInTransferEAP(isTransferEAPEnabled, invitationOrderList, formValues.createdByRoleId as WorkspaceRoleEnum);
    let filteredInvitableRoles: WorkspaceRoleEnum[] = invitationOrderList.filter(role => invitableRoles.includes(role));

    //WEB-16073 Controlling party should be the first in the invitations list
    //field should be displayed only when workspace creator wants to select a controlling party
    if (
      isLinkedWorkspaceRequired(formValues.jurisdictionId, formValues.titleInformation.titleVerificationResult?.hasPaperTitle) && //
      formValues.isControllingParty === false
    ) {
      filteredInvitableRoles = [WorkspaceRoleEnum.ControllingParty, ...filteredInvitableRoles];
    }

    const matterDetailsStepApiResponse: CreateFinancialWorkspaceMatterDetailsStepResponse = {
      ...response,
      invitableRoles: filteredInvitableRoles
    };
    // preserve data for the next step
    this.props.onGlobalValuesUpdate({
      //
      matterDetailsStepData: formValues,
      matterDetailsStepApiResponse
    });

    // move to next step
    this.props.onStepChange(CreateFinancialWorkspaceStepEnum.InviteMembers);
  };

  private handleOnClose = () => {
    this.props.onClose();
  };

  private resetCreatedByRoleIdOption = (values: FormModel, wsTypeOptions: LookupEnumModel<number>[]) => {
    return Number.isInteger(values.createdByRoleId) && wsTypeOptions.findIndex(typeOption => typeOption.id === values.createdByRoleId) === -1;
  };

  private shouldDisableSettleDate = (date: Date) => {
    const { values } = this.formikProps;
    // return true means the date is disabled
    return !isBusinessDay(date, values.jurisdictionId as JurisdictionsEnum);
  };

  private updateFormikTitleInformation(referenceNumber: string) {
    this.formikProps.setValues(values => {
      const titles = values.titleInformation.titleVerificationResult!.titles.filter(title => title.titleReference !== referenceNumber);
      const titleVerificationResult: TitleVerificationResponse | undefined = titles.length
        ? {
            ...values.titleInformation.titleVerificationResult!,
            titles
          }
        : undefined;

      let workspaceCreationErrorsMap: WorkspaceCreationErrorsMap | undefined = values.workspaceCreationErrorsMap;
      if (referenceNumber in (workspaceCreationErrorsMap || {})) {
        delete workspaceCreationErrorsMap![referenceNumber];
        workspaceCreationErrorsMap = {
          ...workspaceCreationErrorsMap
        };
      }

      return {
        ...values,
        titleInformation: { ...values.titleInformation, titleVerificationResult: titleVerificationResult },
        workspaceCreationErrorsMap
      };
    });
  }
}

export default withStyles(styles)(MatterDetail);

import * as React from 'react';

import { useDispatch } from 'react-redux';

import { JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { UserProfileModel } from '@sympli/api-gateway/shared';

import { useWorkspaceAssignableGroups } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { useWorkspaceInvitation, WorkspaceInvitationState } from 'src/containers/workspace/shared/detail/reducers/workspaceInvitation';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import InviteAdditionalParticipants from './InviteAdditionalParticipants';

interface Props {
  isInteroperable?: boolean;
  jurisdictionId: JurisdictionsEnum;
  workspaceId: string;
  participantId: string;
  currentWorkspaceRoleId: WorkspaceRoleEnum;
  openDialog: boolean;
  onClose: () => void;
  workspaceType: WorkspaceTypeEnum;
  currentParticipantGroupId?: string;
  currentParticipantReference?: string;
  // list of current workspace participants
  workspaceParticipants: WorkspaceParticipantApiResponse[];
}

function InviteAdditionalParticipantsContainer(props: Props) {
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceInvitationState: WorkspaceInvitationState = useWorkspaceInvitation(props.workspaceId, props.participantId);
  const currentSubscriber: UserProfileModel = useProfile().data!;
  const { items: assignableGroups } = useWorkspaceAssignableGroups();
  const isTransferEAPEnabled = useFeatureFlag(FeatureToggleEnum.transferEAP);

  return (
    <InviteAdditionalParticipants //
      {...props}
      {...workspaceInvitationState}
      dispatch={dispatch}
      currentSubscriber={currentSubscriber}
      assignableGroups={assignableGroups}
      isTransferEAPEnabled={isTransferEAPEnabled}
    />
  );
}

export default React.memo(InviteAdditionalParticipantsContainer);

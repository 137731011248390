import * as React from 'react';

import { endOfYesterday } from 'date-fns';
import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

import { useAddressField as AddressSelectField } from '@sympli-mfe/document-components/address-field';
import FormGroup from '@sympli-mfe/document-forms-framework/components/form-group';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { createModelKeyAppender } from '@sympli-mfe/document-forms-framework/utils';
import ReadOnlyField from '@sympli/ui-framework/components/form/base-components/read-only-field';
import DatePickerField from '@sympli/ui-framework/components/formik/date-picker-field';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { colors } from 'src/theme';
import { ADDRESS_BOOK_KEY, Form24A_2_24_1Model, PARTY_BOOK_KEY, TransfereeReferenceModel } from '../../models';
import { Context } from '../../RootForm';
import { BOOLEAN_OPTIONS } from './enums';
import { ForeignPersonOrCorporation } from './ForeignPersonOrCorporation';
import { useStyles } from './styles';

const END_OF_YESTERDAY = endOfYesterday();

type FormValues = Form24A_2_24_1Model;

interface Props {
  name: string;
  transferee: TransfereeReferenceModel;
}

function Transferee({ name, transferee }: Props) {
  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;
  const context = useRootFormContext<Context>();
  const { disabled, dialogPortalId } = useDocumentContext();
  const classes = useStyles();

  const fieldName = createModelKeyAppender<TransfereeReferenceModel>(name);
  const partySourceFieldName = createModelKeyAppender<ApiPartyReferenceModel>(fieldName('transferee'));

  const bookIndexOfPartyId = values.partyBook.findIndex(party => party.id === transferee.transferee.partyBookId);
  const party = values.partyBook[bookIndexOfPartyId];
  const displayName: string = [party.firstName, party?.lastName].filter(Boolean).join(' ');

  const partyBookFieldName = createModelKeyAppender<PartyModel>(`${PARTY_BOOK_KEY}[${bookIndexOfPartyId}]`);

  return (
    <FormGroup title="Individual" fieldAreaDirection="column">
      <ReadOnlyField //
        label="Name"
        value={displayName}
      />

      <Field //
        label="Date of birth"
        name={partyBookFieldName('dob')}
        component={DatePickerField}
        disableFuture
        maxDate={END_OF_YESTERDAY}
        disabled={disabled}
        className={classes.dob}
      />

      <AddressSelectField
        name={partySourceFieldName('addressBookId')}
        disabled={disabled}
        bookRef={ADDRESS_BOOK_KEY}
        dialogPortalId={dialogPortalId}
        sharedAddressFormConfig={context.addressFormConfig}
        sharedInitialValuesForNew={context.addressFormInitialValues}
        label="Residential/business address after possession"
      />

      <ForeignPersonOrCorporation name={fieldName('isForeignPersonOrCorp')} />

      <Field //
        label="Does the transferee ordinarily reside in Australia?"
        name={fieldName('isResident')}
        component={RadioField}
        disabled={disabled}
        format="boolean"
        options={BOOLEAN_OPTIONS}
        autoFocusOption="controlled"
        className={classes.radio}
        formTip={
          <>
            <Typography variant="body2" color={colors.WHITE}>
              In the state of Queensland, a person would not be considered to ordinarily reside in Australia if they:{' '}
            </Typography>
            <Typography variant="body2" sx={{ color: colors.WHITE, marginLeft: -2 }}>
              <ul>
                <li>have been absent from Australia for at least 6 months during a year period; or</li>
                <li>were absent from Australia for the 6 month period ending at the conclusion of the relevant financial year.</li>
              </ul>
            </Typography>
            <Typography variant="body2" color={colors.WHITE}>
              Further clarification may be sought through review of the <i>Land Tax Act 2010</i> (QLD).
            </Typography>
          </>
        }
      />
    </FormGroup>
  );
}

export default React.memo(Transferee);

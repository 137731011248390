import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    referenceInput: {
      color: theme.palette.text.secondary,
      '&:focus': {
        outline: 'none'
      },
      fontWeight: 'bold',
      fontSize: 32,
      minHeight: 24,
      justifyContent: 'flex-start'
    },
    reference: {
      '&>p': {
        display: 'none'
      },
      marginBottom: 0
    },
    referenceButton: {
      fontSize: 32
    },
    textEllipsis: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      justifyContent: 'flex-start',
      minHeight: 'inherit'
    },
    tooltip: {
      color: 'black',
      width: 'auto',
      fontSize: 13,
      fontWeight: 'bold',
      boxShadow: theme.shadows[1]
    }
  }),
  { name: 'ReferenceForm' }
);

import { createSelector } from 'reselect';

import {
  ClientWorkspaceRoleEnum,
  JurisdictionsEnum,
  SubscriberOrganisationTypeEnum,
  WorkspaceRoleEnum,
  WorkspaceTransactionTypeEnum,
  WorkspaceTypeEnum
} from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { GroupOptionModel } from 'src/containers/dashboard/shared/models';
import { SupportedJurisdictionApiResponse } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { TitleVerificationStatusEnum, VerifiedTitleResponse } from 'src/containers/shared/verify-property-section/models';
import { TimeFormatTypeEnum, timeStringConvert } from 'src/containers/workspace/financial/settlement-date/helper';
import { refinanceWorkspaceRoles } from 'src/models/roles';
import { initializeTimeOptions } from 'src/utils/timeHelper';
import { isDstObservedInNSW } from 'src/utils/timezone/timezoneHelper';
import { RepresentationSubscriberTypeEnum } from '../../../../models';

export interface JurisdictionSelectorParamModel {
  groupId: string;
  assignableGroupOptions: GroupOptionModel[];
  workspaceTypeId: WorkspaceTypeEnum;
  supportedJurisdictions: SupportedJurisdictionApiResponse;
}

const getGroupId = (data: JurisdictionSelectorParamModel) => data.groupId;
const getAssignableGroupOption = (data: JurisdictionSelectorParamModel) => data.assignableGroupOptions;

const getWorkspaceTypeId = (data: JurisdictionSelectorParamModel) => data.workspaceTypeId;
const getSupportedJurisdictions = (data: JurisdictionSelectorParamModel) => data.supportedJurisdictions;

const DEFAULT_JURISDICTIONS_OPTIONS: LookupEnumModel<JurisdictionsEnum, string>[] = [];
export const jurisdictionSelector = createSelector<
  JurisdictionSelectorParamModel,
  string,
  GroupOptionModel[],
  WorkspaceTypeEnum,
  SupportedJurisdictionApiResponse,
  LookupEnumModel<JurisdictionsEnum>[]
>(
  getGroupId, //
  getAssignableGroupOption,
  getWorkspaceTypeId,
  getSupportedJurisdictions,
  (
    groupId: string, //
    assignableGroupOptions: GroupOptionModel[] = [],
    workspaceTypeId,
    supportedJurisdictions: SupportedJurisdictionApiResponse = []
  ) => {
    if (groupId === '') {
      return DEFAULT_JURISDICTIONS_OPTIONS;
    }
    const selectedGroup = assignableGroupOptions.find(option => option.id === groupId);
    const currentWsTypeSupportedJurisdiction = supportedJurisdictions.find(item => item.workspaceType === workspaceTypeId);
    if (!(selectedGroup && currentWsTypeSupportedJurisdiction)) {
      return DEFAULT_JURISDICTIONS_OPTIONS;
    }
    const supportedJurisdictionSet = new Set(currentWsTypeSupportedJurisdiction.supportedJurisdictions);
    const result = selectedGroup.jurisdictions.filter(item => supportedJurisdictionSet.has(item.id));
    return result;
  }
);
const resolveTimeByNSWDaylightTimeSaving = (
  //
  date: Date | null,
  jurisdictionId: JurisdictionsEnum,
  dstTime: number,
  stdTime: number
) => {
  if (!date) {
    return dstTime;
  }
  const isDST = isDstObservedInNSW(date);
  const time = isDST ? dstTime : stdTime;
  return time;
};
interface SettlementTimeOptionsSelector {
  jurisdictionId: JurisdictionsEnum;
  format?: TimeFormatTypeEnum;
  settleDate?: Date | string | null;
}
const getJurisdictionId = ({ jurisdictionId }) => jurisdictionId;
const getFormat = ({ format = TimeFormatTypeEnum._24H }) => format;
const getSettleDate = ({ settleDate }: SettlementTimeOptionsSelector): Date | null => {
  if (!settleDate) return null;
  if (typeof settleDate === 'string') return new Date(settleDate);
  return settleDate;
};
export const settlementTimeOptionsSelector = createSelector<
  //
  SettlementTimeOptionsSelector,
  JurisdictionsEnum,
  TimeFormatTypeEnum,
  Date | null,
  LookupEnumModel<string>[]
>(
  //
  getJurisdictionId,
  getFormat,
  getSettleDate,
  (jurisdictionId: JurisdictionsEnum, format: TimeFormatTypeEnum, settleDate: Date | null) => {
    let options: LookupEnumModel<string>[];
    switch (jurisdictionId) {
      case JurisdictionsEnum.QLD:
        options = initializeTimeOptions(9 * 60 + 30, 16 * 60);
        break;
      case JurisdictionsEnum.WA:
        const end = resolveTimeByNSWDaylightTimeSaving(settleDate, JurisdictionsEnum.WA, 16 * 60, 15 * 60);
        options = initializeTimeOptions(8 * 60 + 30, end);
        break;
      case JurisdictionsEnum.VIC:
        options = initializeTimeOptions(9 * 60 + 30, 17 * 60);
        break;
      case JurisdictionsEnum.NSW:
        options = initializeTimeOptions(9 * 60 + 30, 17 * 60);
        break;
      default:
        options = initializeTimeOptions();
        break;
    }
    return format === TimeFormatTypeEnum._24H ? options : options.map(({ id, name }) => ({ id, name: timeStringConvert(name) }));
  }
);

export function getInvitationOrderByRole(isReapEnabled: boolean): WorkspaceRoleEnum[] {
  const defaultInvitationOrder: WorkspaceRoleEnum[] = [
    //
    WorkspaceRoleEnum.Vendor,
    WorkspaceRoleEnum.Purchaser,
    WorkspaceRoleEnum.DischargingMortgagee,
    WorkspaceRoleEnum.IncomingMortgagee,
    WorkspaceRoleEnum.DischargingCaveator,
    WorkspaceRoleEnum.Beneficiary
  ];
  return isReapEnabled ? defaultInvitationOrder.filter(role => refinanceWorkspaceRoles.includes(role)) : defaultInvitationOrder;
}

export function isLinkedWorkspaceRequired(jurisdictionId: JurisdictionsEnum | null, hasPaperTitle: boolean = false) {
  return jurisdictionId === JurisdictionsEnum.VIC && hasPaperTitle;
}

const TITLE_VERIFICATION_ERROR_STATUES: TitleVerificationStatusEnum[] = [
  TitleVerificationStatusEnum.ErrorControllerNotRegistered,
  TitleVerificationStatusEnum.ErrorInconsistentControllers,
  TitleVerificationStatusEnum.ErrorNonElectronicLodgement,
  TitleVerificationStatusEnum.ErrorRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotRegistrarOfTitles,
  TitleVerificationStatusEnum.ErrorControllerNotCurrentSubscriber,
  TitleVerificationStatusEnum.ErrorPaperTitle
];

export function isTitleVerificationSuccessful(titles?: VerifiedTitleResponse[]) {
  if (titles && titles.length) {
    return titles.every(t => !TITLE_VERIFICATION_ERROR_STATUES.some(e => e === t.status));
  }
  return false;
}

export function excludeRolesWhenCreateWorkspaceInTransferEAP(
  isTransferEAPEnabled: boolean,
  workspaceRoles: WorkspaceRoleEnum[],
  createdByRoleId: WorkspaceRoleEnum
): WorkspaceRoleEnum[] {
  if (!isTransferEAPEnabled) return workspaceRoles;

  switch (createdByRoleId) {
    case WorkspaceRoleEnum.Purchaser:
    case WorkspaceRoleEnum.Vendor:
    case WorkspaceRoleEnum.Beneficiary:
      return workspaceRoles.filter(role => role !== WorkspaceRoleEnum.DischargingMortgagee && role !== WorkspaceRoleEnum.IncomingMortgagee);
    case WorkspaceRoleEnum.IncomingMortgagee:
    case WorkspaceRoleEnum.DischargingMortgagee:
      return workspaceRoles.filter(role => role !== WorkspaceRoleEnum.Purchaser && role !== WorkspaceRoleEnum.Vendor && role !== WorkspaceRoleEnum.Beneficiary);
    default:
      return workspaceRoles;
  }
}

export const getRepresentationSubscriberType = (isTransferEAPEnabled: boolean, subscriberOrganisationType: SubscriberOrganisationTypeEnum): RepresentationSubscriberTypeEnum => {
  if (!isTransferEAPEnabled) {
    return RepresentationSubscriberTypeEnum.Other;
  }

  switch (subscriberOrganisationType) {
    case SubscriberOrganisationTypeEnum.ConveyancingPractice:
    case SubscriberOrganisationTypeEnum.LawPractice:
      return RepresentationSubscriberTypeEnum.Represented;
    case SubscriberOrganisationTypeEnum.FinancialInstitution:
      return RepresentationSubscriberTypeEnum.SelfRepresented;
    default:
      return RepresentationSubscriberTypeEnum.Other;
  }
};

export const resolveWorkspaceRoleTypeOptionsInTransferEAP = (
  isTransferEAPEnabled: boolean,
  subscriberOrganisationType: SubscriberOrganisationTypeEnum,
  workspaceRoleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[]
): LookupEnumModel<ClientWorkspaceRoleEnum>[] => {
  const representationSubscriberType = getRepresentationSubscriberType(isTransferEAPEnabled, subscriberOrganisationType);
  switch (representationSubscriberType) {
    case RepresentationSubscriberTypeEnum.Represented:
      return workspaceRoleTypeOptions.filter(role => role.id !== ClientWorkspaceRoleEnum.DischargingMortgagee && role.id !== ClientWorkspaceRoleEnum.IncomingMortgagee);
    case RepresentationSubscriberTypeEnum.SelfRepresented:
      return workspaceRoleTypeOptions.filter(
        role => role.id !== ClientWorkspaceRoleEnum.Purchaser && role.id !== ClientWorkspaceRoleEnum.Vendor && role.id !== ClientWorkspaceRoleEnum.Beneficiary
      );
    default:
      return workspaceRoleTypeOptions;
  }
};

export const resolveJurisdictionOptionsInTransferEAP = (
  isTransferEAPEnabled: boolean,
  subscriberOrganisationType: SubscriberOrganisationTypeEnum,
  jurisdictionOptions: LookupEnumModel<JurisdictionsEnum>[]
): LookupEnumModel<JurisdictionsEnum>[] => {
  const representationSubscriberType = getRepresentationSubscriberType(isTransferEAPEnabled, subscriberOrganisationType);
  switch (representationSubscriberType) {
    case RepresentationSubscriberTypeEnum.Represented:
      return jurisdictionOptions.filter(jurisdiction => jurisdiction.id === JurisdictionsEnum.NSW);
    default:
      return jurisdictionOptions;
  }
};

export const TRANSACTION_TYPE_OPTIONS: LookupEnumModel<WorkspaceTransactionTypeEnum>[] = [
  {
    id: WorkspaceTransactionTypeEnum.Refinance,
    name: 'Refinance'
  },
  {
    id: WorkspaceTransactionTypeEnum.Transfer,
    name: 'Transfer'
  },
  {
    id: WorkspaceTransactionTypeEnum.Other,
    name: 'Other'
  }
];

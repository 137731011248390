export enum DateCellStatusEnum {
  Default = 'default',
  Invalid = 'invalid',
  CurrentSettlement = 'currentSettlement',
  ProposedSettlement = 'proposedSettlement'
}

export interface DateCellBaseModel {
  date: Date;
  isInMonth: boolean;
  isPast: boolean;
  isToday: boolean;
  holidayName?: string;
}

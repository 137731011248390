import * as React from 'react';

import ConfirmationDialog from '@sympli/ui-framework/components/dialogs/confirmation-dialog';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconDelete } from '@sympli/ui-framework/icons';

import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  documentName: string;
  isDeleting?: boolean;
  onNext(): Promise<void>;
  onClose(): void;
}

function DeleteDocumentDialog({ isOpen, documentName, isDeleting, children, onNext, onClose }: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  const title = (
    <FlexLayout alignItems="center">
      <IconDelete className={classes.headingIcon} />
      <span className={classes.headingText}>Delete {documentName}?</span>
    </FlexLayout>
  );

  return (
    <ConfirmationDialog //
      title={title}
      open={isOpen}
      onClose={onClose}
      showActionButtons={false}
      scroll="body"
    >
      {children}
      <WizardStepper //
        color="error"
        onBack={onClose}
        backLabel="Cancel"
        nextLabel="Delete"
        onNext={onNext}
        isLoading={isDeleting}
        disabled={isDeleting}
        classes={{ errorButton: classes.errorButton }}
      />
    </ConfirmationDialog>
  );
}

export default React.memo(DeleteDocumentDialog);

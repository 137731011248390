import * as React from 'react';

import classNames from 'classnames';
import { Action, Dispatch } from 'redux';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentStatusEnum, SubscriberOrganisationTypeEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconLocked } from '@sympli/ui-framework/icons';

import CopyToClipboardContainer, { CopyToClipboardContainer101 } from 'src/components/copy-to-clipboard-container';
import { Header } from 'src/components/layout';
import ProfileContext, { ProfileContextModel } from 'src/containers/shared/auth/profile-security/Profile.context';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import ReferenceForm from './reference-form';
import styles, { ClassKeys } from './styles';

// TODO extract api logic!
interface OwnProps {
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceStatus: WorkspaceStatusEnum;
  isLocked?: boolean;
  // current participant
  reference?: string;

  distributionsStatus?: DocumentStatusEnum;
  dispatch: Dispatch<Action<any>> | SafeDispatch<Action<any>>;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class StatusHeader extends React.PureComponent<Props> {
  // maybe need lodgement case status for the disable
  private disableUpdateReferenceList: Set<WorkspaceStatusEnum> = new Set([
    //
    WorkspaceStatusEnum.SettlementInProgress,
    WorkspaceStatusEnum.Success,
    WorkspaceStatusEnum.Finalised
  ]);

  render() {
    const { isLocked, classes } = this.props;
    return (
      <ProfileContext.Consumer>
        {profile => (
          <FlexLayout>
            {isLocked && <IconLocked className={classes.lockIcon101} />}
            {this.renderReference(profile)}
          </FlexLayout>
        )}
      </ProfileContext.Consumer>
    );
  }

  private renderReference(profile: ProfileContextModel) {
    const { reference = '', workspaceStatus, distributionsStatus, classes, isLocked, workspaceId, participantId, dispatch } = this.props;
    const organisationalType = profile?.subscriberOrganisationType;

    if (isLocked || this.disableUpdateReferenceList.has(workspaceStatus)) {
      return (
        <FlexLayout className={classes.header} flexDirection="row" alignItems="flex-start">
          <CopyToClipboardContainer
            textToCopy={reference}
            fieldName={organisationalType === SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan number' : 'matter number'}
            size="large"
            className={classes.header}
          >
            <Header className={classes.header}>{reference}</Header>
          </CopyToClipboardContainer>
        </FlexLayout>
      );
    }

    return (
      <CopyToClipboardContainer101
        className={classNames(classes.inlineEditorContainer, classes.header)}
        textToCopy={reference}
        fieldName={organisationalType === SubscriberOrganisationTypeEnum.FinancialInstitution ? 'loan number' : 'matter number'}
        size="large"
        tooltipType="dashboardFeed"
      >
        <ReferenceForm //
          reference={reference}
          workspaceId={workspaceId}
          participantId={participantId}
          dispatch={dispatch}
          distributionsStatus={distributionsStatus}
        />
      </CopyToClipboardContainer101>
    );
  }
}

const StyledStatusHeader = withStyles(styles)(StatusHeader);
export default StyledStatusHeader;

import * as React from 'react';

import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Tooltip from '@sympli/ui-framework/components/form/base-components/tooltip';

import { colors } from 'src/theme';

interface Props extends Omit<MuiTooltipProps, 'title'> {
  showTooltip: boolean;
  title?: string;
}

function ConditionalEditTooltip({ showTooltip, children, title }: Props) {
  if (showTooltip) {
    return (
      <Tooltip
        //
        title={
          title || (
            <Typography variant="body2" sx={{ color: colors.WHITE }}>
              Please complete the open line item before continuing.
            </Typography>
          )
        }
        placement="top"
      >
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
}

export default React.memo(ConditionalEditTooltip);

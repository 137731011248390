import { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { DirectionCategoryEnum, PaymentMethodEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import {
  UpdateWorkspaceDirectionsApiRequestBody,
  UpdateWorkspaceDirectionsApiResponse,
  WorkspaceDirectionsApiResponse,
  WorkspaceParticipantApiResponse
} from '@sympli/api-gateway/models';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useFeatureFlag } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { actionFetchDirections, actionFetchDirectionsList } from '../../../actions';
import { directionUpdateHelper, isFullyOptedOut } from '../../../helpers';
import { DistributionFormikModel } from '../../../models';
import { CategoryEnum } from '../forms/discharge-mortgage-directions/models';
import { trustAccountOptionsSelector } from '../selectors';

export default function useEditDirections(
  queryParams: WorkspaceDetailRouteParams,
  detail: WorkspaceDirectionsApiResponse,
  currentParticipant: WorkspaceParticipantApiResponse,
  directionCategories: Array<LookupEnumModel<string>>
) {
  const { workspaceId, participantId } = queryParams;
  const { trustAccountOptions, trustAccountBankDetailMap } = trustAccountOptionsSelector(detail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
  const fssLineItemSaveEnabled: boolean = useFeatureFlag(FeatureToggleEnum.fssLineItemSaveEnabled);

  const defaultDistributionsValue = useMemo<DistributionFormikModel>(() => {
    const { name: subscriberName, workspaceRole } = currentParticipant;

    return {
      isEditorOpen: true,
      disableAddAmountAsVendor: false,
      paymentMethod: directionCategories.length === 1 && directionCategories[0].name === CategoryEnum.Other ? PaymentMethodEnum.BankTransfer : ('' as any),
      bankDetails: { bsb: '', accountNumber: '', accountName: '', bankName: '', reference: '' },
      bpayDetails: { billerCode: '', billerReference: '', billerName: '', description: '' },
      holdingAccountDetails: { accountId: '', reference: '', shortfallAmount: '', acceptSurplus: false },
      amount: '' as any,
      directionCategory:
        workspaceRole.id === WorkspaceRoleEnum.IncomingMortgagee || workspaceRole.id === WorkspaceRoleEnum.Purchaser
          ? DirectionCategoryEnum.Purchaser
          : DirectionCategoryEnum.Settlement,
      isLocked: false,
      hasEditPermission: true,
      category: directionCategories.length === 1 ? directionCategories[0].id : '',
      categoryOther: '',
      subscriberName,
      participantId: '',
      prepopulateAccountId: '',
      lineItemBreakdowns: []
    };
  }, [currentParticipant, directionCategories]);

  const onPostSubmit = useCallback(
    (args: FormikPostSubmitArgs<UpdateWorkspaceDirectionsApiRequestBody, UpdateWorkspaceDirectionsApiResponse>) => {
      if (args.error) {
        return;
      }

      // update the initial state values
      directionUpdateHelper({
        dispatch,
        newDirectionForm: {
          ...args.response,
          loanAdvance: args.response.loanAdvance // loanAdvance can be undefined
        },
        newDistributionsParticipantData: { distributionStatus: args.response.documentSummary.status, isPartiallySigned: args.response.documentSummary.isPartiallySigned },
        participantId,
        workspaceId
      });

      // refetch the status
      dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));

      if (isSaveAsDraft) {
        dispatch(actionFetchDirections.request(queryParams));
      }

      if (!isSaveAsDraft && isFullyOptedOut(args.response.documentSummary.paymentsSummary.financialStatus, args.response.documentSummary.sourceFundsSummary.financialStatus)) {
        dispatch(
          actionOpenGlobalSimpleNotification({
            message: (
              <>
                <Typography variant="body2_bold">Financial Settlement Schedule is not applicable</Typography>
                <div className="mb-[16px] mr-[16px] mt-[8px]">
                  <Typography className="mb-[6px]">As you have no financial line items entered your Financial Settlement Schedule status is now set to N/A.</Typography>
                  <Typography className="mb-[6px]">If needed, you can always add a line item in the Financial Settlement Schedule.</Typography>
                  <Typography>
                    System and other participants can add line items that will automatically re-activate your Financial Settlement Schedule and change the status to In Preparation.
                  </Typography>
                </div>
              </>
            ),
            variant: 'warning',
            autoHideDuration: 60000
          })
        );

        // reset form before we navigate away because if the form is dirty, the unsaved changes prompt will block the navigation
        args.formikHelpers.resetForm();

        navigate(
          resolveWorkspaceDetailLink({
            workspaceId: workspaceId,
            participantId: participantId
          })
        );
      }
    },
    [dispatch, participantId, workspaceId, isSaveAsDraft, queryParams, navigate]
  );

  return {
    //
    defaultDistributionsValue,
    workspaceId,
    participantId,
    onPostSubmit,
    isSaveAsDraft,
    setIsSaveAsDraft,
    trustAccountOptions,
    trustAccountBankDetailMap,
    fssLineItemSaveEnabled
  };
}

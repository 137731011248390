import React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { JurisdictionsEnum, SettlementDateKindEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import Formik from '@sympli/ui-framework/components/formik';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import msg from '@sympli/ui-framework/utils/messages';

import { dateTimeLine } from 'src/utils/formatters';
import { GroupOptionModel } from '../../../shared/models';
import { InvitationsTableRowModel } from '../../table/models';
import ToggleCheckbox from '../toggle-checkbox';
import { resolveAssignableGroups } from './helpers';

export interface AcceptInviteFormValues {
  invitationId: string;
  workspaceId: string;
  hasAcceptedSettlementDate: boolean;
  reference: string;
  groupId: string;
}

export type AcceptInviteSubmitType = 'accept' | 'accept-and-view';

const fieldName = modelKey<AcceptInviteFormValues>();

interface AcceptInviteFormProps {
  invitationId: string;
  workspaceId: string;
  proposedSettlementDate: InvitationsTableRowModel['proposedSettlementDate'];
  expectedSettlementDate: InvitationsTableRowModel['expectedSettlementDate'];
  invitationAssignedGroup: InvitationsTableRowModel['groupName'];
  invitationAssignableGroups: GroupOptionModel[];
  jurisdictionId: JurisdictionsEnum;
  workspaceTypeId: WorkspaceTypeEnum;

  onCancel(): void;
  onSubmit(data: AcceptInviteFormValues, type: AcceptInviteSubmitType): Promise<void>;
  matter?: string;
}

const validationSchema = yup.object<AcceptInviteFormValues>({
  invitationId: yup.mixed<string>(),
  workspaceId: yup.mixed<string>(),
  hasAcceptedSettlementDate: yup.boolean(),
  reference: yup.string().trim().required(msg.REQUIRED).max(200, msg.LENGTH_MUST_BE_X_OR_LESS(200, 'character')),
  groupId: yup.string().trim().required(msg.REQUIRED)
});

function AcceptInviteForm({
  //
  proposedSettlementDate,
  expectedSettlementDate,
  invitationAssignableGroups,
  invitationAssignedGroup,
  jurisdictionId,
  workspaceTypeId,
  invitationId,
  workspaceId,
  onCancel,
  onSubmit,
  matter
}: AcceptInviteFormProps) {
  let submitType: AcceptInviteSubmitType = 'accept';
  const GROUP_OPTIONS: LookupEnumModel<string>[] = React.useMemo(
    () => resolveAssignableGroups(invitationAssignableGroups, jurisdictionId), //
    [jurisdictionId, invitationAssignableGroups]
  );
  const defaultGroup: string = GROUP_OPTIONS.length ? GROUP_OPTIONS.filter(x => x.name === invitationAssignedGroup)[0].id : '';

  const formattedSettlementDate = proposedSettlementDate
    ? expectedSettlementDate?.settlementDateKind === SettlementDateKindEnum.DateOnly
      ? dateTimeLine(proposedSettlementDate.workspaceLocalTime, 'd mmm yyyy')
      : dateTimeLine(proposedSettlementDate.userLocalTime, 'd mmm yyyy, h:MMTT') + ` ${proposedSettlementDate.userTimezoneAbbreviation}`
    : undefined;
  const initialValues: AcceptInviteFormValues = React.useMemo<AcceptInviteFormValues>(
    () => ({
      invitationId,
      workspaceId,
      hasAcceptedSettlementDate: false,
      reference: matter ?? '',
      groupId: defaultGroup
    }),
    [defaultGroup, invitationId, workspaceId, matter]
  );

  const handleOnSubmit = React.useCallback(
    async (data: AcceptInviteFormValues): Promise<void> => {
      return onSubmit(data, submitType);
    },
    [onSubmit, submitType]
  );

  const fieldClasses = {
    inputLabel: 'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]',
    formControl: 'mt-[28px]',
    marginBottom: 'mb-[24px]'
  };

  const isOverDue: boolean = !!proposedSettlementDate && proposedSettlementDate?.hoursTill < 0;

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      validateOnMount
    >
      {({ submitForm, isSubmitting, isValid, dirty, values, setFieldValue, setFieldTouched }: FormikProps<AcceptInviteFormValues>) => {
        return (
          <Form className="flex grow flex-col justify-between">
            <div>
              {workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly && !!formattedSettlementDate && !!expectedSettlementDate && (
                <div className={classNames('flex items-center justify-between', fieldClasses.marginBottom)}>
                  <div>
                    <div
                      //
                      className={classNames(fieldClasses.inputLabel, isOverDue && 'text-[var(--neutral-700)] line-through')}
                    >
                      {formattedSettlementDate}
                    </div>
                    <div //
                      className={classNames('text-[14px] font-[400] leading-[20px] tracking-[0.25px] text-[var(--neutral-600)]')}
                    >
                      {expectedSettlementDate.settlementDateKind === SettlementDateKindEnum.DateOnly ? 'Proposed Settlement Date' : 'Proposed Settlement Date & Time'}
                    </div>
                  </div>
                  {expectedSettlementDate.settlementDateKind === SettlementDateKindEnum.DateAndTime && (
                    <ToggleCheckbox
                      disabled={isOverDue || isSubmitting}
                      checked={values.hasAcceptedSettlementDate}
                      label={values.hasAcceptedSettlementDate ? 'Accepted' : 'Not Accepted'}
                      title={values.hasAcceptedSettlementDate ? 'Click to reject proposed date' : 'Click to accept proposed date'}
                      onChange={checked => {
                        setFieldTouched(fieldName('hasAcceptedSettlementDate'), true, false);
                        setFieldValue(fieldName('hasAcceptedSettlementDate'), checked);
                      }}
                    />
                  )}
                </div>
              )}

              <Field //
                component={InputField}
                classes={fieldClasses}
                name={fieldName('reference')}
                label="Workspace Reference"
                fullWidth
                disabled={isSubmitting}
                placeholder="Reference"
              />
              <Field //
                component={SelectField}
                classes={fieldClasses}
                format="string"
                name={fieldName('groupId')}
                label="Assign to Group"
                options={GROUP_OPTIONS}
                fullWidth
                disabled={isSubmitting}
              />
            </div>
            <div className="flex justify-center gap-[8px]">
              <Button
                //
                className="rounded-[34px] border-[2px]  px-[24px] py-[6px] "
                disabled={isSubmitting}
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting && submitType === 'accept-and-view'}
                disabled={isSubmitting || !isValid}
                type="button"
                variant="contained"
                color="primary"
                className="whitespace-nowrap rounded-[34px] px-[24px] py-[6px]"
                onClick={() => {
                  submitType = 'accept-and-view';
                  submitForm();
                }}
              >
                Accept & View
              </Button>

              <Button
                //
                isLoading={isSubmitting && submitType === 'accept'}
                disabled={isSubmitting || !isValid}
                type="button"
                variant="contained"
                color="primary"
                className="rounded-[34px] px-[24px] py-[6px]"
                onClick={() => {
                  submitType = 'accept';
                  submitForm();
                }}
              >
                Accept
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(AcceptInviteForm);

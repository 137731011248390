import * as React from 'react';

import pluralize from 'pluralize';
import { conformToMask } from 'react-text-mask';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { ResupplyLandRegistryInformationApiResponse } from '@sympli/api-gateway/models';
import { dateTimeLine, getAddressLine } from '@sympli/ui-framework/utils/formatters';

import { PersonModel, SettlementDateTimeModel } from 'src/models';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';

export const DATE_DISPLAY_FORMAT = 'ddd d mmm yyyy, "at" h:MM TT';

export const DATE_TIME_FORMAT = 'ddd d mmm yyyy, h:MM TT';

export const DATE_ONLY_FORMAT = 'ddd d mmm yyyy';

export const TIME_ONLY_DISPLAY_FORMAT = 'h:MM TT';

export const LONG_MATTER_DISPLAY_TRUNCATE_AT = 19; // WEB-8637
export const NEW_LONG_MATTER_DISPLAY_TRUNCATE_AT = 21;

export { dateTimeLine, getAddressLine };

export function nameLineWithJobTitle(data: PersonModel) {
  const text = `${data.firstName} ${data.lastName}, ${data.jobTitle}`;
  return text;
}

export function nameConcat<T extends { firstName: string; middleName?: string; lastName: string }>(data?: T) {
  if (data == null) {
    return '';
  }
  if (data.middleName) {
    return `${data.firstName} ${data.middleName} ${data.lastName}`;
  }
  return `${data.firstName} ${data.lastName}`;
}

export function titleAddressLine<T extends { addressLine1?: string; addressLine2?: string }>(address?: T | string) {
  if (address == null) {
    return '';
  }
  if (typeof address === 'string') {
    return address;
  }
  if (!address.addressLine2) {
    return address.addressLine1;
  }
  if (!address.addressLine1) {
    return address.addressLine2;
  }
  return `${address.addressLine1}, ${address.addressLine2}`;
}

const STATES: Set<string> = new Set(Object.keys(JurisdictionsEnum));

function addressEndsWithState(address?: string) {
  return address ? STATES.has(address.split(' ').at(-1)?.toUpperCase() ?? '') : false;
}

// LRS.NSW returns 'NO ADDRESS HELD',
// LRS.WA returns 'No Street Address Information Available',
// other jurisdictions return empty string when they cannot find address
export const NO_ADDRESS_SYNONYMS: Set<string> = new Set([
  'NO ADDRESS HELD', //
  'NO STREET ADDRESS INFORMATION AVAILABLE'
]);

//ToDo: find all places of titleAddressLine and replace with this
export function titleAddress(address?: ResupplyLandRegistryInformationApiResponse['propertyAddress'] | string, jurisdiction?: JurisdictionsEnum): string | undefined {
  const result = titleAddressLine(address);
  const jurisdictionName = Number.isInteger(jurisdiction) ? jurisdictionNameMapping[jurisdiction!] : undefined;
  const addressNotHeldText = jurisdictionName ? `${jurisdictionName} (Address not held)` : 'Address not held';
  if (!result || NO_ADDRESS_SYNONYMS.has(result.toUpperCase())) {
    return addressNotHeldText;
  }
  return !jurisdictionName || addressEndsWithState(result) ? result : `${result}, ${jurisdictionName}`;
}

// Due date settlement is based on calendar days
// WEB-4265
// 1. The weeks remaining until settlement - if settlement time is in 14 calendar days and over
// 2. The days remaining until settlement - if settlement time is in 13 days and under
// 3. "Settlement Today" - if the settlement is on the same day (after 12 am on the same day)
// 4. "Settlement Overdue" - if the settlement date and time has passed
export function tillSettlement(data: SettlementDateTimeModel, config: { suffix?: boolean; rebookOverdue?: boolean } = { suffix: true, rebookOverdue: false }) {
  const { daysTill, hoursTill, weeksTill } = data;

  if (hoursTill < 0) {
    return `${config.rebookOverdue ? 'Rebook settlement' : 'Settlement overdue'}`;
  } else if (hoursTill > 0 && daysTill === 0) {
    return 'Settling today';
  } else if (daysTill > 0 && daysTill < 14) {
    return `${daysTill} ${pluralize('day', daysTill)}${config.suffix ? ' until settlement' : ''}`;
  } else if (daysTill >= 14 && weeksTill > 0) {
    return `${pluralize('week', weeksTill, true)}${config.suffix ? ' until settlement' : ''}`;
  } else {
    return 'Happening soon';
  }
}

export function getSettlementDateTime(data: SettlementDateTimeModel) {
  const { userLocalTime, userTimezone, workspaceLocalTime, workspaceTimezone } = data;
  const showUserLocalTime = userTimezone !== workspaceTimezone;
  const workspaceDateTimeLine = <span className="workspace-datetime">{dateTimeLine(workspaceLocalTime, DATE_DISPLAY_FORMAT)}</span>;
  const userDateTime = <span className="user-localtime">{`(${userTimezone.substring(0, 3)} time ${dateTimeLine(userLocalTime, TIME_ONLY_DISPLAY_FORMAT)})`}</span>;
  const lineBreak = <br />;

  return (
    <React.Fragment>
      {workspaceDateTimeLine}
      {showUserLocalTime && lineBreak}
      {showUserLocalTime && userDateTime}
    </React.Fragment>
  );
}

/** Display input as per mask */
export function maskDisplay(input: string, mask: Array<string | RegExp>, guide: boolean = true) {
  const conformedMask = conformToMask(input, mask, { guide });
  return conformedMask.conformedValue;
}

export function abnDisplay(abn: string) {
  const abnMask: (string | RegExp)[] = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
  return maskDisplay(abn, abnMask);
}

export function acnDisplay(acn: string) {
  const acnMask: (string | RegExp)[] = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
  return maskDisplay(acn, acnMask);
}

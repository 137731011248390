import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    // workspace type
    header: {
      background: theme.palette.grey[50],
      margin: '-40px -40px 0px',
      padding: '40px 40px 6px',
      borderBottom: 0
    },
    workspaceType: {
      marginBottom: 0
    },

    // detail form
    form: {
      paddingTop: 26
    },
    formContent: {
      paddingRight: 2,
      paddingLeft: 2
    },
    borderTop: {
      borderTop: `1px solid ${theme.palette.divider}`
    },

    // custom field style
    jurisdictionField: {
      width: 120,
      marginRight: 10
    },
    settleDate: {
      width: 120
    },
    settleTime: {
      width: 120,
      marginLeft: 10,
      '& .Mui-error': {
        width: 'max-content'
      }
    },
    workspaceRoleType: {
      flexGrow: 1
    },
    controllingParty: {
      paddingBottom: 0
    },
    controllingPartyCheckbox: {
      marginTop: -6
    },
    helperTextError: {
      width: 150
    },
    checkbox: {
      '& input:checked + svg rect': {
        fill: theme.palette.primary.main
      },
      '& input:checked + svg path': {
        fill: theme.palette.primary.contrastText
      }
    }
  });

export type ClassKeys = typeof styles;

export default styles;

import { ClientWorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { SubscriberWorkspaceRoleModel } from '@sympli/api-gateway/shared';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import useFeatureFlag from 'src/hooks/useFeatureFlag';
import { financialWorkspaceRoles } from 'src/models/roles';
import { resolveWorkspaceTypeOptions } from './helper';

export const useWorkspaceTypeOptions = (): LookupEnumModel<WorkspaceTypeEnum>[] => {
  const roles: SubscriberWorkspaceRoleModel[] | undefined = useProfile().data?.subscriberWorkspaceRoles;
  const isFinanceEnabled = useFeatureFlag(FeatureToggleEnum.financialSettlementsEnabled);
  let WORKSPACE_TYPE_OPTIONS: LookupEnumModel<WorkspaceTypeEnum>[] = [];

  if (!roles) return WORKSPACE_TYPE_OPTIONS;

  const enabledRoles: ClientWorkspaceRoleEnum[] = roles.filter(role => role.isEnabled).map(role => role.workspaceRole);
  const enabledRolesSet = new Set<ClientWorkspaceRoleEnum>(enabledRoles);

  const financial = isFinanceEnabled && financialWorkspaceRoles.some(role => enabledRolesSet.has(role));

  WORKSPACE_TYPE_OPTIONS = resolveWorkspaceTypeOptions({
    financial
  });

  return WORKSPACE_TYPE_OPTIONS;
};

import React from 'react';

import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';

import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import TextTruncator from '@sympli/ui-framework/components/layout/text-truncator';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconAttachFile, IconSortDesc } from '@sympli/ui-framework/icons';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import * as documentApi from 'src/containers/documents/views/edit-document/api';
import useSafeDispatch from 'src/hooks/useSafeDispatch';
import { DocumentAttachment } from '../../models';
import { useStyles } from './styles';

export interface Props {
  participantId: string;
  workspaceId: string;
  documentId: string;
  attachments?: DocumentAttachment[];
}

function DocumentAttachments({ attachments, workspaceId, participantId, documentId }: Props) {
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [loadingDocumentIds, setLoadingDocumentIds] = React.useState<string[]>([]);

  if (!attachments?.length) return null;

  const toggleTooltip = () => setShowTooltip(!showTooltip);
  const hideTooltip = () => setShowTooltip(false);

  const handleOnDownload = async (attachmentId: string) => {
    try {
      setLoadingDocumentIds([...loadingDocumentIds, attachmentId]);
      const response = await documentApi.downloadDocumentAttachment(attachmentId, { workspaceId, documentId, participantId });
      window.open(response.pdfUrl);
    } catch (error) {
      dispatch(actionCreateGlobalErrorMessage({ title: 'Failed to download', ...error }));
    } finally {
      setLoadingDocumentIds(loadingDocumentIds.filter(d => d !== attachmentId));
    }
  };

  const tooltipContent = attachments.map((attachment: DocumentAttachment) => {
    const { attachmentId, fileName } = attachment;
    const fileNameWithoutExtension = fileName.includes('.') ? fileName.split('.').slice(0, -1).join('.') : fileName;
    const isLoading = loadingDocumentIds.includes(attachmentId);

    return (
      <FlexLayout alignItems="center" key={attachmentId}>
        <ButtonLink //
          onClick={e => {
            e.stopPropagation();
            handleOnDownload(attachmentId);
          }}
          disabled={isLoading}
        >
          <FlexLayout alignItems="center">
            <IconAttachFile className={classes.paperclipIcon} />
            <TextTruncator //
              text={`View ${fileNameWithoutExtension}`}
              truncateAt={55}
              showFullTextAsTooltip={false}
              className={classes.fileName}
            />
          </FlexLayout>
        </ButtonLink>
        {isLoading && <CircularProgress size={13} className={classes.spinner} />}
      </FlexLayout>
    );
  });

  const buttonText = `${attachments.length} ${pluralize('attachment', attachments.length)}`;
  return (
    <ClickAwayListener onClickAway={hideTooltip}>
      <div className={classes.button}>
        <SympliButton onClick={toggleTooltip} aria-label={buttonText}>
          <>
            <IconAttachFile className={classes.paperclipIcon} />
            <span className={classes.buttonText}>{buttonText}</span>
            <Tooltip open={showTooltip} placement="bottom-end" title={tooltipContent} classes={{ tooltip: classes.tooltip }}>
              <IconSortDesc className={classes.dropDownIcon} />
            </Tooltip>
          </>
        </SympliButton>
      </div>
    </ClickAwayListener>
  );
}

export default DocumentAttachments;

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// for accessbility we use opacity instead of display to make the button focusable even we cant see it
export const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      '&:hover': {
        '& button': {
          opacity: 1
        }
      }
    },
    button: {
      opacity: 0,
      '&:focus,&:active': {
        opacity: '1 !important'
      }
    },
    buttonRegular: {
      width: 20,
      height: 20
    },
    buttonLarge: {
      width: 32,
      height: 32
    },
    darkTooltip: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
      fontWeight: 'bold'
    },
    tooltip: {
      fontWeight: 'bold'
    },
    darkArrow: {
      color: theme.palette.info.main,
      '&::before': {
        backgroundColor: theme.palette.info.main
      }
    },
    icon: {
      fill: theme.palette.primary.main,
      height: '100%',
      width: '100%'
    },
    textPrimary: {
      color: theme.palette.common.white,
      fontWeight: 'bold'
    }
  }),
  {
    name: 'CopyToClipboardContainer'
  }
);

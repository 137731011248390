import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel } from '@sympli/ui-framework/models';

// * timezone option string for 'date-fns-timezone'
export const JurisdictionTimezoneMapping: Partial<Record<JurisdictionsEnum, string>> = {
  [JurisdictionsEnum.WA]: 'Australia/Perth', // UTC +8 AWST
  [JurisdictionsEnum.SA]: 'Australia/Adelaide', // UTC +9:30 ACST
  [JurisdictionsEnum.NSW]: 'Australia/Sydney', // UTC +10 AEST
  [JurisdictionsEnum.QLD]: 'Australia/Brisbane', // UTC +10 AEST
  [JurisdictionsEnum.VIC]: 'Australia/Melbourne' // UTC +10 AEST
};

export const TIMEZONE_OPTIONS: LookupEnumModel<keyof typeof JurisdictionsEnum>[] = [
  { name: 'NSW - Australian Eastern Standard Time', id: 'NSW' },
  { name: 'VIC - Australian Eastern Standard Time', id: 'VIC' },
  { name: 'TAS - Australian Eastern Standard Time', id: 'TAS' },
  { name: 'QLD - Australian Eastern Standard Time', id: 'QLD' },
  { name: 'ACT - Australian Eastern Standard Time', id: 'ACT' },
  { name: 'NT - Australian Central Standard Time', id: 'NT' },
  { name: 'SA - Australian Central Standard Time', id: 'SA' },
  { name: 'WA - Australian Western Standard Time', id: 'WA' }
];

export function getTimeInJurisdiction(date: Date, jurisdictionId: JurisdictionsEnum | null) {
  if (!Number.isInteger(jurisdictionId)) {
    return date;
  }
  const timeZone = JurisdictionTimezoneMapping[jurisdictionId as JurisdictionsEnum];
  if (timeZone == null) {
    return date;
  }
  return new Date(date.toLocaleString('en-US', { timeZone }));
}

const stdTimezoneOffset = (date: Date): number => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date: Date): boolean => {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
};

// * this is to find if Daylight Saving Time (DST) is observed in AU eastern states on selected date
export const isDstObservedInNSW = (date: Date): boolean => {
  const dateInNSW = getTimeInJurisdiction(date, JurisdictionsEnum.NSW);
  return isDstObserved(dateInNSW);
};

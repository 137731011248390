import * as React from 'react';

import _uniqueId from 'lodash-es/uniqueId';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import DocumentWorkflowPanel from 'src/containers/documents/components/document-workflow-panel';
import { DocumentWorkflowStepsEnum } from 'src/containers/documents/models';
import ChangeBillingMethod from 'src/containers/workspace/shared/components/menu-items/change-billing-method';
import UnsignPayments from 'src/containers/workspace/shared/components/menu-items/unsign-payments';
import UnsignSourceFunds from 'src/containers/workspace/shared/components/menu-items/unsign-source-funds';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { WorkspaceParticipantSettingState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipantSetting';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { havePurchaserParticipantInWorkspace } from '../../helpers';
import { DirectionCommonProps, DirectionDocumentWorkflowMapping } from '../../models';
import BillingMethodDialog from './components/billing-method-dialog';
import DischargeMortgageDirections from './forms/discharge-mortgage-directions';
import IncomingMortgageDirections from './forms/income-mortgage-directions';
import PurchaserDirections from './forms/purchaser-directions';
import StandardDirections from './forms/standard-directions';
import VendorDirections from './forms/vendor-directions';
import { isToggleBillingMethodEnabled } from './helper';
import styles, { ClassKeys } from './styles';

interface OwnProps extends DirectionCommonProps {
  isLocked: boolean;
  dialogOpen: boolean;
  isFormLoading: any;
  workspaceParticipantSetting: WorkspaceParticipantSettingState;
  onDialogToggle: (dialogOpen: boolean) => void;
  dispatch: SafeDispatch<Action>;
  navigate: NavigateFunction;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isWorkflowLoading: boolean; // for unsign
  isDialogOpen: boolean;
}

class EditDirections extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isWorkflowLoading: false,
    isDialogOpen: false
  };

  private currentStep = DocumentWorkflowStepsEnum.Write;
  private portalIdForFormSubmit: string = _uniqueId('portal-form-submit');

  render() {
    const { queryParams, classes } = this.props;
    const { isDialogOpen } = this.state;
    return (
      <>
        {this.renderDocumentStepper()}
        <BillingMethodDialog queryParams={queryParams} open={isDialogOpen} onClose={this.handleOnBillingDialogClose} />
        <WorkspacePageContentWrapper className={undefined}>
          <Typography variant="h1" className={classes.title}>
            Financial Settlement Schedule
          </Typography>
          {this.renderDocumentForm()}
        </WorkspacePageContentWrapper>
      </>
    );
  }

  private renderDocumentStepper() {
    const { isWorkflowLoading } = this.state;
    const { detail, steps, isFormLoading, workspaceParticipantSetting, isLocked: workspaceIsLocked } = this.props;
    const { status } = detail.documentSummary;
    const enabledStep = DirectionDocumentWorkflowMapping[status];
    const isLoading = isWorkflowLoading || isFormLoading || workspaceParticipantSetting.isLoading;

    return (
      <DocumentWorkflowPanel
        mode="light"
        steps={steps}
        currentStep={this.currentStep}
        lastEnabledStep={enabledStep}
        onStepClick={this.handleOnStepClick}
        disableStepper={isLoading}
        disableMenu={isLoading || workspaceIsLocked}
        menuItems={this.renderMenuList()}
      >
        <PortalTarget id={this.portalIdForFormSubmit} />
      </DocumentWorkflowPanel>
    );
  }

  private renderMenuList(): React.ReactElement<MenuItemProps>[] {
    const { queryParams, paymentsPermissions, sourceFundPermissions, dispatch, workspaceParticipantSetting } = this.props;
    const menuItems: React.ReactElement<MenuItemProps>[] = [];
    if (paymentsPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignPayments queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    if (sourceFundPermissions[DocumentWorkflowStepsEnum.Unsign]) {
      menuItems.push(<UnsignSourceFunds queryParams={queryParams} setIsWorkflowLoading={this.setIsWorkflowLoading} dispatch={dispatch} />);
    }
    if (isToggleBillingMethodEnabled(workspaceParticipantSetting.detail)) {
      menuItems.push(<ChangeBillingMethod onClick={this.handleOnBillingDialogOpen} />);
    }
    return menuItems;
  }

  private renderDocumentForm() {
    const {
      detail,
      currentParticipant,
      workspaceType,
      queryParams,
      portalIdForDirectionSummary,
      directionsCategoriesDetail,
      isFormLoading,
      requiresStampDuty,
      dialogOpen,
      onDialogToggle,
      paymentsPermissions,
      sourceFundPermissions,
      workspaceParticipants
    } = this.props;
    const { isWorkflowLoading } = this.state;
    const havePurchaserParticipant = havePurchaserParticipantInWorkspace(workspaceParticipants);
    const canEdit = paymentsPermissions[this.currentStep] || sourceFundPermissions[this.currentStep];

    // TODO fix typings
    const commonProps: any = {
      canEdit,
      queryParams,
      detail,
      currentParticipant,
      workspaceType,
      portalIdForDirectionSummary,
      portalIdForFormSubmit: this.portalIdForFormSubmit,
      directionsCategoriesDetail,
      requiresStampDuty,
      onFormCancel: this.handleOnFormCancel,
      havePurchaserParticipant
    };

    if (isWorkflowLoading && !isFormLoading) {
      return <BlockLoader />;
    }

    const workspaceRole = currentParticipant.workspaceRole.id;
    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser:
        return <PurchaserDirections {...commonProps} onDialogToggle={onDialogToggle} dialogOpen={dialogOpen} />;
      case WorkspaceRoleEnum.Vendor:
        return <VendorDirections {...commonProps} onDialogToggle={onDialogToggle} dialogOpen={dialogOpen} />;
      case WorkspaceRoleEnum.IncomingMortgagee:
        return <IncomingMortgageDirections {...commonProps} />;
      case WorkspaceRoleEnum.DischargingMortgagee:
        return <DischargeMortgageDirections {...commonProps} />;
      default:
        return <StandardDirections {...commonProps} onDialogToggle={onDialogToggle} dialogOpen={dialogOpen} />;
    }
  }

  private handleOnStepClick = (e: React.MouseEvent<HTMLButtonElement>, stepValue: DocumentWorkflowStepsEnum) => {
    this.props.onStepChange && this.props.onStepChange(stepValue);
  };

  private handleOnFormCancel = () => {
    const { queryParams } = this.props;
    const link = resolveWorkspaceDetailLink(queryParams);
    this.props.navigate(link);
  };

  private setIsWorkflowLoading = (isWorkflowLoading: boolean) => {
    this.setState({ isWorkflowLoading });
  };

  private handleOnBillingDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  private handleOnBillingDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };
}

const styledComponent = withStyles(styles)(EditDirections);

export default styledComponent;

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReportTypeEnum } from '@sympli/api-gateway/enums';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import { TABLEAU_ACTION_FILTER_PREFIX } from '../../constant';
import { resolveTableauParameterValue } from '../../helper';
import { useFavouriteDialog } from '../../hooks/useFavouriteDialog';
import { useSchedulerDialog } from '../../hooks/useSchedulerDialog';
import { useTableau } from '../../hooks/useTableau';
import { FavouriteFormikModel, ReportDetailEnumRoutingMap, ReportSubTypesMap, ReportTypeEnumDisplayMap, SchedulerFormikModel } from '../../models';
import { defaultValues } from '../report-scheduler-dialog/ReportSchedulerDialogContainer';
import { defaultValues as defaultValuesFavourite } from './components/favourite-report-dialog';
import useReportDetails from './hooks/useReportDetails';
import ReportDetails from './ReportDetails';

function ReportDetailsContainer() {
  const isReportSubTypesUIEnabled = useFeatureFlag(FeatureToggleEnum.reportSubTypesUIEnabled);
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Array<tableau.Filter>>([]);
  const [parameters, setParameters] = useState<Array<tableau.Parameter> | undefined>(undefined);
  const dispatch = useDispatch();

  const { viz, tableauRef, setInitialiseData } = useTableau();

  const {
    //
    reportDetails: {
      //
      isLoading: isReportLoading,
      isNewGeneratedReport,
      result: reportDetails
    },
    reportType,
    subType
  } = useReportDetails();

  const { isSchedulerDialogOpen, schedulerDialogCloseHandler, schedulerDialogClickHandler, asyncDialogSchedulerDispatch } = useSchedulerDialog();
  const { isFavouriteDialogOpen, favouriteDialogCloseHandler, favouriteDialogClickHandler, asyncDialogFavouriteDispatch } = useFavouriteDialog();

  // this should be first effect
  useEffect(() => {
    if (reportDetails?.endpoint) {
      setInitialiseData({
        //
        reportLink: reportDetails.endpoint
      });
    }
  }, [reportDetails, setInitialiseData]);

  // we need to always get the first sheet as it contains the table format and filters
  const firstWorksheet: Promise<tableau.Worksheet | undefined> = useMemo(async () => {
    if (viz) {
      const sheet = await viz.getWorkbook().activateSheetAsync(viz.getWorkbook().getActiveSheet().getName());

      // this sheet is a dashboard
      if (sheet.getSheetType() === tableau.SheetType.DASHBOARD) {
        // convert dashboard to its worksheets
        const workSheets: tableau.Worksheet[] = (sheet as tableau.Dashboard).getWorksheets();

        // explicitly return the first item as the items in the collection can be out of order
        return workSheets.find(sheet => sheet.getIndex() === 0);
      }

      return undefined;
    }
    return undefined;
  }, [viz]);

  // get the Tableau filters
  useEffect(() => {
    async function getFilters() {
      const rawDataSheet = await firstWorksheet;
      if (rawDataSheet) {
        setFilters(await rawDataSheet.getFiltersAsync());
      }
    }

    getFilters();
  }, [firstWorksheet, isSchedulerDialogOpen, isFavouriteDialogOpen]);

  //get the Tableau parameters
  useEffect(() => {
    async function getParameters() {
      if (viz) {
        const parameters = await viz.getWorkbook().getParametersAsync();
        if (!parameters?.length) {
          //We want to set as undefined since some workbooks will not have parameters ie UserPermission
          //and we don't want to be sending this information to the backend
          setParameters(undefined);
          return;
        }
        setParameters(parameters);
      }
    }

    getParameters();
  }, [viz, isFavouriteDialogOpen]);

  useEffect(() => {
    async function applyFilters() {
      const filters = reportDetails?.filterData;
      const rawDataSheet = await firstWorksheet;

      //apply filters when report is a favourite report
      if (rawDataSheet && !isNewGeneratedReport && filters) {
        const filterKeys = Object.keys(filters);

        if (filterKeys.length <= 0) {
          return;
        }

        // the filter only works for the first sheet which contains the raw data
        filterKeys.forEach(k => rawDataSheet.applyFilterAsync(k, filters[k], tableau.FilterUpdateType.REPLACE));
      }
    }

    async function applyParameters() {
      const parameters = reportDetails?.parameterData;

      //apply parameters when report is a favourite report
      if (viz && parameters && !isNewGeneratedReport) {
        if (!parameters.length) {
          return;
        }
        const workbook = viz.getWorkbook();

        parameters.forEach(param =>
          workbook.changeParameterValueAsync(
            //
            param.name,
            resolveTableauParameterValue(param)
          )
        );
      }
    }

    applyParameters();
    applyFilters();
  }, [viz, firstWorksheet, reportDetails, isNewGeneratedReport]);

  // when filters are set, update the filterData with Tableau's API
  const filterData = useMemo(() => {
    const categoricalFilters = filters.filter(filter => filter.getFilterType() === tableau.FilterType.CATEGORICAL) as Array<tableau.CategoricalFilter>;
    const result: {
      [key: string]: string[];
    } = {};
    categoricalFilters.forEach(filter => {
      //@ts-ignore this method is the latest, so our ts package for Tableau doesn't have it. documentation is here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm
      if (filter.getIsAllSelected()) {
        return; // if all values are selected, which is default behaviour, no need to send to DB
      }

      /* 
        WEB-22607 UI needs to filter out the Action filters. 
        Unfortunately, Tableau doesn't provide any way to distinguish
        graph action filter and table dropdown action filter from, 
        they both belong to the same filter type: CATEGORICAL
        we have to hardcode this way
      */
      if (filter.getFieldName().startsWith(TABLEAU_ACTION_FILTER_PREFIX)) {
        return;
      }

      // the type in the tableau does not correct, it can return null value.
      const appliedValues = filter.getAppliedValues();

      result[filter.getFieldName()] = appliedValues?.map(value => value.formattedValue);
    });

    return JSON.stringify(result);
  }, [filters]);

  // when parameters are set, update the parameterData with Tableau's API
  const parameterData = useMemo(() => {
    if (parameters) {
      const result = parameters.map(p => ({
        name: p.getName(),
        value: p.getCurrentValue().value,
        type: p.getDataType()
      }));

      if (result?.length) {
        return JSON.stringify(result);
      }
    }
    return undefined;
  }, [parameters]);

  const reportSubTypeOnChangeHandler = useCallback(
    (subType: string) => {
      navigate(`/reports/${ReportDetailEnumRoutingMap[reportType]}/new?subType=${subType}`);
    },
    [navigate, reportType]
  );

  const handleOnDownloadCSVClick = useCallback(
    (_: React.MouseEvent<HTMLButtonElement>) => {
      if (viz) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'download-csv',
          logGroupId: 'reporting'
        });
        // always download the first sheet
        firstWorksheet.then(sheet => {
          if (sheet) {
            return viz.showExportCrossTabDialog(sheet.getName());
          }
        });
      }
    },
    [viz, firstWorksheet]
  );

  const handleOnDownloadPDFClick = useCallback(
    (_: React.MouseEvent<HTMLButtonElement>) => {
      if (viz) {
        Logger.capturePageAction(PageActionEnum.FeatureTracking, {
          feature: 'download-pdf',
          logGroupId: 'reporting'
        });
        return viz.showExportPDFDialog();
      }
    },
    [viz]
  );
  const handleOnSchedulerPostSubmit = (args: FormikPostSubmitArgs<SchedulerFormikModel>) => {
    // We already had globalErrorDialog fired by Formik's action, so we don't need to deal with error here.
    if (args.error) {
      return;
    }
    // close dialog
    asyncDialogSchedulerDispatch({ type: 'close' });
    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: 'Report has been scheduled',
        autoHideDuration: 3000,
        variant: 'new-success'
      })
    );
  };

  const handleOnFavouritePostSubmit = (args: FormikPostSubmitArgs<FavouriteFormikModel>) => {
    // We already had globalErrorDialog fired by Formik's action, so we don't need to deal with error here.
    if (args.error) {
      return;
    }
    // close dialog
    asyncDialogFavouriteDispatch({ type: 'close' });
    dispatch(
      actionOpenGlobalSimpleNotification({
        //
        message: 'Report added to your favourites',
        autoHideDuration: 3000,
        variant: 'new-success'
      })
    );
  };

  const getSchedulerProps = () => {
    return isNewGeneratedReport && reportType !== ReportTypeEnum.TeamWorkloadReport
      ? {
          initialValues: { ...defaultValues, reportType: reportType ?? ReportTypeEnum.UserPermissionReport },
          isOpen: isSchedulerDialogOpen,
          handleOnClick: schedulerDialogClickHandler,
          handleOnClose: schedulerDialogCloseHandler,
          handleOnPostSubmit: handleOnSchedulerPostSubmit
        }
      : undefined;
  };

  const getFavouriteProps = () => {
    return isNewGeneratedReport && reportType !== ReportTypeEnum.TeamWorkloadReport
      ? {
          initialValues: { ...defaultValuesFavourite, reportType: reportType ?? ReportTypeEnum.UserPermissionReport },
          isOpen: isFavouriteDialogOpen,
          handleOnClick: favouriteDialogClickHandler,
          handleOnClose: favouriteDialogCloseHandler,
          handleOnPostSubmit: handleOnFavouritePostSubmit
        }
      : undefined;
  };

  const getReportHeaderProps = () => {
    return {
      title: isNewGeneratedReport ? ReportTypeEnumDisplayMap[reportType] : reportDetails ? reportDetails.reportName : undefined,
      subTypes: isNewGeneratedReport ? ReportSubTypesMap[reportType] : undefined,
      selectedSubType: subType,
      onSubTypeChangedHandler: reportSubTypeOnChangeHandler
    };
  };

  return (
    <ReportDetails //
      schedulerProps={getSchedulerProps()}
      favouriteProps={getFavouriteProps()}
      reportHeaderProps={getReportHeaderProps()}
      onDownloadCSVClick={handleOnDownloadCSVClick}
      onDownloadPDFClick={handleOnDownloadPDFClick}
      viz={viz}
      tableauRef={tableauRef}
      reportDetail={reportDetails}
      isLoading={isReportLoading}
      filterData={filterData}
      parameterData={parameterData}
      isReportSubTypesUIEnabled={isReportSubTypesUIEnabled}
    />
  );
}

export default React.memo(ReportDetailsContainer);

import * as React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentTypeIdentifierEnum, JurisdictionsEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { UserProfileModel } from '@sympli/api-gateway/shared';
import Radio from '@sympli/ui-framework/components/form/base-components/radio';
import FormGroup from '@sympli/ui-framework/components/form/layout/form-group';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { SeverityEnum } from '@sympli/ui-logger';

import Formik from 'src/components/formik';
import WizardErrorMessage from 'src/containers/dashboard/components/create-new-workspace/components/wizard-error-message';
import { SupportedJurisdictionsState } from 'src/containers/dashboard/shared/reducers/supportedJurisdictions';
import { WorkspaceAssignableGroupsState } from 'src/containers/dashboard/shared/reducers/workspaceAssignableGroups';
import VerifyPropertySection from 'src/containers/shared/verify-property-section';
import { DuplicateWorkspaceItem, duplicateWorkspacesSelector } from 'src/containers/shared/verify-property-section/components/duplicate-workspaces';
import { TitleListItem, titleListItemsSelector } from 'src/containers/shared/verify-property-section/components/title-list';
import { resolveTitles, verifyTitleReferences } from 'src/containers/shared/verify-property-section/helpers';
import {
  titleVerificationErrorStatuses,
  TitleVerificationRequest,
  TitleVerificationResponse,
  TitleVerificationStatusEnum
} from 'src/containers/shared/verify-property-section/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createArrayItemModelKeyAppender, modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { DocumentRoleMappingLookupModel, SharedInitialValues, WorkspaceCreationErrorsMap } from '../../../../models';
import AboutYourMatterSectionContainer from '../../../financial-form/components/about-your-matter-section';
import { jurisdictionSelector } from '../../../financial-form/steps/matter-detail/helpers';
import { getCachedOrCreateDocumentRoleMappingOptionsStores, getRoleOption, unifyTitleFormat } from '../../../reg-only-form/helpers';
import {
  CreateLodgementOnlyWorkspaceInvitationsStepRequest,
  CreateLodgementOnlyWorkspaceInvitationsStepResponse,
  CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel,
  CreateLodgementOnlyWorkspaceMatterDetailsStepRequest,
  CreateLodgementOnlyWorkspaceMatterDetailsStepResponse,
  CreateLodgementOnlyWorkspaceSharedModel,
  CreateLodgementOnlyWorkspaceTitleDealingsStepResponse,
  CreateLodgeOnlyWorkspaceStepEnum,
  MatterDetailDocumentItemModel
} from '../../models';
import { getInitialValues, prepareRegSubmitData } from '../invite-participants/helpers';
import {
  createDocumentItem,
  prepareRegCreationDetailRequestData,
  prepareSingleStepRegSubmitData,
  requiresInvitationStep,
  requiresLinkedWorkspace,
  resolveDocumentsForLinkedWorkspace
} from './helpers';
import styles, { ClassKeys } from './styles';
import validationSchema from './validationSchema';

type FormModel = CreateLodgementOnlyWorkspaceMatterDetailsStepFormModel;

interface OwnProps {
  // redux
  assignGroupsState: WorkspaceAssignableGroupsState;
  supportedJurisdictionsState: SupportedJurisdictionsState;
  subscriberProfile: UserProfileModel;
  // other
  workspaceTypeOptions: LookupEnumModel<WorkspaceTypeEnum>[];
  workspaceTypeId?: WorkspaceTypeEnum;
  onVerifyTitleClick(payload: TitleVerificationRequest): Promise<TitleVerificationResponse>;
  onGlobalValuesUpdate: (value?: Partial<CreateLodgementOnlyWorkspaceSharedModel>) => CreateLodgementOnlyWorkspaceSharedModel;
  onStepChange: (step: CreateLodgeOnlyWorkspaceStepEnum) => void;
  onClose: (link?: string) => void;
  onWorkspaceTypeChange: (workspaceTypeId: WorkspaceTypeEnum, sharedInitialValues: Partial<SharedInitialValues>) => void;

  onWorkspaceCreated(args: { workspaceId: string; participantId: string; documentId: string });
  dispatch: SafeDispatch<any>;
  isSearchAndJoinWorkspaceEnabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isVerifyLoading: boolean;
}

const fieldName = modelKey<FormModel>();
const documentsFieldNameAppender = createArrayItemModelKeyAppender<MatterDetailDocumentItemModel>(fieldName('documents'));

class MatterDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    isVerifyLoading: false
  };

  private formikProps: FormikProps<FormModel>;
  private globalValues: CreateLodgementOnlyWorkspaceSharedModel = this.props.onGlobalValuesUpdate();
  private inputRef = React.createRef<HTMLInputElement>();

  private getInitialValues = (): FormModel => {
    let { matterDetailsStepData } = this.globalValues;

    // in case when user is returning back from invitation step we want to  make sure that
    // we remove the extra document that we have injected during onPreSubmit step related to linked workspaces
    if (requiresLinkedWorkspace(matterDetailsStepData)) {
      const explicitLinkedWorkspaceDocuments: DocumentTypeIdentifierEnum[] = [
        DocumentTypeIdentifierEnum.ConvertPctAndNominateToTheLodgementCase,
        DocumentTypeIdentifierEnum.NominateEctToTheLodgementCase
      ];

      const documents = matterDetailsStepData.documents.filter(({ documentIdentifier }) => {
        return !explicitLinkedWorkspaceDocuments.includes(documentIdentifier as DocumentTypeIdentifierEnum);
      });

      // update global values
      if (documents.length !== matterDetailsStepData.documents.length) {
        // update global values
        this.globalValues = this.props.onGlobalValuesUpdate({
          matterDetailsStepData: {
            ...matterDetailsStepData,
            documents
          }
        });
        matterDetailsStepData = this.globalValues.matterDetailsStepData;
      }
    }

    const groupOptions = this.props.assignGroupsState.items;
    // initialise the group id if only one group
    if (groupOptions.length === 1) {
      return {
        ...matterDetailsStepData,
        groupId: groupOptions[0].id
      };
    }
    return matterDetailsStepData;
  };

  public componentDidUpdate(preProps: Props) {
    const { assignGroupsState } = this.props;
    if (preProps.assignGroupsState !== assignGroupsState) {
      const { items: groupOptions = [] } = assignGroupsState;
      if (groupOptions.length === 1) {
        this.formikProps.setFieldValue(fieldName('groupId'), groupOptions[0].id);
      }
    }
  }

  private resolveActionPath = (
    values: FormModel,
    formikProps: FormikProps<FormModel>,
    submitData: CreateLodgementOnlyWorkspaceMatterDetailsStepRequest | CreateLodgementOnlyWorkspaceInvitationsStepRequest
  ): string => {
    // because payloads are different
    // based on the calculated submit data we can figure out the next step
    if (Number.isInteger(submitData.documents[0])) {
      // we will need to move to next step
      return '/workspaces/registration-only/creation-details';
    }

    return '/workspaces/registration-only';
  };

  render() {
    const { classes, workspaceTypeId, workspaceTypeOptions } = this.props;
    return (
      <>
        <FormGroup //
          title={<Typography variant="subtitle1">Create new workspace</Typography>}
          className={classes.header}
        >
          {this.showWorkspaceTypeSelection ? (
            <Radio //
              className={classes.workspaceType}
              name="workspaceType" /* danger:disable */
              options={workspaceTypeOptions}
              format="number"
              onChange={this.handleOnWorkspaceTypeChange}
              value={workspaceTypeId}
              aria-label="Workspace type"
            />
          ) : null}
        </FormGroup>
        <Formik //
          method="post"
          action={this.resolveActionPath}
          getInitialValues={this.getInitialValues}
          onPreSubmit={this.handleOnPreSubmit}
          onPostSubmit={this.handleOnPostSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {(formikProps: FormikProps<FormModel>) => this.renderForm(formikProps)}
        </Formik>
      </>
    );
  }

  private get showWorkspaceTypeSelection(): boolean {
    return this.props.workspaceTypeOptions.length > 1;
  }

  private get showDocumentDetails(): boolean {
    const { values } = this.formikProps;
    // group must be selected
    if (values.groupId) {
      // if workspace selection is allowed
      if (this.showWorkspaceTypeSelection) {
        // user must select workspace type
        return Number.isInteger(this.props.workspaceTypeId);
      }
      return true;
    }
    return false;
  }

  private allDocumentsAreSpecified(documents: MatterDetailDocumentItemModel[]): boolean {
    return !!documents.length && documents.every(d => d.documentIdentifier);
  }

  private get showTitles(): boolean {
    const { values } = this.formikProps;
    return Number.isInteger(values.jurisdictionId) && this.allDocumentsAreSpecified(values.documents);
  }

  private canShowVicControllingParty(titleVerificationResponse?: TitleVerificationResponse): boolean {
    // In registration only, we do not support multiple nomination types or controlling parties in one lodgement case
    if (titleVerificationResponse) {
      const { titles, hasPaperTitle } = titleVerificationResponse;
      if (hasPaperTitle) {
        if (titles.length === 1) return true;
        const nonPaperTitles = titles.filter(t => t.status !== TitleVerificationStatusEnum.WarningPaperTitle);
        if (nonPaperTitles.every(t => t.status === TitleVerificationStatusEnum.Success)) return true;
      }
    }
    return false;
  }

  private get showControllingParty(): boolean {
    const {
      titleInformation: { titleVerificationResult }
    } = this.formikProps.values;
    if (
      !(
        this.canShowVicControllingParty(titleVerificationResult) //
      )
    ) {
      return false;
    }
    return true;
  }

  private get disableVerifyButton(): boolean {
    const {
      values: { titleInformation },
      errors
    } = this.formikProps;
    return Boolean((!titleInformation.titleInputValue && !titleInformation.titleChips.length) || errors.titleInformation?.titleChips);
  }

  private get disableContinue(): boolean {
    const {
      isValid,
      isSubmitting,
      values: { titleInformation }
    } = this.formikProps;
    if (!isValid || isSubmitting || titleInformation.titleVerificationResult?.titles.some(t => titleVerificationErrorStatuses.has(t.status))) {
      return true;
    }

    return false;
  }

  private renderForm(formikProps: FormikProps<FormModel>) {
    const { classes, workspaceTypeId, isSearchAndJoinWorkspaceEnabled, onClose } = this.props;
    const { values, errors, isSubmitting, isValid } = formikProps;
    this.formikProps = formikProps;

    const titles: TitleListItem[] = titleListItemsSelector({
      titleVerificationResult: values.titleInformation.titleVerificationResult,
      workspaceCreationErrorsMap: values.workspaceCreationErrorsMap
    });
    const duplicateWorkspaces: DuplicateWorkspaceItem[] = duplicateWorkspacesSelector(
      //
      values.titleInformation.titleVerificationResult
    );

    Logger.console(SeverityEnum.Debug, 'errors', errors);

    return (
      <Form>
        <fieldset disabled={isSubmitting} className={classes.fieldset}>
          <WizardErrorMessage //
            workspaceCreationErrorsMap={values.workspaceCreationErrorsMap}
            titleVerificationResult={values.titleInformation.titleVerificationResult}
          />
          <AboutYourMatterSectionContainer //
            onGroupFieldChange={this.handleOnGroupFieldChange}
          />
          {this.showDocumentDetails && this.renderDocumentsList(formikProps)}
          {
            // we can show title section only if jurisdiction and documents has been provided
            this.showTitles && (
              <VerifyPropertySection //
                name={fieldName('titleInformation')}
                jurisdictionId={values.jurisdictionId as JurisdictionsEnum}
                disableVerifyButton={this.disableVerifyButton}
                showLoader={this.state.isVerifyLoading}
                titles={titles}
                duplicateWorkspaces={duplicateWorkspaces}
                onVerifyClick={this.handleOnVerifyRequest}
                onDeleteClick={this.handleOnDeleteTitleClick}
                inputRef={this.inputRef}
                isFormValid={isValid}
                workspaceTypeId={workspaceTypeId}
                isSearchAndJoinWorkspaceEnabled={isSearchAndJoinWorkspaceEnabled}
                groupId={values.groupId}
                onClose={onClose}
              />
            )
          }
          {this.showControllingParty && this.renderControllingParty()}
        </fieldset>
        <WizardStepper //
          isLoading={isSubmitting}
          showNext={true}
          disabled={this.disableContinue}
          onBack={this.handleOnClose}
          backLabel="Cancel"
          nextLabel="Continue"
          onNext={this.handleOnNext}
        />
      </Form>
    );
  }

  private renderDocumentsList(formikProps: FormikProps<FormModel>) {
    const { classes, assignGroupsState, supportedJurisdictionsState } = this.props;
    const { values } = formikProps;

    const { groupId, documents } = values;
    const { items: assignableGroupOptions } = assignGroupsState;
    const { items: supportedJurisdictions } = supportedJurisdictionsState;

    const jurisdictionOptions = jurisdictionSelector({
      //
      groupId,
      assignableGroupOptions,
      workspaceTypeId: WorkspaceTypeEnum.RegistrationOnly,
      supportedJurisdictions
    });
    return (
      <FormGroup //
        title="Document to be lodged"
        className={classNames(classes.borderTop, classes.formGroup)}
      >
        <Field //
          label="Jurisdiction"
          name={fieldName('jurisdictionId')}
          format="number"
          component={SelectField}
          placeholder={resolveSelectPlaceholder(true)}
          options={jurisdictionOptions}
          className={classes.jurisdictionField}
          onChange={this.handleOnJurisdictionFieldChange}
        />
        {Number.isInteger(values.jurisdictionId) && (
          <div className={classes.fullWidth}>
            {documents.map((document, index) => {
              return this.renderMainDocument(formikProps, index);
            })}
          </div>
        )}
      </FormGroup>
    );
  }

  private renderMainDocument(formikProps: FormikProps<FormModel>, index: number) {
    const { jurisdictionId } = formikProps.values;
    const store = getCachedOrCreateDocumentRoleMappingOptionsStores(jurisdictionId as JurisdictionsEnum, this.props.dispatch); // jurisdictionId will be present in this case always

    return (
      <Field //
        key={`document-${index}`}
        label="Select document"
        name={documentsFieldNameAppender(index, 'documentIdentifier')}
        format="number"
        component={SelectField}
        store={store}
        placeholder={resolveSelectPlaceholder(true)}
        onChange={this.handleOnMainDocumentFieldChange}
        fullWidth
      />
    );
  }

  private renderControllingParty() {
    const { classes } = this.props;

    return (
      <FormGroup //
        title="Controlling party"
        className={classNames(classes.controllingParty, classes.formGroup)}
      >
        <Field //
          component={CheckboxField}
          name={fieldName('isControllingParty')}
          label="I have control of this title"
          className={classes.controllingPartyCheckbox}
        />
      </FormGroup>
    );
  }

  private handleOnMainDocumentFieldChange = () => {
    const { setValues } = this.formikProps;

    setValues(values => {
      const {
        documents,
        titleInformation: { titleChips: formTitleChips, titleVerificationResult }
      } = values;

      const newDocuments: MatterDetailDocumentItemModel[] = [documents[0]];

      // preserve list of titles that has been potentially already verified
      const titleChips = resolveTitles(formTitleChips, titleVerificationResult?.titles);
      // if there was any change, we update formik values
      if (documents.length !== newDocuments.length) {
        return {
          ...values,
          documents: newDocuments,
          titleChips, // preserve list of titles that has been potentially already verified
          // and explicitly cleanup previous results, both verification and ws creation as well
          titleInformation: {
            ...values.titleInformation,
            titleVerificationResult: undefined
          },
          workspaceCreationErrorsMap: undefined
        };
      }

      // we don't need to update documents however we still want to reset title verification and potential previous workspace creation attempt result
      return {
        ...values,
        titleChips, // preserve list of titles that has been potentially already verified
        // and explicitly cleanup previous results, both verification and ws creation as well
        titleInformation: {
          ...values.titleInformation,
          titleVerificationResult: undefined
        },
        workspaceCreationErrorsMap: undefined
      };
    });
  };

  private handleOnGroupFieldChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
    const {
      values: { groupId },
      setValues
    } = this.formikProps;

    if (groupId !== resolvedValue) {
      setValues(values => {
        return {
          ...values, //
          // explicitly reset the following data
          jurisdictionId: null,
          documents: [createDocumentItem()],
          titleVerificationResult: undefined
        };
      });
    }
  };

  private handleOnJurisdictionFieldChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: JurisdictionsEnum | null) => {
    const {
      values: { jurisdictionId },
      setValues
    } = this.formikProps;

    if (jurisdictionId !== resolvedValue) {
      // perf optimization here:
      // this will cause automatic pre-fetch data that we will later need during form submission
      if (Number.isInteger(resolvedValue)) {
        getCachedOrCreateDocumentRoleMappingOptionsStores(resolvedValue as JurisdictionsEnum, this.props.dispatch);
      }

      setValues(values => {
        return {
          ...values, //
          // explicitly reset the following data
          documents: [createDocumentItem()],
          titleInformation: {
            ...values.titleInformation,
            jurisdictionId: resolvedValue
          },
          titleVerificationResult: undefined
        };
      });
    }
  };

  private handleOnWorkspaceTypeChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: WorkspaceTypeEnum) => {
    const {
      values: { createdByReference, groupId, instructingOrganisationSettlementAgentId }
    } = this.formikProps;

    this.props.onWorkspaceTypeChange(resolvedValue, {
      //
      createdByReference,
      instructingOrganisationSettlementAgentId,
      groupId
    });
  };

  private resolveTitleVerificationPayload(): TitleVerificationRequest {
    const {
      jurisdictionId,
      documents,
      titleInformation: { titleChips, titleVerificationResult }
    } = this.formikProps.values;
    // merge existing titles with new title chips
    const references = resolveTitles(titleChips, titleVerificationResult?.titles);
    const documentTypeIds: DocumentTypeIdentifierEnum[] = documents.map(d => d.documentIdentifier as DocumentTypeIdentifierEnum);
    const payload: TitleVerificationRequest = {
      checkTitleReferences: true,
      checkDuplicateWorkspaces: true,
      jurisdictionId: jurisdictionId as JurisdictionsEnum,
      workspaceType: WorkspaceTypeEnum.RegistrationOnly,
      references,
      documentTypeIds
    };

    return payload;
  }

  private handleOnVerifyRequest = async () => {
    this.setState({ isVerifyLoading: true });
    const payload: TitleVerificationRequest = this.resolveTitleVerificationPayload();

    try {
      const titleVerificationResult: TitleVerificationResponse = await this.props.onVerifyTitleClick(payload);

      // update ineligibility reason based on the status
      this.formikProps.setValues(values => ({
        ...values,
        titleInformation: {
          ...values.titleInformation,
          // reset the following data
          titleInputValue: '',
          titleChips: [],
          // save verification result
          titleVerificationResult
        }
      }));
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    } catch (error) {
      // this is handled in the parent component
    }

    this.setState({ isVerifyLoading: false });
  };

  private verifyDuplicateWorkspacesOnTitleDelete = async (referenceNumber: string) => {
    const payload: TitleVerificationRequest = this.resolveTitleVerificationPayload();
    payload.checkTitleReferences = false;
    payload.references = payload.references.filter(reference => reference !== referenceNumber);
    if (!payload.references.length) {
      return;
    }

    this.setState({ isVerifyLoading: true });

    try {
      const response: TitleVerificationResponse = await verifyTitleReferences(payload);

      // update ineligibility reason based on the status
      this.formikProps.setValues(values => ({
        ...values,
        titleInformation: {
          ...values.titleInformation,
          // save verification result
          titleVerificationResult: {
            ...(values.titleInformation.titleVerificationResult ?? {
              titles: [],
              hasPaperTitle: false
            }),
            duplicateWorkspaces: response.duplicateWorkspaces
          }
        }
      }));
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    } catch (error) {
      // The search for duplicate workspaces will not block the process if the user still want to create a new workspace
      // hence if there is any error we just log it and ignore it
      const scope = Logger.scopeWithCustomAttributes({ ...payload });
      Logger.captureException(error, scope);
    }

    this.setState({ isVerifyLoading: false });
  };

  private handleOnDeleteTitleClick = (_: React.MouseEvent<HTMLButtonElement>, referenceNumber: string) => {
    this.updateFormikTitleInformation(referenceNumber);
    this.verifyDuplicateWorkspacesOnTitleDelete(referenceNumber);
  };

  private updateFormikTitleInformation(referenceNumber: string) {
    this.formikProps.setValues(values => {
      const {
        titleInformation: { titleChips, titleVerificationResult, jurisdictionId }
      } = values;
      const titles = titleVerificationResult!.titles.filter(title => title.titleReference !== referenceNumber);

      // force user to run new title verification if the previous one was not successful
      if (titles.length && titleVerificationResult!.titles.some(t => t.status !== TitleVerificationStatusEnum.Success)) {
        return {
          ...values,
          titleInformation: {
            jurisdictionId: jurisdictionId,
            titleInputValue: '',
            // preserve list of titles that has been potentially already verified
            titleChips: resolveTitles(titleChips, titles),

            // and explicitly cleanup previous results, both verification and ws creation as well
            titleVerificationResult: undefined
          },
          workspaceCreationErrorsMap: undefined
        };
      }

      const amendedTitleVerificationResult: TitleVerificationResponse | undefined = titles.length
        ? {
            ...titleVerificationResult!,
            titles
          }
        : undefined;

      let workspaceCreationErrorsMap: WorkspaceCreationErrorsMap | undefined = values.workspaceCreationErrorsMap;
      if (referenceNumber in (workspaceCreationErrorsMap || {})) {
        delete workspaceCreationErrorsMap![referenceNumber];
        workspaceCreationErrorsMap = {
          ...workspaceCreationErrorsMap
        };
      }

      return {
        ...values,
        titleInformation: {
          ...values.titleInformation,
          titleVerificationResult: amendedTitleVerificationResult
        },
        workspaceCreationErrorsMap
      };
    });
  }

  private updateWorkspaceCreationErrors(workspaceCreationErrorsMap?: WorkspaceCreationErrorsMap) {
    this.formikProps.setFieldValue(fieldName('workspaceCreationErrorsMap'), workspaceCreationErrorsMap);
    this.globalValues = this.props.onGlobalValuesUpdate({
      matterDetailsStepData: {
        ...this.globalValues.matterDetailsStepData,
        workspaceCreationErrorsMap
      }
    });
  }

  private getDocumentRoleMappingOptions(jurisdictionId: JurisdictionsEnum): Promise<DocumentRoleMappingLookupModel[]> {
    const store = getCachedOrCreateDocumentRoleMappingOptionsStores(jurisdictionId, this.props.dispatch);
    return store.query();
  }

  private handleOnNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    Logger.setInteractionName('Create new - matter detail - submit', 'click');
    this.formikProps.submitForm();
  };

  private handleOnPreSubmit = async (
    values: FormModel
  ): Promise<CreateLodgementOnlyWorkspaceMatterDetailsStepRequest | CreateLodgementOnlyWorkspaceInvitationsStepRequest | undefined> => {
    try {
      // first we want to resolve the list of roles for documents
      const documentRoleMapping: DocumentRoleMappingLookupModel[] = await this.getDocumentRoleMappingOptions(values.jurisdictionId as JurisdictionsEnum);

      // update documents with list of role options
      const documentsWithResolvedRoles: MatterDetailDocumentItemModel[] = values.documents.map(({ documentIdentifier }) => {
        const ROLE_OPTIONS: LookupEnumModel<WorkspaceRoleEnum>[] = (documentRoleMapping.find(r => r.id === documentIdentifier)?.roleIds || []).map(getRoleOption);
        return createDocumentItem(documentIdentifier, ROLE_OPTIONS);
      });

      // store it for later reuse.
      const { matterDetailsStepData } = (this.globalValues = this.props.onGlobalValuesUpdate({
        matterDetailsStepData: {
          ...values,
          documents: documentsWithResolvedRoles
        }
      }));

      // from now, explicitly use data from globalValues since it already contains updated documents

      if (requiresLinkedWorkspace(matterDetailsStepData)) {
        const documentsForLinkedWorkspaces = resolveDocumentsForLinkedWorkspace(matterDetailsStepData.titleInformation.titleVerificationResult!.hasPaperTitle);
        // directly push more documents
        matterDetailsStepData.documents.push(...documentsForLinkedWorkspaces);

        const needsSelfInvite =
          matterDetailsStepData.jurisdictionId === JurisdictionsEnum.VIC && //
          matterDetailsStepData.titleInformation.titleVerificationResult?.hasPaperTitle && //
          matterDetailsStepData.isControllingParty;

        if (needsSelfInvite) {
          const inviteParticipantsStepData = getInitialValues(this.props.subscriberProfile, this.globalValues, needsSelfInvite);
          const regSubmitData = prepareRegSubmitData({ ...this.globalValues, inviteParticipantsStepData }, needsSelfInvite);
          return regSubmitData;
        }
      }

      if (requiresInvitationStep(matterDetailsStepData)) {
        const regSubmitData: CreateLodgementOnlyWorkspaceMatterDetailsStepRequest = prepareRegCreationDetailRequestData(matterDetailsStepData);
        return regSubmitData;
      }

      const regSubmitData: CreateLodgementOnlyWorkspaceInvitationsStepRequest = prepareSingleStepRegSubmitData(matterDetailsStepData, this.props.subscriberProfile.subscriberId);
      return regSubmitData;
    } catch (err) {
      // TODO
    }
  };

  private handleOnPostSubmit = (
    args: FormikPostSubmitArgs<
      FormModel,
      CreateLodgementOnlyWorkspaceMatterDetailsStepResponse | CreateLodgementOnlyWorkspaceInvitationsStepResponse | CreateLodgementOnlyWorkspaceTitleDealingsStepResponse
    >
  ) => {
    if (args.error) {
      // Handle http error using general error pop up
      return;
    }

    const { isSuccess, titleReferenceErrors } = args.response;
    const {
      titleInformation: { titleVerificationResult }
    } = args.formValues;

    if (!isSuccess) {
      // calculate map of titles and their errors for easy use
      const titleReferences: string[] = titleVerificationResult?.titles.map(t => t.titleReference) || [];
      const unifiedTitleReferences: string[] = titleReferences.map(unifyTitleFormat); // we need to unify the format due to potential inconsistency in between user input and returned title reference from API
      const workspaceCreationErrorsMap: WorkspaceCreationErrorsMap = (titleReferenceErrors || [])
        //WEB-15148: titleReferenceErrors can contain document verification errors (not linked to a title), we need to filter them out
        .filter(error => unifiedTitleReferences.includes(unifyTitleFormat(error.titleReference)))
        .reduce((acc, item) => {
          const index: number = unifiedTitleReferences.indexOf(unifyTitleFormat(item.titleReference));
          acc[titleReferences[index]] = item.message;
          return acc;
        }, {});

      // update formik so we can execute validation and block continue button
      this.updateWorkspaceCreationErrors(workspaceCreationErrorsMap);
      // when WizardErrorMessage is shown after submit, it's better we auto scroll to it if it's not visible
      document.querySelector('[data-error-name="workspaceCreationErrorsMap"]')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    // explicitly use data from globalValues since it already contains updated documents
    if (requiresInvitationStep(this.globalValues.matterDetailsStepData)) {
      // update global values
      this.globalValues = this.props.onGlobalValuesUpdate({
        matterDetailsStepApiResponse: args.response as CreateLodgementOnlyWorkspaceMatterDetailsStepResponse
      });
      // move to next step
      this.props.onStepChange(CreateLodgeOnlyWorkspaceStepEnum.InviteParticipants);
      return;
    }

    // redirect user directly to document detail page
    const { workspaceId, createdByParticipantId: participantId, createdDocuments } = args.response as CreateLodgementOnlyWorkspaceInvitationsStepResponse;

    this.props.onWorkspaceCreated({
      workspaceId,
      participantId,
      documentId: createdDocuments[0].id
    });
  };

  private handleOnClose = () => {
    Logger.setInteractionName('Create new - matter detail - back', 'click');
    this.props.onClose();
  };
}

export default withStyles(styles)(MatterDetail);

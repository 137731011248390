import * as React from 'react';

import InlineLoader from '@sympli/ui-framework/components/loaders/inline-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';

import { Card } from 'src/containers/dashboard/components/card';
import { AllDirectionsState } from 'src/containers/workspace/financial/all-directions/reducer';
import { SettlementDateDetailsState } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceDetailState } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { WorkspaceDocumentsState } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { ApiStatus } from 'src/utils/http';
import { LastSystemActivityModel } from './models';
import DocumentsCardDetail from './row-detail/DocumentsCardDetail';
import FinancialsCardDetail from './row-detail/FinancialsCardDetail';
import FundsCardDetail from './row-detail/FundsCardDetail';
import InvitationsCardDetail from './row-detail/InvitationsCardDetail';
import MessagesCardDetail from './row-detail/MessagesCardDetail';
import MyTasksCardDetail from './row-detail/MyTasksCardDetail';
import SystemActivityCardDetail from './row-detail/SystemActivityCardDetail';
import WorkspaceCardDetail from './row-detail/WorkspaceCardDetail';

// TODO share this with other dashboards
function TableRowDetailWrapper({
  //
  children,
  status,
  error,
  linkTo
}: React.PropsWithChildren<{
  //
  status?: ApiStatus;
  error?: string;
  linkTo?: string;
}>) {
  if (status === 'rejected') {
    return <div className="p-[20px]">{error}</div>;
  }

  if (status === 'idle' || status === 'pending') {
    return (
      <div className="p-[20px]">
        <InlineLoader />
      </div>
    );
  }

  return (
    <div className="p-[20px]">
      <div className="grid grid-cols-[repeat(2,540px)] justify-between gap-[20px] medium-screen:grid-cols-[repeat(4,320px)] large-screen:grid-cols-[repeat(4,380px)]">
        {children}
      </div>
      <div className="flex justify-center pb-[28px] pt-[38px]">
        <SympliButton href={linkTo} variant="contained" color="primary" className="rounded-[34px] px-[45px] py-[8px]">
          View Workspace
        </SympliButton>
      </div>
    </div>
  );
}

interface FinancialTableRowDetailProps {
  linkTo?: string;
  matterReference?: string;
  workspaceBasicInfoState: WorkspaceBasicInfoState;
  workspaceDetailState: WorkspaceDetailState;
  workspaceParticipantsState: WorkspaceParticipantsState;
  workspaceDocumentsState: WorkspaceDocumentsState;
  workspaceTasksState: WorkspaceTasksState;
  allDirectionsState: AllDirectionsState;
  settlementDateDetailsState: SettlementDateDetailsState;
  isFinancialBalanced?: boolean;
  unreadMessageCount?: number;
  lastSystemActivity?: LastSystemActivityModel;
  participantId: string;
  userId: string;
}

function FinancialTableRowDetail({
  //
  linkTo,
  matterReference,
  workspaceBasicInfoState,
  workspaceDetailState,
  workspaceDocumentsState,
  workspaceParticipantsState,
  workspaceTasksState,
  allDirectionsState,
  settlementDateDetailsState,
  isFinancialBalanced,
  unreadMessageCount,
  lastSystemActivity,
  participantId,
  userId
}: FinancialTableRowDetailProps) {
  // const screenSizeVariant: ScreenSizeVariant = useScreenSize();
  // const classes = useFinancialTableRowDetailStyles();

  // TODO reimplement this based on the story requirements
  return (
    <TableRowDetailWrapper
      //
      // status={status}
      // error={error}
      linkTo={linkTo}
    >
      {/* {status !== 'idle' ? (
        <> */}
      <Card>
        <WorkspaceCardDetail
          //
          matterReference={matterReference}
          workspaceBasicInfoState={workspaceBasicInfoState}
          workspaceDetailState={workspaceDetailState}
          workspaceParticipantsState={workspaceParticipantsState}
        />
        <InvitationsCardDetail //
          workspaceParticipantsState={workspaceParticipantsState}
          settlementDateDetailsState={settlementDateDetailsState}
          workspaceBasicInfoState={workspaceBasicInfoState}
        />
      </Card>
      <Card>
        <DocumentsCardDetail //
          workspaceDocumentsState={workspaceDocumentsState}
          participantId={participantId}
          workspaceDetailState={workspaceDetailState}
          userId={userId}
        />
      </Card>
      <Card>
        <FinancialsCardDetail //
          allDirectionsState={allDirectionsState}
        />
        <FundsCardDetail //
          isFinancialBalanced={isFinancialBalanced}
        />
      </Card>
      <Card>
        <SystemActivityCardDetail //
          lastSystemActivity={lastSystemActivity}
        />
        <MessagesCardDetail //
          unreadMessageCount={unreadMessageCount}
        />
        <MyTasksCardDetail //
          workspaceBasicInfoState={workspaceBasicInfoState}
          workspaceTasksState={workspaceTasksState}
        />
      </Card>
      {/* </>
      ) : null} */}
    </TableRowDetailWrapper>
  );
}

export default FinancialTableRowDetail;

import { createSelector } from 'reselect';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

import { WorkspaceBasicInfoState } from '../workspace/shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceParticipantsState } from '../workspace/shared/detail/reducers/workspaceParticipants';
import { MessagingDisabledReasonEnum, MessagingDisabledStatus } from './models';

const getParticipant = (participantId: string, workspaceParticipantsState: WorkspaceParticipantsState): WorkspaceParticipantApiResponse | undefined =>
  workspaceParticipantsState.items.find(participant => participant.id === participantId);
const getOtherParticipantIds = (participantId: string, workspaceParticipantsState: WorkspaceParticipantsState): Array<string> =>
  workspaceParticipantsState.items.filter(participant => participant.id !== participantId).map(participant => participant.id);

const getWithdrawnParticipantIds = (workspaceParticipantsState: WorkspaceParticipantsState): Array<string> =>
  workspaceParticipantsState.items.filter(participant => participant.archivedStatus).map(participant => participant.id);

const getIsFinancialWorkspace = function (workspaceBasicInfoState: WorkspaceBasicInfoState): boolean {
  const workspaceTypeId = workspaceBasicInfoState?.detail?.workspaceTypeId;
  return (
    workspaceTypeId !== undefined &&
    [
      //
      WorkspaceTypeEnum.FinancialSettlement,
      WorkspaceTypeEnum.Refinance
    ].includes(workspaceTypeId)
  );
};
export const getIsFinalisedOrArchived = function (workspaceBasicInfoState: WorkspaceBasicInfoState): boolean {
  const workspaceStatusId = workspaceBasicInfoState?.detail?.workspaceStatusId;
  return (
    workspaceStatusId !== undefined &&
    [
      //
      WorkspaceStatusEnum.Finalised,
      WorkspaceStatusEnum.Success,
      WorkspaceStatusEnum.Archived,
      WorkspaceStatusEnum.Abandoned,
      WorkspaceStatusEnum.Withdrawn
    ].includes(workspaceStatusId)
  );
};

export const messagingDisabledSelector = createSelector<
  //
  {
    participantId: string;
    workspaceBasicInfoState: WorkspaceBasicInfoState;
    workspaceParticipantsState: WorkspaceParticipantsState;
    recipientParticipantIds?: Array<string>;
  },
  boolean,
  boolean,
  WorkspaceParticipantApiResponse | undefined,
  Array<string>,
  Array<string> | undefined,
  boolean
>(
  args => getIsFinancialWorkspace(args.workspaceBasicInfoState),
  args => getIsFinalisedOrArchived(args.workspaceBasicInfoState),
  args => getParticipant(args.participantId, args.workspaceParticipantsState),
  args => getOtherParticipantIds(args.participantId, args.workspaceParticipantsState),
  args => args.recipientParticipantIds,
  (
    //
    isFinancialWorkspace: boolean,
    isFinalisedOrArchived: boolean,
    currentParticipant: WorkspaceParticipantApiResponse | undefined,
    otherParticipantIds: Array<string>,
    recipientParticipantIds?: Array<string>
  ) => {
    const withdrawnParticipantIds = currentParticipant?.withdrawnParticipantIdsAfterAcceptedInvite;
    const participantIds = recipientParticipantIds ?? otherParticipantIds;
    const isParticipantWithdrawn = participantIds.every(participantId => withdrawnParticipantIds?.includes(participantId));
    return isFinancialWorkspace && (isParticipantWithdrawn || isFinalisedOrArchived);
  }
);

export const messagingDisabledSelector101 = createSelector<
  //
  {
    participantId: string;
    workspaceBasicInfoState: WorkspaceBasicInfoState;
    workspaceParticipantsState: WorkspaceParticipantsState;
    recipientParticipantIds?: Array<string>;
  },
  boolean,
  WorkspaceParticipantApiResponse | undefined,
  Array<string>,
  Array<string> | undefined,
  MessagingDisabledStatus
>(
  args => getIsFinalisedOrArchived(args.workspaceBasicInfoState),
  args => getParticipant(args.participantId, args.workspaceParticipantsState),
  args => getWithdrawnParticipantIds(args.workspaceParticipantsState),
  args => args.recipientParticipantIds,
  (
    //
    isFinalisedOrArchived: boolean,
    currentParticipant: WorkspaceParticipantApiResponse | undefined,
    withdrawnParticipantIds: Array<string>,
    recipientParticipantIds?: Array<string>
  ) => {
    if (currentParticipant?.archivedStatus) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.OwnWithdrawn
      };
    }

    if (isFinalisedOrArchived) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.WorkspaceInActive
      };
    }

    const isAnyParticipantWithdrawn = recipientParticipantIds?.some(participantId => withdrawnParticipantIds?.includes(participantId));

    if (isAnyParticipantWithdrawn) {
      return {
        isDisabled: true,
        disabledReason: MessagingDisabledReasonEnum.OtherPartyWithdrawn
      };
    }

    return {
      isDisabled: false
    };
  }
);

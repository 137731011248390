import { useCallback, useEffect, useRef, useState } from 'react';

import Logger, { SeverityEnum } from '@sympli/ui-logger';

import { TableauInitialiseData } from '../models';

export const useTableau = () => {
  let vizContainerRef = useRef<HTMLDivElement>(null);
  const [viz, setViz] = useState<tableau.Viz | null>(null);
  const [initialiseData, setInitialiseData] = useState<TableauInitialiseData | null>(null);
  const [tableauInstanceCreated, setTableauInstanceCreated] = useState<boolean>(false);

  const initialiseViz = useCallback((containerDiv: HTMLDivElement | null, initialiseData: TableauInitialiseData | null) => {
    if (initialiseData == null || containerDiv == null) {
      return null;
    }

    const url = initialiseData.reportLink;
    const options: tableau.VizCreateOptions = {
      width: '100%',
      height: '100%',
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: (e: tableau.TableauEvent) => {
        Logger.console(SeverityEnum.Log, 'viz has finished loading.');
        setViz(e.getViz());
      },
      ...initialiseData.filters //applying existing filters before Viz Load
    };
    setTableauInstanceCreated(true);
    new tableau.Viz(containerDiv, url, options);
  }, []);

  useEffect(() => {
    if (!viz && initialiseData && !tableauInstanceCreated) {
      initialiseViz(vizContainerRef.current, initialiseData);
    }

    return () => {
      if (viz) {
        viz.dispose();
        setViz(null);
        setTableauInstanceCreated(false);
      }
    };
  }, [initialiseData, initialiseViz, vizContainerRef, viz, tableauInstanceCreated]);

  return {
    setInitialiseData,
    tableauRef: vizContainerRef,
    viz
  };
};

import React, { useCallback } from 'react';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { getTacWarning } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { TitleReferenceEntityModel } from 'src/models';
import TitleAndAddresses, { TitleAndAddressesProps as Props } from './TitleAndAddresses';

function TitleAndAddressesContainer(props: Props) {
  const { titleReferences = [], workspaceId, participantId } = props;

  const updateTacWithLocalStorage = useCallback((items: TitleReferenceEntityModel[], key?: string) => {
    if (key === undefined) {
      return items;
    }
    return items.map(x => {
      let hasChanges = getTacWarning(key, x.reference, x);
      return { ...x, hasChanges: hasChanges };
    });
  }, []);

  const userId = useProfile().data?.userId;
  const tacKey = `tac/${workspaceId}/${participantId}/${userId}`;
  const updatedTitles = updateTacWithLocalStorage(titleReferences, tacKey);

  return <TitleAndAddresses {...props} titleReferences={updatedTitles}></TitleAndAddresses>;
}

export default TitleAndAddressesContainer;

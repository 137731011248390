import React from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';

import { useStyles } from './styles';

interface ToggleCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  name?: string;
  className?: string;
  label: string;
  disabled?: boolean;
  title?: string;
}

function ToggleCheckbox({
  //
  checked,
  onChange,
  name,
  label,
  disabled,
  title
}: ToggleCheckboxProps) {
  const classes = useStyles();

  const id = React.useMemo(() => _uniqueId('ToggleCheckbox-'), []);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.checked);
  };

  return (
    <label title={disabled ? undefined : title} htmlFor={id} className={classNames('relative flex w-[130px] items-center justify-center', !disabled && 'cursor-pointer')}>
      <input disabled={disabled} type="checkbox" id={id} checked={checked} className={classNames('sr-only', classes.input)} onChange={handleOnChange} name={name} />
      {checked ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" className="toggle-tick absolute right-[12px] top-[5px]">
          <path
            d="M8.58741 15C8.27364 15 7.89712 14.875 7.6461 14.625L4.25738 11.25C4.00637 11 3.88086 10.6875 3.88086 10.3125C3.88086 9.9375 4.00637 9.625 4.25738 9.375C4.75942 8.875 5.63797 8.875 6.14001 9.375L8.58741 11.8125L15.0511 5.375C15.5531 4.875 16.4317 4.875 16.9337 5.375C17.4357 5.875 17.4357 6.75 16.9337 7.25L9.52872 14.625C9.27771 14.875 8.96394 15 8.58741 15Z"
            fill="#292F36"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="toggle-cross absolute left-[12px] top-[5px]">
          <path d="M10.9742 10.0001L14.7992 6.1751C15.068 5.90635 15.068 5.46885 14.7992 5.2001C14.5305 4.93135 14.093 4.93135 13.8305 5.2001L10.0055 9.0251L6.18047 5.2001C5.91172 4.93135 5.47422 4.93135 5.20547 5.2001C4.93672 5.46885 4.93672 5.90635 5.20547 6.1751L9.03047 10.0001L5.20547 13.8251C4.93672 14.0938 4.93672 14.5313 5.20547 14.8001C5.33672 14.9313 5.51797 15.0001 5.69297 15.0001C5.86797 15.0001 6.04297 14.9313 6.18047 14.8001L10.0055 10.9751L13.8305 14.8001C13.9617 14.9313 14.143 15.0001 14.318 15.0001C14.493 15.0001 14.668 14.9313 14.8055 14.8001C15.0742 14.5313 15.0742 14.0938 14.8055 13.8251L10.9805 10.0001H10.9742Z" />
        </svg>
      )}

      <div className="toggle-bg block h-[30px] w-[130px] rounded-full"></div>
      <div className="toggle-dot absolute top-[2px] h-[26px] rounded-full"></div>
      <div className="toggle-text absolute  top-[8px] w-[74px] text-center text-[12px]">{label}</div>
    </label>
  );
}

export default ToggleCheckbox;

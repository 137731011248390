import { ComplianceLevelEnum, LodgementCaseStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse } from '@sympli/api-gateway/models';
import { LodgementDetail } from '@sympli/api-gateway/shared';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import { StatusProgressModel } from 'src/components/workspace-status-rebuild/status-progress-with-breadcrumb/models';
import { WorkspaceStatusColorVariantEnum } from 'src/components/workspace-status-rebuild/status-progress/components/models';
import Completed from './components/tooltip-contents/completed/Completed';
import InPreparationLodgementFailed from './components/tooltip-contents/in-preparation-lodgement-failed/InPreparationLodgementFailed';
import InPreparationUnverifiedFinancial from './components/tooltip-contents/in-preparation-unverified-financial/InPreparationUnverifiedFinancial';
import InPreparationUnverifiedRegistrationOnly from './components/tooltip-contents/in-preparation-unverified-registration-only/InPreparationUnverifiedRegistrationOnly';
import InPreparationVerificationFailedBothDocs from './components/tooltip-contents/in-preparation-verification-failed-both-docs/InPreparationVerificationFailedBothDocs';
import InPreparationVerificationFailedMyDocs from './components/tooltip-contents/in-preparation-verification-failed-my-docs/InPreparationVerificationFailedMyDocs';
import InPreparationVerificationFailedOtherDocs from './components/tooltip-contents/in-preparation-verification-failed-other-docs/InPreparationVerificationFailedOtherDocs';
import Lodged from './components/tooltip-contents/lodged/Lodged';
import Lodging from './components/tooltip-contents/lodging/Lodging';
import PreparedFinancial from './components/tooltip-contents/prepared-financial/PreparedFinancial';
import PreparedRegistrationOnly from './components/tooltip-contents/prepared-registration-only/PreparedRegistrationOnly';
import ReadyFinancial from './components/tooltip-contents/ready-financial/ReadyFinancial';
import ReadyRegistrationOnly from './components/tooltip-contents/ready-registration-only/ReadyRegistrationOnly';
import Verifying from './components/tooltip-contents/verifying/Verifying';
import { LodgementCaseStatusSteps } from './models';

export function resolveLodgementCaseStepAndVariant({
  lodgementCaseStatusId,
  allDocumentsSigned
}: {
  lodgementCaseStatusId: LodgementCaseStatusEnum;
  allDocumentsSigned: boolean;
}): Omit<StatusProgressModel, 'tooltipContent'> | null {
  switch (lodgementCaseStatusId) {
    case LodgementCaseStatusEnum.Unverified: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationRequested: {
      if (allDocumentsSigned) {
        return {
          step: LodgementCaseStatusSteps.Prepared,
          variant: WorkspaceStatusColorVariantEnum.Warning
        };
      }

      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationError: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Failure
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationSuccess:
    case LodgementCaseStatusEnum.LodgementTechnicalError:
    case LodgementCaseStatusEnum.LodgementConnectionError: {
      if (allDocumentsSigned) {
        return {
          step: LodgementCaseStatusSteps.Ready,
          variant: WorkspaceStatusColorVariantEnum.Success
        };
      }

      return {
        step: LodgementCaseStatusSteps.Prepared,
        variant: WorkspaceStatusColorVariantEnum.Warning
      };
    }
    case LodgementCaseStatusEnum.LodgementError: {
      return {
        step: LodgementCaseStatusSteps.InPreparation,
        variant: WorkspaceStatusColorVariantEnum.Failure
      };
    }
    case LodgementCaseStatusEnum.LodgementRequested: {
      return {
        step: LodgementCaseStatusSteps.Lodging,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case LodgementCaseStatusEnum.LodgementSuccess: {
      return {
        step: LodgementCaseStatusSteps.Lodged,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case LodgementCaseStatusEnum.Registered:
    case LodgementCaseStatusEnum.Rejected:
    case LodgementCaseStatusEnum.Withdrawn:
    case LodgementCaseStatusEnum.Unnecessary: {
      return {
        step: LodgementCaseStatusSteps.Completed,
        variant: WorkspaceStatusColorVariantEnum.Success
      };
    }
    case LodgementCaseStatusEnum.AwaitingInvitee: {
      // Intentionally returning null as this status should never be shown on workspace.
      // Only used in a linked workspace where invited participant hasn't accepted.
      return null;
    }
    default: {
      const scope = Logger.scopeWithCustomAttributes({
        lodgementCaseStatus: LodgementCaseStatusEnum[lodgementCaseStatusId]
      });
      Logger.captureException(new BusinessLogicError(`Unmapped lodgement case status`), scope);
      return null;
    }
  }
}

export function resolveLodgementCaseStatus({
  lodgementCaseStatusId,
  documents,
  workspaceTypeId,
  participantId,
  lodgementDetail,
  isSLC
}: {
  lodgementCaseStatusId: LodgementCaseStatusEnum;
  documents: WorkspaceDocumentSummaryApiResponse[];
  workspaceTypeId: WorkspaceTypeEnum;
  participantId: string;
  lodgementDetail?: LodgementDetail;
  isSLC: boolean;
}): StatusProgressModel | null {
  const allDocumentsSigned: boolean = documents.every(d => d.isFullySignedByAllParticipants);

  const lcInfo: Omit<StatusProgressModel, 'tooltipContent'> | null = resolveLodgementCaseStepAndVariant({
    lodgementCaseStatusId,
    allDocumentsSigned
  });
  if (!lcInfo) {
    return null;
  }

  const { step, variant } = lcInfo;

  switch (lodgementCaseStatusId) {
    case LodgementCaseStatusEnum.Unverified: {
      return {
        step,
        variant,
        tooltipContent: workspaceTypeId === WorkspaceTypeEnum.FinancialSettlement && !isSLC ? <InPreparationUnverifiedFinancial /> : <InPreparationUnverifiedRegistrationOnly />
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationRequested: {
      return {
        step,
        variant,
        tooltipContent: <Verifying />
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationError: {
      let tooltipContent = <InPreparationVerificationFailedMyDocs lodgementDetail={lodgementDetail!} />;
      if (workspaceTypeId === WorkspaceTypeEnum.FinancialSettlement) {
        const allDocuments = documents.flatMap(({ documentParticipants, lodgementDetail, supportingDocuments = [] }) => [
          {
            documentParticipants,
            lodgementDetail
          },
          ...supportingDocuments.map(({ documentParticipants: sdParticipants, lodgementDetail: sdLodgeDetail }) => ({
            documentParticipants: sdParticipants,
            lodgementDetail: sdLodgeDetail
          }))
        ]);

        const documentsWithComplianceErrors = allDocuments.filter(d =>
          d.lodgementDetail?.documentLodgementCompliances.some(dlc => dlc.complianceLevel === ComplianceLevelEnum.Error)
        );
        const myDocsWithComplianceErrors = documentsWithComplianceErrors.filter(x => Boolean(x.documentParticipants.filter(dc => dc.id === participantId).length));
        const otherDocsWithComplianceErrors = documentsWithComplianceErrors.filter(x => Boolean(x.documentParticipants.filter(dc => dc.id !== participantId).length));

        if (myDocsWithComplianceErrors.length && otherDocsWithComplianceErrors.length) {
          tooltipContent = <InPreparationVerificationFailedBothDocs lodgementDetail={lodgementDetail!} />;
        } else if (otherDocsWithComplianceErrors.length) {
          tooltipContent = <InPreparationVerificationFailedOtherDocs lodgementDetail={lodgementDetail!} />;
        }
      }
      return {
        step,
        variant,
        tooltipContent
      };
    }
    case LodgementCaseStatusEnum.LodgementVerificationSuccess:
    case LodgementCaseStatusEnum.LodgementTechnicalError:
    case LodgementCaseStatusEnum.LodgementConnectionError: {
      if (allDocumentsSigned) {
        return {
          step,
          variant,
          tooltipContent:
            workspaceTypeId === WorkspaceTypeEnum.FinancialSettlement ? (
              <ReadyFinancial lodgementDetail={lodgementDetail!} />
            ) : (
              <ReadyRegistrationOnly lodgementDetail={lodgementDetail!} />
            )
        };
      }

      return {
        step,
        variant,
        tooltipContent:
          workspaceTypeId === WorkspaceTypeEnum.FinancialSettlement ? (
            <PreparedFinancial lodgementDetail={lodgementDetail!} />
          ) : (
            <PreparedRegistrationOnly lodgementDetail={lodgementDetail!} />
          )
      };
    }
    case LodgementCaseStatusEnum.LodgementRequested: {
      return {
        step,
        variant,
        tooltipContent: <Lodging />
      };
    }
    case LodgementCaseStatusEnum.LodgementSuccess: {
      return {
        step,
        variant,
        tooltipContent: <Lodged />
      };
    }
    case LodgementCaseStatusEnum.LodgementError: {
      return {
        step,
        variant,
        tooltipContent: <InPreparationLodgementFailed lodgementDetail={lodgementDetail!} />
      };
    }
    case LodgementCaseStatusEnum.Registered:
    case LodgementCaseStatusEnum.Rejected:
    case LodgementCaseStatusEnum.Withdrawn:
    case LodgementCaseStatusEnum.Unnecessary: {
      return {
        step,
        variant,
        tooltipContent: <Completed />
      };
    }
    case LodgementCaseStatusEnum.AwaitingInvitee: {
      // Intentionally returning null as this status should never be shown on workspace.
      // Only used in a linked workspace where invited participant hasn't accepted.
      return null;
    }
    default: {
      const scope = Logger.scopeWithCustomAttributes({
        lodgementCaseStatus: LodgementCaseStatusEnum[lodgementCaseStatusId]
      });
      Logger.captureException(new BusinessLogicError(`Unmapped lodgement case status`), scope);
      return null;
    }
  }
}

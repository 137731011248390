import * as React from 'react';

import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate } from '@sympli/api-gateway/shared';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag, useSafeDispatch, useStoreSelector } from 'src/hooks';
import { RescindApprovalModel, SettlementDateFormModel } from '../../../models';
import RenderedEditForm from './RenderedEditForm';

interface Props {
  formikProps: FormikProps<SettlementDateFormModel>;
  workspaceSettlementDate?: string;
  expectedSettlementDate?: ExpectedSettlementDate;
  workspaceId: string;
  participantId?: string;
  jurisdictionId: JurisdictionsEnum;
  reasonText?: string;
  declined?: boolean;
  onSettlementDateChange(newWorkspaceSettlementDate: string): void;
  settlementAcceptedByAll: boolean;
  rescindApprovalData: RescindApprovalModel;
  proposedSettlementDate?: string;
}

function RenderedEditFormContainer(props: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const settlementEditFormState = useStoreSelector(store => store.settlementEditForm);
  const isRevokeSettlementDateApprovalEnabled: boolean = useFeatureFlag(FeatureToggleEnum.revokeSettlementDateApproval);

  return (
    <RenderedEditForm
      {...props}
      {...settlementEditFormState}
      dispatch={dispatch}
      navigate={navigate}
      isRevokeSettlementDateApprovalEnabled={isRevokeSettlementDateApprovalEnabled}
    />
  );
}

export default React.memo(RenderedEditFormContainer);

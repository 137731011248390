import * as React from 'react';

import classNames from 'classnames';
import { FieldArray, getIn, useFormikContext } from 'formik';
import IconButton from '@mui/material/IconButton';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconDelete } from '@sympli/ui-framework/icons';

import AddItemButton from 'src/containers/documents/dynamic-form/components/add-item-button';
import Evidence from '../../components/evidence';
import { EvidenceModel } from '../../models';
import { useStyles } from './styles';

// this file was automatically generated from components/ArrayComponent.tsx.mustache
interface Props {
  name: string;
  maxItems: number;
  disabled: boolean;
  proprietorSelected: boolean;
}

function EvidenceArray({ name, maxItems, disabled, proprietorSelected }: Props): JSX.Element {
  const { values } = useFormikContext();
  const classes = useStyles();
  const evidences: EvidenceModel[] = getIn(values, name) ?? [];
  const createNewItem = (): EvidenceModel => ({
    type: null,
    date: null,
    documentReference: '',
    defaultAdded: false,
    jurisdiction: null
  });

  return (
    <div data-section={name}>
      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <>
            {evidences.map((item, index) => {
              const canDelete = index !== 0 && !item.defaultAdded;
              return (
                <FlexLayout
                  className={classNames(canDelete && classes.flexEvidence)}
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignContent="stretch"
                  alignItems="baseline"
                  key={index}
                >
                  {canDelete && (
                    <IconButton className={classes.removeButton} color="primary" disabled={disabled} onClick={() => remove(index)} size="large">
                      <IconDelete fill="currentColor" />
                    </IconButton>
                  )}
                  <Evidence name={`${name}[${index}]`} disabled={disabled} proprietorSelected={proprietorSelected} />
                </FlexLayout>
              );
            })}
            {evidences.length < maxItems && (
              <AddItemButton //
                onClick={() => push(createNewItem())}
                disabled={disabled}
              >
                Add another evidence
              </AddItemButton>
            )}
          </>
        )}
      />
    </div>
  );
}

export default React.memo(EvidenceArray);

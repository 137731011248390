import { LookupItemModel } from '@sympli/ui-framework/models';

export enum DateOfDeathTypeEnum {
  ActualDate = 'ActualDate',
  DateDescription = 'DateDescription',
  DateRange = 'DateRange'
}

export const DATE_OF_DEATH_TYPE_LOOKUP_OPTIONS: LookupItemModel<DateOfDeathTypeEnum>[] = [
  { id: DateOfDeathTypeEnum.ActualDate, name: 'Actual date' },
  { id: DateOfDeathTypeEnum.DateDescription, name: 'Date description' },
  { id: DateOfDeathTypeEnum.DateRange, name: 'Date range' }
];

import * as React from 'react';

import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { DocumentWorkflowTypeEnum } from '@sympli/api-gateway/enums';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';

import styles, { ClassKeys } from './styles';

interface OwnProps {
  workflowType: DocumentWorkflowTypeEnum;
  isSubmitting: boolean;
  canEdit: boolean;
  isSaveAsDraft: boolean;
  haveCancelButton: boolean;
  formDirty: boolean;

  onFormCancel: () => void;
  handleOnSaveAndContinueClick: () => void;
  handleOnSaveDraftClick: () => void;

  isFssLineItemSaveEnabled: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;
function SaveFormStepper(props: Props) {
  const {
    workflowType,
    haveCancelButton,
    onFormCancel,
    isSubmitting,
    canEdit,
    isSaveAsDraft,
    handleOnSaveAndContinueClick,
    handleOnSaveDraftClick,
    classes,
    formDirty,
    isFssLineItemSaveEnabled
  } = props;
  const onBack = haveCancelButton ? onFormCancel : undefined;
  const nextLabel = workflowType === DocumentWorkflowTypeEnum.Standard ? 'Save and approve' : 'Save and review';
  return (
    <WizardStepper
      onBack={onBack}
      backLabel="Cancel"
      nextLabel={nextLabel}
      isLoading={isSubmitting && !isSaveAsDraft}
      onNext={handleOnSaveAndContinueClick}
      disabled={!canEdit || isSubmitting}
    >
      {!isFssLineItemSaveEnabled && (
        <div className={classes.saveDraftButtonContainer}>
          <SympliButton
            color="primary"
            variant="outlined"
            isLoading={isSubmitting && isSaveAsDraft}
            onClick={handleOnSaveDraftClick}
            disabled={!canEdit || !formDirty || isSubmitting}
          >
            Save changes
          </SympliButton>
        </div>
      )}
    </WizardStepper>
  );
}

export default withStyles(styles)(SaveFormStepper);

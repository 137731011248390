import React from 'react';

import List from '@mui/material/List';

import { DocumentItem } from 'src/containers/shared/document-list/components/document-item';
import { DocumentListModel } from 'src/containers/shared/document-list/models';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  items?: DocumentListModel[];
  disabled?: boolean;
  dataTestId?: string;
}

function DocumentListBase({ items, disabled, dataTestId }: Props) {
  const classes = useStyles();

  function renderList(items?: DocumentListModel[]) {
    return items?.map((item, index) => (
      <React.Fragment key={`${item.name}-${index}`}>
        <DocumentItem document={item} forceDisable={disabled} dataTestId={`${dataTestId}-${item.name}`} className={item.className} />
        {item.supportingDocuments && renderList(item.supportingDocuments)}
      </React.Fragment>
    ));
  }

  return <List className={classes.outerGutters}>{renderList(items)}</List>;
}

export default DocumentListBase;

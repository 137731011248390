import React from 'react';

import { useNavigate } from 'react-router-dom';

import DocumentFormWrapper from '@sympli-mfe/document-forms-framework/components/document-form-wrapper';
import { DocumentFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { DndContextProvider } from '@sympli-mfe/document-forms-framework/providers/dnd-context';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { resolveWorkspaceDetailLink } from '@sympli-mfe/document-forms-framework/utils';
import { FormikPostSubmitArgs } from '@sympli/ui-framework/components/formik';

import { DocumentsPageRouteAndQueryModel } from 'src/containers/documents/models';
import converter from './conversion';
import { ApiLodgementInstructions2_21_1Model, LodgementInstructions2_21_1Model } from './models';
import RootForm from './RootForm';
import validationSchema from './validationSchema';

// this file was automatically generated from DocumentForm.tsx.mustache
export type Props = DocumentFormProps<LodgementInstructions2_21_1Model, ApiLodgementInstructions2_21_1Model> & {
  queryParams: DocumentsPageRouteAndQueryModel;
};

export default function DocumentForm(props: Props): JSX.Element {
  const navigate = useNavigate();
  const { currentParticipant } = useDocumentContext();
  const { queryParams, onPostSubmit } = props;
  const { workspaceId, participantId } = queryParams;

  const handleOnPostSubmit = (args: FormikPostSubmitArgs<LodgementInstructions2_21_1Model, ApiLodgementInstructions2_21_1Model>) => {
    return new Promise<void>(resolve => {
      if (!args.error) {
        const { isResponsibleParticipant, responsibleParticipantId } = args.formValues;
        if (isResponsibleParticipant && currentParticipant?.id !== responsibleParticipantId) {
          // Changing workspace responsible participant should redirect user to the workspace overview
          navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
        } else {
          onPostSubmit?.(args);
        }
      }
      resolve();
    });
  };

  return (
    <DndContextProvider>
      <DocumentFormWrapper //
        {...props}
        converter={converter}
        validationSchema={validationSchema}
        RootForm={RootForm}
        onPostSubmit={handleOnPostSubmit}
      />
    </DndContextProvider>
  );
}

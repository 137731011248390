import { FormikProps, setIn } from 'formik';
import queryString from 'query-string';
import { Action, Dispatch } from 'redux';

import { BankAccountTypeEnum, DirectionCategoryEnum, FinancialStatusEnum, PaymentMethodEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { UpdateWorkspaceDirectionsApiResponse, WorkspaceDirectionsApiResponse } from '@sympli/api-gateway/models';
import { DistributionsParticipantModel, WorkspaceParticipant } from '@sympli/api-gateway/shared';

import { WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS } from 'src/containers/router/constants';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { createArrayItemModelKeyAppender, modelKey } from 'src/utils/formUtils';
import { actionCombinationAllDirectionActionsApprove } from './actions';
import { BankDetailsModel, DirectionQueryParams, DirectionsTrustAccountModel, DistributionFormikModel, SourceFundFormikModel, SourceFundModel, TrustAccountMap } from './models';
import { PurchaserDirectionsFormModel } from './views/edit-directions/forms/purchaser-directions/models';
import { VendorDirectionsFormModel } from './views/edit-directions/forms/vendor-directions/models';

export const FSS_DOCUMENT_TYPE = 'Financial Settlement Schedule';

export const ASTERISK = '*';

/**
 * Description. (use period)
 *
 * @return {boolean}
 *   true: means it's a 'settlement distribution from purchaser'
 */
export function isVendorAmountDirection(workspaceRole: WorkspaceRoleEnum | undefined, details: WorkspaceDirectionsApiResponse['directions'][number]) {
  switch (workspaceRole) {
    case WorkspaceRoleEnum.Purchaser: {
      return details.directionCategory === DirectionCategoryEnum.Settlement;
    }
    case WorkspaceRoleEnum.Vendor: {
      // * Assumptions in vendor directions
      // * - sympli fee:
      // *     directionCategory === Settlement, isLocked, NO participantId
      // * - direction by PURCHASER:
      // *     directionCategory === Settlement, isLocked, has participantId (assumed to be purchaser participantId)
      // * - other direction:
      // *     directionCategory === Settlement, NOT isLocked, has participantId (assumed to be vendor participantId)

      // an amount actually paid by other party (Purchaser), i.e there is a participantId, Sympli fee does not have a participantId in the detail
      return !!details.participantId && details.directionCategory === DirectionCategoryEnum.Settlement && details.isLocked;
    }
    default: {
      return false;
    }
  }
}

export function getVendorAmountDirectionDesc(workspaceRole: WorkspaceRoleEnum | undefined, directions: WorkspaceDirectionsApiResponse['directions']) {
  const hasVendorAmountDirection = directions.some(details => isVendorAmountDirection(workspaceRole, details));
  if (hasVendorAmountDirection) {
    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser: {
        return `${ASTERISK} Added to vendor's directions`;
      }
      case WorkspaceRoleEnum.Vendor: {
        return `${ASTERISK} Added by purchaser`;
      }
      default: {
        return null;
      }
    }
  }
  return null;
}

export function resolveDirectionsLink(params: DirectionQueryParams) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  return `/workspace/${encodeURIComponent(workspaceId)}/directions?${query}`;
}

export function resolveAllDirectionsLink(params: WorkspaceDetailRouteParams) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  return `/workspace/${encodeURIComponent(workspaceId)}/${WORKSPACE_DETAIL_PAGE_ALL_DIRECTIONS}?${query}`;
}

export function resolveDirectionsTAAFLink(params: WorkspaceDetailRouteParams, accountId: string) {
  const { workspaceId, ...rest } = params;
  const query = queryString.stringify(rest);
  return `/workspace/${encodeURIComponent(workspaceId)}/trust-account-authorisation-records/${encodeURIComponent(accountId)}?${query}`;
}

export function accountDescriptionMapping(bankDetail: BankDetailsModel) {
  const { accountNumber, bsb, bankName } = bankDetail || {};
  const bsbText = bsb ? `BSB ${bsb}` : '';
  const accountNumberText = accountNumber ? `Account number ${accountNumber}` : '';

  return [bankName, bsbText, accountNumberText].filter(Boolean).join(' | ');
}

export function resolveBankTransferCategoryDescription(defaultLabel: string, bankDetails: BankDetailsModel, category: string, categoryOther: string | undefined) {
  const { accountName, reference } = bankDetails || {};

  if (category === '' && categoryOther === '' && accountName) {
    return defaultLabel;
  }
  switch (category) {
    case 'Stamp duty':
      return `Stamp Duty (transaction id: ${reference})`;
    case 'Other':
      return reference ? `${categoryOther} (${reference})` : categoryOther;
    default:
      return reference ? `${category} (${reference})` : category;
  }
}

export function getExpectedSympliSourceAccountAmount(sourceFunds: Array<SourceFundModel> = [], trustAccountBankDetailMap: TrustAccountMap): number | null {
  if (trustAccountBankDetailMap == null || sourceFunds.length === 0) {
    return null;
  }

  return sourceFunds.reduce((totalAmount, sourceFund) => {
    const trustAccountBankDetail = trustAccountBankDetailMap[sourceFund.trustAccountId];
    if (trustAccountBankDetail && trustAccountBankDetail.bankAccountType === BankAccountTypeEnum.SympliSourceAccount) {
      return totalAmount + Number(sourceFund.amount);
    }
    return totalAmount;
  }, 0);
}

export function getVerifiedSympliSourceAccountAmount(trustAccounts: Array<DirectionsTrustAccountModel> = []): number | null {
  if (trustAccounts.some(account => account.bankAccountType === BankAccountTypeEnum.SympliSourceAccount)) {
    return trustAccounts.reduce((totalAmount, trustAccount) => {
      if (trustAccount.bankAccountType === BankAccountTypeEnum.SympliSourceAccount) {
        return totalAmount + trustAccount.balance;
      }
      return totalAmount;
    }, 0);
  }
  return null;
}

export function calSum(array: Array<{ amount: string | number }>) {
  return array.reduce((previousValue, currentValue) => {
    const numberValue = Number(currentValue.amount);
    if (isNaN(numberValue)) {
      return previousValue;
    }
    return previousValue + numberValue;
  }, 0);
}

export type AddAndUpdateProps = {
  formikProps: FormikProps<PurchaserDirectionsFormModel | VendorDirectionsFormModel>;
  trustAccountBankDetailMap: TrustAccountMap;
  defaultValue: DistributionFormikModel;
  sourceBalance: number;
};

export function addAndUpdateDirectionsFormikProps(props: AddAndUpdateProps) {
  const { formikProps, trustAccountBankDetailMap, defaultValue, sourceBalance } = props;
  const { setValues, values } = formikProps;
  const { distributions, sourceFunds } = values;

  let sourceFundIndex = 0;
  let newSourceAmount = Number(sourceFunds[sourceFundIndex].amount) + sourceBalance;

  const sympliSourceAccounts = sourceFunds.filter(item => {
    const trustAccount = trustAccountBankDetailMap[item.trustAccountId];
    if (trustAccount == null) {
      return false;
    }
    return trustAccount.bankAccountType === BankAccountTypeEnum.SympliSourceAccount;
  });

  if (sympliSourceAccounts) {
    sourceFundIndex = sympliSourceAccounts.findIndex(item => item.trustAccountId !== null);
    newSourceAmount = Number(sourceFunds[sourceFundIndex].amount) + sourceBalance;
  }

  // default value for purchaser and vendor always defined the property
  const bankDetails: BankDetailsModel = { ...defaultValue.bankDetails! };
  const distributionItem: DistributionFormikModel = {
    ...defaultValue,
    paymentMethod: PaymentMethodEnum.BankTransfer,
    amount: sourceBalance,
    category: 'Other',
    categoryOther: 'Sympli source account surplus',
    bankDetails
  };

  const fieldName = modelKey<PurchaserDirectionsFormModel | VendorDirectionsFormModel>();
  const sourceFundsArrayItemModelKeyAppender = createArrayItemModelKeyAppender<SourceFundFormikModel>('sourceFunds');
  let newValues = formikProps.values;
  newValues = setIn(newValues, fieldName('distributions'), distributions.concat(distributionItem));
  newValues = setIn(newValues, sourceFundsArrayItemModelKeyAppender(sourceFundIndex, 'amount'), newSourceAmount.toString());
  setValues(newValues);
}

export const isDistributionValid = (item: DistributionFormikModel) => {
  if (item.isLocked) return true;

  switch (item.paymentMethod) {
    case PaymentMethodEnum.BPAY: {
      return item.bpayDetails?.billerCode && item.bpayDetails?.billerName && item.bpayDetails?.billerReference;
    }
    case PaymentMethodEnum.TrustAccount: {
      return item.bankAccountId;
    }
    case PaymentMethodEnum.BankTransfer: {
      return item.bankDetails?.accountName && item.bankDetails?.accountNumber && item.bankDetails?.bsb;
    }
    case PaymentMethodEnum.HoldingAccount: {
      return item.holdingAccountDetails?.accountId;
    }
    default: {
      return true;
    }
  }
};

export const isSourceFundValid = (item: SourceFundFormikModel) => {
  return item.trustAccountId?.length > 0;
};

export const directionUpdateHelper = ({
  dispatch,
  newDirectionForm,
  newDistributionsParticipantData,
  participantId,
  workspaceId
}: {
  dispatch: SafeDispatch<Action> | Dispatch<Action>;
  newDirectionForm: Partial<UpdateWorkspaceDirectionsApiResponse>;
  newDistributionsParticipantData: Partial<DistributionsParticipantModel>;
  participantId?: string;
  workspaceId?: string;
}) => {
  dispatch(actionCombinationAllDirectionActionsApprove.request({ newDirectionForm, newDistributionsParticipantData, participantId, workspaceId }));
};

// we do not attempt to do calculation here, just do string compare with toFixed(2) on the number literally
export function isZeroAmountLiteral(amount: number) {
  return Math.abs(amount).toFixed(2) === '0.00';
}

export function havePurchaserParticipantInWorkspace(workspaceParticipants: WorkspaceParticipant[]) {
  return workspaceParticipants.some(p => p.workspaceRole.id === WorkspaceRoleEnum.Purchaser);
}

export function isFullyOptedOut(paymentsStatus: FinancialStatusEnum, sourceFundsStatus: FinancialStatusEnum): boolean {
  return paymentsStatus === FinancialStatusEnum.NotApplicable && sourceFundsStatus === FinancialStatusEnum.NotApplicable;
}

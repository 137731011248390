import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import { FinalTenancyHoldingsExtractor } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/final-holdings';
import { ReceivingTenancyDetailModel } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/receiving';
import { FractionModel, ProprietorGroupModel, TenancyDetail, TenancyPartyModel } from '@sympli-mfe/document-forms-framework/core/models';
import { ApiPartyCapacityModel, ApiPartyReferenceModel } from '@sympli-mfe/document-forms-framework/shared-config/party';

import { DateOfDeathTypeEnum } from './components/date-of-death/enums';
import { EvidenceTypeEnum } from './components/evidence/enums';
import { DeceasedTenancyPartyCapacityEnum } from './enums';

export interface BaseDeceasedTenancyDetailComponentModel<TNameChange = {}> {
  applicantCapacity?: DeceasedTenancyPartyCapacityEnum | null;
  deceasedTenancyDetail: DeceasedTenancyDetailModel;
  partyBook: PartyModel<TNameChange>[];
}

export type DeceasedTenancyDetailModel = TenancyDetail<DeceasedProprietorGroupModel>;
export type DeceasedProprietorGroupModel = ProprietorGroupModel<DeceasedProprietorModel>;

export const initDeceasedProprietor = (isSelected: boolean, partyBookId: string, partyCapacity?: ApiPartyCapacityModel): DeceasedProprietorModel => {
  return {
    isSelected,
    partyBookId,
    evidences: [],
    dateOfDeath: {
      type: DateOfDeathTypeEnum.ActualDate,
      date: null,
      to: null,
      from: null,
      description: ''
    },
    partyCapacity
  };
};

export interface DeceasedProprietorModel extends ApiPartyReferenceModel {
  dateOfDeath?: DateOfDeathModel;
  evidences: EvidenceModel[];
}

export interface DateOfDeathModel {
  description: string;
  type: DateOfDeathTypeEnum | null;
  date: Date | string | null;
  from: Date | string | null;
  to: Date | string | null;
}

export interface EvidenceModel {
  date: Date | string | null;
  documentReference?: string;
  type: EvidenceTypeEnum | null;
  jurisdiction: string | null;
  defaultAdded: boolean;
}

export class DeceasedTenancyDetailExtractor implements FinalTenancyHoldingsExtractor {
  /**
   *
   */
  constructor(
    public source: DeceasedTenancyDetailModel,
    public receivingTenancyDetail: ReceivingTenancyDetailModel
  ) {}

  extractRemainingGroups(): ProprietorGroupModel<TenancyPartyModel>[] {
    if (this.source.proprietorGroups.every(pg => !pg.isSelected)) {
      return [];
    }

    return this.source.proprietorGroups
      .filter(pg => !pg.isSelected)
      .map(pg => ({ ...pg, parties: pg.parties.map(({ evidences, dateOfDeath, ...p }): TenancyPartyModel => ({ ...p, partyCapacity: { capacity: null } })) }));
  }

  extractShareTransferred(): FractionModel | undefined {
    const selectedGroup = this.source.proprietorGroups.find(pg => pg.isSelected);
    const selectedParty = selectedGroup?.parties.find(p => p.isSelected);

    if (!selectedParty) return undefined;

    return selectedGroup?.shareFraction;
  }
}

import React from 'react';

import classNames from 'classnames';
import { Form, FormikProps } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';

import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { SettlementDateKindEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import Formik from '@sympli/ui-framework/components/formik';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import Button from '@sympli/ui-framework/components/sympli-button';
import msg from '@sympli/ui-framework/utils/messages';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import ToggleCheckbox from 'src/containers/dashboard/invitation/components/toggle-checkbox';
import { dateTimeLine } from 'src/utils/formatters';
import { JoinWorkspaceApiRequest } from '../../active-workspace-accordion/api';
import { JoinWorkspaceDialogModel } from '../models';

export interface JoinWorkspaceFormValues {
  workspaceId: string;
  groupId: string;
  hasAcceptedSettlementDate: boolean;
  reference: string;
  hasAgreed: boolean;
  role: WorkspaceRoleEnum;
}

const validationSchema = yup.object<JoinWorkspaceFormValues>({
  workspaceId: yup.mixed<string>(),
  hasAcceptedSettlementDate: yup.boolean(),
  hasAgreed: yup.boolean().equals([true], 'required').defined(),
  reference: yup.string().trim().required(msg.REQUIRED).max(200, msg.LENGTH_MUST_BE_X_OR_LESS(200, 'character')),
  groupId: yup.string().trim().required(msg.REQUIRED),
  role: yup.mixed<WorkspaceRoleEnum>()
});

const fieldName = modelKey<JoinWorkspaceFormValues>();

interface JoinWorkspaceFormProps {
  workspaceId: string;
  groupId: string;
  onCancel(): void;
  matter?: string;
  proposedSettlementDate: JoinWorkspaceDialogModel['proposedSettlementDate'];
  expectedSettlementDate: JoinWorkspaceDialogModel['expectedSettlementDate'];
  onSubmit: (data: JoinWorkspaceApiRequest) => Promise<void>;
  role: WorkspaceRoleEnum;
}

const inputLabelClassName = 'text-[16px] font-[700] leading-[24px] tracking-[0.15px] text-[var(--neutral-1000)]';
const fieldClasses = {
  inputLabel: inputLabelClassName,
  formControl: 'mt-[28px]',
  marginBottom: 'mb-[24px]'
};

function JoinWorkspaceForm({
  //
  proposedSettlementDate,
  expectedSettlementDate,
  groupId,
  workspaceId,
  onCancel,
  onSubmit,
  matter,
  role
}: JoinWorkspaceFormProps) {
  const formattedSettlementDate = proposedSettlementDate
    ? expectedSettlementDate?.settlementDateKind === SettlementDateKindEnum.DateOnly
      ? dateTimeLine(proposedSettlementDate.workspaceLocalTime, 'd mmm yyyy')
      : dateTimeLine(proposedSettlementDate.userLocalTime, 'd mmm yyyy, h:MMTT') + ` ${proposedSettlementDate.userTimezoneAbbreviation}`
    : undefined;

  const initialValues: JoinWorkspaceFormValues = React.useMemo<JoinWorkspaceFormValues>(
    () => ({
      workspaceId,
      hasAcceptedSettlementDate: false,
      reference: matter ?? '',
      groupId,
      hasAgreed: false,
      role
    }),
    [workspaceId, matter, groupId, role]
  );

  const handleOnSubmit = React.useCallback(
    async (data: JoinWorkspaceFormValues): Promise<void> => {
      return onSubmit({
        workspaceId: data.workspaceId,
        groupId: data.groupId,
        reference: data.reference,
        acceptSettlementDate: data.hasAcceptedSettlementDate,
        role: data.role
      });
    },
    [onSubmit]
  );

  const isOverDue: boolean = !!proposedSettlementDate && proposedSettlementDate?.hoursTill < 0;

  return (
    <Formik //
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      validateOnMount
    >
      {({ submitForm, isSubmitting, isValid, values, setFieldValue, setFieldTouched }: FormikProps<JoinWorkspaceFormValues>) => {
        return (
          <Form className="flex grow flex-col justify-between">
            <div>
              {!!formattedSettlementDate && !!expectedSettlementDate && (
                <div className={classNames('flex items-center justify-between', fieldClasses.marginBottom)}>
                  <div>
                    <div
                      //
                      className={classNames(fieldClasses.inputLabel, isOverDue && 'text-[var(--neutral-700)] line-through')}
                    >
                      {formattedSettlementDate}
                    </div>
                    <div //
                      className={classNames('text-[14px] font-[400] leading-[20px] tracking-[0.25px] text-[var(--neutral-600)]')}
                    >
                      {expectedSettlementDate.settlementDateKind === SettlementDateKindEnum.DateOnly ? 'Proposed Settlement Date' : 'Proposed Settlement Date & Time'}
                    </div>
                  </div>
                  {expectedSettlementDate.settlementDateKind === SettlementDateKindEnum.DateAndTime && (
                    <ToggleCheckbox
                      disabled={isOverDue || isSubmitting}
                      checked={values.hasAcceptedSettlementDate}
                      label={values.hasAcceptedSettlementDate ? 'Accepted' : 'Not Accepted'}
                      title={values.hasAcceptedSettlementDate ? 'Click to reject proposed date' : 'Click to accept proposed date'}
                      onChange={checked => {
                        setFieldTouched(fieldName('hasAcceptedSettlementDate'), true, false);
                        setFieldValue(fieldName('hasAcceptedSettlementDate'), checked);
                      }}
                    />
                  )}
                </div>
              )}

              <Field //
                component={InputField}
                classes={fieldClasses}
                name={fieldName('reference')}
                label="Workspace Reference"
                fullWidth
                disabled={isSubmitting}
                placeholder="Reference"
              />
              <Typography className={inputLabelClassName}>I agree that</Typography>
              <Field //
                label={
                  <Typography variant="body2" className="ml-[8px] mt-[4px] text-[var(--neutral-600)]" style={{ fontFeatureSettings: '"clig" off, "liga" off' }}>
                    I am authorised to participate in the workspace in relation to the transaction involving this land title.
                  </Typography>
                }
                aria-describedby="agreement checkbox"
                name={fieldName('hasAgreed')}
                classes={{
                  root: 'ml-[6px]',
                  helperTextError: 'invisible'
                }}
                CheckboxProps={{
                  classes: {
                    root: 'mt-[-8px]'
                  }
                }}
                component={CheckboxField}
              />
            </div>
            <div className="flex justify-center gap-[8px]">
              <Button
                //
                className="rounded-[34px] border-[2px]  px-[24px] py-[6px] "
                disabled={isSubmitting}
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>

              <Button
                //
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid}
                type="button"
                variant="contained"
                color="primary"
                className="whitespace-nowrap rounded-[34px] px-[24px] py-[6px]"
                onClick={() => {
                  submitForm();
                  Logger.capturePageAction(PageActionEnum.FeatureTracking, {
                    feature: 'click-join-button',
                    logGroupId: 'search-and-join-workspace',
                    role: values.role,
                    workspaceId: values.workspaceId,
                    groupId: values.groupId
                  });
                }}
              >
                Join
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(JoinWorkspaceForm);

import { LookupEnumModel } from '@sympli/ui-framework/models';

export const FILE_DESCRIPTION_LOOKUP_OPTIONS: LookupEnumModel<string>[] = [
  {
    id: 'Bank Guarantee',
    name: 'Bank Guarantee'
  },
  {
    id: 'Contract of Sale',
    name: 'Contract of Sale'
  },
  {
    id: 'Court Order',
    name: 'Court Order'
  },
  {
    id: 'Death Certificate',
    name: 'Death Certificate'
  },
  {
    id: 'Deposit Bond',
    name: 'Deposit Bond'
  },
  {
    id: 'Discharge Authority',
    name: 'Discharge Authority'
  },
  {
    id: 'Rate Notice',
    name: 'Rate Notice'
  },
  {
    id: 'PPSR',
    name: 'PPSR'
  },
  {
    id: 'Title Search',
    name: 'Title Search'
  },
  {
    id: 'Other',
    name: 'Other'
  }
];

export const IS_SHARED_OPTIONS: LookupEnumModel<boolean>[] = [
  { id: false, name: 'Do not share with other participants' },
  { id: true, name: 'Share with participants' }
];

import { createAction, createAsyncAction } from 'typesafe-actions';

import { DateValueMapModel } from './models';
import { SettlementDateDetailsModel } from './reducers/settlementDetail';

export interface FetchCalendarItemsApiRequest {
  jurisdiction: string;
  calendarYearAndMonth: string;
}

export const actionFetchCalendarItems = createAsyncAction(
  //
  'FETCH_CALENDAR_ITEM',
  'FETCH_CALENDAR_ITEM_SUCCESS',
  'FETCH_CALENDAR_ITEM_ERROR'
)<
  //
  FetchCalendarItemsApiRequest,
  { calendarItems: DateValueMapModel },
  { error: Error }
>();

export interface FetchSettlementDetailsApiRequest {
  workspaceId: string;
  participantId: string;
}

export const actionFetchSettlementDetails = createAsyncAction(
  //
  'FETCH_SETTLEMENT_DETAILS',
  'FETCH_SETTLEMENT_DETAILS_SUCCESS',
  'FETCH_SETTLEMENT_DETAILS_ERROR'
)<
  //
  FetchSettlementDetailsApiRequest,
  { data: SettlementDateDetailsModel },
  { error: Error }
>();

export const actionSetEditStatus = createAction(
  //
  'SET_EDIT_STATUS'
)<boolean>();

export const actionSetCalendarMonth = createAction(
  //
  'SET_CALENDAR_MONTH'
)<string>();

export interface SettlementApprovalModel {
  workspaceId: string;
  participantId?: string;
  settlementDateProposalId?: string;
  settlementDate: string;
}

export interface SettlementApprovalRequestModel {
  workspaceId: string;
  participantId: string;
  settlementDateProposalId?: string;
  settlementDate: string;
}
export const actionPostSettlementApproval = createAsyncAction(
  //
  'POST_SETTLEMENT_APPROVAL',
  'POST_SETTLEMENT_APPROVAL_SUCCESS',
  'POST_SETTLEMENT_APPROVAL_ERROR'
)<
  //
  SettlementApprovalRequestModel,
  { data: SettlementApprovalModel },
  { error: Error }
>();

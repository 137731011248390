import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { JurisdictionsEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { useSafeDispatch } from 'src/hooks';
import { TitleReferenceEntityModel } from 'src/models';
import AddTitleDialog from './AddTitleDialog';

interface Props {
  workspaceId: string;
  participantId: string;
  workspaceType: WorkspaceTypeEnum;
  jurisdiction: JurisdictionsEnum;
  titleReferences: TitleReferenceEntityModel[];
  setDialogIsOpen(isOpen: boolean): void;
  isOpen: boolean;
}

export default function AddTitleDialogContainer(props: Props) {
  const navigate = useNavigate();
  const { setDialogIsOpen, workspaceId, participantId } = props;
  const handleCancel = React.useCallback(() => setDialogIsOpen(false), [setDialogIsOpen]);
  const { items: workspaceDocuments } = useWorkspaceDocuments(workspaceId, participantId);
  const allDocuments = workspaceDocuments.flatMap(d => d.supportingDocuments ?? []).concat(workspaceDocuments);
  const documentTypes = allDocuments.map(x => x.documentIdentifier.id);
  const dispatch = useSafeDispatch(useDispatch());
  return (
    <AddTitleDialog //
      {...props}
      handleCancel={handleCancel}
      dispatch={dispatch}
      existingDocumentTypes={documentTypes}
      navigate={navigate}
    />
  );
}

import React, { useState } from 'react';

import classNames from 'classnames';
import { FormikProps, FormikValues, getIn, setIn, useField } from 'formik';
import _get from 'lodash-es/get';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { BankAccountTypeEnum, DirectionTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import Input from '@sympli/ui-framework/components/form/base-components/input';
import CheckboxField from '@sympli/ui-framework/components/formik/checkbox-field';
import CurrencyInputField from '@sympli/ui-framework/components/formik/currency-input-field';
import Field from '@sympli/ui-framework/components/formik/field';
import InputField from '@sympli/ui-framework/components/formik/input-field';
import SelectField from '@sympli/ui-framework/components/formik/select-field';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import { IconInfoCircle } from '@sympli/ui-framework/icons';
import { LookupEnumModel } from '@sympli/ui-framework/models';
import Logger, { BusinessLogicError } from '@sympli/ui-logger';

import Tooltip from 'src/components/tooltip';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createModelKeyAppender, modelKey, resolveSelectPlaceholder } from 'src/utils/formUtils';
import { BankDetailsModel, DirectionsTrustAccountModel, DirectionTrustAccountLookupItemModel, SourceFundFormikModel, TrustAccountMap } from '../../../../models';
import { useDirectionsCategories } from '../../../../reducers/categories';
import { isLoanAdvance, LOAN_ADVANCE_CATEGORY, LOAN_ADVANCE_RANGE_CATEGORY } from '../../forms/income-mortgage-directions/helper';
import { displayToastMessage, getKeyValueMatchingNumberInObject, saveFSSLineItem } from '../../helper';
import { useStyles } from './styles';

export interface Props<T extends FormikValues> {
  workspaceId: string;
  participantId: string;
  formFieldName: string;
  formikProps: FormikProps<T>;
  currentParticipant: WorkspaceParticipantApiResponse;
  trustAccountOptions: Array<DirectionTrustAccountLookupItemModel>;
  trustAccountMap: TrustAccountMap;
  canSetAutoBalanceForIM?: boolean;
  autoBalanceDefaultValue?: boolean;
  isFssLineItemSaveEnabled?: boolean;
  dispatch: SafeDispatch<Action>;
  trustAccountBankDetailMap: TrustAccountMap;
}

const trustAccFieldName = createModelKeyAppender<BankDetailsModel>(modelKey<DirectionsTrustAccountModel>()('bankDetails'));

function SourceFundEditor<T extends FormikValues>({
  //
  workspaceId,
  participantId,
  trustAccountOptions,
  trustAccountMap,
  formFieldName,
  formikProps: { values, setFieldValue, setValues, touched },
  canSetAutoBalanceForIM,
  autoBalanceDefaultValue,
  isFssLineItemSaveEnabled,
  dispatch,
  trustAccountBankDetailMap
}: Props<T>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const { status, detail: directionsCategoriesDetail, error } = useDirectionsCategories(workspaceId, participantId);
  const trustAccountLoanAdvanceOptions = React.useMemo(() => trustAccountOptions.filter(option => option.canUseAsLoanAdvance), [trustAccountOptions]);
  const [, { value: details, error: formError }] = useField<SourceFundFormikModel>(formFieldName);
  const fieldName = React.useMemo(() => createModelKeyAppender<SourceFundFormikModel>(formFieldName), [formFieldName]);
  const filteredTrustAccountOptions: DirectionTrustAccountLookupItemModel[] = React.useMemo(
    () => (isLoanAdvance(details.category) ? trustAccountLoanAdvanceOptions : trustAccountOptions),
    [trustAccountLoanAdvanceOptions, trustAccountOptions, details.category]
  );
  const filteredSourceFundCategories: LookupEnumModel<string, string>[] | undefined = React.useMemo(
    () =>
      details.isAutoBalancingSurplusForIMAllowed
        ? directionsCategoriesDetail?.sourceFundsCategories.filter(x => x.name !== LOAN_ADVANCE_CATEGORY)
        : directionsCategoriesDetail?.sourceFundsCategories.filter(x => x.name !== LOAN_ADVANCE_RANGE_CATEGORY),
    [details.isAutoBalancingSurplusForIMAllowed, directionsCategoriesDetail?.sourceFundsCategories]
  );
  const isAutoBalanceVisible: boolean = React.useMemo(() => !!canSetAutoBalanceForIM && isLoanAdvance(details.category), [canSetAutoBalanceForIM, details.category]);

  const handleSaveSourceFund = React.useCallback(async () => {
    if (isFssLineItemSaveEnabled && getIn(touched, formFieldName)) {
      setIsLoading(true);

      const distribution: SourceFundFormikModel = _get(values, formFieldName);

      const { category } = distribution;

      let res: boolean;
      if (isLoanAdvance(category)) {
        // This is loan advance
        const { amount, trustAccountId, reference, isAutoBalancingSurplusForIMAllowed } = distribution;
        res = await saveFSSLineItem(
          {
            workspaceId,
            participantId,
            requestPayload: {
              type: DirectionTypeEnum.LoanAdvance,
              loanAdvance: {
                amount,
                accountId: trustAccountId,
                reference,
                isAutoBalancingSurplusForIMAllowed
              }
            }
          },
          dispatch,
          getKeyValueMatchingNumberInObject(values, 'isEditorOpen', true) === 1
        );
      } else {
        const { trustAccountId, amount, reference, categoryOther, id } = distribution;
        const trustAccount = trustAccountBankDetailMap[trustAccountId];
        res = await saveFSSLineItem(
          {
            workspaceId,
            participantId,
            requestPayload: {
              type: DirectionTypeEnum.SourceFund,
              sourceFund: {
                trustAccountId,
                amount: Number(amount),
                reference: trustAccount && trustAccount.bankAccountType === BankAccountTypeEnum.SympliSourceAccount ? trustAccount.bankDetails.accountNumber : reference,
                category,
                categoryOther,
                id
              }
            }
          },
          dispatch,
          getKeyValueMatchingNumberInObject(values, 'isEditorOpen', true) === 1
        );
      }

      setIsLoading(false);
      displayToastMessage(res, dispatch);
      if (res) {
        setFieldValue(fieldName('isEditorOpen'), false);
      }
    } else {
      // * We do not update bank detail here because we do not care
      setFieldValue(fieldName('isEditorOpen'), false);
    }
  }, [
    //
    setFieldValue,
    fieldName,
    isFssLineItemSaveEnabled,
    workspaceId,
    participantId,
    formFieldName,
    values,
    dispatch,
    trustAccountBankDetailMap,
    touched
  ]);

  const handleOnCategoryChange = (_: React.ChangeEvent<HTMLInputElement>, resolvedValue: string) => {
    const trustAccountId = details.trustAccountId;
    const isSwitchToLoanAdvance = isLoanAdvance(resolvedValue);
    const shouldEnableAutoBalance = isSwitchToLoanAdvance && !!canSetAutoBalanceForIM && !!autoBalanceDefaultValue;
    const notContainTrustAccount = isSwitchToLoanAdvance && trustAccountId && !trustAccountLoanAdvanceOptions.some(option => option.id === trustAccountId);

    setValues(updatedValues => {
      let newValues = setIn(updatedValues, fieldName('isAutoBalancingSurplusForIMAllowed'), canSetAutoBalanceForIM ? shouldEnableAutoBalance : undefined);

      if (isSwitchToLoanAdvance) {
        newValues = setIn(newValues, fieldName('displayLoanAdvanceAmount'), details.amount);
      }

      if (notContainTrustAccount) {
        newValues = setIn(newValues, fieldName('trustAccountId'), '');
      }

      if (shouldEnableAutoBalance) {
        newValues = setIn(newValues, fieldName('category'), LOAN_ADVANCE_RANGE_CATEGORY);
      }

      return newValues;
    });
  };

  const handleOnAutoBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue(fieldName('category'), checked ? LOAN_ADVANCE_RANGE_CATEGORY : LOAN_ADVANCE_CATEGORY);
  };

  const handleOnAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isLoanAdvance(details.category)) setFieldValue(fieldName('displayLoanAdvanceAmount'), value);
  };

  if (details == null) {
    const scope = Logger.scopeWithCustomAttributes({
      values
    });

    Logger.captureException(new BusinessLogicError(`Empty source fund details of ${formFieldName}`), scope);

    return null;
  }

  if (status === 'pending' || status === 'idle') {
    return <BlockLoader />;
  }

  if (status === 'rejected') {
    return error;
  }

  if (!filteredSourceFundCategories) {
    return null;
  }

  const selectedTrustAccountBankDetail = trustAccountMap[details.trustAccountId];

  // * Only show reference field if selected source fund account is NOT Sympli source account
  // * Use bank account number as reference for Sympli source account (See onPreSubmit function)
  const showReferenceField = selectedTrustAccountBankDetail == null || selectedTrustAccountBankDetail.bankAccountType !== BankAccountTypeEnum.SympliSourceAccount;

  return (
    <div className={classes.editorBox}>
      <FlexLayout flexDirection="column">
        <FlexLayout flexDirection="row">
          <Field
            label="Category"
            component={SelectField}
            placeholder={resolveSelectPlaceholder(true)}
            name={fieldName('category')}
            className={classes.category}
            options={filteredSourceFundCategories}
            onChange={handleOnCategoryChange}
            readOnly={filteredSourceFundCategories.length === 1}
          />
          {details.category === 'Other' && <Field label="Category name" component={InputField} className={classes.categoryName} name={fieldName('categoryOther')} />}
        </FlexLayout>
      </FlexLayout>
      <Divider className={classes.divider} />
      <FlexLayout alignItems="center">
        <Field
          readOnly={Boolean(filteredTrustAccountOptions.length === 1 && details.trustAccountId)}
          label="Account description"
          component={SelectField}
          placeholder={resolveSelectPlaceholder(true)}
          name={fieldName('trustAccountId')}
          options={filteredTrustAccountOptions}
          className={classNames(classes.growField, classes.marginRight)}
        />
        {showReferenceField && (
          <Field label="Reference (optional)" name={fieldName('reference')} component={InputField} className={classNames(classes.largeField, classes.marginRight)} />
        )}
      </FlexLayout>
      <Divider className={classes.divider} />
      <FlexLayout alignItems="center">
        <Input //
          readOnly
          label="Account name"
          // the name here is not real form mapping
          name={`selectedTrustAccount.${trustAccFieldName('accountName')}`} // danger:disable
          value={selectedTrustAccountBankDetail?.bankDetails.accountName || ''}
          className={classNames(classes.growField, classes.marginRight)}
        />
        <Field //
          label="Amount ($)"
          name={fieldName('amount')}
          onChange={handleOnAmountChange}
          component={CurrencyInputField}
          className={classNames(classes.amountField, classes.labelCenter, classes.marginRight)}
          data-testid="sourceFund-editor-amount"
        />
      </FlexLayout>
      <FlexLayout justifyContent="flex-end" fullWidth alignItems="center">
        {isAutoBalanceVisible && (
          <>
            <Field //
              label="Enable auto balance"
              name={fieldName('isAutoBalancingSurplusForIMAllowed')}
              component={CheckboxField}
              className={classes.autoBalanceCheckBox}
              onChange={handleOnAutoBalanceChange}
            />
            <Tooltip title={renderToolTipContent()} placement="top">
              <IconInfoCircle className={classes.iconInfo} />
            </Tooltip>
          </>
        )}
        <SympliButton
          variant="contained"
          color="primary"
          isLoading={isLoading}
          disabled={!!formError || isLoading}
          className={classNames(classes.saveButton, classes.marginRight)}
          onClick={handleSaveSourceFund}
        >
          Update
        </SympliButton>
      </FlexLayout>
    </div>
  );

  function renderToolTipContent() {
    return (
      <Typography>
        If surplus funds are in the workspace, the system will automatically reduce the loan advance amount when all parties approve their Financial Settlement Schedule. The
        reduced loan advance amount will equal the total payments amount in the workspace.
      </Typography>
    );
  }
}

export default React.memo(SourceFundEditor);

import * as yup from 'yup';

import { DistributionFormikModel, TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { getDirectionsYupObject, getSourceFundsYupObject } from '../../sharedValidationSchema';

// yup number use parseFloat() to parse the number, which may not be the validation rules we want
// TODO extra validation rule, need at least one (NEW) distribution OR at least one (NEW) source fund
export function getValidationSchema(trustAccountBankDetailMap: TrustAccountMap, workspaceId: string) {
  return yup.object({
    distributions: yup.array().of(
      yup.lazy(value => {
        const { isLocked } = value as DistributionFormikModel;

        if (isLocked) {
          return yup.object({});
        } else {
          return yup.object(getDirectionsYupObject(workspaceId));
        }
      })
    ),
    sourceFunds: yup.array().of(yup.object(getSourceFundsYupObject(trustAccountBankDetailMap)))
  });
}

import * as React from 'react';

import classNames from 'classnames';
import pluralize from 'pluralize';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { IconTasks } from '@sympli/ui-framework/icons';

import { LineLoader } from 'src/components/loaders';
import LodgementDateTime from 'src/components/workspace-status-rebuild/lodgement-date-time';
import MatterAndTasks from 'src/components/workspace-status-rebuild/matter-and-tasks';
import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { SettlementDateTimeModel } from 'src/models';
import { dateTimeLine } from 'src/utils/formatters';
import styles, { ClassKeys } from './styles';

export interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  numberOfTasksForCurrentParticipant?: number;
  error?: string;
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: WorkspaceTypeEnum;
  matter: string;
  isLocked?: boolean;
  isMatterAndTasksSectionSelected?: boolean;

  // TODO why is this getting only expectedLodgementDate and not settlementDate ?
  expectedLodgementDate?: SettlementDateTimeModel;
  lineLoaderBackgroundColor?: 'white' | 'grey';
  hideMatterIdOnAwaitingTask?: boolean;
  onUpdateLodgementDate?(): void;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  openDatepicker: boolean;
}
class WorkspaceTasksBox extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    openDatepicker: false
  };

  public static defaultProps: Partial<Props> = {
    lineLoaderBackgroundColor: 'white',
    hideMatterIdOnAwaitingTask: false
  };

  private get isLoading() {
    const { error, isLoading } = this.props;
    return !error && isLoading;
  }

  private renderLoader() {
    return (
      <>
        <LineLoader color={this.props.lineLoaderBackgroundColor} variant="medium" icon style={{ marginBottom: 0, marginTop: 12 }} />
        <LineLoader color={this.props.lineLoaderBackgroundColor} widthPercent={70} variant="small" marginLeft={20} style={{ marginBottom: 6, marginTop: 4 }} />
      </>
    );
  }

  private renderError() {
    const { classes, error, matter } = this.props;

    return (
      <FormHelperText role="alert" data-error-name={matter} className={classes.errorMessage} error>
        {error}
      </FormHelperText>
    );
  }

  render() {
    const {
      //
      error,
      numberOfTasksForCurrentParticipant,
      expectedLodgementDate
    } = this.props;

    if (this.isLoading) {
      return this.renderLoader();
    }

    if (error) {
      return this.renderError();
    }

    const icon = this.resolveIcon(expectedLodgementDate);

    const desc = this.resolveDescription(numberOfTasksForCurrentParticipant, expectedLodgementDate);
    // TODO check this logic, why do we receive only sometimes and it's not reliable to use WorkspaceType here
    return expectedLodgementDate ? this.renderLodgementOnlyTaskBox(icon, desc) : this.renderFinancialTaskBox(icon, desc);
  }

  private renderLodgementOnlyTaskBox(icon: JSX.Element, description: string | JSX.Element) {
    const {
      classes,
      matter,
      numberOfTasksForCurrentParticipant,
      workspaceId,
      participantId,
      isLocked,
      expectedLodgementDate,
      isMatterAndTasksSectionSelected,
      onUpdateLodgementDate
    } = this.props;
    const matterLink = resolveWorkspaceDetailLink({ workspaceId, participantId });
    const { businessDaysTill, userLocalTime } = expectedLodgementDate!;
    const dateText = dateTimeLine(userLocalTime, DateFormatEnum.AUSDATETEXT);

    return (
      <List className={classNames(classes.root)} disablePadding={true}>
        <FlexLayout justifyContent="flex-start" flexDirection="column" className={classNames(classes.item)}>
          <MatterAndTasks //
            matter={matter}
            matterLink={matterLink}
            numberOfTasks={numberOfTasksForCurrentParticipant}
            selected={isMatterAndTasksSectionSelected}
          />
        </FlexLayout>
        <LodgementDateTime
          workspaceId={workspaceId}
          participantId={participantId}
          lodgementDate={new Date(dateTimeLine(userLocalTime, DateFormatEnum.DATETIME))} // Need to format here to strip out the timezone portion so new Date does not convert to local time again
          open={this.state.openDatepicker}
          onOpenOrClose={this.onOpenOrClose}
          onUpdate={onUpdateLodgementDate}
          lodgementDateTime={dateText}
          businessDaysTill={businessDaysTill}
          onclick={this.onClick}
          isLocked={isLocked}
        />
      </List>
    );
  }

  private resolveIcon = <T extends { businessDaysTill: number }>(expectedLodgementDate?: T) => {
    const { classes } = this.props;
    return expectedLodgementDate && expectedLodgementDate.businessDaysTill < 0 ? (
      <IconTasks className={classes.sectionIcon} color="error" />
    ) : (
      <IconTasks className={classes.sectionIcon} />
    );
  };

  private resolveDescription = <T extends { businessDaysTill: number; userLocalTime: string | Date }>(count?: number, expectedLodgementDate?: T, isDescriptionLink?: boolean) => {
    if (count === undefined) return '';

    if (expectedLodgementDate) {
      const { userLocalTime, businessDaysTill } = expectedLodgementDate;
      const dateText = dateTimeLine(userLocalTime, DateFormatEnum.AUSDATETEXT);
      if (businessDaysTill < 0) {
        return count > 0 ? `${dateText}, ${count} overdue ${pluralize('task', count)}` : dateText;
      } else {
        return count > 0 ? `${dateText}, ${count} ${pluralize('task', count)} awaiting you` : dateText;
      }
    }

    if (count > 0) {
      return isDescriptionLink ? (
        <>
          <span className={this.props.classes.linkDescription}>
            {count} {pluralize('task', count)}
          </span>
          <span> awaiting you</span>
        </>
      ) : (
        `${count} ${pluralize('task', count)} awaiting you`
      );
    }

    return '';
  };

  private resolveLodgementOnlyTitle = (difference: number) => {
    if (difference > 0) {
      return `${difference} ${pluralize('day', difference)} until lodgement`;
    } else if (difference < 0) {
      const absDifference = Math.abs(difference);
      return `${absDifference} ${pluralize('day', absDifference)} after expected lodgement`;
    } else {
      return 'Lodgement due today';
    }
  };

  private renderFinancialTaskBox(icon: JSX.Element, description: string | JSX.Element) {
    const { workspaceId, participantId, matter, isMatterAndTasksSectionSelected, numberOfTasksForCurrentParticipant = 0 } = this.props;
    const matterLink = resolveWorkspaceDetailLink({ workspaceId, participantId });
    return (
      <MatterAndTasks //
        matter={matter}
        matterLink={matterLink}
        numberOfTasks={numberOfTasksForCurrentParticipant}
        selected={isMatterAndTasksSectionSelected}
      />
    );
  }

  private onOpenOrClose = (open: boolean) => this.setState({ openDatepicker: open });

  private onClick = () =>
    this.setState(prevState => ({
      openDatepicker: !prevState.openDatepicker
    }));
}

export default withStyles(styles)(WorkspaceTasksBox);

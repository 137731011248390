import * as React from 'react';

import classNames from 'classnames';
import List from '@mui/material/List';

import { JurisdictionsEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import ButtonLink from '@sympli/ui-framework/components/button-link';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import ListItemLink from 'src/components/list-item-link';
import LineLoader from 'src/components/loaders/line-loader';
import IconTypography from 'src/components/workspace-status-rebuild/icon-typography';
import FeatureToggle from 'src/containers/shared/auth/profile-feature-toggle';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import DocumentItemBase from 'src/containers/shared/document-list/components/document-item-base';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import { resolveTitleDetailLink } from 'src/containers/workspace/titles/helper';
import { TitleReferenceEntityModel } from 'src/models';
import AddTitleDialog from 'src/pages/workspace/shared/workspace-detail-box/components/add-title-dialog';
import { titleAddress } from 'src/utils/formatters';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';
import { useStyles } from './styles';

export type TitleAndAddressesProps = {
  isLoading: boolean;
  workspaceId: string;
  participantId: string;
  // basic workspace info
  workspaceType: WorkspaceTypeEnum;
  jurisdictionId?: JurisdictionsEnum;
  // full workspace info
  propertyAddress?: string;
  titleReferences?: TitleReferenceEntityModel[];
  workspaceStatusId: WorkspaceStatusEnum;
  selectedTitleId?: string;
  loaderBackgroundColor: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  additionalTitlesEnabled?: boolean;
  workspaceIsLocked: boolean;
  linkedWorkspaces?: LinkedWorkspaceApiResponseModel[];
  hideAddTitleButton?: boolean;
};

export type Props = TitleAndAddressesProps;

function TitleAndAddresses(props: Props) {
  const {
    //
    isLoading,
    workspaceId,
    workspaceType,
    participantId,
    jurisdictionId,
    titleReferences = [],
    propertyAddress,
    selectedTitleId,
    workspaceStatusId,
    additionalTitlesEnabled,
    workspaceIsLocked,
    linkedWorkspaces,
    loaderBackgroundColor,
    hideAddTitleButton = false
  } = props;
  const classes = useStyles();

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  if (titleReferences.length === 0) {
    return null;
  }

  if (isLoading) {
    return (
      <>
        <LineLoader data-part="title-and-addresses" color={loaderBackgroundColor} variant="medium" icon widthPercent={35} />
        <LineLoader data-part="title-and-addresses" color={loaderBackgroundColor} variant="small" widthPercent={60} marginLeft={20} style={{ marginBottom: 8 }} />
      </>
    );
  }

  // WEB-21114: Add title button should be hidden in a linked workspace (that is not the hero workspace)
  const showAddTitleButton = (linkedWorkspaces?.length ? linkedWorkspaces[linkedWorkspaces?.length - 1].workspaceId === workspaceId : true) && !hideAddTitleButton;
  const notAccessible = workspaceStatusId === WorkspaceStatusEnum.Abandoned || workspaceStatusId === WorkspaceStatusEnum.Withdrawn;
  const disableAddTitleButton = workspaceIsLocked || titleReferences.length === 20 || notAccessible;

  function renderTitleAndAddressForNewStatuses101() {
    return (
      <div className={additionalTitlesEnabled ? classes.sectionSpacingBottom : undefined} data-testid="title-address">
        <List //
          component="nav"
          className={classNames(classes.titleList, classes.sectionSpacingTop)}
          disablePadding
          aria-label="Titles"
        >
          {titleReferences.map((item: TitleReferenceEntityModel, i) => {
            const desc = (propertyAddress ? titleAddress(propertyAddress, jurisdictionId) : titleAddress(item.address, jurisdictionId)) || '';
            const titleLink = notAccessible
              ? undefined
              : resolveTitleDetailLink({
                  //
                  workspaceId,
                  participantId,
                  titleReference: item.reference
                });

            const selected = selectedTitleId === item.reference;

            return (
              <ListItemLink //
                key={item.reference + i}
                linkTo={titleLink}
                className={classes.titleItems}
                selected={selected}
              >
                <DocumentItemBase
                  title={item.reference}
                  titleAriaLabel="Title reference"
                  description={desc}
                  selected={selected}
                  classes={{ title: classes.smallRow }}
                  underline={!!titleLink}
                  enableCopyToClipboardButton
                />
                {item.hasChanges ? <IconTypography text="Positive tac" /> : null}
              </ListItemLink>
            );
          })}
        </List>
        {showAddTitleButton && (
          <FeatureToggle featureToggleType={FeatureToggleEnum.additionalTitlesEnabled}>
            <AddTitleDialog //
              setDialogIsOpen={setDialogIsOpen}
              isOpen={dialogIsOpen}
              workspaceId={workspaceId}
              participantId={participantId}
              jurisdiction={jurisdictionId!}
              workspaceType={workspaceType}
              titleReferences={titleReferences}
            />
            <FlexLayout flexDirection="row" alignItems="center" className={classes.sectionSpacingBottom}>
              <FlexLayout alignItems="center">
                <ButtonLink onClick={() => setDialogIsOpen(true)} disabled={disableAddTitleButton} className={classes.buttonLink}>
                  Add
                </ButtonLink>
              </FlexLayout>
            </FlexLayout>
            <div className={classNames(classes.borderBottom)} />
          </FeatureToggle>
        )}
      </div>
    );
  }

  return renderTitleAndAddressForNewStatuses101();
}

export default React.memo(TitleAndAddresses);

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272 } from 'src/components/layout/constants';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    inputContainer: {
      width: WIDTH_PANEL_SIDEBAR_CONTENT_INNER_272,
      padding: '31px 40px 0px 40px',
      backgroundColor: theme.palette.grey[50]
    },
    marginBottom: {
      marginBottom: 30
    },
    paginationPanel: {
      height: 36,
      backgroundColor: '#4D5258',
      color: theme.palette.primary.contrastText
    },
    paginationButton: {
      height: 36,
      width: 36
    },
    flexGrow: { flexGrow: 1 },
    proposedDateTimeContainer: {
      paddingBottom: 20,
      marginBottom: 30,
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    proposedDateTime: {
      color: '#F58923'
    },
    yearMonthDisplay: {
      // marginLeft: '25%'
    },
    today: {
      textDecoration: 'underline',
      color: theme.palette.common.white
    },
    calendarContainer: {
      width: 415,
      marginRight: 32,
      position: 'relative'
    },
    helperTextError: {
      position: 'absolute',
      marginTop: 4,
      top: '100%'
    },
    topPanel: {
      marginLeft: -18,
      padding: '0 40px 0 60px',
      height: 60,
      background: theme.palette.grey[50],
      flexShrink: 0,
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    topPanelV2: {
      height: 60,
      background: theme.palette.grey[50],
      flexShrink: 0,
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  });

export type ClassKeys = typeof styles;

export default styles;

import * as React from 'react';

import { WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import StatusProgress from 'src/components/workspace-status-rebuild/status-progress';
import { financialWorkspaceStatusTooltipContent } from 'src/components/workspace-status-rebuild/status-progress/mappings/financialWorkspaceStatusTooltipContent';
import { resolveFinancialWorkspaceStatus, workspaceStatusSteps } from 'src/utils/status-mapping/workspaceStatusHelper';
import { useStyles } from './styles';

interface Props {
  workspaceStatusId: WorkspaceStatusEnum;
  isLocked?: boolean;
}

function FinancialWorkspaceStatusBadgeNew({ workspaceStatusId }: Props) {
  const classes = useStyles();

  const wsStatusInfo = resolveFinancialWorkspaceStatus(workspaceStatusId);
  if (!wsStatusInfo) {
    return null;
  }
  const { variant, title } = wsStatusInfo;

  const currentStep = variant === 'success' || variant === 'failure' || variant === 'warning' ? workspaceStatusSteps.indexOf(title) + 1 : -1;

  return (
    <div className={classes.FinancialWorkspaceStatusBadgeNew}>
      <StatusProgress
        currentStep={currentStep}
        steps={workspaceStatusSteps}
        variant={variant}
        tooltipTitle={financialWorkspaceStatusTooltipContent[variant][title]}
        customizedStep={currentStep < 0 ? title : undefined}
      />
    </div>
  );
}

export default FinancialWorkspaceStatusBadgeNew;

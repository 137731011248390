import { AppEnvironmentEnum } from './models';

// By default, we point to dev env for localhost
// Update this variable if you want to switch other envs
// eg: dev1, sit1, iwt, feat1, pp
export const LOCAL_ENV = 'dev1';

// Update this variable if you want to point to your local api
// eg: 'http://localhost:4000'
const TARGET_MACHINE = 'https://api.[0].sympli.com.au';

// Update this variable if you want to point to your local realtime server
// eg: 'http://localhost:3100'
const SOCKET_SERVER_URL = 'https://api.[0].sympli.com.au';

const TARGET_API = '/gateway/api/1';
const TARGET_API_V2 = '/gateway/api/2';

const STORE_NAMESPACE_PREFIX = 'tickle-ui';

const BASENAME = '/app';

const hostname = window.location.hostname.toLowerCase();
const APP_ENV: AppEnvironmentEnum =
  hostname === AppEnvironmentEnum.local ? AppEnvironmentEnum.local : ((hostname.replace('sympli.com.au', '').split('.')[1] || 'production') as AppEnvironmentEnum);

export const CONFIG_PATH = APP_ENV === 'localhost' ? '/configs/config.local.json' : '/configs/config.json';

export interface RunTimeEnv {
  SOCKET_SERVER_URL: string;
  TARGET_MACHINE: string;
  APP_ENV: AppEnvironmentEnum;
  STORE_NAMESPACE: string;
  TARGET_API: string;
  BASENAME: string;
  TARGET_API_V2: string;
}

export function resolveRunTimeEnv(): RunTimeEnv {
  const STORE_NAMESPACE = `${STORE_NAMESPACE_PREFIX}_${APP_ENV}`;
  // local env
  if (APP_ENV === 'localhost') {
    return {
      SOCKET_SERVER_URL: SOCKET_SERVER_URL.replace('[0].', `${LOCAL_ENV}.`),
      TARGET_MACHINE: TARGET_MACHINE.replace('[0].', `${LOCAL_ENV}.`),
      STORE_NAMESPACE,
      TARGET_API,
      TARGET_API_V2,
      BASENAME,
      APP_ENV
    };
  }

  const isProduction = APP_ENV === 'production';
  const replaceString = isProduction ? '' : `${APP_ENV}.`;

  return {
    SOCKET_SERVER_URL: SOCKET_SERVER_URL.replace('[0].', replaceString),
    TARGET_MACHINE: TARGET_MACHINE.replace('[0].', replaceString),
    STORE_NAMESPACE,
    TARGET_API,
    TARGET_API_V2,
    BASENAME,
    APP_ENV
  };
}

import React from 'react';

import { useDispatch } from 'react-redux';
import { MenuItemProps } from '@mui/material/MenuItem';

import endpoints from '@sympli/api-gateway/endpoints';
import { DocumentPermissionEnum, DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { UnsignWorkspaceDocumentsApiResponse } from '@sympli/api-gateway/models';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import SwitchFormView from 'src/containers/workspace/shared/components/menu-items/switch-form-view';
import UnsignDocument from 'src/containers/workspace/shared/components/menu-items/unsign-document';
import { docUpdateHelper } from 'src/containers/workspace/shared/components/menu-items/unsign-document/helper';
import { WorkflowPanelIssuesContainer } from 'src/containers/workspace/shared/components/workflow-panel/components/workflow-panel-issues';
import { actionToggleCurrentParticipantDocDisplay } from 'src/containers/workspace/shared/detail/actions';
import { useSafeDispatch } from 'src/hooks';
import { DocumentWorkflowPanelProps } from '../components/document-workflow-panel';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from '../models';
import { memoizedWorkflowSteps } from '../steps';

function useWorkflowPanel(
  queryParams: DocumentPageRouteParams,
  workflowStep: DocumentWorkflowStepsEnum,
  detail: DocumentFormAndDetailModel,
  displayDocumentAsPdf: boolean,
  handleStepChange: (_: React.MouseEvent<HTMLButtonElement>, targetStep: DocumentWorkflowStepsEnum) => void
) {
  const { participantId, documentId } = queryParams;

  const dispatch = useSafeDispatch(useDispatch());

  if (!detail) {
    return undefined;
  }

  const handleUnsignClick = () =>
    endpoints
      .unsignMultipleDocuments(queryParams, { documentIds: [documentId] })
      .then((response: UnsignWorkspaceDocumentsApiResponse[]) => {
        docUpdateHelper(dispatch, queryParams, response);
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message: `${detail.documentForm.name} was unsigned.`,
            variant: 'new-success'
          })
        );
      })
      .catch(error => dispatch(actionCreateGlobalErrorMessage(error)));

  const handleViewChange = () => dispatch(actionToggleCurrentParticipantDocDisplay(participantId));

  const renderMenuList = (): React.ReactElement<MenuItemProps>[] => {
    const menuItems: React.ReactElement<MenuItemProps>[] = [];
    const hasPermissionToUnsign = detail.documentPermissions.includes(DocumentPermissionEnum.Unsign);

    if (hasPermissionToUnsign) {
      menuItems.push(<UnsignDocument onClick={handleUnsignClick} />);
    }

    if (!(DocumentTypeIdentifierEnum.LodgementInstructions === detail.documentForm.documentType)) {
      menuItems.push(<SwitchFormView displayDocumentAsPdf={displayDocumentAsPdf} onClick={handleViewChange} />);
    }

    return menuItems;
  };

  const { steps, lastEnabledStep } = memoizedWorkflowSteps(detail);
  // * When editing form, workflow panel is alway 'light'
  const mode = [DocumentWorkflowStepsEnum.Write, DocumentWorkflowStepsEnum.Resolve].includes(workflowStep) || !displayDocumentAsPdf ? 'light' : 'dark';
  const workflowPanelProps: Omit<DocumentWorkflowPanelProps<string, DocumentWorkflowStepsEnum>, 'disableMenu' | 'disableStepper'> = {
    mode,
    steps,
    lastEnabledStep,
    currentStep: workflowStep,
    menuItems: renderMenuList(),
    documentIssues: <WorkflowPanelIssuesContainer buttonTheme={mode} queryParams={queryParams} />,
    onStepClick: handleStepChange
  };

  return workflowPanelProps;
}

export { useWorkflowPanel };

import _groupBy from 'lodash-es/groupBy';
import { createSelector } from 'reselect';

import { WorkspaceDirectionsOverviewApiResponse } from '@sympli/api-gateway/models';

import { allDistributionsParticipantOrderedList } from 'src/containers/shared/document-list/models';

const getDistributionsParticipants = (distributionsParticipants: WorkspaceDirectionsOverviewApiResponse['distributionsParticipants']) => distributionsParticipants;

export const distributionsParticipantOrderedListSelector = createSelector<
  //
  WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'],
  WorkspaceDirectionsOverviewApiResponse['distributionsParticipants'],
  WorkspaceDirectionsOverviewApiResponse['distributionsParticipants']
>(getDistributionsParticipants, distributionsParticipants => {
  const participantsByWorkspaceRoleId = _groupBy(distributionsParticipants, 'participant.workspaceRole.id');
  return allDistributionsParticipantOrderedList.flatMap(roleId => participantsByWorkspaceRoleId[roleId]).filter(Boolean);
});

import * as yup from 'yup';

import { AddressBookEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import { PartyModel } from '@sympli-mfe/document-forms-framework/components/party-form';
import yupResponsibleParticipantId from '@sympli-mfe/document-forms-framework/components/sections/responsible-subscriber/validation-schema';

import { LodgementInstructions2_19_1Model } from './models';
import yupDuplicateHoldingDetail from './sections/duplicate-holding-detail/validationSchema';

// this file was automatically generated from validationSchema.ts.mustache
export default yup
  .object<LodgementInstructions2_19_1Model>({
    partyBook: yup.mixed<PartyModel[]>(),
    addressBook: yup.mixed<AddressBookEntityModel[]>(),
    responsibleParticipantId: yupResponsibleParticipantId,
    duplicateHoldingDetail: yupDuplicateHoldingDetail
  })
  .defined()
  .log();

import React from 'react';

import { DateTimeInfo } from '@sympli/api-gateway/shared';
import { ColumnsModel } from '@sympli/ui-framework/components/table';

import { CardDetail } from 'src/containers/dashboard/components/card';
import { ArchivedInvitationLogsActionEnum } from '../models';
import { useCardDetailStyles } from './styles';

export interface Row {
  inviteLog: number;
  date: DateTimeInfo;
  originalSender?: string;
  from?: string;
  to?: string;
  action: {
    userName?: string;
    type: ArchivedInvitationLogsActionEnum;
  };
  receivedMessage?: string;
  forwardedMessage?: string;
  message?: string;
  declineNote?: string;
}

export interface Props {
  rows: Row[];
  columns: ColumnsModel<Row>;
}

function InvitationsCardDetail({
  //
  rows,
  columns
}: Props) {
  const classes = useCardDetailStyles();

  return (
    <CardDetail //
      columns={columns}
      rows={rows}
      className={classes.root}
    />
  );
}

export default React.memo(InvitationsCardDetail);

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    saveButton: {
      display: 'flex',
      // * ideally same size as small field
      width: 120,
      // to solve the issue that loader expands width
      whiteSpace: 'nowrap',
      marginLeft: 'auto'
    },
    fullWidth: {
      width: '100%'
    },
    labelCenter: {
      '& label': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },
    formGroupFooter: {
      backgroundColor: 'none',
      border: 'none',
      paddingLeft: 0,
      fontSize: 14
    }
  }),
  { name: 'EditBankDetails' }
);

import { DocumentTypeIdentifierEnum, WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LookupEnumModel, LookupItemModel } from '@sympli/ui-framework/models';

export const WORKSPACE_TYPE_OPTIONS: LookupEnumModel<WorkspaceTypeEnum>[] = [
  { id: WorkspaceTypeEnum.FinancialSettlement, name: 'Financial settlement' },
  { id: WorkspaceTypeEnum.Refinance, name: 'Refinance' },
  { id: WorkspaceTypeEnum.RegistrationOnly, name: 'Lodgement only' }
];

// Map Workspace Type
export const workspaceTypeMapping: Partial<Record<WorkspaceTypeEnum, string>> = {
  [WorkspaceTypeEnum.FinancialSettlement]: 'Financial settlement',
  [WorkspaceTypeEnum.Refinance]: 'Refinance',
  [WorkspaceTypeEnum.RegistrationOnly]: 'Standalone'
};

export interface CreateWSDefaultValueModel {
  workspaceType: WorkspaceTypeEnum;
  createdByRoleId: WorkspaceRoleEnum;
}

export interface DocumentRoleMappingLookupModel extends LookupEnumModel<DocumentTypeIdentifierEnum> {
  roleIds: WorkspaceRoleEnum[];
  validationMessage?: string;
}

export interface DocumentRoleMappingResponse {
  documentRoles: Array<{
    //
    documentFormIdentifier: DocumentTypeIdentifierEnum;
    documentDescription: string;
    workspaceRoles: WorkspaceRoleEnum[];
    validationMessage?: string;
  }>;
}

export interface TitleReferenceErrorItem {
  titleReference: string;
  message: string;
}

export interface CreateWorkspaceInvitationRequestItemModel {
  subscriberId?: string;
  roleId: WorkspaceRoleEnum;
  reference?: string;
  groupId?: string;
  elnoId?: string;
  elnoSubscriberId?: string;
  displayName: string;
  invitationDescription?: string;
  abn: string;
}

export interface SharedInitialValues {
  createdByReference: string;
  groupId: string;
  instructingOrganisationSettlementAgentId: string;
}

export type WorkspaceCreationErrorsMap = Record<TitleReferenceErrorItem['titleReference'], TitleReferenceErrorItem['message']>;

export interface AllowedAdditionalParticipantRole {
  dealings: Dealing[];
  noParticipantLimit: boolean;
  numberOfParticipantsAllowed: number;
  workspaceRole: WorkspaceRoleEnum;
  documentType?: DocumentTypeIdentifierEnum;
  compatibleWorkspaceRoles: WorkspaceRoleEnum[];
}

interface Dealing {
  dealingNumber?: string;
  parties: LookupItemModel[];
}

export enum RepresentationSubscriberTypeEnum {
  Other = 0,
  Represented = 1,
  SelfRepresented = 2
}

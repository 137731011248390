import React from 'react';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { PortalTarget } from '@sympli/ui-framework/components/portal';

import LineLoader from 'src/components/loaders/line-loader';
import SettlementDateBoxContainer from 'src/containers/shared/settlement-date-box/SettlementDateBoxContainer';
import { resolveSettlementDateLink } from 'src/containers/workspace/financial/settlement-date/helper';
import { useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { SettlementDateTimeModel } from 'src/models';
import { WorkspaceDetailBoxProps } from '../../WorkspaceDetailBox';

export type SettlementDateBoxContainerProps = {
  // settlementDetailsState loading status
  isLoading: boolean;
  // basic workspace info
  workspaceTypeId?: WorkspaceTypeEnum;
  workspaceStatusId?: WorkspaceStatusEnum;
  settlementDate?: SettlementDateTimeModel;
  isLocked?: boolean;
  // inherited from parent
  workspaceId: string;
  participantId: string;
  disableSettlementDateLink?: boolean;
  // styling
  loaderBackgroundColor: WorkspaceDetailBoxProps['loaderBackgroundColor'];
  // this allows us to inject updated info when user visits settlement date page
  portalIdForSettlementDate?: string;
};

function SettlementDateBoxNewContainer({
  isLoading,
  workspaceTypeId,
  workspaceStatusId,
  settlementDate,
  isLocked,
  workspaceId,
  participantId,
  disableSettlementDateLink,
  loaderBackgroundColor,
  portalIdForSettlementDate
}: React.PropsWithChildren<SettlementDateBoxContainerProps>) {
  // all data needed
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipants = useWorkspaceParticipants(workspaceId);
  const settlementDetailsState = useSettlementDateDetails(workspaceId, participantId);

  if (workspaceParticipants.isLoading || settlementDetailsState.isLoading || workspaceBasicInfoState.isLoading) {
    <>
      <LineLoader color={loaderBackgroundColor} variant="medium" icon style={{ marginBottom: 0, marginTop: 12 }} />
      <LineLoader color={loaderBackgroundColor} widthPercent={70} variant="small" marginLeft={20} style={{ marginBottom: 6, marginTop: 4 }} />
    </>;
  }

  // from basic workspace info
  if (workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly) {
    return null;
  }

  // TODO better error handling when any of three reducer has error
  if (workspaceBasicInfoState.status === 'rejected' || workspaceParticipants.status === 'rejected' || settlementDetailsState.status === 'rejected') {
    return null;
  }

  if (portalIdForSettlementDate) {
    return <PortalTarget id={portalIdForSettlementDate} />;
  }

  const link =
    disableSettlementDateLink || isLocked || workspaceStatusId === WorkspaceStatusEnum.Abandoned || workspaceStatusId === WorkspaceStatusEnum.Withdrawn
      ? undefined
      : resolveSettlementDateLink({ workspaceId, participantId });

  return (
    <SettlementDateBoxContainer //
      // route params
      participantId={participantId}
      // redux data
      settlementDateTime={workspaceBasicInfoState.detail?.settlementDate!}
      workspaceStatusId={workspaceBasicInfoState.detail?.workspaceStatusId!}
      isRollover={workspaceBasicInfoState.detail?.isRollover}
      expectedSettlementDateTime={workspaceBasicInfoState.detail?.expectedSettlementDate}
      //TODO review the data source of this prop is same as settlementDateTime!
      settlementDateTimeData={settlementDate}
      //
      settlementDetailsState={settlementDetailsState}
      workspaceParticipants={workspaceParticipants.items}
      // other
      editLink={link}
    />
  );
}

export default SettlementDateBoxNewContainer;

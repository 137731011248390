import * as React from 'react';

import { defaultMemoize } from 'reselect';

import { DocumentPermissionEnum, DocumentStatusEnum, DocumentWorkflowTypeEnum } from '@sympli/api-gateway/enums';
import { StepObjectModel } from '@sympli/ui-framework/components/workflow-stepper';
import { IconEditDocument, IconEye, IconUsb } from '@sympli/ui-framework/icons';

import { DocumentFormAndDetailModel, DocumentWorkflowStepsEnum, extendedDocumentWorkflowMapping, standardDocumentWorkflowMapping } from './models';

export function resolveWorkflowStep(documentStatusId: DocumentStatusEnum, documentWorkflowType: DocumentWorkflowTypeEnum, documentPermissions: DocumentPermissionEnum[] = []) {
  // WEB-17836
  // Shared documents that involves multiple participants can have mismatching document workflow types.
  if (documentWorkflowType === DocumentWorkflowTypeEnum.Extended) {
    return extendedDocumentWorkflowMapping[documentStatusId];
  }
  // In financial workspaces, Vendor participant cannot edit however must review and sign.
  // If the Vendor participant has Save and approve workflow, they must be redirect to the Write workflow (ViewDocument.tsx)
  if (documentStatusId === DocumentStatusEnum.Reviewing && documentPermissions.length && documentPermissions.every(p => p !== DocumentPermissionEnum.Write)) {
    return DocumentWorkflowStepsEnum.Write;
  }
  return standardDocumentWorkflowMapping[documentStatusId];
}

// same steps are shared between F and LO ws
export const memoizedWorkflowSteps = defaultMemoize(function calculateSteps(documentDetail: DocumentFormAndDetailModel) {
  const canEdit = documentDetail.documentPermissions.includes(DocumentPermissionEnum.Write);
  const lastEnabledStep = resolveWorkflowStep(documentDetail.status, documentDetail.documentWorkflowType, documentDetail.documentPermissions);
  const steps: StepObjectModel<string, DocumentWorkflowStepsEnum>[] =
    documentDetail.status === DocumentStatusEnum.ResolveIssues
      ? [
          {
            icon: null,
            label: canEdit ? 'Issues found' : 'Resolve issues',
            value: DocumentWorkflowStepsEnum.Resolve
          }
        ]
      : ([
          {
            icon: <IconEditDocument />,
            label: 'Edit form',
            value: DocumentWorkflowStepsEnum.Write
          },
          documentDetail.documentWorkflowType === DocumentWorkflowTypeEnum.Extended && {
            icon: <IconEye />,
            label: 'Review',
            value: DocumentWorkflowStepsEnum.Review
          },
          {
            icon: <IconUsb />,
            label: 'Sign',
            value: DocumentWorkflowStepsEnum.Sign
          }
        ].filter(Boolean) as StepObjectModel<string, DocumentWorkflowStepsEnum>[]);

  return {
    steps,
    lastEnabledStep
  };
});

import React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useProfile } from 'src/containers/shared/auth/reducers';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { useFeatureFlag, useRouterParams, useSafeDispatch } from 'src/hooks';
import { useLrsInfo } from 'src/reducers/lrsInfo';
import { actionFetchAllDirections } from '../financial/all-directions/actions';
import { actionFetchDirections, actionFetchDirectionsList } from '../financial/directions/actions';
import { useDirectionsBreakdown } from '../financial/directions/reducers/directionsBreakdown';
import { useDirectionsList } from '../financial/directions/reducers/directionsList';
import { actionOpenLodgementVerificationDialog } from '../shared/components/lodgement-verification-dialog/actions';
import SigningProvider, { OnSignArgs } from '../shared/components/signing-provider';
import { multiSignDocuments, MultiSignedDocumentModel, ToBeMultiSignDocumentsModel } from '../shared/components/signing-provider/helpers';
import { selectWorkspaceFiles } from '../shared/components/workspace-files-container/selector';
import { WorkspaceFilesApiResponse } from '../shared/components/workspace-files/models';
import { actionFetchWorkspaceDocuments } from '../shared/detail/actions';
import { resolveWorkspaceDetailLink } from '../shared/detail/helpers';
import { useWorkspaceBasicInfo } from '../shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from '../shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from '../shared/detail/reducers/workspaceDocuments';
import { useWorkspaceParticipants } from '../shared/detail/reducers/workspaceParticipants';
import { actionFetchDocumentMultiSign } from './actions';
import { multiSign, resolveLineItems, resolveSignDocuments, resolveToBeMultiSignDocumentsModel } from './helpers';
import { FinancialLineItem, SignDocument, SignDocumentsRouteParams, SignDocumentTypeEnum } from './models';
import { useDocumentMultiSign } from './reducer';
import SignDocuments from './SignDocuments';
import { useStyles } from './styles';

const EMPTY_WORKSPACE_FILES: WorkspaceFilesApiResponse[] = [];

function SignDocumentsContainer() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useSafeDispatch(useDispatch());
  const { workspaceId, participantId, returnUrl } = useRouterParams<SignDocumentsRouteParams>();
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const currentParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: participantId,
    workspaceParticipants: workspaceParticipantsState
  });
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const lrsInfo = useLrsInfo(workspaceBasicInfoState.detail?.jurisdictionId);
  const workspaceTypeId: WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const workspaceStatusId: WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const isNonLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly;
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  const isNotAbandoned = Number.isInteger(workspaceStatusId) && ![WorkspaceStatusEnum.Abandoned, WorkspaceStatusEnum.Withdrawn].includes(workspaceStatusId!);
  const [toBeMultiSignDocumentsModel, setToBeMultiSignDocumentsModel] = React.useState<ToBeMultiSignDocumentsModel | undefined>(undefined);
  const [readyToSignCallback, setReadyToSignCallback] = React.useState<(() => void) | undefined>(undefined);
  const { isLoading: isWorkspaceLoading, error: workspaceError } = useWorkspaceDetail(workspaceId, participantId);
  const { items: documents, error: documentsError, isLoading: isDocumentsLoading } = useWorkspaceDocuments(workspaceId, participantId);
  const { detail: directionsDetail, isLoading: isDirectionLoading, error: directionsError } = useDirectionsList(workspaceId, participantId);
  const { detail: directionListDetail, isLoading: isDirectionListLoading, error: directionListError } = useDirectionsBreakdown(workspaceId, participantId);
  const {
    detail: multiSignDetail,
    isLoading: isMultiSignDetailLoading,
    isRefetching: isMultiSignDetailRefetching,
    error: multiSignError
  } = useDocumentMultiSign(workspaceId, participantId);
  const workspaceDocumentState = useWorkspaceDocuments(workspaceId, participantId);
  const isDocumentAttachmentsEnhancementEnabled = useFeatureFlag(FeatureToggleEnum.documentAttachmentsEnhancementEnabled);
  const { data: userData, isLoading: isProfileLoading, error: profileError } = useProfile();
  const [isSigning, setIsSigning] = React.useState(false);
  const [isSignAndLodge, setIsSignAndLodge] = React.useState(false);
  // we need to make sure multiSigning data is always up to date
  const isMultiSignLoading = isMultiSignDetailLoading || isMultiSignDetailRefetching;
  // we don't need to worry about direction error for lodgement only
  const error = profileError || documentsError || multiSignError || ((directionsError || directionListError) && !isLodgementOnly);
  // we don't need to worry about direction loading for lodgement only
  const isLoading = isProfileLoading || isDocumentsLoading || ((isDirectionLoading || isDirectionListLoading) && !isLodgementOnly);

  const documentAttachments = selectWorkspaceFiles({
    workspaceDocuments: workspaceDocumentState.items,
    workspaceFiles: EMPTY_WORKSPACE_FILES,
    participantId,
    isDocumentAttachmentsEnhancementEnabled
  });

  const handleReadyToSign = React.useCallback(
    (readyToSignCallback: () => void) => {
      setReadyToSignCallback(() => readyToSignCallback);
    },
    [setReadyToSignCallback]
  );

  React.useEffect(() => {
    if (readyToSignCallback && toBeMultiSignDocumentsModel) {
      readyToSignCallback();
      setReadyToSignCallback(undefined); // we need to clean up the ready to sign callback to avoid loop
      setIsSigning(true);
    }
  }, [readyToSignCallback, toBeMultiSignDocumentsModel]);

  const handleOnSign = React.useCallback(
    (args: OnSignArgs): Promise<MultiSignedDocumentModel> => {
      return multiSignDocuments({
        ...args,
        ...toBeMultiSignDocumentsModel! // this cant be undefined when onSign is called
      });
    },
    [toBeMultiSignDocumentsModel]
  );

  const onBack = React.useCallback(() => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
      navigate(link);
    }
  }, [navigate, participantId, returnUrl, workspaceId]);

  const handlePostSign = React.useCallback(
    (signedData: MultiSignedDocumentModel) => {
      return multiSign({
        //
        workspaceId,
        participantId,
        isLodgementRequired: isSignAndLodge,
        signedData
      })
        .then(() => {
          batch(() => {
            // update document list
            dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
            // if it is not lodgement only
            if (isNonLodgementOnly) {
              // direction list
              dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
              // directions
              dispatch(actionFetchDirections.request({ workspaceId, participantId }));
              // directions overview
              dispatch(actionFetchAllDirections.request({ workspaceId, participantId }));
            }
            dispatch(
              actionOpenGlobalSimpleNotification({
                variant: 'loading',
                message: `Signing documents. Documents for ${currentParticipant?.reference} are being signed, we'll let you know if there are any problems`,
                autoHideDuration: 5000
              })
            );
          });

          onBack();
        })
        .finally(() => {
          setIsSigning(false);
        });
    },
    [currentParticipant?.reference, dispatch, isNonLodgementOnly, isSignAndLodge, onBack, participantId, workspaceId]
  );

  const signDocuments: SignDocument[] = React.useMemo<SignDocument[]>(() => {
    if (isLoading) {
      return [];
    }
    return resolveSignDocuments(
      workspaceId,
      participantId,
      documents,
      documentAttachments,
      userData!.supportPageUrl,
      isLodgementOnly ? undefined : directionsDetail,
      multiSignDetail
    );
  }, [workspaceId, participantId, directionsDetail, documents, isLoading, isLodgementOnly, multiSignDetail, documentAttachments, userData]);

  const financialLineItems = React.useMemo<Map<SignDocumentTypeEnum, FinancialLineItem[]>>(() => {
    if (isLoading) {
      return new Map<SignDocumentTypeEnum, FinancialLineItem[]>();
    }
    return resolveLineItems(participantId, isLodgementOnly ? undefined : directionListDetail);
  }, [isLoading, isLodgementOnly, directionListDetail, participantId]);

  // TODO move fetch logic to page level
  // we need to refetch to get latest documents data
  React.useEffect(() => {
    dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
  }, [dispatch, participantId, workspaceId]);

  // we need to refetch to get latest direction data
  React.useEffect(() => {
    if (isNonLodgementOnly && isNotAbandoned) {
      batch(() => {
        dispatch(actionFetchDirectionsList.request({ workspaceId, participantId }));
        dispatch(actionFetchDirections.request({ workspaceId, participantId }));
      });
    }
  }, [dispatch, workspaceId, participantId, isNonLodgementOnly, isNotAbandoned]);

  React.useEffect(() => {
    if (isDocumentsLoading) {
      return;
    }

    dispatch(actionFetchDocumentMultiSign.request({ workspaceId, participantId }));
    // * We need to refetch document multi-sign data when document is added/removed or document list is reordered
  }, [
    isDocumentsLoading,
    dispatch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    documents.map(doc => doc.documentId).join(),
    workspaceId,
    participantId
  ]);

  const handleOnShowVerificationResultsClick = React.useCallback(() => {
    dispatch(actionOpenLodgementVerificationDialog());
  }, [dispatch]);

  const handleOnSubmit = React.useCallback(
    values => {
      setToBeMultiSignDocumentsModel(resolveToBeMultiSignDocumentsModel(values, multiSignDetail!));
    },
    [multiSignDetail]
  );

  if (workspaceError || error) {
    return <GeneralCrashedContent />;
  }

  if (isWorkspaceLoading || isLoading) {
    return <BlockLoader />;
  }

  return (
    <SignDocuments
      // route params
      workspaceId={workspaceId}
      participantId={participantId}
      // signing data
      signDocuments={signDocuments}
      lineItems={financialLineItems}
      mergedPdfUrl={multiSignDetail?.mergedPdfUrl}
      isLoading={isMultiSignLoading}
      disabled={isSigning}
      // wizard handlers
      onBack={onBack}
      onSubmit={handleOnSubmit}
      // compliance issues handlers
      onShowVerificationResultsClick={handleOnShowVerificationResultsClick}
    >
      {formikProps => (
        <FlexLayout className={classes.signingButtons} fullHeight>
          {isLodgementOnly && (
            <SigningProvider //
              disabled={
                !formikProps.values.documents.every(d => d.isSelected || d.data.signed) ||
                formikProps.values.documents.some(d => d.data.toBeSignedByOtherSigners) ||
                isSigning ||
                isMultiSignLoading ||
                !lrsInfo.isValidOperatingHoursForRegOnly
              }
              preStartSigning={() => {
                setIsSignAndLodge(true);
                setToBeMultiSignDocumentsModel(undefined);
                return formikProps.submitForm();
              }}
              mode="light"
              label="Sign selected and lodge"
              onReadyToSign={handleReadyToSign}
              onSign={handleOnSign}
              onPostSign={handlePostSign}
              isLoading={isMultiSignLoading}
              variant="outlined"
              showArrow={false}
            />
          )}
          <SigningProvider //
            disabled={!formikProps.isValid || isSigning || isMultiSignLoading}
            preStartSigning={() => {
              setIsSignAndLodge(false);
              setToBeMultiSignDocumentsModel(undefined);
              return formikProps.submitForm();
            }}
            mode="light"
            label="Sign selected"
            onReadyToSign={handleReadyToSign}
            onSign={handleOnSign}
            onPostSign={handlePostSign}
            isLoading={isMultiSignLoading}
          />
        </FlexLayout>
      )}
    </SignDocuments>
  );
}

export default SignDocumentsContainer;

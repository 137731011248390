import { format } from 'date-fns';

import { LookupItemModel } from '@sympli/ui-framework/models';
import Logger from '@sympli/ui-logger';

import { AssignmentTypeEnum, DashboardDateRangeModel } from 'src/containers/dashboard/shared/models';
import { DashboardFilterModel, FinancialDashboardRouteTabsEnumV2 } from '../models';
import {
  DocumentsStatusEnum,
  DocumentsStatusOptions,
  FilterEnum,
  FilterOptions,
  FinancialStatusEnum,
  FinancialStatusOptions,
  WorkspaceArchivedStatusOptions,
  WorkspaceStatusEnum,
  WorkspaceStatusOptions
} from './models';

export const getFiltersWithSelectedTab = (
  //
  key: string,
  selectedTab: FinancialDashboardRouteTabsEnumV2,
  assignmentType: AssignmentTypeEnum
): [DashboardFilterModel, boolean] => {
  const value = localStorage.getItem(key);

  const defaultOptions = defaultDashboardFilterModel(selectedTab, assignmentType);

  if (value) {
    const savedFilters = JSON.parse(value) as DashboardFilterModel[];
    const savedFilter = savedFilters.find(d => d.selectedTab === selectedTab && d.assignmentType === assignmentType);

    if (!savedFilter) {
      return [defaultOptions, false];
    }

    return [
      {
        selectedTab: selectedTab,
        assignmentType: assignmentType,
        selectedFilters: savedFilter.selectedFilters,
        myTasks: savedFilter.myTasks,
        myRoles: savedFilter.myRoles,
        jurisdictions: savedFilter.jurisdictions,
        financialStatus: savedFilter.financialStatus,
        fundsStatus: savedFilter.fundsStatus,
        workspaceStatus: savedFilter.workspaceStatus,
        documentStatus: savedFilter.documentStatus,
        ...defaultDateTimeRangeModel(selectedTab, savedFilter),
        groupIds: savedFilter.groupIds,
        userIds: savedFilter.userIds,
        workspaceMessageStatus: savedFilter.workspaceMessageStatus
      },
      true
    ];
  }

  return [defaultOptions, false];
};

const resolveSelectedFiltersAndDefaultWorkspaceStatus = //
  (selectedTab: FinancialDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): Pick<DashboardFilterModel, 'selectedFilters' | 'workspaceStatus'> => {
    const selectedFilters: FilterEnum[] = [];

    if (assignmentType === AssignmentTypeEnum.ReAssigned) {
      selectedFilters.push(FilterEnum.Groups);
    }
    switch (selectedTab) {
      case FinancialDashboardRouteTabsEnumV2.active:
        selectedFilters.unshift(FilterEnum.SettlementDate);
        return { selectedFilters: selectedFilters, workspaceStatus: [] };

      case FinancialDashboardRouteTabsEnumV2.recent:
        selectedFilters.unshift(FilterEnum.AccessedDate);
        return { selectedFilters: selectedFilters, workspaceStatus: [] };

      default: // all, complete, to be rebooked, archived
        return { selectedFilters: selectedFilters, workspaceStatus: [] };
    }
  };

export const defaultDashboardFilterModel = (selectedTab: FinancialDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): DashboardFilterModel => {
  const resolveSelectedFilters = resolveSelectedFiltersAndDefaultWorkspaceStatus(selectedTab, assignmentType);

  const defaultOptions: DashboardFilterModel = {
    assignmentType: assignmentType,
    selectedTab: selectedTab,
    selectedFilters: resolveSelectedFilters.selectedFilters,
    myTasks: [],
    myRoles: [],
    jurisdictions: [],
    financialStatus: [],
    fundsStatus: [],
    workspaceStatus: resolveSelectedFilters.workspaceStatus,
    documentStatus: [],

    ...defaultDateTimeRangeModel(selectedTab, undefined),
    groupIds: []
  };

  return defaultOptions;
};

// if saved value has a value then use it, if we do not have saved value then based on selected tab show the default value
const defaultDateTimeRangeModel = (
  selectedTab: FinancialDashboardRouteTabsEnumV2,
  savedFilter?: DashboardFilterModel
): { settlementPeriod?: DashboardDateRangeModel; lastAccessPeriod?: DashboardDateRangeModel; archivedDatePeriod?: DashboardDateRangeModel } => {
  const settlementPeriod = savedFilter?.settlementPeriod
    ? savedFilter.settlementPeriod
    : selectedTab === FinancialDashboardRouteTabsEnumV2.active
      ? { start: format(new Date(), 'dd/MM/yyyy'), end: format(new Date(), 'dd/MM/yyyy') }
      : undefined;

  const lastAccessPeriod = savedFilter?.lastAccessPeriod
    ? savedFilter.lastAccessPeriod
    : selectedTab === FinancialDashboardRouteTabsEnumV2.recent
      ? { start: format(new Date(), 'dd/MM/yyyy'), end: format(new Date(), 'dd/MM/yyyy') }
      : undefined;

  const archivedDatePeriod = savedFilter?.archivedDatePeriod ? savedFilter.archivedDatePeriod : undefined;

  return { settlementPeriod, lastAccessPeriod, archivedDatePeriod };
};

export const getDefaultSelectOptions = (selectedTab: FinancialDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): LookupItemModel<number>[] => {
  // each one of them have diff default select list and assign to group allow has the members
  const defaultFilterOptions =
    assignmentType !== AssignmentTypeEnum.AssignedToMe
      ? FilterOptions.concat([
          {
            id: FilterEnum.Groups,
            name: 'Group'
          },
          {
            id: FilterEnum.Members,
            name: 'Member'
          }
        ])
      : FilterOptions;

  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.active:
    case FinancialDashboardRouteTabsEnumV2.all:
      return defaultFilterOptions;
    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return defaultFilterOptions.filter(d => d.id !== FilterEnum.WorkspaceStatus);

    case FinancialDashboardRouteTabsEnumV2.complete:
      return defaultFilterOptions.filter(
        d => d.id !== FilterEnum.MyTasks && d.id !== FilterEnum.WorkspaceStatus && d.id !== FilterEnum.FinancialStatus && d.id !== FilterEnum.Messages
      );

    case FinancialDashboardRouteTabsEnumV2.archived:
      const archivedFilters = [
        {
          id: FilterEnum.ArchivedDate,
          name: 'Archived Date'
        },
        {
          id: FilterEnum.Jurisdiction,
          name: 'Jurisdiction (JDX)'
        },
        {
          id: FilterEnum.MyRole,
          name: 'My Role'
        },
        {
          id: FilterEnum.WorkspaceStatus,
          name: 'Status' // this is different to other tab which is workspace status
        }
      ];
      if (assignmentType === AssignmentTypeEnum.AssignedToGroup) {
        archivedFilters.push({
          id: FilterEnum.Groups,
          name: 'Group'
        });
      }

      return archivedFilters;

    case FinancialDashboardRouteTabsEnumV2.recent:
      return [
        {
          id: FilterEnum.AccessedDate,
          name: 'Access Date'
        }
      ].concat(defaultFilterOptions);

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return defaultFilterOptions;
  }
};

export const getDefaultWorkspaceOptions = (selectedTab: FinancialDashboardRouteTabsEnumV2, assignmentType: AssignmentTypeEnum): LookupItemModel<number>[] => {
  if (assignmentType === AssignmentTypeEnum.ReAssigned) {
    return getDefaultReassignWorkspaceOptions(selectedTab);
  }
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all:
      return WorkspaceStatusOptions;

    case FinancialDashboardRouteTabsEnumV2.active:
      return WorkspaceStatusOptions.filter(d => d.id !== WorkspaceStatusEnum.Settled);

    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.InPreparationWithErrors);

    case FinancialDashboardRouteTabsEnumV2.complete:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.Settled);

    case FinancialDashboardRouteTabsEnumV2.archived:
      return WorkspaceArchivedStatusOptions;

    case FinancialDashboardRouteTabsEnumV2.recent:
      return WorkspaceStatusOptions;

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return WorkspaceStatusOptions;
  }
};

const getDefaultReassignWorkspaceOptions = (selectedTab: FinancialDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all:
    case FinancialDashboardRouteTabsEnumV2.active:
    case FinancialDashboardRouteTabsEnumV2.recent:
      return WorkspaceStatusOptions.filter(d => d.id !== WorkspaceStatusEnum.Settled && d.id !== WorkspaceStatusEnum.Settling && d.id !== WorkspaceStatusEnum.SettlingWithErrors);

    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return WorkspaceStatusOptions.filter(d => d.id === WorkspaceStatusEnum.InPreparationWithErrors);

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported `));
      return WorkspaceStatusOptions;
  }
};

export const getDefaultFinancialStatusOptions = (selectedTab: FinancialDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.archived:
    case FinancialDashboardRouteTabsEnumV2.all:
    case FinancialDashboardRouteTabsEnumV2.recent:
      return FinancialStatusOptions;

    case FinancialDashboardRouteTabsEnumV2.active:
      return FinancialStatusOptions.filter(d => d.id !== FinancialStatusEnum.Disbursed);

    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return FinancialStatusOptions.filter(
        d => d.id === FinancialStatusEnum.InPreparation || d.id === FinancialStatusEnum.InPreparationWithErrors || d.id === FinancialStatusEnum.Ready
      );

    case FinancialDashboardRouteTabsEnumV2.complete:
      return FinancialStatusOptions.filter(d => d.id === FinancialStatusEnum.Settled || d.id === FinancialStatusEnum.Disbursed);

    default:
      Logger.captureException(new Error(`${selectedTab} is not supported for financial status`));
      return FinancialStatusOptions;
  }
};

export const getDefaultDocumentsStatusOptions = (selectedTab: FinancialDashboardRouteTabsEnumV2): LookupItemModel<number>[] => {
  switch (selectedTab) {
    case FinancialDashboardRouteTabsEnumV2.all:
    case FinancialDashboardRouteTabsEnumV2.recent:
    case FinancialDashboardRouteTabsEnumV2.active:
      return DocumentsStatusOptions;

    case FinancialDashboardRouteTabsEnumV2.toBeRebooked:
      return DocumentsStatusOptions.filter(d => d.id !== DocumentsStatusEnum.Lodging && d.id !== DocumentsStatusEnum.Lodged && d.id !== DocumentsStatusEnum.Completed);

    case FinancialDashboardRouteTabsEnumV2.complete:
      return DocumentsStatusOptions.filter(d => d.id === DocumentsStatusEnum.Lodged || d.id === DocumentsStatusEnum.Completed);
    case FinancialDashboardRouteTabsEnumV2.archived:
      return [];
    default:
      Logger.captureException(new Error(`${selectedTab} is not supported for documents status`));
      return DocumentsStatusOptions;
  }
};

export const convertDateTimeObjectToArrayString = (datePeriod: DashboardDateRangeModel | undefined) => {
  const result: string[] = [];
  if (!datePeriod) {
    return result;
  }

  if (datePeriod.start && typeof datePeriod.start === 'string') {
    result.push(datePeriod.start);

    if (
      datePeriod.end &&
      typeof datePeriod.end === 'string' && //
      datePeriod.start === datePeriod.end
    ) {
      return result;
    }
  }

  if (datePeriod.end && typeof datePeriod.end === 'string') {
    result.push(datePeriod.end);
  }

  return result;
};

export interface WorkspaceUpdateMessage {
  WorkspaceId: string;
  Category: WorkspaceUpdateMessageCategoryEnum;
  RequireRefetch: boolean;
  Message: string;
  CorrelationId: string;
  MessageId: string;
}

export enum WorkspaceUpdateMessageCategoryEnum {
  Workspace = 0,
  Document = 1,
  Task = 2,
  Direction = 3,
  Lodgement = 4,
  StampDuty = 5,
  Messenger = 6,
  OffPlatformTask = 7,
  Billing = 8,
  Notification = 9,
  Participant = 10,
  TrustAccountAuthorisationRecord = 11
}

export const CategoryMapping: Record<WorkspaceUpdateMessageCategoryEnum, string> = {
  [WorkspaceUpdateMessageCategoryEnum.Workspace]: 'Workspace event',
  [WorkspaceUpdateMessageCategoryEnum.Document]: 'Document event',
  [WorkspaceUpdateMessageCategoryEnum.Task]: 'Task event',
  [WorkspaceUpdateMessageCategoryEnum.Lodgement]: 'Lodgement event',
  [WorkspaceUpdateMessageCategoryEnum.Direction]: 'Direction event',
  [WorkspaceUpdateMessageCategoryEnum.StampDuty]: 'StampDuty event',
  [WorkspaceUpdateMessageCategoryEnum.Messenger]: 'Messenger event',
  [WorkspaceUpdateMessageCategoryEnum.OffPlatformTask]: 'OffPlatformTask event',
  [WorkspaceUpdateMessageCategoryEnum.Billing]: 'Billing event',
  [WorkspaceUpdateMessageCategoryEnum.Notification]: 'Notification event',
  [WorkspaceUpdateMessageCategoryEnum.Participant]: 'Participant event',
  [WorkspaceUpdateMessageCategoryEnum.TrustAccountAuthorisationRecord]: 'Trust Account Authorisation Record event'
};

export const unsupportedSocketIoCategoryTemplate = (category: WorkspaceUpdateMessageCategoryEnum) => `unsupported ${CategoryMapping[category]} received from server `;

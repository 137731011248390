import { JurisdictionsEnum, ParticipantStatusEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';

export const filterAwaitingParticipants = (item: WorkspaceParticipantApiResponse) => {
  return item.participantStatus.id === ParticipantStatusEnum.Pending;
};

export const filterAcceptedParticipants = (item: WorkspaceParticipantApiResponse) => {
  return item.participantStatus.id === ParticipantStatusEnum.Accepted;
};

const nswSampleTitle: string = 'e.g 59/1185283, 68/SP1185283';
const vicSampleTitle: string = 'e.g 11829/216, 11840/803';
const qldSampleTitle: string = 'e.g 50839357, 50839364';
const saSampleTitle: string = 'e.g 6100/173, 6107/196';
const waSampleTitle: string = 'e.g 2001-23, 113-93A';

export function resolveTitlePlaceholder(jurisdiction: JurisdictionsEnum | string): string {
  switch (jurisdiction) {
    case JurisdictionsEnum.NSW:
      return nswSampleTitle;
    case JurisdictionsEnum.VIC:
      return vicSampleTitle;
    case JurisdictionsEnum.QLD:
      return qldSampleTitle;
    case JurisdictionsEnum.SA:
      return saSampleTitle;
    case JurisdictionsEnum.WA:
      return waSampleTitle;
    default:
      return '';
  }
}

export const DEBOUNCE_LIMIT = 250;

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from 'src/theme';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    tooltip: {
      color: colors.BLACK,
      width: 'auto',
      fontSize: 13,
      marginTop: 20
    },
    button: {
      width: 24,
      height: 24,
      marginTop: -2,
      marginBottom: -2
    },
    buttonIcon: {
      width: 14,
      height: 16
    },
    editButton: {
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 10
    },
    descriptionClickable: {
      marginTop: -4
    },
    fileStatus: {
      paddingLeft: '24px',
      fontSize: 13,
      lineHeight: '13px'
    },
    fileStatusIcon: {
      marginRight: 4,
      lineHeight: '13px'
    },
    fileStatusMessage: {
      fontSize: 13
    },
    fileStatusContainer: {
      flexGrow: 1,
      paddingLeft: '24px',
      fontSize: 13,
      lineHeight: '13px'
    },
    removeFile: {
      width: 17,
      height: 17
    },
    error: {
      color: theme.palette.error.dark,
      '& svg': {
        fill: theme.palette.error.dark
      }
    },
    iconDisabled: {
      fill: theme.palette.action.disabled,
      color: theme.palette.action.disabled
    }
  }),
  {
    name: 'WorkspaceFileManageButtons'
  }
);

import { createAction, createAsyncAction } from 'typesafe-actions';

import { AxiosError } from 'src/utils/http';
import { ConversationDocumentsApiRequest, ConversationDocumentsApiResponse } from './models';

export const actionFetchParticipantConversationSummary = createAsyncAction(
  //
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY',
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY_SUCCESS',
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY_ERROR'
)<
  //
  ConversationDocumentsApiRequest,
  { data: ConversationDocumentsApiResponse },
  { error: Error }
>();

export const actionFetchParticipantConversationSummarySliently = createAsyncAction(
  //
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY_SLIENTLY',
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY_SLIENTLY_SUCCESS',
  'FETCH_PARTICIPANT_CONVERSATION_SUMMARY_SLIENTLY_ERROR'
)<
  //
  UpdateConversationModel,
  { data: ConversationDocumentsApiResponse; conversationId: string },
  { error: Error }
>();

export interface SendConversationMessageApiRequest {
  workspaceId: string;
  participantId: string;
  conversationId: string;
  message: string;

  sentByUserName: string;
  sentBySubscriberName: string;
  id?: string;
}

export const actionSendConversationMessage = createAsyncAction(
  //
  'SEND_CONVERSATION_MESSAGE',
  'SEND_CONVERSATION_MESSAGE_SUCCESS',
  'SEND_CONVERSATION_MESSAGE_ERROR'
)<
  //
  SendConversationMessageApiRequest,
  undefined,
  { error: AxiosError }
>();

export const actionUpdateSendConversationMessage = createAction(
  //
  'UPDATE_SEND_CONVERSATION_MESSAGE'
)<{ messageId?: string; conversationId: string; isSuccess: boolean }>();

export interface CreateNewConversationModel {
  workspaceId: string;
  participantId: string;
  conversationId: string;
  message: string;
  subject: string;

  sentByUserName: string;
  sentBySubscriberName: string;
  recipientParticipantIds: Array<string>;
}

export const actionCreateNewConversation = createAction(
  //
  'CREATE_NEW_CONVERSATION'
)<CreateNewConversationModel>();

export interface UpdateConversationApiRequest extends UpdateConversationModel {
  isRead: boolean;
}

export interface UpdateConversationModel {
  workspaceId: string;
  participantId: string;
  conversationId: string;
}

export const actionUpdateConversationIsRead = createAsyncAction(
  //
  'UPDATE_CONVERSATION_IS_READ',
  'UPDATE_CONVERSATION_IS_READ_SUCCESS',
  'UPDATE_CONVERSATION_IS_READ_ERROR'
)<
  //
  UpdateConversationApiRequest,
  undefined,
  { error: Error }
>();

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

import { colors } from 'src/theme';

const styles = (theme: Theme) =>
  createStyles({
    // settlement date card wrapper root
    root: {
      position: 'relative',
      width: 272,
      minHeight: 105,
      background: theme.palette.grey[100]
    },
    reminderContainer: {
      flexGrow: 1
    },
    dateTimeContainer: {
      width: 140,
      flexShrink: 0
    },
    // noticeTriangle and tooltip
    noticeTriangle: {
      position: 'absolute',
      // zIndex: 2,
      width: 0,
      height: 0,
      borderRight: '40px solid transparent',
      borderTop: `40px solid ${theme.palette.secondary.main}`,
      '&:after': {
        content: '"!"',
        top: -42,
        left: 8,
        position: 'absolute',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 25
      },
      '&:hover + $card': {
        opacity: 1,
        visibility: 'visible'
      }
    },
    redNoticeTriangle: {
      borderTopColor: theme.palette.error.main
    },
    yellowNoticeTriangle: {
      borderTopColor: colors.SUNNY_DAY
    },
    //  noticeTriangle tooltip
    card: {
      '&:hover': {
        opacity: 1,
        visibility: 'visible'
      },
      visibility: 'hidden',
      opacity: 0,
      position: 'absolute',
      bottom: '108%',
      left: -70,
      width: 340,
      borderRadius: 2,
      transition: 'all 0.3s ease-in',
      // must be greater than noticeTriangle
      zIndex: 3,
      '&:after': {
        content: '" "',
        position: 'absolute',
        width: 22,
        height: 22,
        bottom: -11,
        left: 70,
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.2)',
        zIndex: -1
      }
    },
    cardContent: {
      padding: 16,
      '& p': {
        fontSize: 13
      }
    },
    content: {
      zIndex: 1,
      padding: 16
    },
    dueIn: {
      '& p': {
        height: 36,
        fontWeight: 'bold',
        fontSize: 40,
        lineHeight: '32px'
      },
      '& > p:last-child': {
        fontSize: theme.typography.fontSize,
        fontWeight: 'bold',
        lineHeight: '15px',
        height: 15
      }
    },
    dateLine: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: 0,
      height: 36,
      letterSpacing: 'normal',

      '& + $dateLine': {
        paddingBottom: 0,
        alignItems: 'flex-end',
        borderTop: '1px solid ' + theme.palette.grey['300']
      },

      '& > .material-icons': {
        fontSize: 20,
        marginRight: 8
      },

      '& > a': {
        paddingRight: 0,
        marginLeft: 'auto'
      }
    },
    hasHelpCursor: {
      '&:hover': {
        cursor: 'help'
      }
    },
    bottomBox: {
      boxSizing: 'border-box',
      width: '100%',
      color: 'white',
      height: 36,
      '& svg': {
        fill: theme.palette.primary.main,
        paddingRight: 5
      }
    },
    bottomBoxIcon: {
      '& svg': {
        fill: colors.WHITE
      }
    },
    proposedAndNotAccepted: {
      backgroundColor: theme.palette.info.main,
      fontSize: '13px'
    },
    acceptedByAll: {
      backgroundColor: theme.palette.primary.main,
      fontSize: '14px',
      '& svg': {
        fill: theme.palette.primary.light,
        paddingRight: 5
      }
    },
    acceptedWaitingForOthers: {
      backgroundColor: theme.palette.info.main,
      fontSize: '13px'
    },
    toBeAcceptedByUser: {
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      fontSize: '14px',
      padding: '6px 0px 6px 12px',
      height: 36,
      '& svg': {
        fill: theme.palette.primary.main,
        paddingRight: 5
      }
    },
    quickAcceptButton: {
      fontWeight: 'bold',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        fontColor: '#ddd'
      }
    },

    // date and time Icons
    normalIconColor: {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    noticeIconColor: {
      fill: theme.palette.secondary.main,
      color: theme.palette.secondary.main
    },
    errorIconColor: {
      fill: theme.palette.error.main,
      color: theme.palette.error.main
    },
    nonSDTIconColor: {
      fill: colors.SUNNY_DAY,
      color: colors.SUNNY_DAY
    },
    iconClock: {
      marginRight: 5
    },
    iconCalender: {
      marginRight: 6
    },
    navLink: {
      fontWeight: 'bold',
      fontSize: 13
    },
    dateTimeUnAccept: {
      backgroundColor: theme.palette.secondary.main
    },
    acceptedByAllSpan: {
      fontWeight: 'bold'
    },
    proposedNotAcceptedSpan: {
      fontWeight: 'bold'
    },
    acceptedSpan: {
      fontWeight: 'bold'
    },
    inlineLoaderStyle: {
      height: 66,
      width: '100%',
      '& div': {
        marginTop: 20
      }
    },
    userLocalTime: {
      color: theme.palette.text.secondary,
      fontSize: 13
    },
    paddingTop: {
      paddingTop: 30
    },

    // reminder styles
    iconCheck: {
      width: 35,
      height: 35,
      fill: theme.palette.primary.main
    },
    iconLock: {
      width: 35,
      height: 35
    },
    iconLock101: {
      width: 32,
      height: 32
    },
    iconUnlock: {
      width: 27,
      height: 34
    },
    statusText: {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 5
    },
    settlingToday: {
      fontWeight: 'normal',
      paddingTop: 13
    },
    nonSDT: {
      fontSize: 16
    },
    errorText: {
      color: theme.palette.error.main
    },
    boldText: {
      fontWeight: 'bold'
    },
    editSettlementDate: {
      backgroundColor: theme.palette.info.main,
      fontSize: '13px'
    },
    whiteNavLink: {
      fontWeight: 'bold',
      fontSize: 13,
      color: theme.palette.common.white
    }
  });

export type ClassKeys = typeof styles;

export default styles;

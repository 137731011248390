import * as React from 'react';

import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchDocumentDetail } from 'src/containers/documents/actions';
import { DocumentPageRouteParams } from 'src/containers/documents/models';
import { DocumentListModel, LodgementCaseDetail } from 'src/containers/shared/document-list/models';
import {
  actionFetchWorkspaceBasicInfo,
  actionFetchWorkspaceById,
  actionFetchWorkspaceDocuments,
  actionFetchWorkspaceParticipants
} from 'src/containers/workspace/shared/detail/actions';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { DocumentFormIdentifierDisplayMapping } from 'src/models/document';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import DeleteDocumentDialog from '../delete-document-dialog';
import { isAllowedToDeletePrimaryLodgementCaseDocuments } from '../document-list-toolbar/helpers';
import { deleteDocument } from './api';
import DocumentListDelete from './DocumentListDelete';
import { useStyles } from './styles';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
type Props = {
  workspaceId: string;
  participantId: string;
  workspaceTypeId?: WorkspaceTypeEnum;
  documentId?: string;
  lodgementCaseDetail: LodgementCaseDetail;
  onCancel(): void;
};

function DocumentListDeleteContainer({ workspaceId, participantId, workspaceTypeId, lodgementCaseDetail, onCancel }: Props) {
  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const queryParams = useRouterParams<DocumentPageRouteParams>();
  const [dialogState, setDialogState] = React.useState<{
    //
    isOpen: boolean;
    document?: DocumentListModel;
    lodgementCase?: LodgementCase;
  }>({
    //
    isOpen: false,
    document: undefined
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const classes = useStyles();
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);

  // add delete icons and assign handler that displays dialog
  const itemsToDelete = React.useMemo(() => {
    return lodgementCaseDetail.documents.map(item => {
      return item.icon && !item.isLI
        ? {
            ...item,
            icon: React.cloneElement(item.icon, {
              className: classes.deleteIcon,
              onClick: (event: React.MouseEvent) => {
                // prevent default behaviour which is navigation to a particular document
                event.preventDefault();
                event.stopPropagation();

                setDialogState({
                  //
                  isOpen: true,
                  document: item,
                  lodgementCase: lodgementCaseDetail.lodgementCase
                });
              }
            })
          }
        : item;
    });
  }, [lodgementCaseDetail, classes]);

  const handleOnClose = () => {
    setDialogState({
      //
      isOpen: false,
      document: undefined
    });
  };

  const handleOnNext = async () => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'delete-document',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      documentType: DocumentFormIdentifierDisplayMapping[dialogState.document!.documentIdentifier!],
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
    });

    setIsDeleting(true);
    try {
      if (queryParams.documentId === dialogState.document!.documentId) {
        // before delete need to change the route otherwise realtime refresh will try to load deleted document
        navigate(resolveWorkspaceDetailLink({ workspaceId, participantId }));
      }

      await deleteDocument(workspaceId, dialogState.document!.documentId, participantId);
      setIsDeleting(false);
      //always close the dialog
      handleOnClose();

      // remove document from document list, and fetch workspace tasks again, this should be manual
      const lodgementInstructionsDocumentId = lodgementCaseDetail.documents.find(item => item.isLI)?.documentId;

      batch(() => {
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message: 'Document has been deleted',
            autoHideDuration: 5000,
            variant: 'new-success'
          })
        );
        dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
        dispatch(actionFetchWorkspaceById.request({ workspaceId, participantId }));
        dispatch(actionFetchWorkspaceBasicInfo.request({ workspaceId, participantId }));
        if (queryParams.documentId === lodgementInstructionsDocumentId) {
          dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId: lodgementInstructionsDocumentId }));
        }
        dispatch(actionFetchWorkspaceParticipants.request({ workspaceId, participantId }));
      });

      // close the DocumentListDelete
      onCancel();
    } catch (error) {
      setIsDeleting(false);
      dispatch(actionCreateGlobalErrorMessage(error));
    }
  };

  const isParticipantAllowedToDeletePLCDocuments = isAllowedToDeletePrimaryLodgementCaseDocuments({
    workspaceType: workspaceDetailState.detail?.workspaceType.id,
    currentParticipantId: workspaceDetailState.detail?.currentParticipantId,
    workspaceParticipants: workspaceParticipantsState
  });

  return (
    <>
      <DocumentListDelete //
        items={itemsToDelete}
        onCancel={onCancel}
        forceDisable={lodgementCaseDetail.lodgementCase?.isPrimary && !isParticipantAllowedToDeletePLCDocuments}
      />
      <DeleteDocumentDialog //
        isOpen={dialogState.isOpen}
        documentName={dialogState.document?.name ?? ''}
        isDeleting={isDeleting}
        onNext={handleOnNext}
        onClose={handleOnClose}
      >
        {workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly || !dialogState.lodgementCase || dialogState.lodgementCase.isPrimary ? (
          <Typography>This document will be permanently deleted from the workspace.</Typography>
        ) : (
          <Typography>
            This document and any related content (including the {dialogState.lodgementCase?.name} and Lodgement Instructions) will be <b>permanently deleted</b> for all workspace
            participants.
          </Typography>
        )}
      </DeleteDocumentDialog>
    </>
  );
}

export default React.memo(DocumentListDeleteContainer);

import React from 'react';

import { ParticipantArchivedStatusEnum } from '@sympli/api-gateway/enums';
import { ArchivedInvitationDetailApiResponse } from '@sympli/api-gateway/models';

import { Card } from 'src/containers/dashboard/components/card';
import useScreenSize from 'src/hooks/useScreenSize';
import { ScreenSizeVariant } from 'src/theme/screens';
import { resolveArchivedInvitationLogRole, resolveArchivedInvitationLogsAction } from '../helper';
import { InvitationsTableRowModel } from './models';
import { getRowsAndColumns } from './row-detail/helpers';
import InvitationsCardDetail from './row-detail/InvitationsCardDetail';

interface InvitationsTableRowDetailProps {
  items: ArchivedInvitationDetailApiResponse[];
  invitationDetail: InvitationsTableRowModel;
}

// This is the container
function InvitationsTableRowDetail({
  //
  items,
  invitationDetail
}: InvitationsTableRowDetailProps) {
  const screenVariant: ScreenSizeVariant = useScreenSize();
  const role = resolveArchivedInvitationLogRole(items, invitationDetail);
  const action = resolveArchivedInvitationLogsAction(items);
  const isSentAndReceived = invitationDetail.type === ParticipantArchivedStatusEnum.SentAndReceived;

  return (
    <div className="p-[20px] flex">
      <Card>
        <InvitationsCardDetail {...getRowsAndColumns(role, action, items, screenVariant, isSentAndReceived)} />
      </Card>
    </div>
  );
}

export default React.memo(InvitationsTableRowDetail);

import * as React from 'react';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import DocumentListAddContainer from './components/document-list-add';
import { DocumentListBase } from './components/document-list-base';
import { DocumentListDeleteContainer } from './components/document-list-delete';
import DocumentListReorderContainer from './components/document-list-reorder';
import { DocumentListToolbarContainer } from './components/document-list-toolbar';
import { DocumentListAction, LodgementCaseDetail } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
export interface Props {
  //route props
  workspaceId: string;
  participantId: string;
  documentId?: string;
  // additional workspace info
  workspaceTypeId?: WorkspaceTypeEnum;
  // lodgement case and documents info
  lodgementCaseDetail?: LodgementCaseDetail;
  // other
  mode: DocumentListAction;
  onModeChange(mode: DocumentListAction): void;
  isToolbarEnabled: boolean;
}

function DocumentList({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  documentId,
  lodgementCaseDetail,
  mode,
  onModeChange,
  isToolbarEnabled
}: Props) {
  const handleOnCancel = React.useCallback(() => {
    onModeChange('default');
  }, [onModeChange]);

  function getDocumentList() {
    const documents = lodgementCaseDetail ? lodgementCaseDetail.documents : [];
    switch (mode) {
      case 'reorder':
        return (
          <DocumentListReorderContainer //
            // route props
            workspaceId={workspaceId}
            participantId={participantId}
            // additional workspace info
            workspaceTypeId={workspaceTypeId}
            // workspace documents
            items={documents}
            // other
            onCancel={handleOnCancel}
            onModeChange={onModeChange}
          />
        );
      case 'delete':
        return (
          lodgementCaseDetail && (
            <DocumentListDeleteContainer //
              // route props
              workspaceId={workspaceId}
              participantId={participantId}
              documentId={documentId}
              // additional workspace info
              workspaceTypeId={workspaceTypeId}
              // lodgement case and documents info
              lodgementCaseDetail={lodgementCaseDetail}
              // other
              onCancel={handleOnCancel}
            />
          )
        );
      case 'add':
        return (
          <DocumentListAddContainer //
            // route props
            workspaceId={workspaceId}
            participantId={participantId}
            documentId={documentId}
            // additional workspace info
            workspaceTypeId={workspaceTypeId}
            // workspace documents
            items={documents}
            isPrimary
            // other
            onCancel={handleOnCancel}
          />
        );
      default:
        return (
          <DocumentListBase //
            // workspace documents
            items={documents}
            dataTestId="document-list"
          />
        );
    }
  }

  return (
    <>
      {getDocumentList()}
      {isToolbarEnabled && (
        <DocumentListToolbarContainer //
          // route props
          workspaceId={workspaceId}
          participantId={participantId}
          // workspace documents
          lodgementCases={lodgementCaseDetail ? [lodgementCaseDetail] : []}
          // other
          onActionClick={onModeChange}
        />
      )}
    </>
  );
}

export default React.memo(DocumentList);

import { QLD_ADDRESS_FORM_CONFIG, QLD_ADDRESS_FORM_INITIAL_VALUES, QLD_PHYSICAL_ADDRESS_FORM_CONFIG } from '@sympli-mfe/document-components/address-field/qld/2-24/config';
import { QLD_PARTY_FORM_CONFIG } from '@sympli-mfe/document-components/party-form/qld/2-24/config';
import {
  AddressFieldFormConfig,
  AddressFormValueModel,
  InitialValuesAddressEntityModel,
  PhysicalAddressFormConfig,
  PoBoxAddressFormConfig
} from '@sympli-mfe/document-forms-framework/components/address-field';
import {
  resolveUnitNumberValidationSchema,
  yupAuPostCodeMandatory,
  YupPhysicalAddressSchemaMapModel,
  YupPoBoxAddressSchemaMapModel,
  yupSuburbBuilder
} from '@sympli-mfe/document-forms-framework/components/address-field/validation-schema';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { UnitTypeEnum } from '@sympli-mfe/enums-shared/necds';

import { resolvePlaceholder } from 'src/utils/formUtils';

export const QLD_FORM24A_PARTY_FORM_CONFIG: PartyFormConfig = {
  ...QLD_PARTY_FORM_CONFIG
};

const QLD_FORM24A_PHYSICAL_ADDRESS_FORM_CONFIG: PhysicalAddressFormConfig = {
  ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG,
  unitType: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitType,
    hidden: true
  },
  unitNumber: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.unitNumber,
    placeholder: resolvePlaceholder(false)
  },
  suburb: {
    ...QLD_PHYSICAL_ADDRESS_FORM_CONFIG.suburb
  },
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPhysicalAddressSchemaMapModel) => {
    // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
    const customiseValidation = QLD_PHYSICAL_ADDRESS_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;

    return {
      ...nonJurisdictionSpecificSchemaMap,
      ...customiseValidation,
      unitNumber: resolveUnitNumberValidationSchema(10, false),
      suburb: yupSuburbBuilder({ length: 50 })
    };
  }
};

const QLD_PO_BOX_FORM_CONFIG: PoBoxAddressFormConfig = QLD_ADDRESS_FORM_CONFIG.poBoxAddressFormConfig! as PoBoxAddressFormConfig;
const QLD_FORM24A_PO_BOX_FORM_CONFIG: PoBoxAddressFormConfig = {
  ...QLD_PO_BOX_FORM_CONFIG,
  customizeValidation: (nonJurisdictionSpecificSchemaMap: YupPoBoxAddressSchemaMapModel) => {
    // please note the schemaMap passed in here is the schema passed by the component in mfe-document-forms-framework
    const customiseValidation = QLD_PO_BOX_FORM_CONFIG.customizeValidation?.(nonJurisdictionSpecificSchemaMap)!;

    return {
      ...nonJurisdictionSpecificSchemaMap,
      ...customiseValidation,
      postcode: yupAuPostCodeMandatory,
      suburb: yupSuburbBuilder({ length: 50 })
    };
  }
};

export const QLD_FORM24A_ADDRESS_FORM_CONFIG: AddressFieldFormConfig = {
  ...QLD_ADDRESS_FORM_CONFIG,
  poBoxAddressFormConfig: QLD_FORM24A_PO_BOX_FORM_CONFIG,
  physicalAddressFormConfig: QLD_FORM24A_PHYSICAL_ADDRESS_FORM_CONFIG,
  electronicAddressFormConfig: false
};

export const QLD_FORM24A_ADDRESS_FORM_INITIAL_VALUES: AddressFormValueModel<InitialValuesAddressEntityModel> = {
  ...QLD_ADDRESS_FORM_INITIAL_VALUES,
  addressDetail: {
    ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail,
    physicalAddress: {
      ...QLD_ADDRESS_FORM_INITIAL_VALUES.addressDetail.physicalAddress!,
      unitType: UnitTypeEnum.UNIT
    }
  }
};

export const QLD_FORM24A_POSTAL_ADDRESS_FORM_CONFIG = QLD_FORM24A_ADDRESS_FORM_CONFIG;

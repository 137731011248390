import * as React from 'react';

import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import Logger from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import logo from 'src/assets/logo-sympli.svg';
import { LineLoader } from 'src/components/loaders';
import { getQrCode, MfaDataApiResponse, pollFactorStatus } from 'src/containers/personal-profile/multi-factor-auth/api';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  token?: string;
  dispatch: SafeDispatch<Action>;
  navigate: NavigateFunction;
}

type Props = OwnProps & WithStyles<ClassKeys>;

interface State {
  isLoading: boolean;
  data?: MfaDataApiResponse;
  milliSeconds: number;
}

class MfaLinkDevice extends React.Component<Props, State> {
  public readonly state: Readonly<State> = {
    isLoading: false,
    milliSeconds: 2000
  };

  private logoutTimer: NodeJS.Timer;
  private pollFactorStatusTimer: NodeJS.Timer;

  componentDidMount() {
    this.fetchData();
    this.logoutTimer = setTimeout(
      () => {
        this.props.navigate('/logout');
      },
      5 * 60 * 1000
    );
  }

  componentWillUnmount() {
    this.pollFactorStatusTimer && clearTimeout(this.pollFactorStatusTimer);

    if (this.logoutTimer != null) {
      clearInterval(this.logoutTimer);
    }
  }

  private fetchData() {
    this.setState({ isLoading: true }, () => {
      getQrCode()
        .then(mfaData => {
          // polling the data
          this.setState({ isLoading: false, data: mfaData });
          this.checkFactorEnrollStatus(mfaData.code);
        })
        .catch(error => {
          Logger.captureException(error);
          this.setState({ isLoading: false }, () => {
            this.props.dispatch(actionCreateGlobalErrorMessage(error));
          });
        });
    });
  }

  private checkFactorEnrollStatus = (factorId: string) => {
    pollFactorStatus(factorId).then(d => {
      if (d.isActive) {
        this.pollFactorStatusTimer && clearTimeout(this.pollFactorStatusTimer);
        window.location.reload();
      } else {
        const incrementalSeconds = this.state.milliSeconds + 1000;
        this.setState({ milliSeconds: incrementalSeconds });
        this.pollFactorStatusTimer = setTimeout(this.checkFactorEnrollStatus, incrementalSeconds, factorId);
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <FlexLayout justifyContent="space-around" alignItems="center" className={classes.container}>
        <Paper className={classes.paper}>
          <img className={classes.logo} src={logo} alt="logo-sympli" />
          <Typography variant="subtitle1">Link your device</Typography>
          <Typography className={classes.qrCodeDescription}>Open the OKTA mobile authentication app and follow the prompts</Typography>
          <FlexLayout justifyContent="space-around">{this.renderQrCode()}</FlexLayout>
        </Paper>
        <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={this.handleOnCloseClick} size="large">
          <Icon style={{ fontSize: 24 }}>close</Icon>
        </IconButton>
      </FlexLayout>
    );
  }

  private renderQrCode() {
    const { isLoading, data } = this.state;
    const { classes } = this.props;

    if (isLoading || data == null) {
      return (
        <FlexLayout justifyContent="space-around" alignItems="center" className={classes.qrCode}>
          <LineLoader color="white" variant="small" widthPercent={100} style={{ width: 180, height: 180 }} />
        </FlexLayout>
      );
    }
    return <img alt="" src={data.qrCode} className={classes.qrCode} />;
  }

  private handleOnCloseClick = () => {
    this.props.navigate('/logout');
  };
}

const styledComponent = withStyles(styles)(MfaLinkDevice);

export default styledComponent;

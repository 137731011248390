import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { UserAvatar101 as UserAvatar } from 'src/components/avatars';
import { OnlineStatusEnum } from 'src/components/online-status-icon/models';
import { ResendInviteApiRequest } from 'src/containers/dashboard/invitation/actions';
import { InvitesDashboardApiRequest, WithdrawParticipantInvitationRequestModel } from 'src/containers/dashboard/invitation/models';
import WithdrawResendFormatter from 'src/containers/shared/participant-list/components/withdraw-resend-formatter';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import styles, { ClassKeys } from './styles';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  item: WorkspaceParticipantApiResponse;
  hasAcceptedSettlement?: boolean; // optionally pass info whether he has accepted settlement, if not we will display orange dot

  workspaceId: string;
  participantId?: string;

  hasSwitchLink?: boolean; // display link to workspace detail
  onWithdrawClick?(e: React.MouseEvent<HTMLElement>): void;
  isRenderWithdrawAndResendButtons?: boolean;
  callbackActions?: Action[];
  canResend?: boolean;
  query?: InvitesDashboardApiRequest;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class ParticipantItem extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    hasAcceptedSettlement: true
  };

  get settlementAcceptanceStatus() {
    if (this.props.hasAcceptedSettlement === false) {
      return OnlineStatusEnum.Idle;
    }
    return undefined;
  }

  get statusTooltip() {
    if (this.props.hasAcceptedSettlement === false) {
      return 'Participant has not accepted proposed settlement date yet.';
    }

    return undefined;
  }

  render() {
    const { classes, className, style } = this.props;
    return (
      <FlexLayout flexDirection="row" className={classNames(classes.root, className)} style={style}>
        {this.renderAvatar()}
        <FlexLayout flexDirection="column" className="ml-2 grow">
          <FlexLayout className={classes.name} flexDirection="row" justifyContent="space-between">
            {this.renderParticipantName()}
            {this.renderLastColumn()}
          </FlexLayout>
          <div className={classes.description}>{this.renderParticipantRole()}</div>
        </FlexLayout>
      </FlexLayout>
    );
  }

  private renderAvatar() {
    const { item, classes } = this.props;
    const itemLink = this.getItemLink();
    const userAvatar = (
      <UserAvatar //
        status={this.settlementAcceptanceStatus}
        statusTooltip={this.statusTooltip}
        toolTipPlacement="left-start"
        src={item.avatarUrl}
        item={item}
        highlighted={this.isCurrentUser(item)}
      />
    );

    if (itemLink) {
      return (
        <Link to={itemLink} title="Switch view" className={classes.a}>
          {userAvatar}
        </Link>
      );
    }

    return userAvatar;
  }

  private renderName() {
    const { classes, item } = this.props;

    return (
      <div className={classes.text}>
        <div className={classes.name}>{this.renderParticipantName()}</div>
        <FlexLayout alignItems="center" className={classes.description}>
          {item.forwardedFromInvitationId ? `Forwarded - ${item.workspaceRole?.name}` : item.workspaceRole?.name}
        </FlexLayout>
      </div>
    );
  }

  private renderParticipantName() {
    const { item } = this.props;
    const itemLink = this.getItemLink();

    if (itemLink) {
      return (
        <Link to={itemLink} title="Switch view">
          {item.name}
        </Link>
      );
    }

    return item.name;
  }

  private renderParticipantRole() {
    const { item } = this.props;
    return item.forwardedFromInvitationId ? `Forwarded - ${item.workspaceRole?.name}` : item.workspaceRole?.name;
  }

  private renderLastColumn() {
    const { classes, item, participantId, isRenderWithdrawAndResendButtons, workspaceId } = this.props;

    if (!workspaceId || !isRenderWithdrawAndResendButtons || item.invitedBy == null || item.invitedBy.id !== participantId) {
      return null;
    }

    return <div className={classes.lastColumn}>{this.renderWithdrawAndResendButtons(item)}</div>;
  }

  private renderWithdrawAndResendButtons(item: WorkspaceParticipantApiResponse) {
    const { workspaceId, canResend, query } = this.props;

    const resolveWithdrawInvitePayload = (): WithdrawParticipantInvitationRequestModel => ({
      //
      workspaceId: workspaceId,
      participantId: item.id,
      participantName: item.name,
      query
    });

    const resolveResendInvitePayload = (): ResendInviteApiRequest => ({
      //
      id: item.id,
      query,
      participantName: item.name
    });

    return (
      <WithdrawResendFormatter //
        canResend={Boolean(canResend || item.canResend)}
        sentToName={item.name}
        tooltipType="smallIcon"
        // action payload resolvers
        resolveResendInvitePayload={resolveResendInvitePayload}
        resolveWithdrawInvitePayload={resolveWithdrawInvitePayload}
      />
    );
  }

  private isCurrentUser(item: WorkspaceParticipantApiResponse) {
    return item.id === this.props.participantId;
  }

  private getItemLink() {
    const { item, workspaceId, hasSwitchLink } = this.props;
    if (hasSwitchLink && !this.isCurrentUser(item)) {
      const link = resolveWorkspaceDetailLink({ workspaceId, participantId: item.id });
      return link;
    }
    return undefined;
  }
}

export default withStyles(styles)(ParticipantItem);

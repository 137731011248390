import React, { useEffect } from 'react';

import { Form, useFormikContext } from 'formik';
import { ButtonBaseActions } from '@mui/material/ButtonBase';

import { AddressFieldFormConfig, AddressFormValueModel, InitialValuesAddressEntityModel } from '@sympli-mfe/document-forms-framework/components/address-field';
import DocumentActionPanel from '@sympli-mfe/document-forms-framework/components/document-action-panel';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { TenancyComponentLabelConfig } from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail';
import TitleReferences, { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { RootFormProps } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';
import { NameChangeConversion } from '@sympli-mfe/document-forms-framework/shared-config/party';
import { modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import { LookupItemModel } from '@sympli/ui-framework/models';

import DeceasedTenancyDetail from 'src/containers/documents/scaffolded-form/shared/components/deceased-tenancy-detail';
import { DeceasedTenancyContextData } from '../../components/deceased-tenancy-detail/context/DeceasedTenancyContext';
import { generateDeceasedTenancy } from './helpers';
import { BaseTransmissionApplicationModel } from './models';
import ApplicantAddress from './sections/applicant-address/ApplicantAddress';
import Applicants from './sections/applicants/Applicants';

export interface Context<TDocumentPartyJustification, TNameChange extends {}> {
  partyFormConfig: PartyFormConfig<TNameChange>;
  nameChangeConversion: NameChangeConversion<TDocumentPartyJustification, TNameChange>;
  partLandAffectedType?: 'description' | 'extent';
  sharedAddressFormConfig: AddressFieldFormConfig;
  sharedInitialValuesForNew: AddressFormValueModel<InitialValuesAddressEntityModel>;
  componentLabels: TenancyComponentLabelConfig;
  partyCapacityLookup: LookupItemModel<string>[];
  deceasedTenancyContextData: DeceasedTenancyContextData;
}

function RootForm<TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>({
  className, //
  hideActionsPanel,
  onSaveChanges,
  onCancel
}: RootFormProps): JSX.Element {
  const fieldName = modelKey<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const { isLoading, disabled, dialogPortalId, nextActionLabel } = useDocumentContext();
  const context = useRootFormContext<Context<TDocumentPartyJustification, TNameChange>>();
  const { setValues } = useFormikContext<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>();
  const focusOnTitleReferencesRef = React.useRef<ButtonBaseActions>(null);

  useEffect(() => {
    const focusOnFirstItem = focusOnTitleReferencesRef.current;
    if (!focusOnFirstItem) {
      return;
    }
    focusOnFirstItem.focusVisible();
  }, []);

  const handleSelectedTitleReferenceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checkedIndexes: number[]) => {
      setValues((values: BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>) =>
        generateDeceasedTenancy(values, context.partyFormConfig, context.nameChangeConversion, context.deceasedTenancyContextData)
      );
    },
    [setValues, context.partyFormConfig, context.nameChangeConversion, context.deceasedTenancyContextData]
  );

  return (
    <>
      <Form className={className}>
        <TitleReferences //
          name={fieldName('titleReferences')}
          onChange={handleSelectedTitleReferenceChange}
          skipTouchedCheck
          partLandAffectedType={context.partLandAffectedType}
          resolvePartLandAffectedVisibility={() => false}
        />

        <DeceasedTenancyDetail //
          config={context.deceasedTenancyContextData}
          partyFormConfig={context.partyFormConfig}
        />

        <Applicants
          name={fieldName('applicants')}
          partyFormConfig={context.partyFormConfig}
          componentLabels={context.componentLabels}
          partyCapacityLookup={context.partyCapacityLookup}
        />

        <ApplicantAddress
          name={fieldName('applicantAddress')}
          sharedAddressFormConfig={context.sharedAddressFormConfig}
          sharedInitialValuesForNew={context.sharedInitialValuesForNew}
        />

        {!hideActionsPanel && (
          <DocumentActionPanel //
            isLoading={isLoading}
            disabled={disabled}
            onBack={onCancel}
            nextLabel={nextActionLabel}
            onSaveChanges={onSaveChanges}
          />
        )}
      </Form>
      <PortalTarget id={dialogPortalId} />
    </>
  );
}

export default React.memo(RootForm);

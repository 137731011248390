import { isSameDay } from 'date-fns';
import dateformat from 'dateformat';
import { Action } from 'redux';

import { JurisdictionsEnum } from '@sympli/api-gateway/enums';
import Logger, { InvalidDataError } from '@sympli/ui-logger';

import { DateFormatEnum } from 'src/containers/workspace/financial/settlement-date/models';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { WorkspaceUpdateMessage, WorkspaceUpdateMessageCategoryEnum } from 'src/socket/workspace-update/models';
import { getTimeInJurisdiction } from 'src/utils/timezone/timezoneHelper';
import { actionFetchParticipantConversationSummarySliently } from './actions';
import { ConversationDocument, UpdatedRealtimeMessageModel } from './models';

// Return 10:30 AM for today, 30/10/2019 for other days
export function getFormattedMessageDate(date: Date, currentDate: Date = new Date(), timezone: string = '') {
  const dateFormatString = isSameDay(date, currentDate) ? 'h:MM TT' : DateFormatEnum.AUSSHORTDATE;
  const jurisdiction = JurisdictionsEnum[timezone];
  const dateInTimezone = getTimeInJurisdiction(date, jurisdiction);
  return dateformat(dateInTimezone, dateFormatString);
}

// Return 10:30 AM for today, 12 Apr for other days
export function getFormattedMessageDate101(date: Date, currentDate: Date = new Date(), timezone: string = '') {
  const dateFormatString = isSameDay(date, currentDate) ? DateFormatEnum.HOUR_MIN_TIMEMARKER : DateFormatEnum.AU_SHORT_DAY_MONTH;
  const jurisdiction = JurisdictionsEnum[timezone];
  const dateInTimezone = getTimeInJurisdiction(date, jurisdiction);
  return dateformat(dateInTimezone, dateFormatString);
}

export function getFormattedDate101(date: Date, timezone: string = '') {
  const jurisdiction = JurisdictionsEnum[timezone];
  const dateInTimezone = getTimeInJurisdiction(date, jurisdiction);
  return {
    dateWithoutYear: dateformat(dateInTimezone, DateFormatEnum.HOUR_MIN_TIMEMARKER), // i.e   9:30am
    dateWithShortYear: dateformat(dateInTimezone, DateFormatEnum.DATEMONTH_SHORTYEAR) // i.e  14 Apr 24
  };
}

export function getFormattedLongDate101(date: Date, timezone: string = '') {
  const jurisdiction = JurisdictionsEnum[timezone];
  const dateInTimezone = getTimeInJurisdiction(date, jurisdiction);
  return dateformat(dateInTimezone, DateFormatEnum.DAY_MONTH_HOUR_MIN_MARKER_2); // i.e   9:30am
}

export function getConversationSubject(conversation: ConversationDocument) {
  return conversation.subject;
}

export function handleRealtimeConversationUpdate(dispatch: SafeDispatch<Action>, workspaceId: string, participantId: string, message: WorkspaceUpdateMessage) {
  if (message.Category === WorkspaceUpdateMessageCategoryEnum.Messenger) {
    try {
      const updatedPayload: UpdatedRealtimeMessageModel = JSON.parse(message.Message);

      // need to make sure the message is send to the relevant participant
      if (!updatedPayload.RecipientParticipantIds.includes(participantId)) {
        return;
      }

      const conversationId = updatedPayload.ConversationId;

      dispatch(actionFetchParticipantConversationSummarySliently.request({ workspaceId, participantId, conversationId }));
    } catch (ex) {
      const scope = Logger.scopeWithCustomAttributes({ message });
      Logger.captureException(new InvalidDataError('Unsupported message category', message.Category), scope);
    }
  }
}

import * as React from 'react';

import Typography from '@mui/material/Typography';

import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import Field from '@sympli/ui-framework/components/formik/field';
import RadioField from '@sympli/ui-framework/components/formik/radio-field';

import { colors } from 'src/theme';
import { BOOLEAN_OPTIONS } from './enums';
import { useStyles } from './styles';

interface Props {
  name: string;
}
export function ForeignPersonOrCorporation({ name }: Props) {
  const { disabled } = useDocumentContext();
  const classes = useStyles();

  return (
    <Field //
      label="Is the transferee a foreign person or corporation?"
      name={name}
      component={RadioField}
      disabled={disabled}
      format="boolean"
      options={BOOLEAN_OPTIONS}
      autoFocusOption="controlled"
      className={classes.radio}
      formTip={
        <>
          <Typography variant="body2" color={colors.WHITE}>
            In the state of Queensland, a transferee may be considered a foreign person or corporation if they are a:
          </Typography>
          <Typography variant="body2" sx={{ color: colors.WHITE, marginLeft: -2 }}>
            <ul>
              <li>foreign individual;</li>
              <li>foreign corporation; or</li>
              <li>the trustee of a foreign trust.</li>
            </ul>
          </Typography>
          <Typography variant="body2" color={colors.WHITE}>
            Further clarification on these definitions may be sought in the <i>Duties Act 2001</i> (QLD).
          </Typography>
        </>
      }
    />
  );
}

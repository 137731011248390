import React, { useCallback } from 'react';

import { getIn, useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DocumentFieldArray, { DocumentArrayItemRenderProps } from '@sympli-mfe/document-forms-framework/components/document-field-array';
import { PartyFormConfig } from '@sympli-mfe/document-forms-framework/components/party-form';
import { ProprietorGroupTypeEnum, TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';
import { useDocumentContext } from '@sympli-mfe/document-forms-framework/providers/document-context';
import { createModelKeyAppender, modelKey } from '@sympli-mfe/document-forms-framework/utils';
import { PartyTypeEnum } from '@sympli-mfe/enums-2-21/nsw';
import Checkbox from '@sympli/ui-framework/components/form/base-components/checkbox';

import { useDeceasedTenancyContext } from '../../context/DeceasedTenancyContext';
import { DeceasedTenancyPartyCapacityEnum } from '../../enums';
import { filterOrganisationProprietors, updateProprietorWithCapacity } from '../../helpers';
import { BaseDeceasedTenancyDetailComponentModel, DeceasedProprietorGroupModel, DeceasedProprietorModel } from '../../models';
import DeceasedProprietor from '../deceased-proprietor/DeceasedProprietor';
import { useStyles } from './styles';

interface Props<TNameChange extends {}> {
  itemBinding: string;
  numberOfPartiesInPreceedingGroups: number;
  partyFormConfig: PartyFormConfig<TNameChange>;
  disabledCheckbox: boolean;
}

const baseFieldName = modelKey<BaseDeceasedTenancyDetailComponentModel>();

function DeceasedProprietorArray<TNameChange extends {}>({ itemBinding, numberOfPartiesInPreceedingGroups, partyFormConfig, disabledCheckbox }: Props<TNameChange>): JSX.Element {
  const fieldName = createModelKeyAppender<DeceasedProprietorGroupModel>(itemBinding);
  const name = fieldName('parties');
  const { disabled } = useDocumentContext();
  const classes = useStyles();
  const formikProps = useFormikContext<BaseDeceasedTenancyDetailComponentModel>();
  const { maxEvidences: configMaxEvidences, disableProprietorCapacity } = useDeceasedTenancyContext();
  const { values, setFieldValue } = formikProps;
  const {
    deceasedTenancyDetail: { tenancyType, proprietorGroups },
    partyBook,
    applicantCapacity
  } = values;

  const { parties: proprietors, isSelected: groupIsSelected, proprietorGroupType } = getIn(values, itemBinding);

  const isSingleEvidence =
    configMaxEvidences === 1 ||
    ((applicantCapacity === DeceasedTenancyPartyCapacityEnum.Administrator || applicantCapacity === DeceasedTenancyPartyCapacityEnum.Executor) &&
      tenancyType === TenancyTypeEnum.SoleProprietor);

  const isJointTenants = tenancyType === TenancyTypeEnum.JointTenants || proprietorGroupType === ProprietorGroupTypeEnum.JointTenantsInterse;

  const renderItem = ({ item, itemBinding, itemIndex }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const maxEvidences =
      isSingleEvidence || !item.isSelected ? 1 : configMaxEvidences - proprietors.filter((_, index: number) => index !== itemIndex).flatMap(x => x.evidences).length;
    const showEvidences = groupIsSelected && (isJointTenants ? proprietors.some((p: DeceasedProprietorModel) => p.isSelected) : item.isSelected);
    const onPartyTypeChange = (newPartyType: PartyTypeEnum) => {
      // Organisation selected set all isSelected to false
      if (newPartyType === PartyTypeEnum.Organisation) {
        setFieldValue(fieldName('isSelected'), false);
        const partyFieldName = createModelKeyAppender<DeceasedProprietorModel>(itemBinding);
        setFieldValue(partyFieldName('isSelected'), false);
        return;
      }

      // Only one proprietor group can be selected
      // if any group is already selected, takes priority
      if (proprietorGroups.some(p => p.isSelected)) {
        return;
      }

      if (!isJointTenants) {
        const updatedProprietorGroup: DeceasedProprietorModel[] = proprietors.map((p: DeceasedProprietorModel, index: number) => {
          const updateProprietor: DeceasedProprietorModel = {
            ...p,
            isSelected: index === itemIndex
          };

          return updateProprietor.isSelected ? updateProprietorWithCapacity(updateProprietor, true, applicantCapacity) : updateProprietor;
        });
        updateProprietorGroup(updatedProprietorGroup);
      } else {
        // proprietors uses stale data so we can assume if we only had 1 organisation proprietor, we have already updated it as part of this onPartyTypeChange
        if (proprietors.filter((p: DeceasedProprietorModel) => filterOrganisationProprietors(p, partyBook) !== undefined).length === 1) {
          setFieldValue(fieldName('isSelected'), true);
        }
      }
    };

    return (
      <DeceasedProprietor //
        name={itemBinding}
        partyFormConfig={partyFormConfig}
        maxEvidences={maxEvidences}
        proprietor={item}
        showEvidences={showEvidences}
        onPartyTypeChange={onPartyTypeChange}
      />
    );
  };

  const renderPartyLabel = (item: DeceasedProprietorModel, itemIndex: number): string => {
    if (!isJointTenants) return `Party ${numberOfPartiesInPreceedingGroups + itemIndex + 1}`;
    const party = partyBook.find(pb => pb.id === item.partyBookId)!;
    return `Party ${numberOfPartiesInPreceedingGroups + itemIndex + 1} ${
      proprietors.some((proprietor: DeceasedProprietorModel) => proprietor.isSelected) && groupIsSelected
        ? item.isSelected
          ? '(recently deceased)'
          : !party.mergeMetadata?.requiresJustification
            ? '(pre-deceased)'
            : ''
        : ''
    }`;
  };

  const renderItemTitle = ({ itemIndex, item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>) => {
    const label = renderPartyLabel(item, itemIndex);

    return (
      <FormControlLabel
        key={itemIndex}
        value={itemIndex}
        label={isJointTenants && label}
        checked={!disabledCheckbox && item.isSelected}
        disabled={disabledCheckbox}
        control={isJointTenants ? <Radio color="primary" aria-label="Last Deceased Party" /> : <Checkbox label={label} />}
        onChange={isJointTenants ? handleOnRadioButtonChange : handleProprietorGroupIsSelectedChange}
        className={isJointTenants ? classes.radioButtonRoot : classes.checkboxLabelRoot}
        classes={isJointTenants ? { label: classes.radioButtonLabel } : undefined}
      />
    );
  };

  const updateProprietorGroup = useCallback(
    (updatedProprietors: DeceasedProprietorModel[], previouslySelectedProprietor?: DeceasedProprietorModel) => {
      if (previouslySelectedProprietor) {
        const proprietorParty = partyBook.find(pb => pb.id === previouslySelectedProprietor.partyBookId);
        if (proprietorParty!.receivingOrRelinquishingDetails.isRelinquishing) {
          proprietorParty!.receivingOrRelinquishingDetails.isChangingName = false;
          proprietorParty!.receivingOrRelinquishingDetails.nameChange = undefined;
        }
        setFieldValue(baseFieldName('partyBook'), partyBook);
      }

      setFieldValue(fieldName('parties'), updatedProprietors);
      setFieldValue(
        fieldName('isSelected'),
        updatedProprietors.some(p => p.isSelected)
      );
    },
    [fieldName, partyBook, setFieldValue]
  );

  const handleProprietorGroupIsSelectedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const itemIndex = event.target.value;
    let previouslySelectedProprietor: DeceasedProprietorModel | undefined = proprietors.find((p: DeceasedProprietorModel) => p.isSelected);
    const updatedProprietors: DeceasedProprietorModel[] = proprietors.map((proprietor: DeceasedProprietorModel, index) => {
      const updateProprietor: DeceasedProprietorModel = {
        ...proprietor,
        isSelected: index.toString() === itemIndex ? event.target.checked : false
      };

      return updateProprietor.isSelected ? updateProprietorWithCapacity(updateProprietor, true, applicantCapacity) : updateProprietor;
    });

    updateProprietorGroup(updatedProprietors, previouslySelectedProprietor);
  };

  const handleOnRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      //The idea here is that when the user clicks the radio to change the Last Deceased Party,
      //we will exchange the DateOfDeath and Evidences model between the two Proprietors.
      //The other parties in group have not changed.
      const lastDeceasedProprietor: DeceasedProprietorModel | undefined = proprietors.find((p: DeceasedProprietorModel) => p.isSelected);
      const swapDeceasedProprietor = proprietors.find((_: DeceasedProprietorModel, index: number) => index.toString() === event.target.value)!;
      const updatedProprietors = proprietors.map((proprietor: DeceasedProprietorModel) => {
        const isCurrentProprietor = swapDeceasedProprietor === proprietor;

        const newProprietor: DeceasedProprietorModel = {
          ...proprietor,
          isSelected: isCurrentProprietor
        };

        if (isCurrentProprietor) {
          return updateProprietorWithCapacity(newProprietor, isCurrentProprietor, applicantCapacity, lastDeceasedProprietor);
        }

        if (proprietor === lastDeceasedProprietor) {
          return updateProprietorWithCapacity(newProprietor, isCurrentProprietor, applicantCapacity, swapDeceasedProprietor);
        }

        return updateProprietorWithCapacity(newProprietor, isCurrentProprietor, applicantCapacity);
      });

      updateProprietorGroup(updatedProprietors, lastDeceasedProprietor);
    },
    [proprietors, updateProprietorGroup, applicantCapacity]
  );

  const getItemErrors = ({ item }: DocumentArrayItemRenderProps<DeceasedProprietorModel>): string[] => {
    const { partyCapacity } = item;
    let errors: string[] = [];
    if (disableProprietorCapacity && tenancyType !== TenancyTypeEnum.SoleProprietor && partyCapacity?.capacity) {
      errors.push('Party capacity is not supported in this document');
    }

    return errors;
  };

  return (
    <div data-section={name}>
      <DocumentFieldArray //
        arrayBinding={name}
        renderItem={renderItem}
        itemTitle={renderItemTitle}
        itemError={getItemErrors}
        disabled={disabled}
        itemStyle="formGroup"
        mode="fixed"
      />
    </div>
  );
}

export default React.memo(DeceasedProprietorArray);

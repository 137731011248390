import React, { useState } from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash-es/uniqueId';
import IconButton from '@mui/material/IconButton';

import { UserAvatar101 } from 'src/components/avatars';
import { getNameInitWithFirsLastName } from '../user-profile-avatar/helper';
import UserProfileModal from '../user-profile-modal/UserProfileModal';
import { useStyles } from './styles';

const userProfileMenuId: string = _uniqueId('menu-appbar_');

interface UserProfileNavProps {
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  subscriberName?: string;
  timezone?: string;
  className?: string;
  avatarClassName?: string;
}
function UserProfileNav({
  //
  name,
  firstName,
  lastName,
  email,
  avatarUrl,
  subscriberName,
  timezone,
  avatarClassName,
  className
}: UserProfileNavProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleMenuOnClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOnClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton //
        aria-owns={anchorEl ? userProfileMenuId : undefined}
        aria-haspopup="true"
        onClick={handleMenuOnClick}
        color="inherit"
        className={classNames(classes.avatarRoot, className)}
        size="large"
        data-testid="user-profile-icon"
      >
        <UserAvatar101
          shouldShowNameInit={true}
          text={getNameInitWithFirsLastName(firstName, lastName)}
          src={avatarUrl}
          className={avatarClassName}
          toolTipPlacement="left"
          showToolTip={false}
          classes={{ avatar: classNames(classes.avatar, anchorEl && classes.avatarSelected) }}
        />
      </IconButton>
      {
        <UserProfileModal //
          id={userProfileMenuId}
          name={name}
          firstName={firstName}
          lastName={lastName}
          email={email}
          avatarUrl={avatarUrl}
          subscriberName={subscriberName}
          timezone={timezone}
          onClose={handleMenuOnClose}
          anchorEl={anchorEl}
        />
      }
    </>
  );
}

export default UserProfileNav;

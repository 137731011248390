import _cloneDeep from 'lodash-es/cloneDeep';
import _isEqual from 'lodash-es/isEqual';
import { Action, createReducer } from 'typesafe-actions';

import { useStoreSelector } from 'src/hooks';
import { ApiStatus } from 'src/utils/http';
import {
  actionCreateNewConversation,
  actionFetchParticipantConversationSummary,
  actionFetchParticipantConversationSummarySliently,
  actionSendConversationMessage,
  actionUpdateConversationIsRead,
  actionUpdateSendConversationMessage
} from './actions';
import { ConversationDocument, ConversationDocumentsApiRequest, MessageModel, MessagingSendingStatusEnum } from './models';

export function useMessengerConversations(workspaceId: string, participantId: string) {
  const state = useStoreSelector(store => store.messengerConversations);
  if (state.args?.workspaceId === workspaceId && state.args?.participantId === participantId) {
    return state;
  }
  return initialState;
}

export interface MessengerConversationsState {
  items: Array<ConversationDocument>;
  status: ApiStatus;
  isLoading: boolean;
  isRefetching: boolean;
  error?: string;
  realtimeUpdatedConversationId: string | null; // this is send and only used by the real time pushed back
  args?: ConversationDocumentsApiRequest;
}

const initialState: MessengerConversationsState = {
  items: [],
  status: 'idle',
  isLoading: true,
  isRefetching: false,
  error: undefined,
  realtimeUpdatedConversationId: null
};

const messengerConversationsReducer = createReducer<
  //
  MessengerConversationsState,
  Action
>(initialState)
  .handleAction(actionFetchParticipantConversationSummary.request, (state, action): MessengerConversationsState => {
    const status = _isEqual(state.args, action.payload) ? (state.status === 'pending' ? 'pending' : 'refetching') : 'pending';
    return {
      ...(status === 'pending' ? initialState : state),
      status,
      isLoading: status !== 'refetching',
      isRefetching: status === 'refetching',
      error: undefined,
      args: action.payload
    };
  })
  .handleAction(actionFetchParticipantConversationSummary.success, (state, action): MessengerConversationsState => {
    return {
      ...state,
      isLoading: false,
      isRefetching: false,
      items: action.payload.data.conversations
    };
  })
  .handleAction(actionFetchParticipantConversationSummary.failure, (state, action): MessengerConversationsState => {
    return {
      ...state,
      isLoading: false,
      isRefetching: false,
      error: action.payload.error.message
    };
  })
  .handleAction(actionSendConversationMessage.request, (state, action): MessengerConversationsState => {
    const { conversationId, message, sentByUserName, sentBySubscriberName, participantId, id } = action.payload;
    const { items: conversationList } = state;
    const conversationIndex = conversationList.findIndex(c => c.conversationId === conversationId);

    if (conversationIndex === -1) {
      return state;
    }

    const conversation = conversationList[conversationIndex];
    const newMessageItem: MessageModel = {
      text: message,
      sentByParticipantId: participantId,
      createdDate: new Date().toISOString(),
      sentByUserName,
      sentBySubscriberName,
      sendingStatus: MessagingSendingStatusEnum.Sending,
      id
    };
    const newConversations = conversationList.concat();
    newConversations[conversationIndex] = {
      ...conversation,
      messages: conversation.messages.concat(newMessageItem)
    };
    return {
      ...state,
      items: newConversations
    };
  })
  .handleAction(actionUpdateSendConversationMessage, (state, action): MessengerConversationsState => {
    const { messageId, conversationId, isSuccess } = action.payload;

    if (messageId) {
      const newItems = _cloneDeep(state.items);
      const conversation = newItems.find(x => x.conversationId === conversationId);
      if (conversation) {
        const message = conversation.messages.find(x => x.id === messageId);
        if (message) {
          message.sendingStatus = isSuccess ? MessagingSendingStatusEnum.Sent : MessagingSendingStatusEnum.Failed;
          return { ...state, items: newItems };
        }
      }
    }
    return state;
  })
  .handleAction(actionUpdateConversationIsRead.request, (state, action): MessengerConversationsState => {
    const { conversationId, isRead } = action.payload;
    const { items: conversationList } = state;
    const conversationIndex = conversationList.findIndex(c => c.conversationId === conversationId);

    if (conversationIndex === -1) {
      return state;
    }

    const conversation = conversationList[conversationIndex];
    const newConversations = conversationList.concat();
    newConversations[conversationIndex] = { ...conversation, isRead };
    return {
      ...state,
      realtimeUpdatedConversationId: null,
      items: newConversations
    };
  })
  .handleAction(actionCreateNewConversation, (state, action): MessengerConversationsState => {
    const { conversationId, subject, message, sentByUserName, sentBySubscriberName, recipientParticipantIds, participantId } = action.payload;
    const { items: conversationList } = state;
    const newConversation: ConversationDocument = {
      conversationId,
      isRead: true,
      subject: subject,
      messages: [{ text: message, createdDate: new Date().toISOString(), sentByUserName, sentBySubscriberName, sentByParticipantId: participantId }],
      recipientParticipantIds
    };
    return { ...state, items: conversationList.concat(newConversation) };
  })
  .handleAction(actionFetchParticipantConversationSummarySliently.request, (state): MessengerConversationsState => {
    return {
      ...state
    };
  })
  .handleAction(actionFetchParticipantConversationSummarySliently.success, (state, action): MessengerConversationsState => {
    const {
      conversationId,
      data: { conversations }
    } = action.payload;

    // compare the message here, append the new message if not exist, and
    // if the user was on the thread dashboard and new thread pushed, we will
    // mark the message thread to read in the message detail level

    return {
      ...state,
      items: conversations,
      realtimeUpdatedConversationId: conversationId
    };
  })
  .handleAction(actionFetchParticipantConversationSummarySliently.failure, (state, action): MessengerConversationsState => {
    return {
      ...state,
      error: action.payload.error.message
    };
  });

export default messengerConversationsReducer;

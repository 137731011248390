import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import visuallyHidden from '@mui/utils/visuallyHidden';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import { MultiUserAvatar } from 'src/components/avatars';
import { UserAvatarModel } from 'src/components/avatars/multi-user-avatar';
import { CopyToClipboardContainer101 as CopyToClipboardContainer } from 'src/components/copy-to-clipboard-container';
import styles, { ClassKeys } from './styles';

interface OwnProps {
  className?: string;

  title: string | JSX.Element;
  underline?: boolean;
  description: string | JSX.Element;

  disabled?: boolean;
  selected?: boolean;
  isTitleSecondaryColor?: boolean;

  linkTo?: string;
  participants?: UserAvatarModel[];

  // ARIA roles
  titleAriaLabel?: string;
  descriptionAriaLabel?: string;

  enableCopyToClipboardButton?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class DocumentItemBase extends React.PureComponent<Props> {
  render() {
    const { classes, className } = this.props;
    return (
      <FlexLayout flexDirection="row" alignItems="center" className={classNames(classes.root, className)}>
        {this.renderMain()}
        {this.renderAvatar()}
      </FlexLayout>
    );
  }

  private renderMain() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        {this.renderTitle()}
        {this.renderDescription()}
      </div>
    );
  }

  private renderAvatar() {
    const { classes, participants } = this.props;
    if (!participants) {
      return null;
    }
    return <MultiUserAvatar className={classes.avatar} items={participants} tooltipPlacement="right-start" />;
  }

  private renderDescription() {
    const { classes, description, isTitleSecondaryColor, disabled, descriptionAriaLabel, enableCopyToClipboardButton } = this.props;
    return (
      <div //
        className={classNames(classes.description, (isTitleSecondaryColor || disabled) && classes.descriptionSecondary)}
      >
        {enableCopyToClipboardButton && typeof description === 'string' && description.length ? (
          <CopyToClipboardContainer textToCopy={description} fieldName="address">
            <span style={visuallyHidden}>{descriptionAriaLabel}</span>
            {description}
          </CopyToClipboardContainer>
        ) : (
          <>
            <span style={visuallyHidden}>{descriptionAriaLabel}</span>
            {description}
          </>
        )}
      </div>
    );
  }

  private renderTitle() {
    const { classes, disabled, title, linkTo, selected, isTitleSecondaryColor, underline = false, titleAriaLabel, enableCopyToClipboardButton } = this.props;
    return (
      <div
        className={classNames(classes.title, (linkTo || underline) && classes.titleLink, {
          [classes.titlePending]: isTitleSecondaryColor,
          [classes.titleDisabled]: disabled,
          [classes.selected]: selected
        })}
      >
        {enableCopyToClipboardButton && typeof title === 'string' ? (
          <CopyToClipboardContainer textToCopy={title} fieldName="title">
            <span style={visuallyHidden}>{titleAriaLabel}</span>
            {linkTo && !disabled ? ( //
              <Link to={linkTo}>{title}</Link>
            ) : (
              title
            )}
          </CopyToClipboardContainer>
        ) : (
          <>
            <span style={visuallyHidden}>{titleAriaLabel}</span>
            {linkTo && !disabled ? ( //
              <Link to={linkTo}>{title}</Link>
            ) : (
              title
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DocumentItemBase);

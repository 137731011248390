import { ArchivedInvitationDetailApiResponse } from '@sympli/api-gateway/models';

import { resolveIsoDate } from 'src/components/date-range-picker/helpers';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import environments from 'src/environments';
import http from 'src/utils/http';
import { InvitationsDashboardAggregatorApiRequest, InvitationsDashboardAggregatorApiResponse } from './aggregators/models';
import { InvitationResponseTypeEnum } from './models';

// import { InvitationResponseTypeEnum } from '../invitations/components/received-invite-detail/models';
const { TARGET_MACHINE, TARGET_API_V2 } = environments;

export const fetchAggregatorInfo = (request: InvitationsDashboardAggregatorApiRequest) => {
  const { queries: existingQueries, assignmentType: existingAssignmentType, ...rest } = request;

  // we need to format the datetime in this case
  const queries = existingQueries.map(query => {
    if (query.lastInvitationSentPeriod) {
      const formatPeriod = {
        start: resolveIsoDate(query.lastInvitationSentPeriod.start as Date, 'start'),
        end: resolveIsoDate(query.lastInvitationSentPeriod.end as Date, 'end')
      };
      return { ...query, lastInvitationSentPeriod: { ...formatPeriod } };
    }
    return query;
  });

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;

  return http.post<InvitationsDashboardAggregatorApiResponse>(
    '/dashboards/invitation-aggregators',
    { queries, assignmentType, ...rest },
    { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` }
  );
};

type AcceptDashboardInvitationApiRequest = {
  invitationId: string;
  workspaceId: string;
  invitationResponseTypeId: InvitationResponseTypeEnum.Accept;
  groupId?: string;
  reference?: string;
  // this property decides whether user has accepted settlement date as well or not
  acceptInviteOnly: boolean;
};

type DeclineDashboardInvitationApiRequest = {
  invitationId: string;
  workspaceId: string;
  invitationResponseTypeId: InvitationResponseTypeEnum.Decline;
  reason: string;
};

type ForwardDashboardInvitationToGroupApiRequest = {
  invitationId: string;
  workspaceId: string;
  groupId: string;
};

type ForwardDashboardInvitationToSubscriberApiRequest = {
  invitationId: string;
  workspaceId: string;
  isLinkedWorkspace?: boolean;
  newInvitationDescription?: string;
  forwardedSubscriberDisplayName: string;
  forwardedSubscriberElnoId: string;
  forwardedSubscriberId: string;
};

/**
 * Tickle.Application.Service.Model.Dashboard.InvitationResponse
 */
export interface DashboardInvitationResponseApiRequest {
  invitationId: string;
  workspaceId: string;
  invitationResponseTypeId: InvitationResponseTypeEnum;
  groupId?: string;
  reason?: string; // This is reason for decline
  reference?: string;
  taskId?: string;
  integrationReference?: string;
}

export interface DashboardForwardInvitationApiRequest {
  invitationId: string;
  workspaceId: string;
  groupId?: string;
}

export function respondToInvitation(body: AcceptDashboardInvitationApiRequest): Promise<void>;
export function respondToInvitation(body: DeclineDashboardInvitationApiRequest): Promise<void>;
export function respondToInvitation(body: AcceptDashboardInvitationApiRequest | DeclineDashboardInvitationApiRequest): Promise<void> {
  return http.post<
    //
    void,
    DashboardInvitationResponseApiRequest
  >('/invitations/invitation-response', body, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

// todo: move this into the new invitation dashboard and rename to remove to group
export function forwardInvitationToGroup(body: ForwardDashboardInvitationToGroupApiRequest): Promise<void> {
  return http.post<
    //
    void,
    DashboardForwardInvitationApiRequest
  >('/dashboards/forward-invitation', body, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

export function forwardInvitationToSubscriber(body: ForwardDashboardInvitationToSubscriberApiRequest): Promise<void> {
  return http.post<
    //
    void,
    ForwardDashboardInvitationToSubscriberApiRequest
  >('/Invitations/forward-to-subscriber', body, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

// this is shared for both assign & un-assigned
export interface DashboardInvitationAllocateInvitationsApiRequest {
  participantId: string;
  userId?: string;
}

export interface DashboardInvitationAllocateInvitationsApiResponse {
  allSuccess: boolean;
  successfulIds: Array<string>;
  failedIdsAndReasons: Array<{ workspaceId: string; reason: string }>;
}

export interface CommonApiResponse {
  allSuccess: boolean;
}

export function allocateInvitations(request: DashboardInvitationAllocateInvitationsApiRequest[]) {
  return http.post<DashboardInvitationAllocateInvitationsApiResponse>('/dashboards/allocate-invitations', request, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

// it is calling different endpoint but same request/response
export function invitationsAssignment(isAssignAction: boolean, request: DashboardInvitationAllocateInvitationsApiRequest[]) {
  if (isAssignAction) {
    return http.post<DashboardInvitationAllocateInvitationsApiResponse>('/Invitations/assign', request, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
  }

  return http.post<DashboardInvitationAllocateInvitationsApiResponse>('/Invitations/unassign', request, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

export interface InvitationDashboardArchivedDetailsApiRequest {
  invitationId: string;
}

//
export function getInvitationDashboardArchivedDetails(args: InvitationDashboardArchivedDetailsApiRequest) {
  return http.get<ArchivedInvitationDetailApiResponse>(`/Invitations/${encodeURIComponent(args.invitationId)}/archived-detail`, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
}

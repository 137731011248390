import { ArchivedInvitationDetailApiResponse } from '@sympli/api-gateway/models';
import { ColumnsModel } from '@sympli/ui-framework/components/table';

import { ScreenSizeVariant } from 'src/theme/screens';
import { modelKey } from 'src/utils/formUtils';
import { ArchivedInvitationLogsActionEnum, ArchivedInvitationLogsRoleEnum } from '../models';
import { actionFormatter, dateFormatter, fromToFormatter, inviteLogFormatter, messageFormatter } from './formatters';
import { Row } from './InvitationsCardDetail';

const fieldName = modelKey<Row>();

export const getColumns = (screenVariant: ScreenSizeVariant): ColumnsModel<Row> => {
  return {
    inviteLog: {
      label: 'INVITE LOG',
      name: fieldName('inviteLog'),
      verticalAlign: 'top',
      thClassName: 'w-[80px]',
      formatter: inviteLogFormatter
    },
    date: {
      label: 'DATE',
      name: fieldName('date'),
      verticalAlign: 'top',
      thClassName: 'w-[94px]',
      formatter: dateFormatter
    },
    action: {
      label: 'ACTION',
      name: fieldName('action'),
      verticalAlign: 'top',
      thClassName: 'w-[90px]',
      formatter: actionFormatter
    },
    message: {
      label: 'MESSAGE',
      name: fieldName('message'),
      verticalAlign: 'top',
      get: ({ message }: Row) => ({ message }),
      formatter: messageFormatter
    },
    originalSender: {
      label: 'ORIGINAL SENDER',
      name: fieldName('originalSender'),
      verticalAlign: 'top',
      thClassName: screenVariant === 'small-laptop' ? 'w-[110px]' : 'w-[140px]',
      get: ({ originalSender }: Row) => ({ subscriberName: originalSender }),
      formatter: fromToFormatter
    },
    from: {
      label: 'FROM',
      name: fieldName('from'),
      verticalAlign: 'top',
      thClassName: screenVariant === 'small-laptop' ? 'w-[110px]' : 'w-[140px]',
      get: ({ from }: Row) => ({ subscriberName: from }),
      formatter: fromToFormatter
    },
    to: {
      label: 'TO',
      name: fieldName('to'),
      verticalAlign: 'top',
      thClassName: screenVariant === 'small-laptop' ? 'w-[110px]' : 'w-[140px]',
      get: ({ to }: Row) => ({ subscriberName: to }),
      formatter: fromToFormatter
    },
    receivedMessage: {
      label: 'RECEIVED MESSAGE',
      name: fieldName('receivedMessage'),
      verticalAlign: 'top',
      get: ({ receivedMessage }: Row) => ({ message: receivedMessage }),
      formatter: messageFormatter
    },
    forwardedMessage: {
      label: 'FORWARDED MESSAGE',
      name: fieldName('forwardedMessage'),
      verticalAlign: 'top',
      get: ({ forwardedMessage }: Row) => ({ message: forwardedMessage }),
      formatter: messageFormatter
    },
    declinedMessage: {
      label: 'DECLINE NOTE',
      name: fieldName('declineNote'),
      verticalAlign: 'top',
      get: ({ declineNote }: Row) => ({ message: declineNote }),
      formatter: messageFormatter
    }
  };
};

export const getRowsAndColumns = (
  role: ArchivedInvitationLogsRoleEnum,
  action: ArchivedInvitationLogsActionEnum,
  items: ArchivedInvitationDetailApiResponse[],
  screenVariant: ScreenSizeVariant,
  isSentAndReceived: boolean
): { rows: Row[]; columns: ColumnsModel<Row> } => {
  const columns = getColumns(screenVariant);

  // the subscriber is the forwarder, i.e A->B->C->D, this is for B or C
  if (role === ArchivedInvitationLogsRoleEnum.Forwarder) {
    return {
      rows: items.map((x, index) => {
        return {
          inviteLog: index,
          date: x.date,
          originalSender: x.originalFromSubscriber,
          from: x.forwardedFromSubscriber,
          to: x.forwardedToSubscriber,
          action: {
            userName: x.actionUser,
            type: ArchivedInvitationLogsActionEnum.Forwarded
          },
          receivedMessage: x.forwardedFromInstruction,
          forwardedMessage: x.forwardedToInstruction
        } satisfies Row;
      }),
      columns: {
        //
        inviteLog: columns.inviteLog,
        date: columns.date,
        originalSender: columns.originalSender,
        from: columns.from,
        to: columns.to,
        action: columns.action,
        receivedMessage: columns.receivedMessage,
        forwardedMessage: columns.forwardedMessage
      } satisfies ColumnsModel<Row>
    };
  } else if (role === ArchivedInvitationLogsRoleEnum.LastReceiver) {
    // the subscriber is the last receiver, i.e A->B->C->D, or just A->B.
    // this is for D or B
    const lastReceiverColumns: ColumnsModel<Row> = {
      inviteLog: columns.inviteLog,
      date: { ...columns.date, label: isSentAndReceived ? 'SENT/RECEIVED DATE' : 'RECEIVED DATE', thClassName: isSentAndReceived ? 'w-[140px]' : 'w-[104px]' },
      action: columns.action,
      message: columns.message
    };

    if (action === ArchivedInvitationLogsActionEnum.Declined) {
      lastReceiverColumns.declineNote = columns.declinedMessage;
    }

    return {
      rows: items.map((x, index) => {
        return {
          inviteLog: index,
          date: x.date,
          action: {
            userName: x.actionUser,
            type: action
          },
          message: x.instruction,
          declineNote: action === ArchivedInvitationLogsActionEnum.Declined ? x.declinedReason : undefined
        } satisfies Row;
      }),
      columns: lastReceiverColumns satisfies ColumnsModel<Row>
    };
  }

  // the rest scenarios, subscriber is the sender,

  // For non-forwarding process, i.e A-> B, this is for A
  if (items.length === 1) {
    const nonForwardingSender: ColumnsModel<Row> = {
      //
      inviteLog: columns.inviteLog,
      date: { ...columns.date, label: 'SENT DATE' },
      action: columns.action,
      message: columns.message
    };

    if (action === ArchivedInvitationLogsActionEnum.Declined) {
      nonForwardingSender.declineNote = columns.declinedMessage;
    }

    return {
      rows: items.map((x, index) => {
        return {
          inviteLog: index,
          date: x.date,
          action: {
            userName: x.actionUser,
            type: action === ArchivedInvitationLogsActionEnum.Withdrawn ? ArchivedInvitationLogsActionEnum.Withdrawn : ArchivedInvitationLogsActionEnum.Sent
          },
          message: x.instruction,
          declineNote: action === ArchivedInvitationLogsActionEnum.Declined ? x.declinedReason : undefined
        } satisfies Row;
      }),
      columns: nonForwardingSender satisfies ColumnsModel<Row>
    };
  }

  // For forwarding process, i.e. A->B->C->D, this is for A
  return {
    rows: items.map((x, index) => {
      return {
        inviteLog: index,
        date: x.date,
        from: index === 0 ? x.originalFromSubscriber : x.forwardedFromSubscriber,
        to: x.forwardedToSubscriber,
        action: {
          userName: x.actionUser,
          type: index === 0 ? ArchivedInvitationLogsActionEnum.Sent : ArchivedInvitationLogsActionEnum.Forwarded
        },
        message: index === 0 ? x.instruction : ''
      } satisfies Row;
    }),
    columns: {
      inviteLog: columns.inviteLog,
      date: columns.date,
      from: columns.from,
      to: columns.to,
      action: columns.action,
      message: columns.message
    }
  };
};

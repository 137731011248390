import * as React from 'react';

import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { WorkspaceRoleEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDirectionsApiResponse } from '@sympli/api-gateway/models';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { PortalSource } from '@sympli/ui-framework/components/portal';
import { currency } from '@sympli/ui-framework/utils/formatters';

import { Box } from 'src/components/layout';
import WorkspaceExpansionBox from 'src/containers/shared/workspace-expansion-box';
import { isZeroAmountLiteral } from '../../helpers';
import { DirectionsTrustAccountModel, SourceFundModel, TrustAccountMap } from '../../models';
import SympliSourceAccountCalculator from '../sympli-source-account-calculator';
import styles, { ClassKeys } from './styles';

export interface OwnProps {
  // basic workspace info
  workspaceTypeId: WorkspaceTypeEnum;
  // current participant info
  workspaceRoleId: WorkspaceRoleEnum;
  // direction summary
  directionsSummary: WorkspaceDirectionsApiResponse['directionsSummary'];
  trustAccounts: Array<DirectionsTrustAccountModel>;
  trustAccountBankDetailMap?: TrustAccountMap;
  sourceFunds?: Array<SourceFundModel>; // This is used to determine if we show Sympli Source Account calculator
  // other
  portalIdForDirectionSummary?: string;
  requiresStampDuty: boolean;
  onExpandChange?(expand: boolean): void;
  havePurchaserParticipant?: boolean;
}
type Props = OwnProps & WithStyles<ClassKeys>;

class DirectionsSummary extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    trustAccountBankDetailMap: {}
  };

  render() {
    const { portalIdForDirectionSummary } = this.props;
    if (!portalIdForDirectionSummary) {
      return this.renderContent();
    }
    return <PortalSource id={portalIdForDirectionSummary}>{this.renderContent()}</PortalSource>;
  }

  private renderContent() {
    const { classes, requiresStampDuty, onExpandChange } = this.props;
    if (requiresStampDuty) {
      return (
        <WorkspaceExpansionBox
          title={
            <Typography variant="h6" className="text-[18px] font-bold leading-[36px]">
              Calculators
            </Typography>
          }
          onExpandChange={onExpandChange}
          className={classes.WorkspaceExpansionBox}
        >
          {this.renderCalculators()}
        </WorkspaceExpansionBox>
      );
    }

    return <Box>{this.renderSettlementBalanceCalculator()}</Box>;
  }

  private renderCalculators() {
    const { workspaceRoleId: workspaceRole, classes, havePurchaserParticipant } = this.props;
    switch (workspaceRole) {
      case WorkspaceRoleEnum.Purchaser:
        return (
          <>
            {this.renderPurchaserTotalFundRequiredCalculator(Boolean(havePurchaserParticipant))}
            {this.renderSettlementAndSourceAccountBalanceCalculator()}
          </>
        );
      case WorkspaceRoleEnum.Vendor:
        return (
          <>
            {this.renderVendorTotalFundRequiredCalculator()}
            {this.renderSettlementAndSourceAccountBalanceCalculator()}
          </>
        );
      case WorkspaceRoleEnum.IncomingMortgagee:
        return (
          <>
            {this.renderPurchaserTotalFundRequiredCalculator(Boolean(havePurchaserParticipant))}
            <Divider className={classes.divider101} />
            {this.renderSettlementBalanceCalculator()}
          </>
        );
      case WorkspaceRoleEnum.DischargingMortgagee:
      case WorkspaceRoleEnum.DischargingCaveator:
        return <>{this.renderSettlementBalanceCalculator()}</>;
      case WorkspaceRoleEnum.Beneficiary:
        return <>{this.renderSettlementAndSourceAccountBalanceCalculator()}</>;
      default:
        return null;
    }
  }

  private renderSettlementAndSourceAccountBalanceCalculator() {
    const { classes, trustAccounts, trustAccountBankDetailMap, sourceFunds } = this.props;
    return (
      <>
        <Divider className={classes.divider101} />
        {this.renderSettlementBalanceCalculator()}
        <SympliSourceAccountCalculator //
          trustAccounts={trustAccounts}
          trustAccountBankDetailMap={trustAccountBankDetailMap}
          sourceFunds={sourceFunds}
        />
      </>
    );
  }

  private renderVendorTotalFundRequiredCalculator() {
    const { directionsSummary, classes } = this.props;
    const total = Number(directionsSummary.purchaserPayAmount) + Number(directionsSummary.vendorSourceFunds) - Number(directionsSummary.settlementDirections);
    const isZeroBalanced = isZeroAmountLiteral(total);
    const totalStyle = isZeroBalanced ? classes.balancedTotal101 : classes.balanceTotal101;
    return (
      <>
        <FlexLayout className={classNames(classes.summaryRow101, classes.summaryTitle)} flexDirection="row" alignItems="center">
          <div className={classes.summaryTitleDesc}>Total Funds Available</div>
        </FlexLayout>
        <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
          <div className={classes.summaryDes}>Purchaser Pays Vendor:</div>
          <div>{currency(directionsSummary.purchaserPayAmount)}</div>
        </FlexLayout>
        <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
          <div>
            <span className={classes.summaryMinusSymbol}>&#8722;</span>
            <span>Settlement Directions:</span>
          </div>
          <div>{currency(directionsSummary.settlementDirections)}</div>
        </FlexLayout>
        <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
          <div>
            <span className={classes.summaryPlusSymbol}>&#43;</span>
            <span>Vendor's Source Funds:</span>
          </div>
          <div>{currency(directionsSummary.vendorSourceFunds)}</div>
        </FlexLayout>
        <FlexLayout className={classNames(classes.summaryRow101, classes.lastSummaryRow101, classes.halfMarginLeft)} justifyContent="space-between">
          <div />
          <div className={totalStyle}>{currency(isZeroBalanced ? 0 : total)}</div>
        </FlexLayout>
      </>
    );
  }

  private renderPurchaserTotalFundRequiredCalculator(havePurchaserParticipant: boolean) {
    const { directionsSummary, classes } = this.props;
    const totalImSourceFunds = Number(directionsSummary.calculatedLoanAdvanceAmount) + Number(directionsSummary.incomingMortgageeSourceFunds);
    const total =
      Number(directionsSummary.purchaserPayAmount) + Number(directionsSummary.purchaserDirections) - Number(directionsSummary.purchaserSourceFunds) - Number(totalImSourceFunds);
    const isZeroBalanced = isZeroAmountLiteral(total);
    const totalStyle = isZeroBalanced ? classes.balancedTotal101 : classes.balanceTotal101;

    return (
      havePurchaserParticipant && (
        <>
          <FlexLayout className={classNames(classes.summaryRow101, classes.summaryTitle)} flexDirection="row" alignItems="center">
            <div className={classes.summaryTitleDesc}>Total Funds Required</div>
          </FlexLayout>
          <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
            <div className={classes.summaryDes}>Purchaser Pays Vendor:</div>
            <div>{currency(directionsSummary.purchaserPayAmount)}</div>
          </FlexLayout>
          <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
            <div>
              <span className={classes.summaryPlusSymbol}>&#43;</span>
              <span>Purchaser's Payments:</span>
            </div>
            <div>{currency(directionsSummary.purchaserDirections)}</div>
          </FlexLayout>
          <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
            <div>
              <span className={classes.summaryMinusSymbol}>&#8722;</span>
              <span>IM's Source Funds:</span>
            </div>
            <div>{currency(totalImSourceFunds)}</div>
          </FlexLayout>
          <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
            <div>
              <span className={classes.summaryMinusSymbol}>&#8722;</span>
              <span>Purchaser's Source Funds:</span>
            </div>
            <div>{currency(directionsSummary.purchaserSourceFunds)}</div>
          </FlexLayout>
          <FlexLayout className={classNames(classes.summaryRow101, classes.lastSummaryRow101, classes.halfMarginLeft)} justifyContent="space-between">
            <div />
            <div className={totalStyle}>{currency(isZeroBalanced ? 0 : total)}</div>
          </FlexLayout>
        </>
      )
    );
  }

  private renderSettlementBalanceCalculator() {
    const { directionsSummary, classes } = this.props;
    const sourceTotalWithoutLoanAdvanceAmount =
      Number(directionsSummary.vendorSourceFunds) +
      Number(directionsSummary.purchaserSourceFunds) +
      Number(directionsSummary.beneficiarySourceFunds) +
      Number(directionsSummary.incomingMortgageeSourceFunds);
    const sourceTotal = sourceTotalWithoutLoanAdvanceAmount + Number(directionsSummary.calculatedLoanAdvanceAmount);
    const directionTotal = Number(directionsSummary.settlementDirections) + Number(directionsSummary.purchaserDirections);
    const surplus = Number(directionsSummary.settlementDirectionsSurplus);
    const total = sourceTotal - directionTotal - surplus;
    const isZeroBalanced = isZeroAmountLiteral(total);
    const totalStyle = isZeroBalanced ? classes.balancedTotal101 : classes.balanceTotal101;

    return (
      <>
        <FlexLayout className={classes.summaryRow101} flexDirection="row" alignItems="center">
          <div className={classes.summaryTitleDesc}>Settlement Balance</div>
        </FlexLayout>
        <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
          <div className={classes.summaryDes}>Total Sources:</div>
          <div data-testid="display-sourceTotal">{currency(sourceTotal)}</div>
        </FlexLayout>
        <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
          <div>
            <span className={classes.summaryMinusSymbol}>&#8722;</span>
            <span>Total Payments:</span>
          </div>
          <div>{currency(directionTotal)}</div>
        </FlexLayout>
        {surplus !== 0 && (
          <FlexLayout className={classes.summaryRow101} justifyContent="space-between">
            <div>
              <span className={classes.summaryMinusSymbol}>&#8722;</span>
              <span>Surplus:</span>
            </div>
            <div>{currency(surplus)}</div>
          </FlexLayout>
        )}
        <FlexLayout className={classNames(classes.summaryRow101, classes.lastSummaryRow101, classes.halfMarginLeft)} justifyContent="space-between">
          <div />
          <div className={totalStyle}>{currency(isZeroBalanced ? 0 : total)}</div>
        </FlexLayout>
      </>
    );
  }
}

export default withStyles(styles)(DirectionsSummary);

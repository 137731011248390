import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    saveDraftButtonContainer: {
      flexGrow: 1,
      marginRight: 15,
      textAlign: 'right'
    }
  });

export type ClassKeys = typeof styles;

export default styles;

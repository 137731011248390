import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { EmptyContext, memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import {
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS,
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE,
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT
} from '../../isRequiredChecks';
import { DocumentAttachmentModel, Mortgage2_20_1Model, MortgageTermsAndConditionsModel, StandardTermsDocumentReferenceModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
export const ADDITIONAL_TERMS_AND_CONDITIONS_MAX_CHARACTER_COUNT = 4000;
const DOCUMENT_REFERENCE_CHARACTER_LENGTH = 9;

interface StDrItemContext {
  termsAndConditions: MortgageTermsAndConditionsModel;
  standardTermsDocumentReferences: StandardTermsDocumentReferenceModel[];
}

const yupStDrItem = memoizeSchemaWithContext(
  yup
    .object<StandardTermsDocumentReferenceModel, StDrItemContext>({
      documentReference: yup
        .string()
        .defined()
        .default('')
        .trim()
        .typeError(msg.INVALID_VALUE)
        .testContextualRule({
          message: msg.REQUIRED,
          uniqueTestName: '"standardTermsDocumentReferences.documentReference" required check',
          onlyIf: (parent: StandardTermsDocumentReferenceModel, context: StDrItemContext) => {
            return REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(parent, context.termsAndConditions);
          },
          requirement: (parent: StandardTermsDocumentReferenceModel /*, context: StDrItemContext*/) => {
            const result = isNotNullOrEmpty(parent.documentReference);
            return result;
          }
        })
        // we can't use .length rule automatically because this field can be null and length does not work with null
        .testContextualRule({
          message: msg.LENGTH_MUST_BE_X_CHARACTERS(DOCUMENT_REFERENCE_CHARACTER_LENGTH),
          uniqueTestName: '"standardTermsDocumentReferences.documentReference" length check',
          onlyIf: (parent: StandardTermsDocumentReferenceModel /*, context: StDrItemContext*/) => {
            return !!parent.documentReference;
          },
          requirement: (parent: StandardTermsDocumentReferenceModel /*, context: StDrItemContext*/) => {
            return parent.documentReference?.length === DOCUMENT_REFERENCE_CHARACTER_LENGTH;
          }
        })

        .testContextualRule({
          message: 'This document reference is already used',
          uniqueTestName: '"documentReference" unique among all standardTermsDocumentReferences',
          onlyIf: (parent: StandardTermsDocumentReferenceModel, context: StDrItemContext) => {
            return !!parent.documentReference && context.standardTermsDocumentReferences.length > 0;
          },
          requirement: (parent: StandardTermsDocumentReferenceModel, context: StDrItemContext) => {
            // find item with identical documentReference that is not the current item.
            const otherItems = context.standardTermsDocumentReferences.filter(item => item !== parent);
            if (!otherItems.length) {
              return true;
            }
            return !otherItems.some(item => {
              return item.documentReference === parent.documentReference;
            });
          }
        })
        .log()
      // .test({
      //   name: '"documentReference" unique among all standardTermsDocumentReferences',
      //   message: 'This document reference is already used',
      //   test: function test(this: yup.TestContext<Mortgage2_20_1Model>, fieldValue: string): boolean {
      //     if (!fieldValue) {
      //       return true;
      //     }
      //     const rootModel = this.options.context!;
      //     const standardTermsDocumentReferences = rootModel.termsAndConditions.standardTermsDocumentReferences;

      //     const parentPath = getParentPath(this.path);
      //     const parent: StandardTermsDocumentReferenceModel = _get(rootModel, parentPath);
      //     const index = standardTermsDocumentReferences.indexOf(parent);

      //     const foundIndex = standardTermsDocumentReferences.findIndex(x => (x.documentReference ?? '').trim() === fieldValue);
      //     if (foundIndex === -1) {
      //       return true;
      //     }
      //     return index === foundIndex;
      //   }
      // })
    })
    .defined()
    .log(),
  (parentContext: TermsAndConditionsContext, parentValue: StandardTermsDocumentReferenceModel[]): StDrItemContext => {
    return {
      termsAndConditions: parentContext.termsAndConditions,
      standardTermsDocumentReferences: parentValue
    };
  }
);

const yupStDrArray = memoizeSchemaWithContext(
  yup //
    .array<StandardTermsDocumentReferenceModel, TermsAndConditionsContext>()
    .defined()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of(yupStDrItem),
  (parentContext: TermsAndConditionsContext): TermsAndConditionsContext => parentContext
);

interface TermsAndConditionsContext {
  termsAndConditions: Mortgage2_20_1Model['termsAndConditions'];
}
// path: termsAndConditions
const yupTermsAndConditions = memoizeSchemaWithContext(
  yup //
    .object<MortgageTermsAndConditionsModel, TermsAndConditionsContext>({
      preRegisteredStandardTermsDocumentReferences: yup //
        .array<string>()
        .defined()
        .of(yup.string().defined()),
      // at least one of these three (standardTermsDocumentReferences, additionalTermsAndConditions, termsAndConditionsAttachment) must be populated
      standardTermsDocumentReferences: yupStDrArray,
      additionalTermsAndConditions: yup
        .string()
        .defined()
        .default('')
        .trim()
        .typeError(msg.INVALID_VALUE)
        .max(
          ADDITIONAL_TERMS_AND_CONDITIONS_MAX_CHARACTER_COUNT,
          ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${value.length}/${max} characters (including spaces and formatting)`
        )
        .testContextualRule({
          uniqueTestName: '"additionalTermsAndConditions" contextual validation rule #1',
          onlyIf: (parent: MortgageTermsAndConditionsModel /*, context: EmptyContext*/) => {
            return REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS(parent);
          },
          requirement: (parent: MortgageTermsAndConditionsModel /*, context: EmptyContext*/) => {
            // no need to use trim here because we yup.trim() was applied
            return parent.additionalTermsAndConditions !== '';
          },
          message: msg.REQUIRED
        }),
      termsAndConditionsAttachment: yup
        .array<DocumentAttachmentModel, EmptyContext>()
        .defined()
        .max(1, msg.MAX_ITEMS(1))
        .of(
          yup
            .object<DocumentAttachmentModel, EmptyContext>({
              fileName: yup.string().default('').trim().typeError(msg.INVALID_VALUE),
              fileType: yup.string().default('').trim().typeError(msg.INVALID_VALUE),
              id: yup.string().default('').trim().typeError(msg.INVALID_VALUE)
            })
            .defined()
        )
        .testContextualRule({
          uniqueTestName: '"termsAndConditionsAttachment" contextual validation rule #1',
          onlyIf: (parent: MortgageTermsAndConditionsModel /*, context: EmptyContext*/) => REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT(parent),
          requirement: (parent: MortgageTermsAndConditionsModel /*, context: EmptyContext*/) => parent.termsAndConditionsAttachment.length > 0,
          message: msg.REQUIRED
        })
      // .test(
      //   testContextualRule({
      //     uniqueTestName: '"termsAndConditionsAttachment" contextual validation rule #1',
      //     onlyIf: REQUIRED_CHECK_TERMS_AND_CONDITIONS$TERMS_AND_CONDITIONS_ATTACHMENT,
      //     requirement: (value: DocumentAttachmentModel[]) => value.length > 0,
      //     message: msg.REQUIRED
      //   })
      // )
    })
    .defined()
    .log(),
  ({ termsAndConditions }: Mortgage2_20_1Model): TermsAndConditionsContext => {
    return {
      termsAndConditions
    };
  }
);

export default yupTermsAndConditions;

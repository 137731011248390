import * as React from 'react';

import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

import Tooltip from 'src/components/tooltip';
import { useStyles } from './styles';

interface Props extends MuiTooltipProps {
  showTooltip: boolean;
}

function ConditionalTooltip({ showTooltip, placement = 'top', title, className, children }: Props) {
  const classes = useStyles();
  if (showTooltip) {
    return (
      <Tooltip
        //
        title={title}
        placement={placement}
        classes={{ tooltip: classes.tooltip }}
      >
        <span className={className}>{children}</span>
      </Tooltip>
    );
  }

  return children;
}

export default React.memo(ConditionalTooltip);

import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';
import { AnimationVariant, ProgressNotificationPanel } from '@sympli/components-library/progress-notification-panel';
import { StatusChip, StatusChipVariant } from '@sympli/components-library/status-chip';

import { VIEWPORT_FOR_SCREEN_HEIGHT_800 } from 'src/components/layout/constants';
import { IconStar } from 'src/components/layout/v2/Icons';

export enum WorkspaceProgressPanelNotificationTypeEnum {
  /**
   * Preview: ![](__preview/lodgement_is_queued.png)
   */
  LodgementInQueue,
  /**
   * Preview: ![](__preview/lodgement_in_progress.png)
   */
  LodgementInProgress,
  /**
   * Preview: ![](__preview/lodged.png)
   */
  Lodged,
  /**
   * Preview: ![](__preview/lodgement_error.png)
   */
  LodgementError,
  /**
   * Preview: ![](__preview/lodgement_completed.png)
   */
  LodgementCompleted,
  /**
   * Preview: ![](__preview/lodgement_verification_in_progress.png)
   */
  LodgementVerificationInProgress,
  /**
   * Preview: ![](__preview/lodgement_paused.png)
   */
  LodgementPaused
}

export function resolveWorkspaceProgressNotificationPanelTypeForPLC(props: {
  //
  workspaceStatusId: WorkspaceStatusEnum;
  lodgementCaseStatusId?: LodgementCaseStatusEnum;
  allDocumentsSigned?: boolean;
}): WorkspaceProgressPanelNotificationTypeEnum | undefined {
  // If workspace status is 'LodgementInQueue', it doesn't mean lodgement yet
  // it's a transition status between OnSchedule and Lodging. When the scheduled
  // lodgement is kicked to lodgement processing queue, workspace status will
  // go back to 'OnSchedule' and LodgementCaseStatus will be 'LodgementRequested'
  // and the rest is the same as before

  if (props.workspaceStatusId === WorkspaceStatusEnum.LodgementInQueue) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementInQueue;
  }

  if (props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementRequested) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress;
  }

  if (props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementSuccess) {
    return WorkspaceProgressPanelNotificationTypeEnum.Lodged;
  }

  if (props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementError) {
    // in case of business logic error,
    // documents will get unsigned (meaning something is wrong in our documents)
    // a new task should be created by system
    // no panel will be displayed
    return undefined;
  }

  if (
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementTechnicalError || //
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementConnectionError
  ) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused;
  }

  if (props.lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementVerificationRequested) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress;
  }

  if (
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.Registered || //
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.Rejected || //
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.Unnecessary || //
    props.lodgementCaseStatusId === LodgementCaseStatusEnum.Withdrawn
  ) {
    return WorkspaceProgressPanelNotificationTypeEnum.LodgementCompleted;
  }
}

function notificationTypeToPanelProps(
  //
  notificationType: WorkspaceProgressPanelNotificationTypeEnum
  // templateArgs: Partial<{
  //   documentName: string;
  //   lodgementCaseName: string;
  // }> = {}
): {
  animationVariant?: AnimationVariant;
  title: {
    variant?: StatusChipVariant;
    children: React.ReactNode;
  };
  description: React.ReactNode;
} {
  switch (notificationType) {
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress:
      return {
        animationVariant: undefined,
        title: {
          variant: 'yellow',
          children: 'Lodgement Verification In Progress'
        },
        description: (
          <div>
            The verification of the <b>Lodgement Case</b> is with the <b>Land Registry</b>.<br />
            This might take a <b>few seconds</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementInQueue:
      return {
        animationVariant: 'rollover',
        title: {
          variant: 'light-green',
          children: 'Lodgement is Queued'
        },
        description: (
          <div>
            This workspace was submitted for lodgement <b>outside of Land Registry business hours</b>. This has been queued to be lodged at the next available time.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress:
      return {
        animationVariant: 'in-progress',
        title: {
          variant: 'light-green',
          children: 'Lodgement In Progress'
        },
        description: (
          <div>
            The lodgement with the Land Registry <b>has now started</b>. This might take a <b>few seconds</b>.<br />
            Not long now!
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.Lodged:
      return {
        animationVariant: 'lodged',
        title: {
          variant: 'light-green',
          children: 'Lodged'
        },
        description: (
          <div>
            The lodgement has <b>been accepted</b> by the Land Registry. They are presently <b>processing your documents</b>.
          </div>
        )
      };
    case WorkspaceProgressPanelNotificationTypeEnum.LodgementError:
      return {
        animationVariant: 'error',
        title: {
          variant: 'red',
          children: 'Lodgement Unsuccessful'
        },
        description: (
          <div>
            <b>Something went wrong.</b>
            <br />
            This workspace has <b>attempted to lodge but was unsuccessful</b>.
          </div>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused:
      return {
        animationVariant: 'paused',
        title: {
          variant: 'red',
          children: 'Lodgement Paused'
        },
        description: (
          <div>
            The document in this workspace has attempted to lodge, however, it has <b>encountered technical issues.</b>
            <br />
            Please <b>lodge the workspace again</b>.
            <br />
            Alternatively, please call <b>Sympli Customer Support</b> for <b>help</b>.
          </div>
        )
      };

    case WorkspaceProgressPanelNotificationTypeEnum.LodgementCompleted:
      return {
        animationVariant: 'success',
        title: {
          variant: 'light-green',
          children: (
            <>
              <span className="pr-2">Completed </span>
              <IconStar />
            </>
          )
        },
        description: (
          <>
            <div>
              The workspace has now <b>been completed</b>! For further information regarding the <b>registration</b> please refer to <b>individual documents status</b>(es).
            </div>
            <div className="text-sm italic text-[color:var(--neutral-500)]">
              Note | <b>90 days</b> from workspace completion the system <b>will archive this workspace</b> The system is archiving so users are able to search faster.
            </div>
          </>
        )
      };
    default:
      throw new Error(`Unknown notification type: ${notificationType}`);
  }
}

interface Props {
  notificationType: WorkspaceProgressPanelNotificationTypeEnum;
  className?: string;
  // template args used for lodgement in progress and lodgement verification in progress
  // documentName?: string;
  // lodgementCaseName?: string;
}

export function WorkspaceProgressNotificationPanel(props: Props) {
  const {
    //
    animationVariant,
    title,
    description
  } = notificationTypeToPanelProps(
    props.notificationType //
    //,{
    //   documentName: props.documentName,
    //   lodgementCaseName: props.lodgementCaseName
    // }
  );
  return (
    <ProgressNotificationPanel //
      className={props.className}
      animationVariant={animationVariant}
    >
      {title.variant ? (
        <StatusChip //
          variant={title.variant}
        >
          {title.children}
        </StatusChip>
      ) : (
        <div className="text-sm font-bold not-italic leading-5 text-[color:var(--neutral-700)]">{title.children}</div>
      )}
      {description}
    </ProgressNotificationPanel>
  );
}

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      left: -32,
      // this needs to ba aligned with MuiTab settings in our ui-framework
      top: -28,
      [`@media (max-height: ${VIEWPORT_FOR_SCREEN_HEIGHT_800}px)`]: {
        top: -8
      }
    }
  }),
  {
    name: 'WorkspaceProgressNotificationPanelBackground'
  }
);

export function WorkspaceProgressNotificationPanelBackground({
  //
  notificationType,
  className
}: {
  //
  notificationType: WorkspaceProgressPanelNotificationTypeEnum;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        //
        'w-[calc(100vw_-_calc(var(--small-content-left-panel)_+_calc(100vw_-_var(--small-content))_/_2)_-_19px)]',
        'medium-screen:w-[calc(100vw_-_calc(var(--medium-content-left-panel)_+_calc(100vw_-_var(--medium-content))_/_2)_-_19px)]',
        'large-screen:w-[calc(100vw_-_calc(var(--large-content-left-panel)_+_calc(100vw_-_var(--large-content))_/_2)_-_19px)]',
        'h-[144px]',
        classes.root,
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementInQueue && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementInProgress && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementVerificationInProgress && 'bg-[var(--sunny-day-translucent)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.Lodged && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementError && 'bg-[var(--watermelon-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementCompleted && 'bg-[var(--greek-waters-lite)]',
        notificationType === WorkspaceProgressPanelNotificationTypeEnum.LodgementPaused && 'bg-[var(--watermelon-lite)]',
        className
      )}
    ></div>
  );
}

import * as React from 'react';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { useFeatureFlag } from 'src/hooks';
import DirectionForm, { Props } from './DirectionForm';

function DirectionFormContainer(props: Omit<Props, 'isFssLineItemSaveEnabled'>) {
  const isFssLineItemSaveEnabled = useFeatureFlag(FeatureToggleEnum.fssLineItemSaveEnabled);

  return <DirectionForm {...props} isFssLineItemSaveEnabled={isFssLineItemSaveEnabled} />;
}

export default DirectionFormContainer;

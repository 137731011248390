import * as React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';

import logo from 'src/assets/logo-sympli.svg';
import styles, { ClassKeys } from './styles';

class LoadingProfile extends React.PureComponent<WithStyles<ClassKeys>, { completed: number }> {
  public readonly state = { completed: 0 };
  private timer: NodeJS.Timer;

  public componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  public componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  render() {
    const { classes } = this.props;
    const { completed } = this.state;

    return (
      <FlexLayout justifyContent="space-around" alignItems="center" fullHeight>
        <Paper className={classes.paper} elevation={0}>
          <img className={classes.largeLogo} src={logo} alt="logo-sympli" />
          <LinearProgress className={classes.progressBar} variant="determinate" value={completed} />
          <Typography variant="subtitle1" className={classes.loadingText}>
            Loading Sympli
          </Typography>
        </Paper>
      </FlexLayout>
    );
  }

  private progress = () => {
    const { completed } = this.state;
    const diff = Math.random() * 40 + 10;
    this.setState({ completed: completed + diff }, () => {
      if (this.state.completed >= 100) {
        this.timer && clearInterval(this.timer);
      }
    });
  };
}

export default withStyles(styles)(LoadingProfile);

import * as React from 'react';

import { DropResult } from 'react-beautiful-dnd';
import { batch, useDispatch } from 'react-redux';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import Logger, { BusinessLogicError, PageActionEnum } from '@sympli/ui-logger';

import { actionCreateGlobalErrorMessage } from 'src/actions/globalErrors';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { actionFetchDocumentDetail } from 'src/containers/documents/actions';
import { DocumentPageRouteParams, SaveDocumentFormRequest } from 'src/containers/documents/models';
import { actionFetchWorkspaceDocuments, actionUpdateWorkspaceDocuments } from 'src/containers/workspace/shared/detail/actions';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import { DocumentListAction, DocumentListModel } from '../../models';
import { updateDocumentOrders } from './api';
import DocumentListReorder from './DocumentListReorder';
import { reorder } from './helpers';
import { DocumentOrder, UpdateDocumentOrdersRequestModel } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  workspaceId: string;
  participantId: string;
  // additional workspace info
  workspaceTypeId?: WorkspaceTypeEnum;
  // other
  items: DocumentListModel[];
  onCancel(): void;
  onModeChange(mode: DocumentListAction): void;
}

function DocumentListReorderContainer({
  //
  workspaceId,
  participantId,
  workspaceTypeId,
  items,
  onCancel,
  onModeChange
}: Props) {
  const [orderedItems, setOrderedItems] = React.useState<DocumentListModel[]>(items);
  const [isSaving, setIsSaving] = React.useState(false);
  const dispatch = useSafeDispatch(useDispatch());
  const queryParams = useRouterParams<DocumentPageRouteParams>();

  React.useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  const handleOnNext = async () => {
    Logger.capturePageAction(PageActionEnum.FeatureTracking, {
      feature: 'reorder-document',
      logGroupId: 'workspace',
      workspaceId,
      participantId,
      workspaceType: WorkspaceTypeDisplayMapping[workspaceTypeId!]
    });

    const lodgementInstructionsDocumentId = items.find(item => item.isLI)?.documentId;
    if (!lodgementInstructionsDocumentId) {
      // this should never happen because each workspace now contains lodgement instructions document
      Logger.captureException(new BusinessLogicError('Lodgement instruction document was not found'));
      return;
    }

    // calculate order of the outstanding documents
    const orders: DocumentOrder[] = orderedItems
      .filter(doc => doc.documentId !== lodgementInstructionsDocumentId)
      .map((doc, index) => ({
        documentId: doc.documentId,
        order: index + 1
      }));
    const request: UpdateDocumentOrdersRequestModel = { orders };
    const query: SaveDocumentFormRequest = {
      workspaceId,
      participantId,
      documentId: lodgementInstructionsDocumentId
    };

    setIsSaving(true);
    try {
      await updateDocumentOrders(request, query);
      batch(() => {
        dispatch(
          actionOpenGlobalSimpleNotification({
            //
            message: 'Documents lodgement order updated',
            variant: 'new-success'
          })
        );
        // update data in reducer with this newly reordered list
        dispatch(actionUpdateWorkspaceDocuments({ items: orderedItems }));
        // refresh workspace documents list as LI document status could change
        // retaining actionUpdateWorkspaceDocuments above to retains visual ui order after dnd exit avoiding flicker
        dispatch(actionFetchWorkspaceDocuments.request({ workspaceId, participantId }));
        if (queryParams.documentId === lodgementInstructionsDocumentId) {
          dispatch(actionFetchDocumentDetail.request({ workspaceId, participantId, documentId: lodgementInstructionsDocumentId }));
        }
      });
    } catch (error) {
      dispatch(
        actionCreateGlobalErrorMessage({
          //
          ...error, // keep the original error and its message
          title: 'Unable to update document orders'
        })
      );
    }
    setIsSaving(false);
    onModeChange('default');
  };

  const handleOnDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const reorderedItems = reorder(orderedItems, source.index, destination.index);
    setOrderedItems(reorderedItems);
  };

  return (
    <DocumentListReorder //
      isSaving={isSaving}
      orderedItems={orderedItems}
      onDragEnd={handleOnDragEnd}
      onSave={handleOnNext}
      onCancel={onCancel}
    />
  );
}

export default React.memo(DocumentListReorderContainer);

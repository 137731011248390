import React from 'react';

import { FormikProps, getIn } from 'formik';
import _get from 'lodash-es/get';
import pluralize from 'pluralize';

import Section from '@sympli/ui-framework/components/form/base-components/section';

import MortgageePartyReadonlyArray from '../../components/mortgagee-party-readonly-array';
import { DischargeOfMortgage2_24_0Model } from '../../models';

interface Props {
  name: string;
  formikProps: FormikProps<DischargeOfMortgage2_24_0Model>;
  disabled: boolean;
  dialogPortalId: string;
}

const SectionMortgagees = ({ formikProps, disabled, name, dialogPortalId }: Props): JSX.Element => {
  const { errors, values } = formikProps;
  const errorMessage = getIn(errors, name);

  const items = _get(values, name) ?? [];
  return (
    <Section //
      title={pluralize('Mortgagee', items.length)}
      data-name={name}
      data-testid={name}
      error={errorMessage}
    >
      <MortgageePartyReadonlyArray //
        dialogPortalId={dialogPortalId}
        disabled={disabled}
        formikProps={formikProps}
        name={name}
      />
    </Section>
  );
};

export default SectionMortgagees;

import * as yup from 'yup';

import { PartyTypeEnum } from '@sympli/api-gateway/enums';

import { PartLandAffectedModel } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new';
import { resolveTitleReferencesValidationSchema } from '@sympli-mfe/document-forms-framework/components/sections/title-reference-new/validation-schema';
import { TenancyTypeEnum } from '@sympli-mfe/document-forms-framework/core/models';

import { MergeFailedReasonsEnum } from 'src/containers/documents/party-merge/model';

import { BaseTransmissionApplicationModel, TitleReferenceModel } from '../../models';

const yupTitleReferenceItemSchema = <TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification>() =>
  yup
    .object<TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>>({
      isSelected: yup.boolean().defined(),
      reference: yup.string(),
      proprietor: yup.mixed()
    })
    .defined();

export const yupTitleReferences = <TPartLandAffectedModel extends PartLandAffectedModel, TDocumentPartyJustification, TNameChange extends {}>(disableJointTenants: boolean) =>
  resolveTitleReferencesValidationSchema(yupTitleReferenceItemSchema<TPartLandAffectedModel, TDocumentPartyJustification>())
    .test(
      'Selected title with organisation proprietor validation',
      'The party type of proprietors is Organisation; such titles are not supported for the document. Please remove the mismatched titles.',
      function test(this: yup.TestContext<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { deceasedTenancyDetail, partyBook } = this.options.context!;
        if (deceasedTenancyDetail.proprietorGroups.length === 0) {
          return true;
        }

        return deceasedTenancyDetail.proprietorGroups.some(pg => pg.parties.every(p => partyBook.find(pb => pb.id === p.partyBookId)!.partyType === PartyTypeEnum.Individual));
      }
    )
    .test(
      'Multiple title selected: check for tenancy structure is same',
      'Party merge failed.',
      function test(this: yup.TestContext<BaseTransmissionApplicationModel<TPartLandAffectedModel, TDocumentPartyJustification, TNameChange>>) {
        const { mergeFailedReason } = this.options.context!;
        if (!mergeFailedReason) return true;

        if (mergeFailedReason === MergeFailedReasonsEnum.StructureNotSame)
          return this.createError({ message: 'The tenancy of the proprietors does not match; please remove the mismatched titles' });

        if (mergeFailedReason === MergeFailedReasonsEnum.PartyTypeMismatch)
          return this.createError({ message: 'The party types of the proprietors do not match; please remove the mismatched titles.' });

        if (mergeFailedReason === MergeFailedReasonsEnum.ShareFractionMismatch)
          return this.createError({ message: 'The share fraction of the proprietors does not match; please remove the mismatched titles.' });

        return false;
      }
    )
    .test(
      'Selected title must not be Life Estate or Multi tenancy type',
      'The tenancy of proprietors is Multi or Life Estate; such title is not supported for the document. Please remove the mismatched titles.',
      function test(this: yup.TestContext, value: TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>[]) {
        const titleReferences: TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>[] = value ?? [];
        const selectedTitles = titleReferences.filter(({ isSelected }) => isSelected);
        return selectedTitles.every(({ proprietor }) => proprietor.tenancyType !== TenancyTypeEnum.LifeEstate && proprietor.tenancyType !== TenancyTypeEnum.Multi);
      }
    )
    .test(
      'Check for invalid joint tenancy type',
      'The tenancy of proprietors is Joint Tenants; such title is not supported for the document. Please remove the mismatched titles.',
      function test(this: yup.TestContext, titleReferences: TitleReferenceModel<TPartLandAffectedModel, TDocumentPartyJustification>[]) {
        if (!disableJointTenants) return true;

        return titleReferences.filter(({ isSelected }) => isSelected).every(({ proprietor }) => proprietor.tenancyType !== TenancyTypeEnum.JointTenants);
      }
    );

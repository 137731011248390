import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import { AllDirectionsState, useAllDirections } from 'src/containers/workspace/financial/all-directions/reducer';
import { SettlementDateDetailsState, useSettlementDateDetails } from 'src/containers/workspace/financial/settlement-date/reducers/settlementDetail';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useWorkspaceBasicInfo, WorkspaceBasicInfoState } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail, WorkspaceDetailState } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments, WorkspaceDocumentsState } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { useWorkspaceParticipants, WorkspaceParticipantsState } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useWorkspaceTasks, WorkspaceTasksState } from 'src/containers/workspace/shared/detail/reducers/workspaceTasks';
import { useSafeDispatch } from 'src/hooks';
import { actionFetchFinancialWorkspacesFeedDetailV2 } from './actions';
import { useFinancialWorkspacesFeedV2 } from './reducers/financialWorkspacesFeedV2';
import FinancialTableRowDetail from './table/FinancialTableRowDetail';
import { LastSystemActivityModel } from './table/models';

function FinancialWorkspaceRowContainer({
  //
  workspaceId,
  participantId
}: {
  workspaceId: string;
  participantId: string;
}) {
  const dispatch = useSafeDispatch(useDispatch());
  const { items, rowDetailIndex } = useFinancialWorkspacesFeedV2();
  const { userId } = useProfile().data!;
  const workspaceBasicInfoState: WorkspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceParticipantsState: WorkspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const workspaceDetailState: WorkspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState: WorkspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const workspaceTasksState: WorkspaceTasksState = useWorkspaceTasks(workspaceId, participantId);
  const allDirectionsState: AllDirectionsState = useAllDirections(workspaceId, participantId);
  const settlementDateDetailsState: SettlementDateDetailsState = useSettlementDateDetails(workspaceId, participantId);
  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const isFinancialBalanced: boolean | undefined = Number.isInteger(rowDetailIndex) ? items[rowDetailIndex!]?.isFinancialBalanced : undefined;
  const unreadMessageCount: number | undefined = Number.isInteger(rowDetailIndex) ? items[rowDetailIndex!]?.unreadMessagesCount : undefined;
  const lastSystemActivity: LastSystemActivityModel | undefined = Number.isInteger(rowDetailIndex) ? items[rowDetailIndex!]?.lastSystemActivity : undefined;

  useEffect(() => {
    dispatch(
      actionFetchFinancialWorkspacesFeedDetailV2.request({
        workspaceId,
        participantId
      })
    );
  }, [workspaceId, participantId, dispatch]);

  const linkTo: string = resolveWorkspaceDetailLink({
    workspaceId,
    participantId
  });

  return (
    <FinancialTableRowDetail //
      linkTo={linkTo}
      matterReference={currentWorkspaceParticipant?.reference}
      workspaceBasicInfoState={workspaceBasicInfoState}
      workspaceParticipantsState={workspaceParticipantsState}
      workspaceDetailState={workspaceDetailState}
      workspaceDocumentsState={workspaceDocumentsState}
      workspaceTasksState={workspaceTasksState}
      allDirectionsState={allDirectionsState}
      settlementDateDetailsState={settlementDateDetailsState}
      isFinancialBalanced={isFinancialBalanced}
      unreadMessageCount={unreadMessageCount}
      lastSystemActivity={lastSystemActivity}
      participantId={participantId}
      userId={userId}
    />
  );
}

export default React.memo(FinancialWorkspaceRowContainer);

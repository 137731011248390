import * as yup from 'yup';

import { isNotNullOrEmpty } from '@sympli-mfe/document-forms-framework/utils';
import { EmptyContext, memoizeSchemaWithContext } from '@sympli-mfe/document-forms-framework/validation';
import msg from '@sympli/ui-framework/utils/messages';

import {
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS,
  REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE
} from '../../isRequiredChecks';
import { DocumentAttachmentModel, Mortgage2_17_1Model, StandardTermsDealingNumbersModel, StandardTermsModel } from '../../models';

// this file was automatically generated from sections/validationSchema.ts.mustache
// path: termsAndConditions
const DOCUMENT_REFERENCE_CHARACTER_LENGTH = 9;
export const COVENANTS_MAX_CHARACTERS: number = 4000;

interface StDrItemContext {
  termsAndConditions: StandardTermsModel;
  standardTermsDocumentReferences: StandardTermsDealingNumbersModel[];
}

interface TermsAndConditionsContext {
  termsAndConditions: Mortgage2_17_1Model['termsAndConditions'];
}

const yupStDrItem = memoizeSchemaWithContext(
  yup
    .object<StandardTermsDealingNumbersModel, StDrItemContext>({
      termNumber: yup
        .string()
        .defined()
        .default('')
        .trim()
        .typeError(msg.INVALID_VALUE)
        .testContextualRule({
          message: msg.REQUIRED,
          uniqueTestName: '"standardTermsDocumentReferences.documentReference" required check',
          onlyIf: (parent: StandardTermsDealingNumbersModel, context: StDrItemContext) => {
            return REQUIRED_CHECK_TERMS_AND_CONDITIONS$STANDARD_TERMS_DOCUMENT_REFERENCES$DOCUMENT_REFERENCE(parent, context.termsAndConditions);
          },
          requirement: (parent: StandardTermsDealingNumbersModel /*, context: StDrItemContext*/) => {
            const result = isNotNullOrEmpty(parent.termNumber);
            return result;
          }
        })
        // we can't use .length rule automatically because this field can be null and length does not work with null
        .testContextualRule({
          message: msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(DOCUMENT_REFERENCE_CHARACTER_LENGTH),
          uniqueTestName: '"standardTermsDocumentReferences.documentReference" length check',
          onlyIf: (parent: StandardTermsDealingNumbersModel /*, context: StDrItemContext*/) => {
            return !!parent.termNumber;
          },
          requirement: (parent: StandardTermsDealingNumbersModel /*, context: StDrItemContext*/) => {
            return parent.termNumber?.length <= DOCUMENT_REFERENCE_CHARACTER_LENGTH;
          }
        })

        .testContextualRule({
          message: 'This document reference is already used',
          uniqueTestName: '"documentReference" unique among all standardTermsDocumentReferences',
          onlyIf: (parent: StandardTermsDealingNumbersModel, context: StDrItemContext) => {
            return !!parent.termNumber && context.standardTermsDocumentReferences.length > 0;
          },
          requirement: (parent: StandardTermsDealingNumbersModel, context: StDrItemContext) => {
            // find item with identical documentReference that is not the current item.
            const otherItems = context.standardTermsDocumentReferences.filter(item => item !== parent);
            if (!otherItems.length) {
              return true;
            }
            return !otherItems.some(item => {
              return item.termNumber === parent.termNumber;
            });
          }
        })
        .log()
    })
    .defined()
    .log(),
  (parentContext: TermsAndConditionsContext, parentValue: StandardTermsDealingNumbersModel[]): StDrItemContext => {
    return {
      termsAndConditions: parentContext.termsAndConditions,
      standardTermsDocumentReferences: parentValue
    };
  }
);

const yupStDrArray = memoizeSchemaWithContext(
  yup //
    .array<StandardTermsDealingNumbersModel, TermsAndConditionsContext>()
    .defined()
    .required(msg.REQUIRED)
    .min(1, msg.MIN_ITEMS(1))
    .max(20, msg.MAX_ITEMS(20))
    .of(yupStDrItem),
  (parentContext: TermsAndConditionsContext): TermsAndConditionsContext => parentContext
);

const yupTermsAndConditions = memoizeSchemaWithContext(
  yup //
    .object<StandardTermsModel, TermsAndConditionsContext>({
      preRegisteredStandardTermsDealingNumbers: yup.array<string>().defined().of(yup.string().defined()),
      // at least one of these three (standardTermsDocumentReferences, additionalTermsAndConditions, termsAndConditionsAttachment) must be populated
      standardTermsDealingNumbers: yupStDrArray,
      additionalCovenants: yup
        .string()
        .defined()
        .default('')
        .trim()
        .typeError(msg.INVALID_VALUE)
        .max(COVENANTS_MAX_CHARACTERS, ({ max, value }) => `${msg.LENGTH_MUST_BE_X_OR_LESS_CHARACTERS(max)}. ${value.length}/${max} characters (including spaces and formatting)`)
        .testContextualRule({
          uniqueTestName: '"additionalTermsAndConditions" contextual validation rule #1',
          onlyIf: (parent: StandardTermsModel /*, context: EmptyContext*/) => {
            return REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS(parent);
          },
          requirement: (parent: StandardTermsModel /*, context: EmptyContext*/) => {
            // no need to use trim here because we yup.trim() was applied
            return parent.additionalCovenants !== '';
          },
          message: msg.REQUIRED
        }),
      supportingDocuments: yup
        .array<DocumentAttachmentModel, EmptyContext>()
        .defined()
        .max(1, msg.MAX_ITEMS(1))
        .of(
          yup
            .object<DocumentAttachmentModel, EmptyContext>({
              fileName: yup.string().default('').trim().typeError(msg.INVALID_VALUE),
              fileType: yup.string().default('').trim().typeError(msg.INVALID_VALUE),
              id: yup.string().default('').trim().typeError(msg.INVALID_VALUE)
            })
            .defined()
        )
        .testContextualRule({
          uniqueTestName: '"termsAndConditionsAttachment" contextual validation rule #1',
          onlyIf: (parent: StandardTermsModel /*, context: EmptyContext*/) => REQUIRED_CHECK_TERMS_AND_CONDITIONS$ADDITIONAL_TERMS_AND_CONDITIONS(parent),
          requirement: (parent: StandardTermsModel /*, context: EmptyContext*/) => parent.supportingDocuments.length > 0,
          message: msg.REQUIRED
        })
    })
    .defined()
    .log(),
  ({ termsAndConditions }: Mortgage2_17_1Model): TermsAndConditionsContext => {
    return {
      termsAndConditions
    };
  }
);

export default yupTermsAndConditions;

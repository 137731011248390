import { hexToRgb, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    listItem: {},
    outerGutters: {
      marginLeft: -16,
      marginRight: -16,
      '& $listItem': {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    isDragging: {
      background: hexToRgb(theme.palette.primary.main).replace(')', ',0.2)').replace('rgb', 'rgba')
    },
    wizardStepper: {
      padding: 0
    }
  }),
  { name: 'DocumentListReorder' }
);

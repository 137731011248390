import { IConverter, IConverterContext } from '@sympli-mfe/document-forms-framework/core/converters';
import { applyDefaultMap, applyVisibilityFallbackMap, FallbackMap } from '@sympli-mfe/document-forms-framework/fallbacks/utils';

import { CtDirectionEnum, IssuingInstructionTypeEnum, PartyIssuedWithCoRDEnum } from './enums';
import { fallbackMap } from './fallback';
import { ApiLodgementInstructionsDocumentModel, LodgementInstructions_2_25_1 } from './models';

// this file was automatically generated from conversion.ts.mustache
class Converter implements IConverter<LodgementInstructions_2_25_1, ApiLodgementInstructionsDocumentModel> {
  constructor(readonly fallback: FallbackMap<ApiLodgementInstructionsDocumentModel> = fallbackMap) {}

  fromApiToFormModel(apiValues: ApiLodgementInstructionsDocumentModel, context: IConverterContext): LodgementInstructions_2_25_1 {
    let formValues = applyDefaultMap(apiValues, this.fallback);
    formValues.isResponsibleParticipant = formValues.responsibleParticipantId === context.currentParticipant?.id || false;
    return formValues;
  }
  fromFormToApiModel = convertFromFormToApiModel;
}

export function convertFromFormToApiModel(formModel: LodgementInstructions_2_25_1, _: ApiLodgementInstructionsDocumentModel): ApiLodgementInstructionsDocumentModel {
  formModel = applyVisibilityFallbackMap(formModel, fallbackMap);
  const { issuingInstructions: issuingInstructionsFormValue, ...rest } = formModel;

  if (issuingInstructionsFormValue?.editable) {
    switch (issuingInstructionsFormValue.issuingInstructionType) {
      case IssuingInstructionTypeEnum.RetainOrAcquireEctControl:
        issuingInstructionsFormValue.ctDirection = CtDirectionEnum.Electronic;
        issuingInstructionsFormValue.partyIssuedWithCoRD = PartyIssuedWithCoRDEnum.IssuingParty;
        break;
      case IssuingInstructionTypeEnum.TransferEctControlToRegistrarOfTitles:
        issuingInstructionsFormValue.ctDirection = undefined;
        issuingInstructionsFormValue.partyIssuedWithCoRD = PartyIssuedWithCoRDEnum.RegistrarOfTitles;
        issuingInstructionsFormValue.ctDirection = CtDirectionEnum.Electronic;
        break;
    }
  }

  return {
    ...rest,
    issuingInstructions: issuingInstructionsFormValue
  };
}

export { Converter };

export default new Converter();

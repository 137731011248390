import { ListItemProps as MuiListItemProps } from '@mui/material/ListItem';
import createStyles from '@mui/styles/createStyles';

import { pxToRem } from '@sympli/ui-framework/theme';

import { AvatarListItemProps } from '../avatar-list-item';
import { IconListItemProps } from './IconListItem';

type StyleProps = IconListItemProps & AvatarListItemProps & Omit<MuiListItemProps, 'button' | 'dense' | 'alignItems'>;

const styles = () =>
  createStyles({
    itemRoot: {
      '&$itemSelected': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white'
        }
      },
      '&$focusVisible': {
        background: 'rgba(0, 0, 0, 0.04)'
      }
    },
    itemSelected: {},
    focusVisible: {},
    textRoot: {
      margin: 0
    },
    textPrimary: {
      lineHeight: ({ secondary }: StyleProps) => (secondary ? pxToRem(20) : undefined), // same as body2_bold
      textDecoration: ({ linkTo, selected }: StyleProps) => (linkTo && !selected ? 'underline' : undefined),
      fontWeight: 700,
      letterSpacing: 0,
      marginRight: 50
    },
    textSecondary: {
      lineHeight: ({ primary, secondary }: StyleProps) => (primary && secondary ? 1 : undefined),
      fontSize: 13
    },
    iconRoot: {
      minWidth: 24
    },
    iconAlignItemsFlexStart: {
      marginTop: 4
    },
    nestedList: {
      paddingLeft: 0
    },
    alignCenter: {
      alignItems: 'center'
    }
  });
export type ClassKeys = typeof styles;
export default styles;

import { LodgementCaseStatusEnum, WorkspaceStatusEnum } from '@sympli/api-gateway/enums';

import { WorkspaceStatusColorVariantEnum } from 'src/components/workspace-status-rebuild/status-progress/components/models';

export const getWorkspaceStatusEnumMapping = (size: 'small' | 'medium' = 'small'): Record<WorkspaceStatusEnum, string> => ({
  // * Overwrite lodgement case variant
  [WorkspaceStatusEnum.Archived]: 'Archived',
  [WorkspaceStatusEnum.Abandoned]: 'Abandoned',

  // shared variant mapping
  [WorkspaceStatusEnum.Failed]: 'Failed',
  [WorkspaceStatusEnum.OnSchedule]: 'In progress',

  // this variant need to be updated as we do not save the At Risk as workspace variant,
  // keep it here as a backward compatible
  [WorkspaceStatusEnum.AtRisk]: 'At risk',

  // this is more for lodgement only ws,
  [WorkspaceStatusEnum.ReadyForLodge]: size === 'small' ? 'Ready' : 'Ready to lodge',

  // this is for the mapping for settlement, we keep it here for the existing data
  [WorkspaceStatusEnum.ReadyForSettlement]: 'Ready for settlement',
  [WorkspaceStatusEnum.SettlementInProgress]: 'Settlement in progress',
  [WorkspaceStatusEnum.SettlementIssue]: 'Settlement issue',
  [WorkspaceStatusEnum.LodgementInQueue]: 'Queued',

  [WorkspaceStatusEnum.Success]: 'Success',
  [WorkspaceStatusEnum.Finalised]: 'Finalised',
  [WorkspaceStatusEnum.Caution]: 'Caution',
  [WorkspaceStatusEnum.AwaitingInvitee]: 'Awaiting invitee',
  [WorkspaceStatusEnum.Withdrawn]: 'Withdrawn'
});

const WORKSPACE_FINANCIAL_STATUS_MAPPING: { [key in WorkspaceStatusEnum]?: { variant: WorkspaceStatusColorVariantEnum; title: string } } = {
  [WorkspaceStatusEnum.Archived]: {
    variant: WorkspaceStatusColorVariantEnum.Inactive, // gray
    title: 'archived'
  },
  [WorkspaceStatusEnum.Abandoned]: {
    variant: WorkspaceStatusColorVariantEnum.Inactive, // gray
    title: 'abandoned'
  },
  [WorkspaceStatusEnum.Failed]: {
    variant: WorkspaceStatusColorVariantEnum.Failure, // red
    title: 'in preparation'
  },
  [WorkspaceStatusEnum.OnSchedule]: {
    variant: WorkspaceStatusColorVariantEnum.Warning, // amber
    title: 'in preparation'
  },
  [WorkspaceStatusEnum.ReadyForSettlement]: {
    variant: WorkspaceStatusColorVariantEnum.Success, // green
    title: 'ready'
  },
  [WorkspaceStatusEnum.SettlementInProgress]: {
    variant: WorkspaceStatusColorVariantEnum.Success, // green
    title: 'settling'
  },
  [WorkspaceStatusEnum.Finalised]: {
    variant: WorkspaceStatusColorVariantEnum.Success, // green
    title: 'completed'
  },
  [WorkspaceStatusEnum.SettlementIssue]: {
    variant: WorkspaceStatusColorVariantEnum.Failure, // red
    title: 'settling'
  },
  [WorkspaceStatusEnum.Success]: {
    variant: WorkspaceStatusColorVariantEnum.Success, // green
    title: 'settled'
  },
  // [WorkspaceStatusEnum.AwaitingInvitee]: 'Awaiting invitee',
  [WorkspaceStatusEnum.Withdrawn]: {
    variant: WorkspaceStatusColorVariantEnum.Inactive, // gray
    title: 'withdrawn'
  }
  //   // this status need to be updated as we do not save the At Risk as workspace status,
  // // keep it here as a backward compatible
  // [WorkspaceStatusEnum.AtRisk]: 'At risk',
};

export const resolveFinancialWorkspaceStatus = (workspaceStatusId: WorkspaceStatusEnum): { variant: WorkspaceStatusColorVariantEnum; title: string } | null => {
  const mapping = WORKSPACE_FINANCIAL_STATUS_MAPPING[workspaceStatusId];

  if (!mapping) {
    return null;
  }
  const { variant, title } = mapping;

  return {
    variant,
    title
  };
};

export const workspaceStatusSteps: string[] = ['in preparation', 'ready', 'settling', 'settled'];

export const getLodgementCaseStatusEnumMapping = (size: 'small' | 'medium' = 'small'): Record<LodgementCaseStatusEnum, string> => ({
  [LodgementCaseStatusEnum.LodgementError]: size === 'small' ? 'Failed' : 'Lodgement failed',
  [LodgementCaseStatusEnum.LodgementConnectionError]: size === 'small' ? 'Ready' : 'Ready to lodge',
  [LodgementCaseStatusEnum.LodgementTechnicalError]: size === 'small' ? 'Ready' : 'Ready to lodge',

  [LodgementCaseStatusEnum.LodgementRequested]: 'Lodging',
  [LodgementCaseStatusEnum.LodgementSuccess]: 'Lodged',
  [LodgementCaseStatusEnum.Registered]: 'Registered',
  [LodgementCaseStatusEnum.Rejected]: 'Rejected',
  [LodgementCaseStatusEnum.Withdrawn]: 'Withdrawn',
  [LodgementCaseStatusEnum.Unnecessary]: 'Unnecessary',

  [LodgementCaseStatusEnum.LodgementVerificationSuccess]: 'In progress',
  [LodgementCaseStatusEnum.LodgementVerificationRequested]: 'In progress',
  [LodgementCaseStatusEnum.LodgementVerificationError]: 'In progress',
  [LodgementCaseStatusEnum.Unverified]: 'In progress',
  [LodgementCaseStatusEnum.AwaitingInvitee]: 'Awaiting invitee'
});

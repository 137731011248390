import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

// This is common workspace type shared by different jurisdiction
// and different workspace type (financial and non-financial)
// MR - moved
// export enum WorkspaceRoleEnum {
//   IncomingMortgagee = 0, ////// Role: Incoming Mortgagee   Type: Advance
//   DischargingMortgagee = 1, /// Role: Mortgagee on Title   Type: Discharge
//   Purchaser = 2, ////////////// Role: Incoming Proprietor  Type: Purchase
//   Vendor = 3, ///////////////// Role: Proprietor on Title  Type: Sale
//   DischargingCaveator = 4, //// Role: Caveator on Title    Type: Caveat
//   IncomingCaveator = 5, /////// Role: Incoming Caveator    Type: Caveat

//   ControllingParty = 6, /////// Role: Controlling Party
//   ResponsibleSubscriber = 7, // Role: Responsible Subscriber

//   Applicant = 8, ////////////// Role: Applicant
//   Lessee = 9, ///// Role: Lessee
//   Lessor = 10 ///// Role: Lessor
// }

// MR - moved
// export enum WorkspaceTypeEnum {
//   FinancialSettlement = 0,
//   RegistrationOnly = 1,
//   Refinance = 2
// }

export const WorkspaceTypeDisplayMapping: Record<WorkspaceTypeEnum, string> = {
  [WorkspaceTypeEnum.FinancialSettlement]: 'Financial',
  [WorkspaceTypeEnum.RegistrationOnly]: 'Standalone',
  [WorkspaceTypeEnum.Refinance]: 'Refinance'
};
